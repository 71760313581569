import React, { Fragment } from 'react';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  AccentHeadingLight,
  BigSection,
  FaqRow,
  HeaderContent,
  HeaderParagraph,
  LandingPageLayout,
  NumberedPoint,
  SectionHeading,
  SocialLinkIcon,
  SocialLinksContainer,
  SocialLinkShareLabel,
  StaticIconText,
  WhyReasonsContainer,
} from 'src/components/landingPages/common/design/PageContent';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import { Heading } from 'src/design/styleguide/Headings';
import Header from 'src/design/components/header/Header';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import {
  aboutUrl,
  brickxMortgageLandingPageUrl,
  getFullImageAssetUrl,
} from 'src/utils/pageUrls';
import { scrollToId } from 'src/browser/window';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import { connect } from 'react-redux';
import Footer from 'src/components/footer/Footer';
import Text from 'src/design/styleguide/text/Text';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';

const BrickXMortgagesLandingPageHeader = connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(({ menuOpen, toggleMenu, closeMenu }) => (
  <Header
    title="Mortgage Trusts"
    logo={<BrickxLogoDark />}
    mobileMenuOpen={menuOpen}
    onLogoClick={closeMenu}
    onHamburgerIconClick={toggleMenu}
  >
    <TopLevelMenuItem
      onClick={() => scrollToId('what-it-is')}
      text="What is BrickX Mortgage Trusts?"
    />
    <TopLevelMenuItem
      onClick={() => scrollToId('why')}
      text="Why BrickX Mortgage Trusts?"
    />
    <TopLevelMenuItem onClick={() => scrollToId('faqs')} text="FAQs" />
    <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal />
  </Header>
));

const BrickXMortgagesLandingPageBody = () => {
  const pagePublicLink = `https://www.brickx.com${brickxMortgageLandingPageUrl()}`;
  return (
    <Fragment>
      <ContentBlock
        overlayOpacity="0.8"
        overlayColor="charcoal"
        backgroundImage={getFullImageAssetUrl('/bg/brickx-mortgage-trusts.jpg')}
      >
        <ContentSection as="section">
          <ContentContainer topPadding="x-large" bottomPadding="x-large">
            <HeaderContent>
              <Heading align="left">
                <AccentHeadingLight>
                  Introducing
                  <br />
                  <strong>BrickX Mortgage Trusts</strong>
                </AccentHeadingLight>
              </Heading>
              <Spacing top="normal">
                <HeaderParagraph>
                  <Text color="light">
                    Mortgage Trust syndications are now available!
                  </Text>
                </HeaderParagraph>
              </Spacing>
              <Spacing bottom="normal">
                <HeaderParagraph>
                  <Text color="light">
                    BrickX members can now diversify their property related
                    investments further via the BrickX Platform.
                  </Text>
                </HeaderParagraph>
              </Spacing>
              <Spacing bottom="normal">
                <SocialLinksContainer>
                  <SocialLinkShareLabel>
                    <Text color="light">
                      <strong>Share On: </strong>
                    </Text>
                  </SocialLinkShareLabel>
                  <SocialLinkIcon link={pagePublicLink} social="facebook" />
                  <SocialLinkIcon link={pagePublicLink} social="linkedin" />
                  <SocialLinkIcon link={pagePublicLink} social="twitter" />
                  <SocialLinkIcon link={pagePublicLink} social="whatsapp" />
                  <SocialLinkIcon
                    link={pagePublicLink}
                    social="email"
                    icon="envelope"
                  />
                </SocialLinksContainer>
              </Spacing>
            </HeaderContent>
          </ContentContainer>
        </ContentSection>
      </ContentBlock>
      <BigSection
        id="what-it-is"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="white"
      >
        <ContentSection>
          <SectionHeading>What is a BrickX Mortgage Trust?</SectionHeading>
        </ContentSection>
        <Spacing top="large">
          <ContentContainer className={ListItemNumber.counterResetClass}>
            <NumberedPoint
              colorCode="darkblue"
              heading="The Investment"
              text="BrickX Mortgage Trust investments enable BrickX members to invest in either individual or pooled 1st mortgage loan investments."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="The Loan Security"
              text="Mortgage Trusts are secured by 1st mortgages against approved residential and commercial real estate assets, with security documentation held by a third party Custodian for investor safety."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="The Interest Income"
              text="Interest Income net of costs are paid monthly along with all other distributing BrickX Trusts."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="The Paperwork"
              text="The process to invest and paperwork is the same as that of investing in other BrickX Trusts."
            />
          </ContentContainer>
        </Spacing>
      </BigSection>

      <BigSection
        id="why"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="lightgrey"
      >
        <ContentSection>
          <SectionHeading>Why BrickX Mortgage Trusts?</SectionHeading>
        </ContentSection>
        <WhyReasonsContainer>
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-control-decision.svg"
            title="Control your purchase decision"
            text="See something you like and want to act – go ahead you now can."
          />
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-private-syndicate.svg"
            title="Diversify your BrickX Portfolio"
            text="You can diversify your BrickX portfolio with both equity and debt focused BrickX strategies."
          />
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-ease-and-efficient.svg"
            title="Ease and Efficiency"
            text="BrickX’s investment and credit assessment teams will handle the identification, processing and loan management responsibilities."
          />
        </WhyReasonsContainer>
      </BigSection>
      <BigSection id="faqs" width="large">
        <SectionHeading>FAQs</SectionHeading>
        <hr />
        <FaqRow>
          <Text className="question">Who can apply?</Text>
          <Text className="answer">
            Any Australian tax resident that are over the age of 18.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            How is a mortgage trust loan asset approved for funding?
          </Text>
          <Text className="answer">
            Loan funding applications are approved subject to a rigorous credit
            assessment process led by the BrickX Investment and Credit
            Assessment teams.
            <br />
            For more information please reach out to the BrickX team.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            What types of property can be used as security?
          </Text>
          <Text className="answer">
            Mortgage Trusts will typically restrict acceptable real estate
            assets to residential and commercial assets. Various factors in
            addition to this are assessed including though not limited to:
            <ul>
              <li>who the Borrower is;</li>
              <li>the Borrower’s capacity to service the loan;</li>
              <li>
                the Loan to Value Ratio (LVR) and additional capital position of
                the Borrower; and,
              </li>
              <li>
                whether the conditions of the financing agreement suit the
                strategic profile of the specific BrickX Mortgage Trust making
                the loan advance.
              </li>
            </ul>
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            How are loan assets identified and selected?
          </Text>
          <Text className="answer">
            The BrickX Investment team will review both direct applications and
            externally referred applications for funding.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            What happens at the end of the trust term?
          </Text>
          <Text className="answer">
            The initial trust term can be up to 10 years.
            <br />
            At the end of a trust term, the standard BrickX processes will kick
            in:
            <br />A member vote will be managed where you and your syndicate
            will vote on whether you want to:
            <ul>
              <li>Wind up the loan portfolio;</li>
              <li>Extend the term.</li>
            </ul>
            Mortgage Trusts will typically also allow for redemptions (returns
            of capital) from time to time to facilitate optimum use of capital
            for the respective BrickX Mortgage Trust
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What happens if a Borrower defaults?</Text>
          <Text className="answer">
            Should any potential default occur, the Loan facility documentation
            put in place will outline the various remediation and debt recovery
            processes that will be enacted by BrickX.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What are the costs?</Text>
          <Text className="answer">
            The costs specific to each Mortgage Trust are outlined in the
            respective Additional Disclosure Documents.
          </Text>
        </FaqRow>
        <hr />
      </BigSection>
    </Fragment>
  );
};

const BrickXMortgagesLandingPage = () => {
  return (
    <LandingPageLayout>
      <BrickXMortgagesLandingPageHeader />
      <BrickXMortgagesLandingPageBody />
      <Footer />
    </LandingPageLayout>
  );
};

export default BrickXMortgagesLandingPage;

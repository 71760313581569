// import classNames from 'classnames';

// import { Heading } from 'src/design/components/heading/Headings';
import HeroVideoSection from 'src/components/landingPages/smartInvest/heroVideoSection/HeroVideoSection';
import TestimonialsCarousel from 'src/components/landingPages/common/testimonialsCarousel/TestimonialsCarousel';
import FeaturedPropertiesCarousel from 'src/components/landingPages/common/featuredPropertiesCarousel/FeaturedPropertiesCarousel';
import PinkSpacerBubble from 'src/components/landingPages/smartInvest/pinkSpacerBubble/PinkSpacerBubble';
import InvestGrowWithdraw from 'src/components/landingPages/smartInvest/investGrowWithdraw/InvestGrowWithdraw';
import Announcements from 'src/components/landingPages/smartInvest/announcements/Announcements';
import MoreOnSmartInvest from 'src/components/landingPages/smartInvest/moreOnSmartInvest/MoreOnSmartInvest';
// import PropertyPerformanceChart from 'src/components/landingPages/common/propertyPerformanceCharting/PropertyPerformanceCharting';
import SubFooter from 'src/components/subFooter/SubFooter';
import SmartInvestMeta from 'src/components/meta/SmartInvestMeta';

import styles from 'src/components/landingPages/smartInvest/SmartInvest.mscss';

const SmartInvest = () => {
  return (
    <div
      data-test-reference="smart-invest"
      className={styles.smartInvestContainer}
    >
      <SmartInvestMeta />
      <HeroVideoSection />
      {/* <section className={styles.buildHomeDepositFaster}>
        <Heading align="center"><h2>Could investing in property help you build your home deposit faster?</h2></Heading>
        <div className={classNames(styles.innerContainer)}>
          <PropertyPerformanceChart/>
        </div>
      </section> */}
      <InvestGrowWithdraw />
      <PinkSpacerBubble />
      <TestimonialsCarousel
        title="Hear real stories from Brick Owners"
        className={styles.testimonialsArea}
      />
      <Announcements />
      <FeaturedPropertiesCarousel />
      <MoreOnSmartInvest />
      <SubFooter />
    </div>
  );
};

export default SmartInvest;

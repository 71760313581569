import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridUnit,
  standardBorderRadius,
} from 'src/design/styleguide/common/measurements';
import { standardTransition } from 'src/design/styleguide/common/animations';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import Paragraph from 'src/design/styleguide/Paragraph';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import uploadFile from 'scripts/services/request/uploadFile';
import withState from 'src/decorators/withState';

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const FileUploadInput = styled.input.attrs(testRefToDataTestReference)`
  height: 0;
  width: 1px;
  opacity: 0;
`;

const ButtonAndNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CtaButton = styled(SecondaryButton).attrs({
  forwardedAs: 'label',
  color: SecondaryButton.colors.PRIMARY,
})`
  flex: 1 0 auto;
`;

const ProgressBarContainer = styled.div`
  border: 1px solid lightgrey;
  padding: ${gridUnit}px;
  height: ${gridUnit * 6}px;
  border-radius: ${standardBorderRadius};
`;

const ProgressBarIndicator = styled.div.attrs((props) => ({
  style: { width: `${props.value * 100}%` },
  'data-test-reference': 'progress-bar',
}))`
  height: 100%;
  background: ${getThemeColor('primary')};
  transition: width ${standardTransition};
`;

const ProgressBar = ({ className, value }) => (
  <ProgressBarContainer className={className}>
    <ProgressBarIndicator value={value} />
  </ProgressBarContainer>
);

const FileName = styled(Paragraph)`
  word-break: break-all;
`;

const FileInfoContainer = styled.div`
  flex: 1 1 100%;
`;

const FileUpload = withState({
  mapPropsToInitialState: () => ({
    fileName: '',
    uploading: false,
    uploadProgress: 0,
    errorMessage: '',
  }),
  Component: ({
    className,
    substituteTestRef,
    ctaText,
    onChange = () => {},
    setState,
    state: { fileName, uploading, uploadProgress, errorMessage },
    endpoint,
    ...props
  }) => {
    const elementId = `${Math.round(Math.random() * 1000000)}`;

    const clear = () => {
      setState({ fileName: undefined, errorMessage: '' });
      onChange(false);
    };

    const upload = (file) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        setState({
          fileName: '',
          errorMessage: `File size exceeds the ${MAX_FILE_SIZE_MB} MB limit.`,
        });
        return;
      }

      setState({
        uploadProgress: 0,
        fileName: undefined,
        uploading: true,
        errorMessage: '',
      });
      onChange(false);
      // encode file to base64 string and prepare payload
      const r = new FileReader();
      r.readAsDataURL(file);
      r.onloadend = (event) => {
        const filebase64 = event.target.result.split(',')[1];
        const filename = file.name;
        const payload = {
          filebase64,
          filename,
        };
        uploadFile(endpoint, payload, {
          onProgress: (pe) =>
            setState({ uploadProgress: pe.loaded / pe.total }),
        })
          .then(() => {
            setState({
              uploading: false,
              fileName: file.name,
            });
            onChange(file);
          })
          .catch((error) => {
            setState({
              uploading: false,
              errorMessage: 'Upload failed. Please try again.',
            });
            console.error('Upload error:', error);
          });
      };
    };

    return (
      <div className={className}>
        <FileUploadInput
          {...props}
          id={elementId}
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            if (!file) return clear();
            upload(file);
          }}
          testRef="file-input"
        />

        <ButtonAndNameContainer>
          <CtaButton htmlFor={elementId} testRef={substituteTestRef}>
            {ctaText}
          </CtaButton>
          <Spacing left="normal">
            {(uploading || fileName || errorMessage) && (
              <FileInfoContainer>
                {uploading && <ProgressBar value={uploadProgress} />}
                {fileName && <FileName>{fileName}</FileName>}
                {errorMessage && (
                  <Paragraph
                    style={{
                      color: '#d00',
                      fontWeight: 500,
                      marginBottom: '10px',
                    }}
                  >
                    {errorMessage}
                  </Paragraph>
                )}
              </FileInfoContainer>
            )}
          </Spacing>
        </ButtonAndNameContainer>
      </div>
    );
  },
});

export default FileUpload;

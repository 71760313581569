import React, { Component } from 'react';
import PropTypes from 'prop-types';import {
  property as propertyPropType,
  account as accountPropType,
  user as userPropType,
  tradeProposal as tradeProposalPropType,
  orderAggregated as orderPropType,
  purchaseProposalForm as purchaseProposalFormPropType,
} from 'scripts/constants/PropTypes';
import { connect } from 'react-redux';
import {
  fetchPurchaseProposal,
  confirmPurchase,
  purchaseDone,
  clearOrders as clearOrdersAction,
} from 'scripts/redux/actions/market';
import { tradeProposalSelector } from 'scripts/redux/selectors/market';
import DynamicNumber from 'react-dynamic-number';
import DepositLink from 'scripts/components/shared/DepositLink';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import { validateTradeProposalQuantity } from 'scripts/utilities/tradeHelper';
import { checkUserWithTradePermission } from 'scripts/utilities/userAccountHelper';
import {
  brickPrice,
  percentDecimal,
  brick,
} from 'scripts/utilities/formatters';
import Trade from 'scripts/constants/Trade';
import Numbers from 'scripts/constants/Numbers';
import Constants from 'scripts/constants/Constants';
import { renderCode } from 'src/settings/properties';

const maxNumOfBricksPerProperty = (property) => property.financials.numberBricks * property.maxOwnership / 100;
const maxNumOfBricksPerPropertyRatio = (property) => `${property.maxOwnership}%`;

const mapStateToProps = (state) => ({
  ...tradeProposalSelector(state),
});

@connect(mapStateToProps, {
  fetchPurchaseProposal,
  confirmPurchase,
  purchaseDone,
  clearOrders: clearOrdersAction,
})
export default class TradeProposalFormPreOrder extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    ordersBuy: PropTypes.arrayOf(orderPropType),
    getTotalBricksOwned: PropTypes.func,
    proposalForm: purchaseProposalFormPropType.isRequired,
    redirectTo: PropTypes.func.isRequired,
    tradeProposal: tradeProposalPropType,
    fetchPurchaseProposal: PropTypes.func.isRequired,
    confirmPurchase: PropTypes.func.isRequired,
    purchaseDone: PropTypes.func.isRequired,
    clearOrders: PropTypes.func,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    buyProposalParams: {},
    terms: {
      accepted: false,
      showErrorMessage: false,
    },
    loading: false,
    showDepositLink: false,
    numOfBricksAvailable: 0,
    numOfBricksAvailableLoaded: false,
    totalBricksOwned: 0,
    totalBricksOwnedLoaded: false,
  };

  componentDidMount() {
    const { fetchPurchaseProposal, proposalForm, property, clearOrders, account, ordersBuy } = this.props;
    fetchPurchaseProposal(property.propertyCode, 0);
    clearOrders();
    this._buildBuyProposalParams(proposalForm);
    this._updateTotalBricksOwned(account);
    this._updateNumOfAvailableBricks(ordersBuy);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this._updateTotalBricksOwned(this.props.account);
    }

    if (prevProps.ordersBuy !== this.props.ordersBuy) {
      this._updateNumOfAvailableBricks(this.props.ordersBuy);
    }

    const { property, tradeProposal, purchaseDone, redirectTo } = this.props;
    if (tradeProposal && tradeProposal.type === Trade.CONFIRMED) {
      purchaseDone(
        Trade.TRADE_TYPE.PRE_ORDER,
        tradeProposal.data,
        this.state.buyProposalParams.quantity
      );
      redirectTo(`/investments/${property.propertyCode}/preorder-success`);
    }
  }

  render() {
    const { property, tradeProposal, proposalForm } = this.props;

    var tradeProposalData = {
      proposedBuyOrders: [],
      propertyCode: property.propertyCode,
      quantity: proposalForm.quantity,
      quantityWillBeTransacted: proposalForm.quantity,
      basisPrice: 0,
      valueOfBricks: 0,
      transactionFee: 0,
      total: 0,
    };
    if (
      tradeProposal &&
      tradeProposal.data &&
      tradeProposal.data.propertyCode === property.propertyCode
    ) {
      tradeProposalData = tradeProposal.data;
    }

    return (
      <div className="place-order-panel">
        <div className="title hidden-xs">Place your order</div>
        <div className="white-boxes-container">
          <div className="container-pre-order-input col-no-padding-left">
            <div className="white-box">
              <ReactPlaceholder
                ready={
                  this.state.numOfBricksAvailableLoaded &&
                  this.state.totalBricksOwnedLoaded
                }
                customPlaceholder={<RectShape color={'#CCCCCD'} style={{ height: 20, width: '60%' }} />}
              >
                Quantity
                <DynamicNumber
                  id="id_buy_quantity"
                  className="quantity-to-be-purchased"
                  name="buy_quantity"
                  value={this.state.buyProposalParams.quantity}
                  integer={Numbers.TEN}
                  fraction={Numbers.ZERO}
                  positive={Constants.TRUE}
                  thousand={Constants.FALSE}
                  placeholder="0"
                  onChange={this._onQuantityChange}
                />
              </ReactPlaceholder>
            </div>
          </div>
          <div className="col-inline col-inline-3 hidden-xs">
            <div className="white-box">
              <div>Initial Brick Price</div>
              <div className="value basis-price">
                {tradeProposalData.basisPrice::brickPrice()}
              </div>
            </div>
          </div>
          <div className="col-inline col-inline-3 hidden-xs">
            <div className="white-box">
              <div>Pre-Order Total</div>
              <div className="value purchase-proposal-total">
                {tradeProposalData.total::brickPrice()}
              </div>
            </div>
          </div>
        </div>

        {proposalForm.hasError && (
          <div className="row">
            <div className="col-xs-12 error error-proposal">
              {proposalForm.errorMsg}
              {this.state.showDepositLink && (
                <span>
                  &nbsp;
                  <DepositLink id="deposit-link-purchase-panel" />
                </span>
              )}
            </div>
          </div>
        )}

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            Cost of Bricks
          </div>
          <div className="col-xs-5 col-no-padding-left col-right value-of-bricks">
            {tradeProposalData.valueOfBricks::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            Transaction fee (<span data-test-reference="transaction-fee">{tradeProposalData.transactionFeeRatio::percentDecimal()}</span>)
          </div>
          <div className="col-xs-5 col-no-padding-left col-right transaction-fee">
            {tradeProposalData.transactionFee::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            Pre-Order Total
          </div>
          <div className="col-xs-5 col-no-padding-left col-right purchase-proposal-total">
            {tradeProposalData.total::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <br />
        <div className="action-buttons">
          <div>
            <div>
              <input
                type="checkbox"
                checked={this.state.terms.accepted}
                id="consent"
                required
                onChange={this._onAcceptTermsChange}
              />
              <label htmlFor="consent">
                &nbsp;I confirm that I have accessed, read and understood the{' '}
                <a href="/pds" target="_blank">
                  Product Disclosure Statement, Financial Services Guide, Target
                  Market Determination and Additional Disclosure Document
                </a>{' '}
                and agree to this pre-order.
              </label>
              &nbsp;
            </div>
            {this.state.terms.showErrorMessage && (
              <span className="error accept-terms-warning">
                Check this box before confirming Pre-Order.
              </span>
            )}
          </div>
          <div>
            <button
              className="button light-blue-button confirm-pre-order-button action-full-button right-arrow-button"
              disabled={
                this.state.buyProposalParams.hasError ||
                this.state.terms.showErrorMessage ||
                this.state.loading
              }
              onClick={::this._confirmPurchase}
            >
              CONFIRM PRE-ORDER
            </button>
          </div>
        </div>
      </div>
    );
  }

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { proposalForm, property } = this.props;
    const { totalBricksOwned, numOfBricksAvailable } = this.state;
    var updatedProposalForm = proposalForm;

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity);
      const maxNumOfBricksCanBuy = maxNumOfBricksPerProperty(property) - totalBricksOwned;

      if (numOfBricksAvailable === 0) {
        updatedProposalForm.hasError = true;
        updatedProposalForm.errorMsg = `Sorry, we are unable to process your Pre-Order. All the Bricks for ${renderCode(property.propertyCode)} have been Pre-Ordered, and there are now 0 Bricks remaining.`;
      } else if (maxNumOfBricksCanBuy === 0) {
        updatedProposalForm.hasError = true;
        updatedProposalForm.errorMsg = `You have reached the maximum number of Bricks you can pre order in this property (${maxNumOfBricksPerProperty(property.propertyCode)::brick()}).`;
      } else if (updatedQuantityInt > numOfBricksAvailable) {
        updatedProposalForm.hasError = true;
        updatedProposalForm.errorMsg = `There ${numOfBricksAvailable === 1 ? 'is' : 'are'} only ${numOfBricksAvailable::brick()} available. Please enter ${numOfBricksAvailable::brick()} or less to continue your transaction.`;
      } else if (updatedQuantityInt > maxNumOfBricksCanBuy) {
        updatedProposalForm.hasError = true;
        updatedProposalForm.errorMsg = `You already reserved ${totalBricksOwned::brick()} in this property, and can only pre order ${maxNumOfBricksCanBuy} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio(property.propertyCode)}, or ${maxNumOfBricksPerProperty(property.propertyCode)::brick()} for this property. Please enter ${maxNumOfBricksCanBuy::brick()} or less to continue your transaction.`;
      } else {
        this._fetchPurchaseProposal(updatedQuantityInt);
        const { account, tradeProposal } = this.props;
        if (account.availableToTradeBalance < tradeProposal.data.total) {
          updatedProposalForm.hasError = true;
          updatedProposalForm.errorMsg = `You have insufficient funds to complete this transaction. Please deposit more funds to your account.`;
          this.setState({ showDepositLink: true });
        } else {
          updatedProposalForm.hasError = false;
          updatedProposalForm.errorMsg = '';
        }
      }
      updatedProposalForm.quantity = updatedQuantityInt;
    } else {
      updatedProposalForm.hasError = true;
      updatedProposalForm.errorMsg = `How many Bricks would you like to buy? Enter a Quantity to continue your transaction.`;
      updatedProposalForm.quantity = updatedQuantity;
    }
    this._updateForm(updatedProposalForm);
  };

  _onAcceptTermsChange = () => {
    this.setState({
      terms: { accepted: !this.state.terms.accepted, showErrorMessage: false },
    });
  };

  async _fetchPurchaseProposal(quantity) {
    const { property, fetchPurchaseProposal } = this.props;
    await fetchPurchaseProposal(property.propertyCode, quantity);
  }

  _updateTotalBricksOwned = (account) => {
    const { getTotalBricksOwned } = this.props;
    const totalBricksOwned = getTotalBricksOwned(account);

    this.setState({
      totalBricksOwned: totalBricksOwned,
      totalBricksOwnedLoaded: true,
    });
  };

  _updateNumOfAvailableBricks = (ordersBuy) => {
    if (!ordersBuy || !this.props.property) return;
    const numberOfBricks = this.props.property.financials.numberBricks;
    const numOfBricksReserved = ordersBuy.reduce((sum, order) => sum + order.quantity, 0);
    const numOfBricksAvailable = numberOfBricks - numOfBricksReserved;

    this.setState({
      numOfBricksAvailable: numOfBricksAvailable,
      numOfBricksAvailableLoaded: true,
    });
  };

  async _confirmPurchase() {
    this.setState({ loading: true });
    const { user, tradeProposal, confirmPurchase } = this.props;
    this._handleQuantityChange(this.state.buyProposalParams.quantity);
    if (this.state.buyProposalParams.hasError) {
      this.setState({ loading: false });
      return;
    }
    if (!this.state.terms.accepted) {
      this.setState({
        loading: false,
        terms: {
          showErrorMessage: true,
        },
      });
      return;
    }
    if (checkUserWithTradePermission(user, this.context.router)) {
      await confirmPurchase(tradeProposal.data, true);
    }
    this.setState({ loading: false });
  }

  _buildBuyProposalParams = (updatedProposalForm) => {
    this.setState({
      buyProposalParams: {
        quantity: updatedProposalForm.quantity,
        hasError: updatedProposalForm.hasError,
        errorMsg: updatedProposalForm.errorMsg,
      },
    });
  };

  _updateForm = (updatedProposalForm) => {
    this._buildBuyProposalParams(updatedProposalForm);
    updatedProposalForm.callback(updatedProposalForm);
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  property as propertyPropType,
  account as accountPropType,
  myOrders as myOrdersPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';

import { myOrdersSelector } from 'scripts/redux/selectors/market';

import {
  fetchAccountInfo,
  fetchAccountInfoV2,
} from 'scripts/redux/actions/account';
import { fetchMyOrders } from 'scripts/redux/actions/market';
import {
  sendTradeButtonClickEvent,
  sendSellButtonClickEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';
import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';

import InvestPanel from 'scripts/components/property/trade/InvestPanel';
import TradePanelBuy from 'scripts/components/property/trade/TradePanelBuy';
import TradePanelPreOrder from 'scripts/components/property/trade/TradePanelPreOrder';
import TradePanelSell from 'scripts/components/property/trade/TradePanelSell';

import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';
import { MOBILE_LARGE } from 'scripts/constants/WindowSize';
import Trade from 'scripts/constants/Trade';

import _ from 'lodash';

const portfolioFor = (records, propertyCode) =>
  _.find(records, { propertyCode });
const orderFor = (orders, propertyCode) => _.filter(orders, { propertyCode });

const mapStateToProps = (state) => ({
  ...myOrdersSelector(state),
});

@connect(mapStateToProps, {
  fetchAccountInfo,
  fetchAccountInfoV2,
  fetchMyOrders,
})
export default class TradeComponent extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    account: accountPropType,
    accountV2: PropTypes.object,
    myOrders: myOrdersPropType.isRequired,
    isPreOrderProperty: PropTypes.bool.isRequired,
    isComingSoonProperty: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf([
      Trade.TRADE_MODE.VIEW,
      Trade.TRADE_MODE.BUY,
      Trade.TRADE_MODE.SELL,
    ]),
    user: userPropType.isRequired,
    fetchAccountInfo: PropTypes.func.isRequired,
    fetchAccountInfoV2: PropTypes.func.isRequired,
    fetchMyOrders: PropTypes.func.isRequired,
    handleTradeModeChange: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this._fetchAccountInfo(this.props.user);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const hasUserBeenUpdated = nextProps.user !== this.props.user;
    if (hasUserBeenUpdated) {
      this._fetchAccountInfo(nextProps.user);
    }
  }

  render() {
    const {
      fetchMyOrders,
      user,
      myOrders,
      property,
      isPreOrderProperty,
      isComingSoonProperty,
      mode,
    } = this.props;

    return (
      <div>
        {mode === Trade.TRADE_MODE.VIEW && (
          <div className="col-md-4">
            <InvestPanel
              user={user}
              property={property}
              isComingSoonProperty={isComingSoonProperty}
              isPreOrderProperty={isPreOrderProperty}
              fetchMyOrdersInfo={fetchMyOrders}
              getTotalBricksOwned={this._getTotalBricksOwned}
              handleBuyButtonClick={this._handleBuyButtonClick}
              handleSellButtonClick={this._handleSellButtonClick}
            />
          </div>
        )}
        {mode === Trade.TRADE_MODE.BUY && (
          <div className="col-md-12">
            {isPreOrderProperty ? (
              <TradePanelPreOrder
                user={user}
                property={property}
                fetchMyOrdersInfo={fetchMyOrders}
                fetchMyAccountInfo={this._fetchAccountInfo}
                getTotalBricksOwned={this._getTotalBricksOwned}
                handleClosePanelClick={this._handleGoBackClick}
                redirectTo={this._redirectTo}
              />
            ) : (
              <TradePanelBuy
                user={user}
                property={property}
                myOrders={myOrders}
                fetchMyOrdersInfo={fetchMyOrders}
                fetchMyAccountInfo={this._fetchAccountInfo}
                getTotalBricksOwned={this._getTotalBricksOwned}
                handleClosePanelClick={this._handleGoBackClick}
                redirectTo={this._redirectTo}
              />
            )}
          </div>
        )}
        {mode === Trade.TRADE_MODE.SELL && (
          <div className="col-md-12">
            <TradePanelSell
              user={user}
              property={property}
              myOrders={myOrders}
              fetchMyOrdersInfo={fetchMyOrders}
              fetchMyAccountInfo={this._fetchAccountInfo}
              getTotalBricksOwned={this._getTotalBricksOwned}
              handleClosePanelClick={this._handleGoBackClick}
              redirectTo={this._redirectTo}
            />
          </div>
        )}
      </div>
    );
  }

  _getTotalBricksOwned = (account) => {
    const { isPreOrderProperty, myOrders } = this.props;
    return isPreOrderProperty
      ? this._getTotalBricksOnMyPendingPreorder(myOrders)
      : this._getTotalBricksHeld(account);
  };

  _getTotalBricksHeld = (account) => {
    const { property } = this.props;
    if (!account || !property) {
      return 0;
    }
    const holding = portfolioFor(
      account.portfolio.records,
      property.propertyCode
    );
    if (!holding) {
      return 0;
    }
    return holding.totalBricks;
  };

  _getTotalBricksOnMyPendingPreorder = () => {
    const { property, myOrders } = this.props;
    if (_.isEmpty(myOrders.pendingPre)) {
      return 0;
    }
    const preOrdersForProperty = orderFor(
      myOrders.pendingPre,
      property.propertyCode
    );
    return _.reduce(
      preOrdersForProperty,
      (sum, order) => sum + order.quantity,
      0
    );
  };

  _handleGoBackClick = () => {
    if (window.innerWidth <= MOBILE_LARGE) {
      const body = document.querySelector('body');
      body.classList.remove('modal-open');
    }
    this.props.handleTradeModeChange(Trade.TRADE_MODE.VIEW);
  };

  _handleBuyButtonClick = () => {
    const { property, isPreOrderProperty } = this.props;
    if (isPreOrderProperty) {
      sendTradeButtonClickEvent({
        propertyCode: property.propertyCode,
        tradeType: SegmentEventTypes.TRADE.PRE_ORDER,
      });
    } else {
      sendTradeButtonClickEvent({
        propertyCode: property.propertyCode,
        tradeType: SegmentEventTypes.TRADE.BUY,
      });
    }
    this._handleInvestButtonClick(Trade.TRADE_MODE.BUY);
  };

  _handleSellButtonClick = () => {
    const { property } = this.props;
    sendSellButtonClickEvent({
      propertyCode: property.propertyCode,
      tradeType: SegmentEventTypes.TRADE.SELL,
    });
    this._handleInvestButtonClick(Trade.TRADE_MODE.SELL);
  };

  _handleInvestButtonClick = (mode) => {
    if (window.innerWidth <= MOBILE_LARGE) {
      const body = document.querySelector('body');
      body.classList.add('modal-open');
    }
    this.props.handleTradeModeChange(mode);
  };

  _fetchAccountInfo = (user) => {
    const { fetchAccountInfo, fetchAccountInfoV2, account, accountV2 } =
      this.props;
    if (isUserLoggedIn(user) && _.isNil(account)) {
      fetchAccountInfo();
    }
    if (isUserLoggedIn(user) && _.isNil(accountV2)) {
      fetchAccountInfoV2();
    }
  };

  _redirectTo = (path) => {
    const { router } = this.context;
    router.push(path);
  };
}

import { Fragment } from 'react';
import {
  TOTAL_PENDING_PRE_ORDERS_HELP_TEXT,
  TOTAL_PENDING_BUY_ORDERS_HELP_TEXT,
  TOTAL_PENDING_SELL_ORDERS_HELP_TEXT,
} from 'src/components/dashboard/home/helpText';
import {
  WelcomePageWidgetPanel,
  PanelValueHeading,
  PanelValueText,
  SeeMoreLink,
  TotalValueContainer
} from 'src/components/dashboard/home/commonComponents';
import { dollarDecimal } from 'src/utils/formats';
import { fetchMyOrders } from 'scripts/redux/actions/market';
import { getTotalPreOrders, getTotalBuyOrders, getTotalSellOrders } from 'src/utils/orders';
import { myOrdersSelector } from 'scripts/redux/selectors/market';
import { pendingOrdersUrl } from 'src/utils/pageUrls';
import Loading from 'src/components/loading/Loading';
import Popover from 'scripts/components/shared/Popover';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import withFetching from 'src/decorators/withFetching';

const PendingOrdersPanelContent = withFetching([myOrdersSelector], [fetchMyOrders])(({ myOrders }) => {
  if (!myOrders.list) return <Loading notFullHeight/>;

  const totalPreOrders = getTotalPreOrders(myOrders.pendingPre);
  const totalBuyOrders = getTotalBuyOrders(myOrders.pendingBuy);
  const totalSellOrders = getTotalSellOrders(myOrders.pendingSell);

  return (
    <Fragment>
      <TotalValueContainer>
        <PanelValueHeading>Total Pending Pre Orders <Popover placement="top" content={TOTAL_PENDING_PRE_ORDERS_HELP_TEXT}/></PanelValueHeading>
        <Spacing top="2x-small">
          <PanelValueText testRef="pending-orders-panel-total-pre-order-value">{dollarDecimal(totalPreOrders)}</PanelValueText>
        </Spacing>
      </TotalValueContainer>
      <Spacing top="x-large">
        <TotalValueContainer>
          <PanelValueHeading>Total Pending Buy Orders <Popover placement="top" content={TOTAL_PENDING_BUY_ORDERS_HELP_TEXT}/></PanelValueHeading>
          <Spacing top="2x-small">
            <PanelValueText testRef="pending-orders-panel-total-buy-order-value">{dollarDecimal(totalBuyOrders)}</PanelValueText>
          </Spacing>
        </TotalValueContainer>
      </Spacing>
      <Spacing top="x-large">
        <TotalValueContainer>
          <PanelValueHeading>Total Pending Sell Orders <Popover placement="top" content={TOTAL_PENDING_SELL_ORDERS_HELP_TEXT}/></PanelValueHeading>
          <Spacing top="2x-small">
            <PanelValueText testRef="pending-orders-panel-total-sell-order-value">{dollarDecimal(totalSellOrders)}</PanelValueText>
          </Spacing>
        </TotalValueContainer>
      </Spacing>
    </Fragment>
  );
});

const PendingOrdersPanel = ({ className }) => (
  <WelcomePageWidgetPanel
    className={className}
    title="Pending Orders"
    action={<SeeMoreLink href={pendingOrdersUrl()} testRef="pending-orders-panel-header-link"/>}
    testRef="pending-orders-panel"
  >
    <PendingOrdersPanelContent/>
  </WelcomePageWidgetPanel>
);

export default PendingOrdersPanel;

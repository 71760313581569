import { cancelOrder as cancelOrderUrl } from 'scripts/services/api';
import { fetchMyOrders } from 'scripts/redux/actions/market';
import { errorAlert } from 'scripts/redux/actions/alerts';
import postWithAuth from 'scripts/services/request/postWithAuth';

export const cancelOrder = (propertyCode, orderId) => async (dispatch) => {
  try {
    await postWithAuth(cancelOrderUrl(propertyCode, orderId), {});
    dispatch(fetchMyOrders());
    return true;
  } catch (e) {
    dispatch(errorAlert(e.message));
    return false;
  }
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  property as propertyPropType,
  account as accountPropType,
  orderAggregated as orderPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';
import { connect } from 'react-redux';
import { fetchOrders } from 'scripts/redux/actions/market';
import { ordersBuySelector } from 'scripts/redux/selectors/market';
import { accountSelector } from 'scripts/redux/selectors/account';
import OrderBookPreOrder from 'scripts/components/property/trade/OrderBookPreOrder';
import TradeProposalFormPreOrder from 'scripts/components/property/trade/TradeProposalFormPreOrder';
import { renderCode } from 'src/settings/properties';

const mapStateToProps = (state) => ({
  ...ordersBuySelector(state),
  ...accountSelector(state),
});

@connect(mapStateToProps, {
  fetchOrders,
})
export default class TradePanelPreOrder extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    ordersBuy: PropTypes.arrayOf(orderPropType),
    getTotalBricksOwned: PropTypes.func.isRequired,
    fetchMyAccountInfo: PropTypes.func.isRequired,
    fetchMyOrdersInfo: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    handleClosePanelClick: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
  };

  state = {
    proposalForm: {
      quantity: '',
      hasError: false,
      errorMsg: '',
      callback: (updatedProposalForm) => {
        this.setState({ proposalForm: updatedProposalForm });
      },
    },
  };

  componentDidMount() {
    const {
      user,
      property,
      fetchMyAccountInfo,
      fetchMyOrdersInfo,
      fetchOrders,
    } = this.props;
    fetchMyAccountInfo(user);
    fetchMyOrdersInfo(user);
    fetchOrders(property.propertyCode);

    this.pollingInterval = setInterval(() => {
      fetchMyAccountInfo(user);
      fetchMyOrdersInfo(user);
      fetchOrders(property.propertyCode);
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  render() {
    const {
      user,
      account,
      property,
      ordersBuy,
      getTotalBricksOwned,
      handleClosePanelClick,
      redirectTo,
    } = this.props;
    const { proposalForm } = this.state;

    return (
      <div className="panel-financials-invest-shadow">
        <div className="panel-financials-blue-header panel-financials-buy panel-financials-table coming-soon-property">
          <div className="header">
            <div className="row">
              <div className="panel-financials-title col-md-8 col-xs-8">
                {`Pre-Order ${renderCode(property.propertyCode)}`}
              </div>
              <div className="link col-xs-4 col-md-4 text-right">
                <span className="close-panel" onClick={handleClosePanelClick}>
                  <span>
                    <i className="fa fa-times-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="body wrap-the-col">
            <div className="col-md-4">
              <OrderBookPreOrder property={property} account={account} />
            </div>

            <div className="col-md-8">
              <TradeProposalFormPreOrder
                user={user}
                account={account}
                property={property}
                ordersBuy={ordersBuy}
                getTotalBricksOwned={getTotalBricksOwned}
                proposalForm={proposalForm}
                redirectTo={redirectTo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

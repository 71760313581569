import { Fragment } from 'react';
import Markdown from 'react-remarkable';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpointMedium } from 'src/design/styleguide/common/breakpoints';
import {
  date,
  monthYear,
  dollar,
  brickPrice,
  percentDecimal,
} from 'scripts/utilities/formatters';
import {
  propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription,
  historicalGrowthDataProvider,
  historicalGrowthTitle,
} from 'src/settings/text';
import {
  property as propertyPropType,
  monthlyUpdates as monthlyUpdatesPropType,
} from 'scripts/constants/PropTypes';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { forcastedCapitalReturn } from 'src/settings/financials';
import PropertyTypes from 'src/enums/propertyTypes';
import InvestmentTypes from 'src/enums/investmentTypes';
import { historicalRBACashRate10yr } from 'src/settings/financials';
import { maximumLoanToValueRatio } from 'src/settings/financials';

const StyledFinancialsBox = styled(FinancialsBox)`
  @media (min-width: 980px) {
    margin-bottom: 15px;
  }

  ${breakpointMedium(`
    flex: 1 0 0px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    & .gray-box {
      height: 100%;
    }
  `)}
`;

const KeyMetricsBoxesContainer = styled.div`
  @media (min-width: 980px) {
    display: flex;
    flex-direction: column;
  }

  ${breakpointMedium(`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

const DevelopmentKeyMetrics = ({ propertyCode }) => {
  const metrics = forcastedCapitalReturn[propertyCode];
  if (!metrics) {
    return null;
  }

  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Development Phase: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'COMPLETE VALUE'}
          value={metrics.completeValue::dollar()}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'CAPITAL COSTS & OUTGOINGS'}
          value={metrics.capitalCostsAndOutgoings::dollar()}
          description={'Total Value - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={metrics.developerUplift::percentDecimal()}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={metrics.annualisedDeveloperUplift::percentDecimal()}
          description={'Annualised - Forecasted'}
        />
      </div>
    </FinancialsPanel>
  );
};

const KeyMetrics = ({
  property,
  isPreorderOrComingSoonProperty,
  monthlyUpdates,
  averageAnnualGrowthAmount,
  averageAnnualGrowthNumberOfYears,
}) => {
  const isPreOrderOrNotIndependentValuedProperty =
    isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;

  const title =
    property.investmentType === InvestmentTypes.mortgage
      ? 'Mortgage (Debt) Asset Info: Key Metrics'
      : property.investmentType === InvestmentTypes.financialAsset
      ? 'Financial Asset (Fund) Info: Key Metrics'
      : 'Capital Returns: Key Metrics';
  const latestValueBoxTitle = isPreOrderOrNotIndependentValuedProperty
    ? 'Total Purchase Cost'
    : 'Latest Value';
  const latestValueBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? property.platformSettlementDate::date()
    : property.financials.latestValuationDate::monthYear();

  const brickValueBoxTitle = isPreOrderOrNotIndependentValuedProperty
    ? 'INITIAL BRICK PRICE'
    : 'BRICK VALUE';
  const brickValueBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? property.platformSettlementDate::date()
    : property.financials.latestValuationDate::monthYear();

  const debtBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? property.platformSettlementDate::date()
    : property.financials.latestValuationDate::monthYear();

  return (
    <Fragment>
      {property.propertyType === PropertyTypes.development && (
        <DevelopmentKeyMetrics propertyCode={property.propertyCode} />
      )}
      <FinancialsPanel
        className="panel-financials-transparent-header panel-financials-capital-returns"
        title={title}
        titleIcon="icn-line-chart-blue"
      >
        <KeyMetricsBoxesContainer>
          {property.investmentType === InvestmentTypes.mortgage ? (
            <StyledFinancialsBox
              className="financials-box-small"
              title={'MAX. LOAN TO VALUE RATIO'}
              value={maximumLoanToValueRatio::percentDecimal()}
              description={latestValueBoxDescription}
            />
          ) : property.investmentType === InvestmentTypes.financialAsset ? (
            <StyledFinancialsBox
              className="financials-box-small"
              title={'HISTORICAL RBA CASH RATE'}
              value={historicalRBACashRate10yr::percentDecimal()}
              description={'10 years average'}
            />
          ) : (
            <StyledFinancialsBox
              className="five-year-historical-suburb-growth financials-box-small"
              title={historicalGrowthTitle(property.propertyType)}
              value={averageAnnualGrowthAmount}
              description={propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription(
                averageAnnualGrowthNumberOfYears,
                historicalGrowthDataProvider(property.propertyType)
              )}
              valueTestRef="capital-returns-value"
              descriptionTestRef="capital-returns-description"
            />
          )}

          {property.investmentType === InvestmentTypes.mortgage ? (
            <StyledFinancialsBox
              className="financials-box-small"
              title={'LASTEST VALUE'}
              value={property.financials.latestValue::dollar()}
              description={latestValueBoxDescription}
            />
          ) : property.investmentType === InvestmentTypes.financialAsset ? (
            <StyledFinancialsBox
              className="financials-box-small"
              title={'LASTEST VALUE'}
              value={property.financials.latestValue::dollar()}
              description={latestValueBoxDescription}
            />
          ) : (
            <StyledFinancialsBox
              className="financials-box-small latest-value"
              title={latestValueBoxTitle}
              value={property.financials.latestValue::dollar()}
              description={latestValueBoxDescription}
            />
          )}

          {property.investmentType === InvestmentTypes.mortgage ? (
            <StyledFinancialsBox
              className="financials-box-small outstanding-debt"
              title={`LOAN ADVANCE`}
              value={property.financials.liveLoanAdvance::dollar()}
              description={debtBoxDescription}
            />
          ) : property.investmentType === InvestmentTypes.financialAsset ? (
            <StyledFinancialsBox
              className="financials-box-small outstanding-debt"
              title={`LOAN ADVANCE`}
              value={property.financials.liveLoanAdvance::dollar()}
              description={debtBoxDescription}
            />
          ) : (
            <StyledFinancialsBox
              className="financials-box-small outstanding-debt"
              title={`DEBT`}
              value={
                property.financials.debtTerms
                  ? property.financials.debtTerms.outstandingDebt::dollar()
                  : '$0'
              }
              description={debtBoxDescription}
            />
          )}

          <StyledFinancialsBox
            className="financials-box-small brick-value"
            title={brickValueBoxTitle}
            value={property.financials.brickValue::brickPrice()}
            description={brickValueBoxDescription}
          />
        </KeyMetricsBoxesContainer>
        <div className="row">
          <div className="col-md-12 monthly-update">
            {monthlyUpdates.length ? (
              <Markdown source={monthlyUpdates[0].brickValuationInfo} />
            ) : (
              ''
            )}
          </div>
        </div>
      </FinancialsPanel>
    </Fragment>
  );
};

KeyMetrics.propTypes = {
  property: propertyPropType.isRequired,
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
  monthlyUpdates: monthlyUpdatesPropType,
  averageAnnualGrowthAmount: PropTypes.string.isRequired,
};

export default KeyMetrics;

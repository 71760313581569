import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import {
  property as propertyPropType,
  monthlyUpdates as monthlyUpdatesPropType,
  propertyAverageAnnualGrowthMetrics as propertyAverageAnnualGrowthMetricsPropType,
  historicalGrowthData as historicalGrowthDataPropType
} from 'scripts/constants/PropTypes';
import { sendPropertyTabLoad } from 'scripts/redux/actions/segment/events/propertiesPageEvents';
import CriticalNews from 'scripts/components/property/CriticalNews';
import HistoricalPurchase from 'scripts/components/property/returns/HistoricalPurchase';
import DebtBreakdown from 'scripts/components/property/returns/DebtBreakdown';
import KeyMetrics from 'scripts/components/property/returns/KeyMetrics';
import AcquisitionCost from 'scripts/components/property/returns/AcquisitionCost';
import IndependentValuation from 'scripts/components/property/returns/IndependentValuation';
import HistoricalSuburbPerformanceInFinancialsPanel from 'scripts/components/property/summary/HistoricalSuburbPerformanceInFinancialsPanel';
import PrevNextBar from 'scripts/components/property/PrevNextBar';
import { RETURNS_META } from 'scripts/metadata/properties';
import { getHistoricalGrowthMetricsHighestNumberOfYearsGrowth, getHistoricalGrowthMetricsHighestNumberOfYears } from 'scripts/utilities/propertyHelper';


@connect(null, {})
export default class PropertyReturns extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
    monthlyUpdates: monthlyUpdatesPropType,
    propertyAverageAnnualGrowthMetrics: propertyAverageAnnualGrowthMetricsPropType,
    historicalGrowthData: historicalGrowthDataPropType,
  };

  componentDidMount() {
    sendPropertyTabLoad({ tabName: 'Capital Returns', propertyCode: this.props.property.propertyCode });
  }

  render() {
    const {
      property,
      isPreorderOrComingSoonProperty,
      monthlyUpdates,
      propertyAverageAnnualGrowthMetrics,
      historicalGrowthData
    } = this.props;

    let averageAnnualGrowthAmount = '---';
    let averageAnnualGrowthNumberOfYears = '---';
    if (propertyAverageAnnualGrowthMetrics) {
      averageAnnualGrowthAmount = getHistoricalGrowthMetricsHighestNumberOfYearsGrowth(propertyAverageAnnualGrowthMetrics);
      averageAnnualGrowthNumberOfYears = getHistoricalGrowthMetricsHighestNumberOfYears(propertyAverageAnnualGrowthMetrics);
    }
    return (
      <div className="property-overview">
        <DocumentMeta {...RETURNS_META(property)} />
        <div className="container">
          <div>
            <DocumentMeta {...RETURNS_META(property)} />
              <div className="row">
                <div className="col-md-12">
                  <CriticalNews criticalNews={property.criticalNews}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-md-offset-0 col-lg-12 col-lg-offset-0">
                  <KeyMetrics
                    isPreorderOrComingSoonProperty={isPreorderOrComingSoonProperty}
                    property={property}
                    monthlyUpdates={monthlyUpdates}
                    averageAnnualGrowthAmount={averageAnnualGrowthAmount}
                    averageAnnualGrowthNumberOfYears={averageAnnualGrowthNumberOfYears}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <IndependentValuation
                    property={property}
                    isPreorderOrComingSoonProperty={isPreorderOrComingSoonProperty}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-md-offset-0 col-lg-12 col-lg-offset-0">
                  <HistoricalSuburbPerformanceInFinancialsPanel
                    property={property}
                    historicalGrowthData={historicalGrowthData}
                    propertyTab="Returns"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <HistoricalPurchase property={property}/>
                </div>
                <div className="col-md-4">
                  <AcquisitionCost property={property} isPreorderOrComingSoonProperty={isPreorderOrComingSoonProperty}/>
                </div>
                <div className="col-md-4">
                  <DebtBreakdown property={property}/>
                </div>
              </div>
              <PrevNextBar
                prevTitle="Monthly Distributions"
                prevLink={`/investments/${property.propertyCode}/distributions`}
                nextTitle="Details"
                nextLink={`/investments/${property.propertyCode}/details`}
              />
          </div>
        </div>
      </div>
    );
  }
}

import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  order as orderPropType,
  property as propertyPropType,
  account as accountPropType,
  editForm as editFormPropType,
} from 'scripts/constants/PropTypes';
import { LOWEST_BRICK_PRICE_POPOVER_TEXT } from 'src/components/dashboard/portfolio/helpText';
import { timeToNow } from 'src/utils/time';
import { longDate, shortReference } from 'src/utils/formats';
import { brick, dollarDecimal, percent } from 'scripts/utilities/formatters';
import {
  getTotalQuantityOfBricks,
  getTotalBuyOrders,
  getAmountYouPayOnPurchase,
  getPropertyFromOrder,
} from 'src/utils/orders';
import {
  validateTradeProposalQuantity,
  validateTradeProposalPrice,
} from 'scripts/utilities/tradeHelper';
import Loading from 'src/components/loading/Loading';
import Popover from 'scripts/components/shared/Popover';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import EditButton from 'src/components/dashboard/pendingOrders/EditButton';
import EditExitButton from 'src/components/dashboard/pendingOrders/EditExitButton';
import EditConfirmButton from 'src/components/dashboard/pendingOrders/EditConfirmButton';
import CancelButton from 'src/components/dashboard/pendingOrders/CancelButton';
import { NoBuyOrders } from 'src/components/dashboard/pendingOrders/NoOrders';
import {
  BodyTableRow,
  ColumnHeading,
  FooterTableData,
  SecondColumn,
  StickyHeader,
  Table,
  TableData,
} from 'src/design/components/table/Table';
import { renderCode } from 'src/settings/properties';
import DynamicNumber from 'react-dynamic-number';
import Numbers from 'scripts/constants/Numbers';
import Constants from 'scripts/constants/Constants';
import { LOWEST_BUY_PRICE_PERCENTAGE } from 'src/settings/trading';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default class BuyOrderPanel extends Component {
  static propTypes = {
    buyOrders: PropTypes.arrayOf(orderPropType),
    properties: PropTypes.arrayOf(propertyPropType),
    account: accountPropType.isRequired,
    fees: PropTypes.number,
    editForm: editFormPropType,
  };

  render() {
    const { buyOrders, properties, fees, editForm } = this.props;
    const today = new Date();

    return !properties.length ? (
      <Loading />
    ) : (
      <WidgetPanel title="Pending Buy Orders" testRef="buy-orders-panel">
        {buyOrders.length ? (
          <Table withStickyHeader centeredRows>
            <thead>
              <tr>
                <StickyHeader>
                  <ColumnHeading>Property</ColumnHeading>
                </StickyHeader>
                <SecondColumn>
                  <ColumnHeading>
                    Date
                    <br />
                    Placed
                  </ColumnHeading>
                </SecondColumn>
                <ColumnHeading>
                  Time
                  <br />
                  Listed
                </ColumnHeading>
                <ColumnHeading>
                  Quantity
                  <br />
                  of Bricks
                </ColumnHeading>
                <ColumnHeading>
                  Brick
                  <br />
                  Buy Price
                </ColumnHeading>
                <ColumnHeading>
                  You
                  <br />
                  Pay
                  <Popover
                    placement="top"
                    content={`The total amount you will pay for this buy order, including the ${fees}% buying fee.`}
                    data-test-reference="you-pay-amount-popover"
                  />
                </ColumnHeading>
                <ColumnHeading>
                  Best
                  <br />
                  Ask
                  <Popover
                    placement="top"
                    content={LOWEST_BRICK_PRICE_POPOVER_TEXT}
                    testRef="lowest-available-brick-price-popover"
                  />
                </ColumnHeading>
                <ColumnHeading>Reference</ColumnHeading>
                <ColumnHeading />
              </tr>
            </thead>
            <tbody>
              {buyOrders.map((order) =>
                order.orderId === editForm.orderId ? (
                  <Fragment key={`edit-${order.orderId}`}>
                    <BodyTableRow key={order.orderId}>
                      <StickyHeader>
                        <TableData>
                          {renderCode(editForm.propertyCode)}
                        </TableData>
                      </StickyHeader>
                      <SecondColumn>
                        <TableData>
                          <b style={{ color: '#3687de' }}>{longDate(today)}</b>
                        </TableData>
                      </SecondColumn>
                      <TableData>
                        <b style={{ color: '#3687de' }}>{Numbers.ZERO}</b>
                      </TableData>
                      <TableData>
                        <DynamicNumber
                          name="edit_quantity"
                          value={editForm.quantity}
                          integer={Numbers.TEN}
                          fraction={Numbers.ZERO}
                          positive={Constants.TRUE}
                          thousand={Constants.FALSE}
                          placeholder="0"
                          onChange={this._onQuantityChange}
                          style={{ backgroundColor: 'white' }}
                        />
                      </TableData>
                      <TableData>
                        <div className="dollar-input">
                          <DynamicNumber
                            name="edit_price"
                            value={editForm.price}
                            separator={'.'}
                            integer={Numbers.TEN}
                            fraction={Numbers.TWO}
                            positive={Constants.TRUE}
                            thousand={Constants.TRUE}
                            placeholder="0.00"
                            onChange={this._onPriceChange}
                            style={{ backgroundColor: 'white' }}
                          />
                        </div>
                      </TableData>
                      <TableData>
                        <b style={{ color: '#3687de' }}>
                          {dollarDecimal(editForm.total)}
                        </b>
                      </TableData>
                      <TableData>
                        {dollarDecimal(
                          getPropertyFromOrder(properties, order).financials
                            .lowestAvailableBrickPrice
                        )}
                      </TableData>
                      <TableData>-</TableData>
                      <TableData>
                        <ButtonContainer>
                          <EditConfirmButton
                            order={order}
                            orderTypeText="Buy Order"
                            editForm={editForm}
                            handleQuantityChange={this._handleQuantityChange}
                            handlePriceChange={this._handlePriceChange}
                            fees={fees}
                            className="leftButton"
                            disable={editForm.hasError}
                          />
                          <EditExitButton
                            editForm={editForm}
                            className="rightButton"
                          />
                        </ButtonContainer>
                      </TableData>
                    </BodyTableRow>
                    {editForm.hasError && (
                      <BodyTableRow>
                        <TableData colSpan="100%" className="text-danger">
                          <b>{editForm.errorMsg}</b>
                        </TableData>
                      </BodyTableRow>
                    )}
                  </Fragment>
                ) : (
                  <BodyTableRow key={order.orderId} testRef="row">
                    <StickyHeader>
                      <TableData testRef="property-code-cell">
                        {renderCode(order.propertyCode)}
                      </TableData>
                    </StickyHeader>
                    <SecondColumn>
                      <TableData testRef="date-cell">
                        {longDate(order.date)}
                      </TableData>
                    </SecondColumn>
                    <TableData testRef="days-listed-cell">
                      {timeToNow(order.date)}
                    </TableData>
                    <TableData testRef="quantity-of-bricks-cell">
                      {order.quantity}
                    </TableData>
                    <TableData testRef="brick-buy-price-cell">
                      {dollarDecimal(order.price)}
                    </TableData>
                    <TableData testRef="you-pay-amount-cell">
                      {dollarDecimal(getAmountYouPayOnPurchase(order))}
                    </TableData>
                    <TableData testRef="lowest-available-brick-price-cell">
                      {dollarDecimal(
                        getPropertyFromOrder(properties, order).financials
                          .lowestAvailableBrickPrice
                      )}
                    </TableData>
                    <TableData testRef="reference-cell">
                      {shortReference(order.orderId)}
                    </TableData>
                    <TableData>
                      <ButtonContainer>
                        <EditButton
                          order={order}
                          orderType="buy"
                          editForm={editForm}
                          className="leftButton"
                        />
                        <CancelButton
                          order={order}
                          orderTypeText="Buy"
                          className="rightButton"
                        />
                      </ButtonContainer>
                    </TableData>
                  </BodyTableRow>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <StickyHeader>
                  <FooterTableData>Total</FooterTableData>
                </StickyHeader>
                <SecondColumn>
                  <FooterTableData>-</FooterTableData>
                </SecondColumn>
                <FooterTableData>-</FooterTableData>
                <FooterTableData testRef="total-quantity-of-bricks-cell">
                  {getTotalQuantityOfBricks(buyOrders)}
                </FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData testRef="total-cell">
                  {dollarDecimal(getTotalBuyOrders(buyOrders))}
                </FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData>-</FooterTableData>
              </tr>
            </tfoot>
          </Table>
        ) : (
          <NoBuyOrders />
        )}
      </WidgetPanel>
    );
  }

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { buyOrders, properties, account, fees, editForm } = this.props;
    var updatedEditForm = { ...editForm };

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity);

      const property = getPropertyFromOrder(properties, editForm);
      const order = buyOrders.find((_order) => _order.orderId === editForm.orderId);

      const holding = account.portfolio.records.find((record) => record.propertyCode === property.propertyCode);
      const totalBricksOwned = holding ? holding.totalBricks : 0;
      const totalBricksOnMyPendingBuy = buyOrders.reduce((acc, _order) => _order.propertyCode === property.propertyCode ? acc + _order.quantity : acc, 0);
      const maxNumOfBricksPerProperty = (property.financials.numberBricks * property.maxOwnership) / 100;
      const maxNumOfBricksPerPropertyRatio = `${property.maxOwnership}%`;
      const maxNumOfBricksCanBuy = maxNumOfBricksPerProperty - totalBricksOwned - totalBricksOnMyPendingBuy + order.quantity;

      const availableToTradeBalance = account.availableToTradeBalance + order.totalPriceInclFees;

      const updatedTotal = updatedEditForm.price * updatedQuantityInt * (1 + fees / 100);

      if (updatedQuantityInt > maxNumOfBricksCanBuy) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `You already own ${totalBricksOwned::brick()} in this property, and can only buy ${maxNumOfBricksCanBuy::brick()} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio}, or ${maxNumOfBricksPerProperty::brick()} for this property.`;
      } else if (updatedTotal > availableToTradeBalance) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `You do not have enough funds to complete this transaction.`;
      } else {
        updatedEditForm.hasError = false;
        updatedEditForm.errorMsg = '';
      }

      updatedEditForm.total = updatedTotal;
      updatedEditForm.quantity = updatedQuantityInt;
    } else {
      updatedEditForm.hasError = true;
      updatedEditForm.errorMsg = 'Enter a valid quantity of Bricks to buy.';
      updatedEditForm.quantity = updatedQuantity;
    }
    updatedEditForm.callback(updatedEditForm);
  };

  _onPriceChange = (event) => {
    const updatedPrice = event.target.value.replace(/,/g, '');
    this._handlePriceChange(updatedPrice);
  };

  _handlePriceChange = (updatedPrice) => {
    const { buyOrders, properties, account, fees, editForm } = this.props;
    var updatedEditForm = { ...editForm };

    if (validateTradeProposalPrice(updatedPrice)) {
      const updatedPriceFloat = Number.parseFloat(updatedPrice);

      const property = getPropertyFromOrder(properties, editForm);
      const order = buyOrders.find((_order) => _order.orderId === editForm.orderId);

      const lowestBuyPriceAllowed = (property.financials.brickValue * LOWEST_BUY_PRICE_PERCENTAGE).toFixed(2);
      const brickValueTitle = property.financials.isIndependentValued
        ? 'Latest Brick Valuation'
        : 'Initial Brick Price';

      const availableToTradeBalance = account.availableToTradeBalance + order.totalPriceInclFees;

      const updatedTotal = updatedPriceFloat * updatedEditForm.quantity * (1 + fees / 100);

      if (updatedPriceFloat < lowestBuyPriceAllowed) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `Sorry, the minimum price you can offer to buy Bricks in ${renderCode(property.propertyCode)} is ${lowestBuyPriceAllowed::dollarDecimal()}. (${(1 - LOWEST_BUY_PRICE_PERCENTAGE)::percent()} lower than the ${brickValueTitle})`;
      } else if (updatedTotal > availableToTradeBalance) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `You do not have enough funds to complete this transaction.`;
      } else {
        updatedEditForm.hasError = false;
        updatedEditForm.errorMsg = '';
      }

      updatedEditForm.total = updatedTotal;
      updatedEditForm.price = updatedPrice;
    } else {
      updatedEditForm.hasError = true;
      updatedEditForm.errorMsg = 'Please enter a valid buy price for your Brick(s).';
      updatedEditForm.price = updatedPrice;
    }
    updatedEditForm.callback(updatedEditForm);
  };
}

import PropTypes from 'prop-types';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import Popover from 'scripts/components/shared/Popover';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import InvestmentTypes from 'src/enums/investmentTypes';

import { getBrickValuation } from 'scripts/utilities/propertyHelper';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  date,
  monthYear,
  dollar,
  brickPrice,
} from 'scripts/utilities/formatters';
import {
  getCashReservePopoverText,
  getEquityPopoverText,
  getLatestValuePopoverText,
  getLatestPropertyValuationPopoverText,
} from 'src/settings/text';

const IndependentValuation = ({ property, isPreorderOrComingSoonProperty }) => {
  const isPreOrderOrNotIndependentValuedProperty =
    isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;
  const title = isPreOrderOrNotIndependentValuedProperty
    ? `Settlement Date: ${property.platformSettlementDate::date()}`
    : `Independent Valuation: ${property.financials.latestValuationDate::monthYear()}`;
  const latestPropertyValuationTitle =
    property.investmentType === InvestmentTypes.mortgage
      ? 'Total Loans Advanced'
      : isPreOrderOrNotIndependentValuedProperty
      ? 'Purchase Cost'
      : 'Latest Property Valuation';
  const latestPropertyValuationPopoverText =
    getLatestPropertyValuationPopoverText(
      property.propertyType,
      isPreOrderOrNotIndependentValuedProperty
    );
  const { brickValuationTitle, brickValuation } = getBrickValuation(property);
  const acquisitionCostPopoverText =
    'The unamortised Acquisition Costs, to be written off over 5 years, including stamp duty, legal and professional fees, and more. Full details below.';
  const loanProvisionPopoverText =
    'The unamortised Loan Provision Expenses, to be written off over the life of the loan. Full details below.';
  const cashReservePopoverText = getCashReservePopoverText(
    property.propertyType
  );
  const latestValueTitle =
    property.investmentType === InvestmentTypes.mortgage
      ? 'Total Trust Assets'
      : isPreOrderOrNotIndependentValuedProperty
      ? 'Total Purchase Cost'
      : 'Latest Value';
  const latestValuePopoverText = getLatestValuePopoverText(
    property.propertyType,
    isPreOrderOrNotIndependentValuedProperty
  );
  const equityPopoverText = getEquityPopoverText(property.propertyType);

  return (
    <FinancialsPanel className="latest-value-panel" title={title}>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="blue-label">ASSETS</div>
                </div>
              </div>
              <div className="gray-line" />
              <div className="row my-row">
                <div className="col-xs-4 col-left">
                  <i className="icn icn-medium icn-house"></i>
                </div>
                <div className="col-xs-8 col-right">
                  {latestPropertyValuationTitle}
                  <Popover
                    placement="top"
                    content={latestPropertyValuationPopoverText}
                  />
                  <div className="value last-property-valuation">
                    {property.investmentType === InvestmentTypes.property
                      ? property.financials.lastPropertyValuation::dollar()
                      : property.financials.liveLoanAdvance::dollar()}
                  </div>
                </div>
              </div>
              <div className="gray-line" />
              <div className="row my-row">
                <div className="col-xs-4 col-left">
                  <i
                    className="icn icn-medium icn-aquisition-costs"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="col-xs-8 col-right">
                  {property.investmentType === InvestmentTypes.property
                    ? 'Acquisition Costs'
                    : 'Loan Provision Expenses'}
                  <Popover
                    placement="top"
                    content={
                      property.investmentType === InvestmentTypes.property
                        ? acquisitionCostPopoverText
                        : loanProvisionPopoverText
                    }
                  />
                  <div className="value unamortised-acquisition-cost">
                    {property.financials.unamortisedAcquisitionCosts::dollar()}
                  </div>
                </div>
              </div>
              <div className="gray-line" />
              <div className="row my-row">
                <div className="col-xs-4 col-left">
                  <i className="icn icn-medium icn-note" aria-hidden="true"></i>
                </div>
                <div className="col-xs-8 col-right">
                  Cash Reserve
                  <Popover placement="top" content={cashReservePopoverText} />
                  <div className="value live-cash-reserve">
                    {property.financials.liveCashReserve::dollar()}
                  </div>
                </div>
              </div>
              <div className="black-line" />
              <div className="row my-row">
                <div className="col-xs-4 col-left">
                  <i
                    className="icn icn-medium icn-house"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="col-xs-8 col-right">
                  {latestValueTitle}
                  <Popover placement="top" content={latestValuePopoverText} />
                  <div className="value latest-value">
                    {property.financials.latestValue::dollar()}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="blue-label">LIABILITIES</div>
                </div>
              </div>
              <div className="gray-line" />
              <div className="row my-row">
                <div className="col-xs-4 col-left">
                  <span className="icn icn-medium icn-debt" />
                </div>
                <div className="col-xs-8 col-right">
                  Debt
                  <div className="value outstanding-debt">
                    {property.financials.debtTerms
                      ? property.financials.debtTerms.outstandingDebt::dollar()
                      : '$0'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="black-line" />
          <div className="row my-row">
            <div className="col-md-12 total">
              Equity = {property.financials.equity::dollar()} &nbsp;
              <Popover placement="top" content={equityPopoverText} />
            </div>
          </div>
          <div className="gray-line" />
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <FinancialsBox
                className="financials-box-small latest-value"
                title="EQUITY"
                value={property.financials.equity::dollar()}
              />
            </div>
            <div className="col-md-6">
              <FinancialsBox
                className="financials-box-small brick-value"
                title={brickValuationTitle}
                value={brickValuation::brickPrice()}
              />
            </div>
          </div>
          <div className="row my-row">
            <div className="col-md-12">
              Next Valuation Date: {property.nextValuationDate::date()}
              <div className="see-faq">
                BrickX conducts Independent Valuations on an annual or
                semi-annual basis for all properties on the platform. To learn
                more,{' '}
                <a href="/faq" className="text-underline">
                  see our FAQs
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </FinancialsPanel>
  );
};

IndependentValuation.propTypes = {
  property: propertyPropType.isRequired,
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
};

export default IndependentValuation;

import classNames from 'classnames';
import { HeadingXSmall } from 'src/design/components/heading/Headings';
import {
  onboardingQuestionsUrl,
  onboardingSmartInvestDetailsUrl,
  helpCenterDifferenceBetweenSiAndBmoArticleUrl,
} from 'src/utils/pageUrls';
import {
  trackSmartInvestSelected,
  trackBuildMyOwnSelected,
  trackPickAPlanClick,
  trackNotSureYetAnswerSelected,
} from 'src/tracking/onboarding';
import Expandable from 'src/design/components/expandable/Expandable';
import Heading from 'src/design/components/heading/Heading';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import OnClicklink from 'src/design/components/hyperlink/OnClicklink';
import OnboardingBackButton from 'src/components/oldOnboarding/common/onboardingBackButton/OnboardingBackButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContentWide,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SelectionButton from 'src/design/components/button/SelectionButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SubHeading from 'src/design/components/heading/SubHeading';
import styles from 'src/components/oldOnboarding/productSelection/ProductSelection.mscss';
import withState from 'src/decorators/withState';

const AnswerButton = ({ href, text, testRef = 'answer-button' }) => {
  const onAnswerSelect = () => trackNotSureYetAnswerSelected(text);

  return (
    <SelectionButton
      fullWidth
      arrow
      testRef={testRef}
      link={href}
      className={styles['notSureYet--answerItem']}
      target="_blank"
      onClick={() => onAnswerSelect()}
    >
      {text}
    </SelectionButton>
  );
};

const ExpandableImNotSureYetButton = withState({
  Component: ({ className, setState, state: { menuOpen } }) => {
    return (
      <div className={classNames(className, styles['notSureYet--container'])}>
        <OnClicklink
          className={classNames(styles['notSureYet--button'], {
            [styles['notSureYet--button-menuOpen']]: menuOpen,
          })}
          onClick={() => {
            trackPickAPlanClick("I'm not sure yet");
            setState({ menuOpen: !menuOpen });
          }}
          testRef="product-select-im-not-sure-yet"
        >
          I'm not sure yet
        </OnClicklink>

        <Expandable
          expanded={menuOpen}
          testRef="product-select-tell-us-more-answer-area"
        >
          <div
            className={styles['notSureYet--tellUsMoreContainer']}
            data-test-reference="expandable-content"
          >
            <Spacing bottom="large">
              <HeadingXSmall>
                <h4>What would you like to do?</h4>
              </HeadingXSmall>
            </Spacing>
            <div className={styles['notSureYet--answersList']}>
              <AnswerButton
                href={helpCenterDifferenceBetweenSiAndBmoArticleUrl()}
                text="Learn more about our different products"
              />
            </div>
          </div>
        </Expandable>
      </div>
    );
  },
  mapPropsToInitialState: () => ({
    menuOpen: false,
  }),
  componentDidMount: () => {},
});

const ProductSelection = () => (
  <OnboardingScreen testRef="onboarding-product-selection">
    <OnboardingScreenHeader>
      <Heading className={styles.heading}>
        How would you like to start investing?
      </Heading>
    </OnboardingScreenHeader>
    <OnboardingScreenContentWide>
      <Spacing bottom="small">
        <Hyperlink
          className={classNames(styles.card, styles.smartInvestCard)}
          href={onboardingSmartInvestDetailsUrl()}
          onClick={trackSmartInvestSelected}
          testRef="product-select-auto-invest"
        >
          <div className={styles.badge}>HASSLE FREE</div>
          <img
            className={styles.graphic}
            src="/static/images/icons/icon-product-selection-smart-invest.svg"
            alt="smart invest graphic"
          />
          <div className={styles.cardContent}>
            <Spacing bottom="x-small">
              <SubHeading>Smart Invest</SubHeading>
            </Spacing>
            <Paragraph>
              Invest monthly (via direct debit) to build a portfolio of Bricks
              across our properties.
            </Paragraph>
          </div>
        </Hyperlink>
      </Spacing>
      <Hyperlink
        className={styles.card}
        href="/account/deposit"
        onClick={trackBuildMyOwnSelected}
        testRef="product-select-diy"
      >
        <img
          className={styles.graphic}
          src="/static/images/icons/icon-product-selection-bmo.svg"
          alt="smart invest graphic"
        />
        <div className={styles.cardContent}>
          <Spacing bottom="x-small">
            <SubHeading>Build My Own</SubHeading>
          </Spacing>
          <Paragraph>
            I'm comfortable with choosing which properties to invest in. I'd
            like to buy my own Bricks.{' '}
          </Paragraph>
        </div>
      </Hyperlink>

      <Spacing top="normal">
        <ExpandableImNotSureYetButton />
      </Spacing>
      <OnboardingBackButton
        href={onboardingQuestionsUrl()}
        testRef="back-button"
      />
    </OnboardingScreenContentWide>
  </OnboardingScreen>
);

export default ProductSelection;

import classNames from 'classnames';
import PropertyFeaturesAmenities from 'src/components/propertyCards/common/propertyFeaturesBar/propertyFeaturesAmenities/PropertyFeaturesAmenities.jsx';
import styles from 'src/components/propertyCards/common/propertyFeaturesBar/PropertyFeaturesBar.mscss';
import InvestmentTypes from 'src/enums/investmentTypes';

const PropertyFeaturesGearedIndicator = ({ property }) =>
  property.financials.lVR > 0 && (
    <div data-test-reference="geared">
      <strong>Geared</strong>
    </div>
  );

const PropertyFeaturesBar = ({ property }) => (
  <div
    className={classNames(styles.propertyFeaturesBar)}
    style={{
      overflow: 'hidden',
    }}
  >
    <PropertyFeaturesAmenities property={property} />
    {property.investmentType === InvestmentTypes.property ? (
      <PropertyFeaturesGearedIndicator property={property} />
    ) : (
      <strong className="capitalize">{property.assetType}</strong>
    )}
  </div>
);

export default PropertyFeaturesBar;

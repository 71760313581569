import _ from 'lodash';
import OrderSide from 'scripts/constants/OrderSide';
import {
  priceDate as priceDateSort,
  date as dateSort
} from 'scripts/utilities/sorters';


function filterByOrderSide(orderSide) {
  return _.filter(this, { orderSide });
}

function filterByOrderCommission(commissionCheck) {
  return _.filter(this, (order) => commissionCheck(order));
}

function sum(numbers) {
  return _.reduce(numbers, (result, num) => result + num, 0);
}

export function aggregateList(array, groupBy, aggregateOn) {
  return _.chain(array)
    .groupBy((item) => Number((item[groupBy])).toFixed(2))
    .map((value, key) => ({
      price: parseFloat(key),
      quantity: sum(_.map(value, aggregateOn)),
    }))
    .value();
}

const monthlyDistributionsGetter = (state) => state.market.monthlyDistributions;
const tradesGetter = (state) => state.market.trades;
const lastNTradesGetter = (state) => state.market.lastNTrades;
const orderBookStatusGetter = (state) => state.market.orderBookStatus;
const ordersGetter = (state) => state.market.orders;
const remainingBricksGetter = (state) => state.market.remainingBricks;
const bricksTransactedLastMonthGetter = (state) => state.market.bricksTransactedLastMonth;
const tradeProposalGetter = (state) => state.market.tradeProposal;
const tradeSucceedGetter = (state) => state.market.tradeSucceed;
const myOrdersGetter = (state) => state.market.myOrders;
const myTradesGetter = (state) => state.market.myTrades;
const transactionsGetter = (state) => state.market.transactions;
const returnsCalculatorStatusGetter = (state) => state.market.returnsCalculatorStatus;
const marketStatusGetter = (state) => state.market.marketStatus;
const promotionsGetter = (state) => state.market.promotions;
const feeFreePromotionStatusGetter = (state) => state.market.feeFreePromotionStatus;


export const monthlyDistributionsSelector = (state) => ({
  monthlyDistributions: monthlyDistributionsGetter(state)
});

export const tradesSelector = (state) => ({
  trades: tradesGetter(state)
});

export const lastNTradesSelector = (state) => ({
  lastNTrades: lastNTradesGetter(state)
});

export const remainingBricksSelector = (state) => ({
  remainingBricks: remainingBricksGetter(state)
});

export const bricksTransactedLastMonthSelector = (state) => ({
  bricksTransactedLastMonth: bricksTransactedLastMonthGetter(state)
});

export const tradeProposalSelector = (state) => ({
  tradeProposal: tradeProposalGetter(state)
});

export const tradeSucceedSelector = (state) => ({
  tradeSucceed: tradeSucceedGetter(state)
});

export const ordersBuySelector = (state) => ({
  ordersBuy: ordersGetter(state) ? aggregateList(ordersGetter(state)::filterByOrderSide(OrderSide.BUY)::priceDateSort(false, true), 'price', 'quantity') : undefined
});

export const ordersSellSelector = (state) => ({
  ordersSell: ordersGetter(state) ? aggregateList(ordersGetter(state)::filterByOrderSide(OrderSide.SELL)::priceDateSort(true, true), 'price', 'quantity') : undefined
});

export const returnsCalculatorStatusSelector = (state) => ({
  returnsCalculatorStatus: returnsCalculatorStatusGetter(state)
});

export const marketStatusSelector = (state) => ({
  marketStatus: marketStatusGetter(state)
});

export const promotionsSelector = (state) => ({
  promotions: promotionsGetter(state)
});

export const feeFreePromotionStatusSelector = (state) => ({
  feeFreePromotionStatus: feeFreePromotionStatusGetter(state)
});

export const orderBookStatusSelector = (state) => ({
  orderBookStatus: orderBookStatusGetter(state)
});

const myOrdersPendingPreSelector = (state) => ({
  pendingPre: myOrdersGetter(state)::filterByOrderSide(OrderSide.BUY)::filterByOrderCommission((order) => order.commission === 0)::dateSort(false)
});

const myOrdersPendingBuySelector = (state) => ({
  pendingBuy: myOrdersGetter(state)::filterByOrderSide(OrderSide.BUY)::filterByOrderCommission((order) => order.commission > 0)::dateSort(false)
});

const myOrdersPendingSellSelector = (state) => ({
  pendingSell: myOrdersGetter(state)::filterByOrderSide(OrderSide.SELL)::dateSort(false)
});

export const myOrdersSelector = (state) => ({
  myOrders: {
    list: myOrdersGetter(state),
    ...myOrdersPendingPreSelector(state),
    ...myOrdersPendingBuySelector(state),
    ...myOrdersPendingSellSelector(state),
  }
});

export const feesSelector = (state) => ({
  fees: state.market.fees,
});

export const myTradesSelector = (state) => ({
  myTrades: {
    list: myTradesGetter(state)
  }
});

export const transactionsSelector = (state) => ({
  transactions: {
    list: transactionsGetter(state)
  }
});

import BrickPriceStat from 'src/components/propertyCards/common/stats/BrickPriceStat';
import EstNetRentalYieldStat from 'src/components/propertyCards/common/stats/EstNetRentalYieldStat';
import HistoricalSuburbGrowthStat from 'src/components/propertyCards/common/stats/HistoricalSuburbGrowthStat';
import PreLaunchFlag from 'src/components/propertyCards/common/flags/PreLaunchFlag';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import PropertyCardBodyFiveStatLayout from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardBodyFiveStatLayout';
import PropertyCardButtonContainer from 'src/components/propertyCards/common/propertyCardButtonContainer/PropertyCardButtonContainer';
import PropertyCardContainer from 'src/components/propertyCards/common/propertyCardContainer/PropertyCardContainer';
import PropertyCardFeature from 'src/components/propertyCards/common/propertyCardHero/PropertyCardHero';
import PropertyCardHeader from 'src/components/propertyCards/common/propertyCardHeader/PropertyCardHeader';
import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import { dayMonthAndShortYearNoDash } from 'scripts/utilities/formatters';
import { propertyUrl } from 'src/utils/pageUrls';

const PropertyCardPreLaunch = ({ property, historicalGrowthMetrics }) => (
  <PropertyCardContainer link={propertyUrl(property.propertyCode)}>
    <PropertyCardHeader
      property={property}
      flag={<PreLaunchFlag />}
      style={PropertyCardHeader.styles.COMING_SOON}
    />
    <PropertyCardFeature property={property} />
    <PropertyCardBodyFiveStatLayout
      secondaries={[
        <EstNetRentalYieldStat property={property} />,
        <HistoricalSuburbGrowthStat
          historicalGrowthMetrics={historicalGrowthMetrics}
          propertyType={property.propertyType}
        />,
        <BrickPriceStat property={property} />,
        <PropertyCardStat
          label={
            <span>
              Pre-Order
              <br />
              Opens
            </span>
          }
          value={property.launchDateTime::dayMonthAndShortYearNoDash()}
          valueProps={{ 'data-test-reference': 'investors' }}
        />,
      ]}
    />
    <PropertyCardButtonContainer>
      <PrimaryButton
        className="property-card__button"
        color={PrimaryButton.colors.ALTERNATIVE}
        arrow
        testRef="button"
      >
        PREVIEW
      </PrimaryButton>
    </PropertyCardButtonContainer>
  </PropertyCardContainer>
);

export default PropertyCardPreLaunch;

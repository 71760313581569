import { Component } from 'react';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

// import BricksSoldProgressBar from 'scripts/components/property/BricksSoldProgressBar';
import InitialBrickPriceBox from 'scripts/components/property/trade/InitialBrickPriceBox';
import InfoBox from 'scripts/components/helpers/InfoBox';
import GeneralInfoBox from 'scripts/components/property/GeneralInfoBox';

import Constants from 'scripts/constants/Constants';
import { timeWithTimeZone, date, number } from 'scripts/utilities/formatters';

export default class ComingSoonInfoBox extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
  };

  render() {
    const { property } = this.props;
    return (
      <div className="coming-soon-info-box">
        <InitialBrickPriceBox
          property={property}
          shownInLargerText={Constants.TRUE}
        />
        <div className="row">
          <div className="general-info">
            <GeneralInfoBox
              image={
                <img
                  src="/static/images/icons/icn_calendar.png"
                  className="icon"
                />
              }
              title="Pre-Order Opens"
              value={
                <span>
                  <span>{property.launchDateTime::date()}</span>
                  <br />
                  <span>{property.launchDateTime::timeWithTimeZone()}</span>
                </span>
              }
              cssClasses="col-xs-12 pre-order-opens-info"
            />
            {/* <div className="col-xs-6"> */}
            {/* <img src="/static/img/bx-logo-x-only.svg" /> */}
            {/* <BricksSoldProgressBar property={property} showText/> */}
            {/* </div> */}
          </div>
        </div>

        <InfoBox>
          {`Interested in reserving Bricks? Only ${property.financials.numberBricks::number()} Bricks are available and
          are reserved on a first come, first served basis. To reserve Bricks
          when Pre-Order opens you must have sufficient Funds Available in your
          BrickX Wallet.`}
        </InfoBox>
      </div>
    );
  }
}

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { capitalize, dollar } from 'scripts/utilities/formatters';
import InvestmentTypes from 'src/enums/investmentTypes';

const DebtBreakdown = ({ property }) => {
  const isGeared = property.financials.gearingEffect > 0;
  const isFixedTerm =
    isGeared && property.financials.debtTerms.fixedLoanTerms > 0;
  return (
    <FinancialsPanel
      className="debt-breakdown-panel"
      title={
        property.investmentType === InvestmentTypes.property
          ? 'Debt Breakdown'
          : 'Loan Advance'
      }
    >
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Initial Debt'
            : 'Initial Loan Advance'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right initial-debt">
          {property.investmentType === InvestmentTypes.property
            ? isGeared
              ? property.financials.debtTerms.initialDebt::dollar()
              : '$0'
            : property.financials.loanAdvance::dollar()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Loan Terms</div>
        <div className="col-xs-5 col-no-padding-left text-right loan-terms">
          {property.investmentType === InvestmentTypes.property
            ? isGeared
              ? `${property.financials.debtTerms.loanTerms} Years`
              : '0 Year'
            : 'up to 3 Years'}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Loan Type</div>
        <div className="col-xs-5 col-no-padding-left text-right loan-term-type">
          {property.investmentType === InvestmentTypes.property
            ? isGeared
              ? property.financials.debtTerms.loanTermType::capitalize()
              : 'NA'
            : '1st mortgages'}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-6 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Fixed Terms'
            : 'Rate Terms'}
        </div>
        <div className="col-xs-6 col-no-padding-left text-right fixed-terms">
          {property.investmentType === InvestmentTypes.property
            ? isFixedTerm
              ? `${property.financials.debtTerms.fixedLoanTerms} Years (Interest Only)`
              : 'NA'
            : 'fixed or variable'}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Interest Rate'
            : 'Interest Rate Target'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right interest-rate">
          {property.investmentType === InvestmentTypes.property
            ? isGeared
              ? `${property.financials.debtTerms.interestRatePerc}%`
              : '0%'
            : '7.00% - 8.00%'}
        </div>
      </div>
      <div className="gray-line" />
      {property.investmentType === InvestmentTypes.property ? (
        <div className="row my-row note">
          <div className="col-md-12">
            Note:{' '}
            {isGeared
              ? `${property.financials.debtTerms.loanDescription}`
              : 'NA'}
          </div>
        </div>
      ) : (
        <div className="row my-row">
          <div className="col-xs-7 col-no-padding-right bold">
            Property Security
          </div>
          <div className="col-xs-5 col-no-padding-left text-right">
            residential / commercial
          </div>
        </div>
      )}
    </FinancialsPanel>
  );
};

DebtBreakdown.propTypes = {
  property: propertyPropType.isRequired,
};

export default DebtBreakdown;

import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import { getHistoricalGrowthMetricsHighestNumberOfYearsGrowth, getPropertyCardHistoricalGrowthLabelYears } from 'scripts/utilities/propertyHelper';
import { historicalGrowthCardTitle } from 'src/settings/text';
import { historicalRBACashRate10yr, historicalNationalGrowthRate10yr } from 'src/settings/financials';

const HistoricalSuburbGrowthStat = ({ historicalGrowthMetrics, propertyType }) => {
  if (propertyType.endsWith('debt')) {
    return (
      <PropertyCardStat
        label={<span>10yr Historical<br/>RBA Cash Rate</span>}
        value={historicalRBACashRate10yr}
      />
    );
  }
  if (propertyType.endsWith('fund')) {
    return (
      <PropertyCardStat
        label={<span>10yr Historical<br/>National Growth Rate</span>}
        value={historicalNationalGrowthRate10yr}
      />
    );
  }
  return (
    <PropertyCardStat
      label={<span>{getPropertyCardHistoricalGrowthLabelYears(historicalGrowthMetrics)} {historicalGrowthCardTitle(propertyType)}</span>}
      value={<span>{getHistoricalGrowthMetricsHighestNumberOfYearsGrowth(historicalGrowthMetrics)} <small>p.a.</small></span>}
      labelProps={{ 'data-test-reference': 'historical-growth-label' }}
      valueProps={{ 'data-test-reference': 'historical-growth-value' }}
    />
  );
};

export default HistoricalSuburbGrowthStat;

import classNames from 'classnames';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { Heading, HeadingSmall } from 'src/design/components/heading/Headings';
import TickedBulletList from 'src/design/components/bulletList/TickedBulletList';
import styles from 'src/components/landingPages/common/howDoIMakeMoneySection/HowDoIMakeMoneySection.mscss';

const SubHeadingAndBulletList = ({ className, title, image, items }) => (
  <div className={classNames(className, styles['subHeadingAndContent--item'])}>
    <div className={styles['subHeadingAndContent--item--inner']}>
      {image ? image : null}
      <Spacing bottom="normal">
        <HeadingSmall className={styles.subHeading}>
          <h3>{title}</h3>
        </HeadingSmall>
      </Spacing>
      <TickedBulletList items={items} />
    </div>
  </div>
);

const HowDoIMakeMoneySection = ({
  className,
  secondaryContent,
  darkBackground,
}) => (
  <PageMarginContainer
    className={className}
    backgroundColor={darkBackground && PageMarginContainer.colors.GREY}
  >
    <section className={styles.howDoIMakeMoneyContainer}>
      <img
        className={styles.headingImage}
        src="/static/img/icon-hand-holding-a-flowing-dollar-sign-plant.svg"
        alt="graphic"
      />

      <Spacing bottom="3x-large">
        <Heading className={styles.sectionHeading} align="center">
          <h2>How do I make money?</h2>
        </Heading>
      </Spacing>

      <div className={styles.subHeadingAndContentContainer}>
        <SubHeadingAndBulletList
          title="Capital Returns"
          items={[
            'As property and financial asset prices change so does the value of your Bricks.',
            'Properties, mortgage and financial asset Investments are re-valued every 6-12 months so you can keep track of your performance',
            'Realise any capital returns by selling Bricks.',
          ]}
          image={
            <img
              className={styles.figureImage}
              src="/static/img/illustration-house-infront-of-a-spark-line-chart.svg"
              alt="an illustraion depicting a house, which is connected to a series of dollar coins, next to a calendar"
            />
          }
        />

        <SubHeadingAndBulletList
          className={classNames(
            styles['subHeadingAndContent--item-withLeftBorder'],
            {
              [styles['subHeadingAndContent--darkBackground-ampersand']]:
                darkBackground,
            }
          )}
          title="Monthly Income Distributions"
          items={[
            'Receive your share of any rent, interest or fund income, after expenses, paid monthly.',
            "We'll take care of the hassles of managing the properties, mortgages and financial assets.",
          ]}
          image={
            <img
              className={styles.figureImage}
              src="/static/img/illustration-house-connected-with-series-of-dollar-coins-and-a-calendar.svg"
              alt="an illustraion depicting a house, which is connected to a series of dollar coins, next to a calendar"
            />
          }
        />
      </div>

      {secondaryContent}
    </section>
  </PageMarginContainer>
);

export default HowDoIMakeMoneySection;

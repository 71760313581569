import React, { Component, Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import Hyperlink from 'scripts/components/shared/Hyperlink';
import ContentsService from 'scripts/services/ContentsService';
import PropertyService from 'scripts/services/PropertyService';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Meta from 'src/components/meta/Meta';
import NumberFormatter from 'scripts/utilities/NumberFormatter';
import PropertyStatus from 'scripts/constants/PropertyStatus';
import { green } from 'src/themes/brickx/colors';
import ChartJsBarWrapper from 'scripts/components/chartjs/ChartJsWrapper';
import { renderCode } from 'src/settings/properties';

const Layout = styled.div`
  padding-bottom: 100px;
  .td-update {
    width: 30%;
  }
  .header {
    h1 {
      margin-bottom: -10px;
    }
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .flexBoxCentered {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    color: #111;
  }
  .DistributionCard {
    min-width: 768px;
    border-bottom: 1px solid #d4d4d4;
    overflow: hidden;
    transition: box-shadow 0.25s ease;

    .DistributionCardInner {
      padding: 15px;
    }
    &:hover {
      background: #f8f8f8;
      cursor: pointer;
    }
    .DistributionCardHeader {
      font-size: 1.5rem;
      .fa {
        font-size: 1rem;
      }
    }
    .DistributionCardAmount {
      font-size: 2rem;
      font-weight: 800;
    }
    .bottomButton {
      font-size: 1rem;
      margin-left: 10px;
    }
  }
  .chartWrapper {
    width: 300px;
  }
  @media only screen and (max-width: 768px) {
    & {
      width: 100%;
    }
    .content {
      padding: 0 10px;
    }
    .DistributionCard {
      width: 100%;
      min-width: unset;
    }
  }
`;

class LatestDistributionUpdate extends Component {
  state = {
    info: null,
  };

  componentDidMount() {
    PropertyService.getLatestMonthlyUpdates(this.props.propertyCode).then(
      (res) => {
        if (res) {
          this.setState({
            info: res,
          });
        }
      }
    );
  }

  render() {
    if (this.state.info) {
      return <div>{this.state.info.monthlyDistributionInfo}</div>;
    }
    return '';
  }
}

LatestDistributionUpdate.propTypes = {
  propertyCode: PropTypes.string,
};

const DistributionCard = ({ payload, ...props }) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const { historical } = payload;

  const chartConfig = {
    data: {
      labels: historical.map((ele) =>
        moment(ele.distributionDate).format('MMM YYYY')
      ),
      datasets: [
        {
          label: 'Payment',
          data: historical.map((ele) => ele.amount),
          backgroundColor: green,
          borderRadius: 10,
          barThickness: 8,
          maxBarThickness: 10,
          barPercentage: 0.5,
        },
      ],
    },
    options: {
      scales: {
        xAxis: {
          categoryPercentage: 0.5,
          grid: { display: false },
          barPercentage: 0.4,
        },
        yAxis: {
          barThickness: 1,
          grid: { display: false },
          cornerRadius: 1,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return (
    <div
      className="DistributionCard"
      {...props}
      style={{ background: isCollapsed && '#f7f7f7' }}
    >
      <div
        className="DistributionCardInner flexBoxCentered"
        onClick={() => setCollapsed(!isCollapsed)}
      >
        <div className="DistributionCardHeader">
          <div
            style={{
              borderLeft: `5px solid ${payload.hasPaid ? green : '#dddddd'}`,
              padding: '0 6px',
            }}
          >
            <Hyperlink
              linkTo={`/investments/${payload.propertyCode}/distributions`}
            >
              <strong>{renderCode(payload.propertyCode)}</strong>
            </Hyperlink>
          </div>
          <div>
            <small>{payload.propertyAddress}</small>
          </div>
        </div>
        <div className="DistributionCardAmount">
          <div className="flexBoxCentered">
            <div>
              ${payload.amount} <small>/ brick</small>
            </div>
            <div className="bottomButton">
              {isCollapsed ? (
                <i className="fa fa-chevron-up"></i>
              ) : (
                <i className="fa fa-chevron-down"></i>
              )}
            </div>
          </div>
        </div>
      </div>
      {isCollapsed && (
        <Fragment>
          <div
            className="DistributionCardInner"
            style={{ borderTop: '1px solid #aaa' }}
          >
            <div className="flexBox">
              <div>
                <div>
                  <small>Total Paid</small>
                </div>
                <div style={{ fontSize: '1.8rem', fontWeight: '800' }}>
                  <NumberFormatter
                    value={payload.totalPaid}
                    prefix="$"
                    suffix=" / brick"
                  />
                </div>
                <div>
                  <small>Total Paid Months</small>
                </div>
                <div style={{ fontSize: '1.8rem', fontWeight: '800' }}>
                  {payload.totalPaidMonths}
                </div>
              </div>
              <div className="chartWrapper">
                <div>
                  <strong>Last 12 payments:</strong>
                </div>
                <ChartJsBarWrapper {...chartConfig} />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const LatestDistributions = () => {
  const [data, setData] = useState([]);
  const [paidMonth, setPaidMonth] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const propertyPayload = await PropertyService.getPublishedProperties();
      const distributionPayload =
        await ContentsService.getLatestDistributions();
      const allDistributionPayload =
        await ContentsService.getAllDistributions();
      const data = propertyPayload
        .filter((ele) => ele.propertyStatus !== PropertyStatus.OFF_MARKET)
        .filter((ele) => ele.propertyCode in distributionPayload)
        .filter((ele) => ele.propertyStatus !== PropertyStatus.OFF_MARKET)
        .map((ele) => ({
          id: ele.id,
          propertyCode: ele.propertyCode,
          propertyAddress: `${ele.address} ${ele.suburb} ${ele.state}`,
          amount: distributionPayload[ele.propertyCode].amount,
          distributionDate:
            distributionPayload[ele.propertyCode].distributionDate,
        }))
        .sort((a, b) => a.id - b.id);
      const maxDate = _.max(data.map((ele) => moment(ele.distributionDate)));
      const tableData = data
        .map((r) => ({
          ...r,
          totalPaid: _.sumBy(
            allDistributionPayload[r.propertyCode] || [],
            'amount'
          ),
          totalPaidMonths: (allDistributionPayload[r.propertyCode] || [])
            .length,
          historical: allDistributionPayload[r.propertyCode]
            ? _.takeRight(_.reverse(allDistributionPayload[r.propertyCode]), 12)
            : [],
        }))
        .map((r) => {
          const isLatest =
            moment(r.distributionDate).format() === maxDate.format();
          return isLatest
            ? {
                ...r,
                hasPaid: 1,
              }
            : {
                ...r,
                amount: 0,
                hasPaid: 0,
              };
        });
      setData(tableData);
      setPaidMonth(maxDate);
    };
    loadData();
  }, []);

  return (
    <Layout>
      <Meta
        {...{
          title: 'Latest Monthly Distributions | BrickX',
          description: 'Latest Monthly Distributions of BrickX Properties',
        }}
      />
      <div className="header">
        <h1>BrickX Latest Monthly Distributions</h1>
        <h3>
          <strong>
            {paidMonth ? paidMonth.format('MMM YYYY') : 'Loading...'}
          </strong>
        </h3>
      </div>
      <div className="content">
        {data.map((ele, idx) => (
          <DistributionCard payload={ele} key={idx} />
        ))}
      </div>
    </Layout>
  );
};

export default LatestDistributions;

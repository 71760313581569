import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import MathFormula from 'scripts/components/property/MathFormula';

import Constants from 'scripts/constants/Constants';
import {
  getBrickPriceTitle,
  getBrickPrice,
} from 'scripts/utilities/propertyHelper';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  brickPrice as brickPriceFmt,
  dollar,
  percentDecimal,
  now,
} from 'scripts/utilities/formatters';
import Formatters from 'scripts/utilities/formattersV2';
import InvestmentTypes from 'src/enums/investmentTypes';

const Equal = () => <i className="equal" />;

const YieldCalculations = ({ property }) => {
  const brickPriceTitle = getBrickPriceTitle(property);
  const brickPrice = getBrickPrice(property);

  const denominatorCopy =
    property.investmentType === InvestmentTypes.mortgage
      ? `Estimated Gross Income is calculated assuming 100% loan drawdown, and using the current interest schedule. Estimated Net Interest Income is forecast based on current interest income and forecasted expenses. ${brickPriceTitle} at ${now()}`
      : property.investmentType === InvestmentTypes.financialAsset
      ? `Estimated Gross Income is calculated assuming 100% loan drawdown and/or tenancy, and using the current interest and rent schedules. Estimated Net Income is forecast based on current interest and rental income and forecasted expenses. ${brickPriceTitle} at ${now()}`
      : property.tenantStatus.tenanted
      ? `Estimated Gross Income is calculated assuming 100% tenancy, and using the current rent. Estimated Net Rental Income is forecast based on current rental income and forecasted expenses. ${brickPriceTitle} at ${now()}`
      : `Estimated Gross Income is calculated assuming 100% tenancy, and using the forecasted rent. Estimated Net Rental Income is forecast based on forecasted rental income and forecasted expenses. ${brickPriceTitle} at ${now()}`;
  return (
    <FinancialsPanel
      title="Yield Calculations"
      titleIcon="icn-calculator"
      className="panel-yield-calculations"
    >
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="col-sm-6">
            <div className="math-formulas">
              <MathFormula
                className="estimated-gross-rental-yield-1"
                molecular={`Est. Gross Income/${Formatters.numeric.number(
                  property.financials.numberBricks
                )} Bricks`}
                denominator={brickPriceTitle}
              />
              <MathFormula
                className="estimated-gross-rental-yield-2"
                molecular={`${property.financials.annualGrossRentalIncome::dollar()} / ${Formatters.numeric.number(
                  property.financials.numberBricks
                )}`}
                denominator={`${brickPrice::brickPriceFmt()}`}
              />
              <Equal />
            </div>
          </div>
          <div className="col-sm-6 gross-rental-yield">
            <FinancialsBox
              className="financials-box-small"
              title={
                <span>
                  ESTIMATED
                  <br />
                  {property.investmentType === InvestmentTypes.mortgage
                    ? 'GROSS INTEREST YIELD'
                    : property.investmentType === InvestmentTypes.financialAsset
                    ? 'GROSS INCOME YIELD'
                    : 'GROSS RENTAL YIELD'}
                </span>
              }
              value={property.financials.grossRentalYield::percentDecimal()}
              description={Constants.ANNUALISED}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="col-sm-6">
            <div className="math-formulas">
              <MathFormula
                molecular={`Est. Net Income/${Formatters.numeric.number(
                  property.financials.numberBricks
                )} Bricks`}
                denominator={brickPriceTitle}
              />
              <MathFormula
                molecular={`${property.financials.annualNetRentalIncome::dollar()} / ${Formatters.numeric.number(
                  property.financials.numberBricks
                )}`}
                denominator={`${brickPrice::brickPriceFmt()}`}
              />
              <Equal />
            </div>
          </div>
          <div className="col-sm-6 net-rental-yield">
            <FinancialsBox
              className="financials-box-small"
              title={
                <span>
                  ESTIMATED
                  <br />
                  {property.investmentType === InvestmentTypes.mortgage
                    ? 'NET INTEREST YIELD'
                    : property.investmentType === InvestmentTypes.financialAsset
                    ? 'NET INCOME YIELD'
                    : 'NET RENTAL YIELD'}
                </span>
              }
              value={property.financials.netRentalYield::percentDecimal()}
              description={Constants.ANNUALISED}
            />
          </div>
        </div>
        <div className="col-md-12">
          <small className="asterisk-info">{denominatorCopy}</small>
        </div>
      </div>
    </FinancialsPanel>
  );
};

YieldCalculations.propTypes = {
  property: propertyPropType.isRequired,
};

export default YieldCalculations;

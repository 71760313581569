import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import styled from 'styled-components';

const NoPendingOrdersIllustration = styled(Image).attrs({
  src: '/static/img/illustration-no-pending-orders-yet.svg',
})`
  max-width: ${(props) => (props.small ? '150px' : '300px')};
`;

const NoOrdersContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoOrdersContent = ({ message, illustration }) => (
  <NoOrdersContentContainer>
    {illustration}
    <Spacing top="normal">
      <Paragraph color="onSurfaceContrast500" align="center">
        {message}
      </Paragraph>
    </Spacing>
  </NoOrdersContentContainer>
);

const NoOrdersAtAll = () => (
  <NoOrdersContent
    message="No pending orders at the moment."
    illustration={<NoPendingOrdersIllustration />}
  />
);

export const NoPreOrders = () => (
  <NoOrdersContent
    message="No pending pre orders at the moment."
    illustration={<NoPendingOrdersIllustration small />}
  />
);

export const NoBuyOrders = () => (
  <NoOrdersContent
    message="No pending buy orders at the moment."
    illustration={<NoPendingOrdersIllustration small />}
  />
);

export const NoSellOrders = () => (
  <NoOrdersContent
    message="No pending sell orders at the moment."
    illustration={<NoPendingOrdersIllustration small />}
  />
);

export const NoOrdersPanel = () => (
  <WidgetPanel title="Pending Orders" testRef="no-orders-panel">
    <NoOrdersAtAll />
  </WidgetPanel>
);

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { depositInfo as depositInfoPropType } from 'scripts/constants/PropTypes';
import { depositInfoSelector } from 'scripts/redux/selectors/transactions';
import { fetchDepositInfo } from 'scripts/redux/actions/transactions';
import { sendPoliCompletedEvent } from 'scripts/redux/actions/segment/events/depositEvents';

import InfoBox from 'scripts/components/shared/InfoBox';


const mapStateToProps = (state) => ({
  ...depositInfoSelector(state),
});

@connect(mapStateToProps, {
  fetchDepositInfo
})
export default class SuccessPoli extends Component {
  static propTypes = {
    fetchDepositInfo: PropTypes.func.isRequired,
    depositInfo: depositInfoPropType,
    params: PropTypes.object.isRequired
  };

  state = {
    poliSucceedEventSent: false
  };

  componentDidMount() {
    const { params: { transferId }, fetchDepositInfo } = this.props;
    fetchDepositInfo(transferId);
    window.scrollTo(0, 0);
  }

  render() {
    const { depositInfo } = this.props;

    let bafmAmount = '__.00';
    if (depositInfo) {
      bafmAmount = depositInfo.bafmAmount;
    }

    this._sendPoliSucceedEvent();

    return (
      <div className="deposit-success deposit-success-poli">
        <div className="container">
          <div className="deposit-success__title deposit-success-poli__title">Thank you, your deposit was successful!</div>
          <div className="deposit-success-body deposit-success-poli-info">
            <div className="deposit-success-poli-info-greeting">You have been credited <span className="deposit-success-poli-info-greeting__amount">${bafmAmount}</span> to buy Bricks</div>
            <img className="deposit-success-poli-info-image" src="/static/images/deposit/streetscene.png"/>
            <div className="deposit-success-poli-info-link">
              <a href="/investments" className="button orange-button right-arrow-button text-center deposit-success-poli-info-link__button">BUY Bricks</a>
            </div>

            {depositInfo && depositInfo.bafmAmount !== depositInfo.amount &&
            <InfoBox className="deposit-success__settings-blue-notification">
              You have deposited over $20,000 in advanced funds. The remaining funds will automatically be credited to your BrickX
              Wallet once your transfer is received (1-3 days).
            </InfoBox>
            }
          </div>
        </div>
      </div>
    );
  }

  _sendPoliSucceedEvent = () => {
    const { depositInfo } = this.props;
    const { poliSucceedEventSent } = this.state;
    if (depositInfo && !poliSucceedEventSent) {
      sendPoliCompletedEvent({ amount: depositInfo.amount, bafmAmount: depositInfo.bafmAmount });
      this.setState({ poliSucceedEventSent: true });
    }
  };
}

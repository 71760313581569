import { Fragment } from 'react';
import PropTypes from 'prop-types';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { dollar } from 'scripts/utilities/formatters';
import { arrangementFeeProperties } from 'src/settings/text';
import InvestmentTypes from 'src/enums/investmentTypes';

const AcquisitionCost = ({ property, isPreorderOrComingSoonProperty }) => {
  return (
    <FinancialsPanel
      title={
        property.investmentType === InvestmentTypes.property
          ? 'Acquisition Costs'
          : 'Loan Provision Expenses'
      }
      className="panel-acquisition-costs"
    >
      {property.investmentType === InvestmentTypes.property && (
        <Fragment>
          <div className="row my-row">
            <div className="col-xs-7 col-no-padding-right bold">Stamp Duty</div>
            <div className="col-xs-5 col-no-padding-left text-right stamp-duty">
              {property.financials.stampDuty::dollar()}
            </div>
          </div>
          <div className="gray-line" />
        </Fragment>
      )}

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Legal &amp; Professional Fees
        </div>
        <div className="col-xs-5 col-no-padding-left text-right legal-professionals">
          {property.financials.legalProfessional::dollar()}
        </div>
      </div>

      <div className="gray-line" />

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {arrangementFeeProperties(property.propertyCode)}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right buyers-agent-fees">
          {property.financials.buyersAgentFees::dollar()}
        </div>
      </div>

      <div className="gray-line" />

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Other Costs</div>
        <div className="col-xs-5 col-no-padding-left text-right other-costs">
          {property.financials.otherAcquisitionCosts::dollar()}
        </div>
      </div>

      <div className="black-line" />

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Total Acquisition Costs'
            : 'Total Loan Provision Expenses'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right acquisition-costs">
          {property.financials.acquisitionCost::dollar()}
        </div>
      </div>

      <div className="black-line" />

      <div className="row my-row note">
        <div className="col-md-12">
          {property.investmentType === InvestmentTypes.property
            ? `Note: Acquisition Costs are amortised evenly over 60 month periods. ${
                isPreorderOrComingSoonProperty &&
                'Minor adjustments to Acquisitions Costs may occur prior to Settlement.'
              }`
            : 'Note: Loan Provision Expenses are amortised evenly over the initial term of the Trust.'}
        </div>
      </div>
    </FinancialsPanel>
  );
};

AcquisitionCost.propTypes = {
  property: propertyPropType.isRequired,
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
};

export default AcquisitionCost;

import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPlaceholder from 'react-placeholder';
import _, { property } from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';
import { SortBy } from 'scripts/constants/properties/SortBy';
import { averageAnnualGrowthMetricsSelector } from 'scripts/redux/selectors/historicalGrowth';
import {
  isAMarketClosedProperty,
  isAPreOrderProperty,
  isANewProperty,
  isAComingSoonProperty,
  getHistoricalGrowthMetricsForProperty,
  sortByDiscountWithPreOrderAndComingSoonFirst,
  sortBy20YearHistoricalSuburbGrowth,
} from 'scripts/utilities/propertyHelper';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { allMonthlyDistributionsSelector } from 'scripts/redux/selectors/property';
import {
  user as userPropType,
  property as propertyPropType,
  averageAnnualGrowthMetrics as averageAnnualGrowthPropType,
} from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
// import CardFutureProperty from 'scripts/components/property/CardFutureProperty';
import Configuration from 'scripts/constants/Configuration';
import ExpandableExplainerVideo from 'src/components/propertiesIndex/ExpandableExplainerVideo';
import FormSelect from 'src/design/components/formSelect/FormSelect';
import MoreAboutBrickxProperties from 'scripts/components/property/MoreAboutBrickxProperties';
import Numbers from 'scripts/constants/Numbers';
import PropertiesMeta from 'src/components/meta/PropertiesMeta';
import PropertyCardNewProperty from 'src/components/propertyCards/propertyCardNewProperty/PropertyCardNewProperty';
import PropertyCardPreLaunch from 'src/components/propertyCards/propertyCardPreLaunch/PropertyCardPreLaunch';
import PropertyCardPlaceHolder from 'scripts/components/placeholders/property/CardPlaceHolder';
import PropertyCardPreOrder from 'src/components/propertyCards/propertyCardPreOrder/PropertyCardPreOrder';
import PropertyCardMarketClosed from 'src/components/propertyCards/PropertyCardMarketClosed';
import PropertyCard from 'src/components/propertyCards/PropertyCardV2';
import PropertySmartInvestSignupDriver from 'src/components/propertySmartInvestSignupDriver/PropertySmartInvestSignupDriver';
import ScrollDownHint from 'src/design/components/scrollDownHint/ScrollDownHint';
import history from 'src/browser/history';
import styles from 'scripts/containers/PropertiesIndex.mscss';
import PropertyStatus from 'scripts/constants/PropertyStatus';
import Formatters from 'scripts/utilities/formattersV2';
import { BsFilter, BsSortDownAlt } from 'react-icons/bs';
import AustralianStatesAndTerritories from 'scripts/constants/AustralianStatesAndTerritories';

const stateMapping = {
  ...AustralianStatesAndTerritories,
  secured_debt: 'Secured Debt',
  unsecured_debt: 'Unsecured Debt',
  diversified_debt: 'Diversified Debt',
  equity_fund: 'Equity Fund',
  debt_fund: 'Debt Fund',
  diversified_fund: 'Diversified Fund',
  financial_asset: 'Financial Asset',
};

const preSortedType = [
  'unit',
  'house',
  'farm',
  'offices',
  'multiunit',
  'multihouse',
  'development',
  'commercial',
  'secured_debt',
  'unsecured_debt',
  'diversified_debt',
  'equity_fund',
  'debt_fund',
  'diversified_fund',
];

const TopBannerContainer = styled.div`
  margin-bottom: 16px;
  padding: 0 15px;
`;

const propertiesListPlaceholder = (
  <div className="properties-list-placeholder">
    <div className="row">
      {[
        ..._.range(
          Numbers.ZERO,
          Configuration.DEFAULT_NUMBER_OF_PROPERTY_CARD_PLACEHOLDER
        ),
      ].map((index) => (
        <div key={index} className="col-md-4 col-sm-6">
          <PropertyCardPlaceHolder />
        </div>
      ))}
    </div>
  </div>
);

const Sort = ({ displayName, sortByList, onSortChange }) => (
  <div className={styles['propertiesListPageFilterAndSort--container']}>
    <div
      style={{
        marginRight: '10px',
      }}
    >
      <span
        style={{
          display: 'inline-block',
          fontSize: '2rem',
          transform: 'translateY(5px)',
          marginRight: '3px',
        }}
      >
        <BsSortDownAlt />
      </span>
      <strong>
        <small>Sort by</small>
      </strong>
    </div>
    <FormSelect
      className={styles['propertiesListPageFilterAndSort--selectContainer']}
      selectClassName={styles['propertiesListPageFilterAndSort--select']}
      value={displayName}
      onChange={onSortChange}
      testRef="properties-sort"
    >
      {sortByList.map((sortOption, index) => (
        <option key={index} value={sortOption.displayName}>
          {sortOption.displayName}
        </option>
      ))}
    </FormSelect>
  </div>
);

const Filter = ({ by, states, onChange, identifier }) => (
  <div className={styles['propertiesListPageFilterAndSort--container']}>
    <div
      style={{
        marginRight: '10px',
      }}
    >
      <span
        style={{
          display: 'inline-block',
          fontSize: '2rem',
          transform: 'translateY(5px)',
          marginRight: '3px',
        }}
      >
        <BsFilter />
      </span>
      <strong>
        <small>{identifier}</small>
      </strong>
    </div>
    <FormSelect
      className={styles['propertiesListPageFilterAndSort--selectContainer']}
      selectClassName={styles['propertiesListPageFilterAndSort--select']}
      value={by}
      onChange={onChange}
      testRef={`properties-filter-${identifier}`}
    >
      <option value="">All {identifier}</option>
      {states.map((state, index) => (
        <option key={index} value={state}>
          {stateMapping[state] || Formatters.text.capitalize(state)}
        </option>
      ))}
    </FormSelect>
  </div>
);

const defaultSortMethod = (filteredProperties, sortMethodSelected) => {
  return _.orderBy(
    filteredProperties,
    sortMethodSelected.propertyName,
    sortMethodSelected.sortingWay
  );
};

const getPropertyCard = (property, averageAnnualGrowthMetrics) => {
  if (isAPreOrderProperty(property)) {
    return (
      <PropertyCardPreOrder
        property={property}
        historicalGrowthMetrics={getHistoricalGrowthMetricsForProperty(
          averageAnnualGrowthMetrics,
          property
        )}
      />
    );
  }

  if (isANewProperty(property)) {
    return (
      <PropertyCardNewProperty
        property={property}
        historicalGrowthMetrics={getHistoricalGrowthMetricsForProperty(
          averageAnnualGrowthMetrics,
          property
        )}
      />
    );
  }

  if (isAComingSoonProperty(property)) {
    return (
      <PropertyCardPreLaunch
        property={property}
        historicalGrowthMetrics={getHistoricalGrowthMetricsForProperty(
          averageAnnualGrowthMetrics,
          property
        )}
      />
    );
  }

  if (isAMarketClosedProperty(property)) {
    return (
      <PropertyCardMarketClosed
        property={property}
        historicalGrowthMetrics={getHistoricalGrowthMetricsForProperty(
          averageAnnualGrowthMetrics,
          property
        )}
      />
    );
  }

  return (
    <PropertyCard
      property={property}
      historicalGrowthMetrics={getHistoricalGrowthMetricsForProperty(
        averageAnnualGrowthMetrics,
        property
      )}
    />
  );
};

const sortOptionsByInvestment = {
  default: [
    SortBy.Newest,
    SortBy.Discount,
    SortBy.BrickPrice,
    SortBy.NetIncome,
  ],
  property: [
    SortBy.Newest,
    SortBy.Discount,
    SortBy.BrickPrice,
    SortBy.NetIncome,
    SortBy.Debt,
    SortBy.HistoricalSuburbGrowth,
  ],
  mortgage: [
    SortBy.Newest,
    SortBy.Discount,
    SortBy.BrickPrice,
    SortBy.NetIncome,
    SortBy.Debt,
    SortBy.LoanTerm,
  ],
  financial_asset: [
    SortBy.Newest,
    SortBy.Discount,
    SortBy.BrickPrice,
    SortBy.NetIncome,
  ],
};

const getStatesFromProperties = (properties) =>
  properties.reduce(
    (listOfStatesSoFar, current) =>
      listOfStatesSoFar.includes(current.state)
        ? listOfStatesSoFar
        : [...listOfStatesSoFar, current.state],
    []
  );

const getTypesFromProperties = (properties) =>
  properties
    .reduce(
      (listOfTypesSoFar, current) =>
        listOfTypesSoFar.includes(current.propertyType)
          ? listOfTypesSoFar
          : [...listOfTypesSoFar, current.propertyType],
      []
    )
    .sort((a, b) => preSortedType.indexOf(a) - preSortedType.indexOf(b));

const getInvestmentsFromProperties = (properties) =>
  properties.reduce(
    (listOfInvestmentsSoFar, current) =>
      listOfInvestmentsSoFar.includes(current.investmentType)
        ? listOfInvestmentsSoFar
        : [...listOfInvestmentsSoFar, current.investmentType],
    []
  );

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...propertiesSelector(state),
  ...averageAnnualGrowthMetricsSelector(state),
  ...allMonthlyDistributionsSelector(state),
});

@connect(mapStateToProps, {})
export default class PropertiesIndex extends Component {
  static propTypes = {
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    user: userPropType.isRequired,
    averageAnnualGrowthMetrics: averageAnnualGrowthPropType,
    location: PropTypes.object,
  };

  state = {
    sortByList: sortOptionsByInvestment.default,
    filters: {
      byState: '',
      byType: '',
      byInvestment: '',
    },
    availableTypes: [],
    sortMethodSelected: sortOptionsByInvestment.default[0],
    filterOrSortChanged: false,
  };

  UNSAFE_componentWillMount() {
    this._setDefaultFilterAndSortValues();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.properties.length > 0 &&
      prevState.availableTypes.length === 0
    ) {
      return {
        availableTypes: getTypesFromProperties(nextProps.properties),
      };
    }
    return null;
  }

  componentDidMount() {
    this._setDefaultFilterAndSortValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this._setDefaultFilterAndSortValues();
    }
    this._onFilterOrSortChange();
  }

  _propertiesLoaded = () => {
    return !_.isEmpty(this.props.properties);
  };

  _setDefaultFilterAndSortValues = () => {
    const { filters } = this.state;
    filters.byState = _.isNil(this.props.location.query.state)
      ? ''
      : this.props.location.query.state;
    filters.byType = _.isNil(this.props.location.query.type)
      ? ''
      : this.props.location.query.type;
    this.setState({
      sortMethodSelected:
        this._getSortMethod(this.props.location.query.sort) ||
        this.state.sortByList[0],
      filters,
    });
    if (this.props.location.query.investment) {
      this._onFilterByInvestmentChange(this.props.location.query.investment);
    }
  };

  _onFilterOrSortChange = () => {
    if (this.state.filterOrSortChanged) {
      history.replace(this._generateUrlWithFiltersAndSortings());
      this.setState({ filterOrSortChanged: false });
    }
  };

  _onSortChange = (value) => {
    const sortMethodSelected = _.filter(this.state.sortByList, function g(l) {
      return l.displayName === value;
    })[0];
    this.setState({
      sortMethodSelected: sortMethodSelected,
      filterOrSortChanged: true,
    });
  };

  _onFilterByLocationChange = (value) => {
    const { filters } = this.state;
    filters.byState = value;
    this.setState({
      filters,
      filterOrSortChanged: true,
    });
  };

  _onFilterByTypeChange = (value) => {
    const { filters } = this.state;
    filters.byType = value;
    this.setState({
      filters,
      filterOrSortChanged: true,
    });
  };

  _onFilterByInvestmentChange = (value) => {
    const { filters } = this.state;
    filters.byInvestment = value;

    const filteredProperties = value
      ? this.props.properties.filter(
          (property) => property.investmentType === value
        )
      : this.props.properties;

    const validTypes = getTypesFromProperties(filteredProperties);

    if (!validTypes.includes(filters.byType)) {
      filters.byType = '';
    }

    const newSortByList =
      sortOptionsByInvestment[value] || sortOptionsByInvestment.default;
    const newSortMethodSelected = newSortByList.includes(
      this.state.sortMethodSelected
    )
      ? this.state.sortMethodSelected
      : newSortByList[0];

    this.setState({
      filters,
      availableTypes: validTypes,
      sortByList: newSortByList,
      sortMethodSelected: newSortMethodSelected,
      filterOrSortChanged: true,
    });
  };

  _generateUrlWithFiltersAndSortings = () => {
    const {
      filters: { byState, byType, byInvestment },
      sortMethodSelected,
    } = this.state;
    var url = 'investments?';
    if (byState !== '') {
      url = `${url}state=${byState}&`;
    }
    if (byType !== '') {
      url = `${url}type=${byType}&`;
    }
    if (byInvestment !== '') {
      url = `${url}investment=${byInvestment}&`;
    }
    url = `${url}sort=${this._formatSortDisplayName(
      sortMethodSelected.displayName
    )}`;
    return url;
  };

  _getSortMethod = (sort) => {
    const { sortByList } = this.state;
    const componentScope = this;
    var sortMethod = sortByList[0];
    if (!_.isNil(sort)) {
      sortMethod = _.filter(sortByList, function (s) {
        return componentScope._formatSortDisplayName(s.displayName) === sort;
      })[0];
    }
    return sortMethod;
  };

  _formatSortDisplayName = (displayName) => {
    return displayName.toLowerCase().replace(/ /gi, '-').replace(/\./gi, '');
  };

  render() {
    const { properties, averageAnnualGrowthMetrics, allMonthlyDistributions } =
      this.props;
    const {
      sortMethodSelected,
      sortByList,
      filters: { byState, byType, byInvestment },
    } = this.state;

    const propertiesLoaded = this._propertiesLoaded();
    var filteredProperties = properties;
    filteredProperties = filteredProperties.filter(
      (ele) => ele.propertyStatus !== PropertyStatus.OFF_MARKET
    );
    if (byState !== '') {
      filteredProperties = _.filter(filteredProperties, function (p) {
        return p.state === byState;
      });
    }
    if (byType !== '') {
      filteredProperties = _.filter(filteredProperties, function (p) {
        return p.propertyType === byType;
      });
    }
    if (byInvestment !== '') {
      filteredProperties = _.filter(filteredProperties, function (p) {
        return p.investmentType === byInvestment;
      });
    }
    const hasPropertiesWithoutBricks = !_.every(
      _.map(filteredProperties, 'financials'),
      function (v) {
        return v.lowestAvailableBrickPrice > 0;
      }
    );

    const sortMethods = {
      [SortBy.Discount.displayName]:
        sortByDiscountWithPreOrderAndComingSoonFirst,
      [SortBy.HistoricalSuburbGrowth.displayName]:
        sortBy20YearHistoricalSuburbGrowth,
    };

    const sortMethod =
      sortMethods[sortMethodSelected.displayName] || defaultSortMethod;
    const orderedProperties = sortMethod(
      filteredProperties,
      sortMethodSelected,
      averageAnnualGrowthMetrics
    );

    /** TODO: refactor so we can dynamically display coming soon property */
    const firstRowProperties = orderedProperties.slice(0, 2);
    const thirdProperty = orderedProperties.slice(2, 3);
    const restOfTheProperties = orderedProperties.slice(3);

    const renderPropertyCard = (property, index) => (
      <div
        key={index}
        className="col-md-4 col-sm-6 properties-index__property-card-container"
        data-test-reference="property-card-container"
      >
        {getPropertyCard(property, averageAnnualGrowthMetrics)}
      </div>
    );

    const firstTwoCards = firstRowProperties.map(renderPropertyCard);
    const thirdCard = thirdProperty.map(renderPropertyCard);
    const restOfTheCards = restOfTheProperties.map(renderPropertyCard);

    const propertySmartInvestSignupDriver = (
      <PropertySmartInvestSignupDriver
        trackingProps={{ from: 'Property Listing Page' }}
      />
    );

    const propertiesOffMarket = properties.filter(
      (p) => p.propertyStatus === PropertyStatus.OFF_MARKET
    );

    return (
      <div>
        <PropertiesMeta />
        <section className="properties-list-page">
          <div className="container">
            <div className="row">
              <div className="properties-list-page-filter-and-sort col-sm-12">
                <Filter
                  identifier="Investments"
                  by={byInvestment}
                  states={getInvestmentsFromProperties(this.props.properties)}
                  onChange={this._onFilterByInvestmentChange}
                />
                <Filter
                  identifier="Types"
                  by={byType}
                  states={this.state.availableTypes}
                  onChange={this._onFilterByTypeChange}
                />
                <Filter
                  identifier="Locations"
                  by={byState}
                  states={getStatesFromProperties(this.props.properties)}
                  onChange={this._onFilterByLocationChange}
                />
                <Sort
                  displayName={sortMethodSelected.displayName}
                  sortByList={sortByList}
                  onSortChange={this._onSortChange}
                />
              </div>
              <div
                className={classNames(
                  styles.propertiesListWrapper,
                  'col-sm-12'
                )}
              >
                <TopBannerContainer>
                  <ExpandableExplainerVideo />
                </TopBannerContainer>
                <ReactPlaceholder
                  ready={propertiesLoaded}
                  customPlaceholder={propertiesListPlaceholder}
                >
                  <div className="properties-list">
                    {/* <div className="col-md-4 col-sm-6">
                      <CardFutureProperty user={user} />
                    </div> */}
                    {firstTwoCards}
                    <div
                      className={classNames(
                        styles.smartInvestSignupDriverMobileTablet,
                        'col-sm-12'
                      )}
                    >
                      {propertySmartInvestSignupDriver}
                      <ScrollDownHint
                        className={styles.morePropertiesHintMobileTablet}
                        text="More Properties"
                      />
                    </div>
                    {thirdCard}
                    <div
                      className={classNames(
                        styles.smartInvestSignupDriverDesktop,
                        'col-sm-12'
                      )}
                    >
                      {propertySmartInvestSignupDriver}
                    </div>
                    {restOfTheCards}
                  </div>
                </ReactPlaceholder>
              </div>
              <div className="col-xs-12">
                <h1>Sold Properties</h1>
              </div>
              <div>
                {propertiesOffMarket.map((property, idx) => {
                  return (
                    <div
                      className="col-xs-12 col-sm-6 col-md-4"
                      style={{ margin: '15px 0' }}
                      key={idx}
                    >
                      <PropertyCard
                        property={property}
                        historicalGrowthMetrics={getHistoricalGrowthMetricsForProperty(
                          averageAnnualGrowthMetrics,
                          property
                        )}
                        distributions={
                          allMonthlyDistributions &&
                          allMonthlyDistributions[property.propertyCode]
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row"></div>

            <div className="row">
              <div className="col-lg-12">
                <div className="property-disclaimer">
                  {hasPropertiesWithoutBricks
                    ? 'Estimates are based on the current Lowest Available Brick Price, unless there are no Bricks Available for sale. In this case the Latest Brick Valuation is used. And finally, in the case there are no Bricks Available, and the property has yet to be valued, the Initial Brick Price will be used. Estimates are subject to change without notice.'
                    : 'All estimates are based on the current Lowest Available Brick Price. Estimates are subject to change without notice.'}
                </div>
              </div>
            </div>
          </div>

          <div className="mini-footer">
            <div className="good-points">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 col-xs-6">
                    <LazyLoad>
                      <img src="/static/images/icons/icn_secure.png" />
                    </LazyLoad>
                    <b>Secure Encryption</b>
                  </div>
                  <div className="col-sm-3 col-xs-6">
                    <LazyLoad>
                      <img src="/static/images/icons/icn_transparent.png" />
                    </LazyLoad>
                    <b>Transparency</b>
                  </div>
                  <div className="col-sm-3 col-xs-6">
                    <LazyLoad>
                      <img src="/static/images/icons/icn_securepay.png" />
                    </LazyLoad>
                    <b>Secure Payment</b>
                  </div>
                  <div className="col-sm-3 col-xs-6">
                    <LazyLoad>
                      <img src="/static/images/icons/icn_portfolio.png" />
                    </LazyLoad>
                    <b>Diversification</b>
                  </div>
                </div>
              </div>
            </div>

            <MoreAboutBrickxProperties />
          </div>
        </section>
      </div>
    );
  }
}

import { Fragment } from 'react';
import classNames from 'classnames';
import { Heading, HeadingXSmall } from 'src/design/components/heading/Headings';
import { howItWorksUrl, propertyTeamUrl } from 'src/utils/pageUrls';
import { howItWorksVideoId } from 'src/utils/videoIds';
import { scrollToId } from 'src/browser/window';
import AboveTheFoldPromo from 'src/components/landingPages/home/AboveTheFoldPromo';
import Analytics from 'src/tracking/analytics';
import FinancialMetricsBar from 'src/components/landingPages/home/FinancialMetricsBar';
import GetStartedButton from 'src/components/landingPages/common/getStartedButton/GetStartedButton';
import HeadingVideoSection from 'src/components/landingPages/common/headingVideoSection/HeadingVideoSection';
import HomeMeta from 'src/components/meta/HomeMeta';
import HowItWorksSection from 'src/components/landingPages/common/howItWorksSection/HowItWorksSection';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Referrals from 'src/components/landingPages/home/Referrals';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import SubFooter from 'src/components/subFooter/SubFooter';
import TickedBulletList from 'src/design/components/bulletList/TickedBulletList';
import styles from 'src/components/landingPages/home/Home.mscss';
import withState from 'src/decorators/withState';
import Tabs from 'src/design/components/tabs/Tabs';

const ThreeIllustratedPoints = ({ className, children }) => (
  <div className={classNames(styles.threeIllustratedPoints, className)}>
    {children}
  </div>
);

const IllustratedPoint = ({ className, children }) => (
  <div className={classNames(styles.illustratedPoint, className)}>
    {children}
  </div>
);

const TabPanel = ({ label, label_plural, instructions, items } = {}) => (
  <div className={styles.propertyBodyContent}>
    <Spacing top="large" bottom="x-small">
      <Paragraph>{instructions}</Paragraph>
    </Spacing>
    <Spacing top="large" bottom="x-small">
      <Paragraph>{label} investment details include:</Paragraph>
    </Spacing>
    <TickedBulletList
      responsiveColumns={{
        base: TickedBulletList.columns.ONE,
        small: TickedBulletList.columns.TWO,
      }}
      items={items}
      textSize="small"
    />
    <Spacing top="large">
      <div className={styles.propertyBodyCtasRow}>
        <SecondaryButton
          link={`/investments?investment=${label.toLowerCase()}`}
          fullWidth
          className={styles.propertyBodyCta}
        >
          View {label_plural}
        </SecondaryButton>
        <SecondaryButton
          link={propertyTeamUrl()}
          fullWidth
          className={styles.propertyBodyCta}
        >
          Meet the BrickX Team
        </SecondaryButton>
      </div>
    </Spacing>
  </div>
);

const PropertiesBanner = () => (
  <PageMarginContainer
    className={classNames(styles.propertiesBanner)}
    as="section"
  >
    <div className={styles.propertiesInner}>
      <Spacing bottom="large">
        <Heading className={styles.propertiesTitle} align="center">
          <h2>BrickX Investments</h2>
        </Heading>
      </Spacing>
      <Spacing top="large" bottom="x-small">
        <img
          className={styles.propertyCardsImage}
          src="/static/img/photo-brickx-property-cards.png"
          srcSet="/static/img/photo-brickx-property-cards.png 600w, /static/img/photo-brickx-property-cards@2x.png 1200w, /static/img/photo-brickx-property-cards@3x.png 1800w"
          alt="3 property cards of current BrickX properties"
        />
      </Spacing>
      <Tabs
        testRef="brickx-investments-tabs"
        startFromPanel={0}
        centeredLabels
        panels={[
          {
            label: 'Property',
            panel: (
              <TabPanel
                label="Property"
                label_plural="Properties"
                instructions="Choose which property to buy Bricks in (or use Smart Invest). Our range of properties is carefully selected by our specialist Property Team based on growth potential."
                items={[
                  'Historical suburb growth',
                  'Independent property valuations',
                  'Forecasted cash flows',
                  'Estimated net rental income and yield',
                ]}
              />
            ),
            labelTestRef: 'property-tabs-label',
          },
          {
            label: 'Mortgage',
            panel: (
              <TabPanel
                label="Mortgage"
                label_plural="Mortgages"
                instructions="Choose which Mortgage Trust to buy Bricks in. Our range of mortgage backed investments is carefully selected by our specialist Investment and Credit Assessment Team focusing on quality of security, Borrower strength and income potential."
                items={[
                  'Historical RBA Cash Rate comparison',
                  'Independent property valuations',
                  'Forecasted cash flows',
                  'Estimated net interest income and yield',
                ]}
              />
            ),
            labelTestRef: 'mortgage-tabs-label',
          },
          {
            label: 'Financial Asset',
            panel: (
              <TabPanel
                label="Financial Asset"
                label_plural="Financial Assets"
                instructions="Choose which financial asset investment Trust to buy Bricks in. A variety of property related financial asset investments is procured and managed by our specialist Investment Team with a  view to also collaborating with specialist Investment sub-Managers to curate and manage portfolio assets that can have a combination of capital return and income potential."
                items={[
                  'Historical RBA Cash Rate comparison',
                  'Independent property valuations',
                  'Forecasted cash flows',
                  'Estimated net trust income and yield',
                ]}
              />
            ),
            labelTestRef: 'financial-asset-tabs-label',
          },
        ]}
      />
    </div>
  </PageMarginContainer>
);

const Home = ({ location }) => {
  return (
    <div className={styles.home} data-test-reference="home-page">
      <HomeMeta />

      <Referrals tokens={location.query} />

      <HeadingVideoSection
        videoId={howItWorksVideoId}
        onPlay={() => Analytics.track('Home Page Video Play')}
        onFinish={() => Analytics.track('Home Page Video Finished Watching')}
        title="Want an easier way to invest in property?"
        captionContent={
          <Fragment>
            <Paragraph>
              BrickX is an award-winning platform that provides a simple and low
              cost way to access the property market.
            </Paragraph>
            <Spacing top="normal" bottom="2x-large">
              <Paragraph>
                It’s the new, easier way to invest in property.
              </Paragraph>
            </Spacing>
            <div className={styles.headingVideoLinksRow}>
              <GetStartedButton
                className={styles['headingVideoLinksRow--getStartedButton']}
              />
              <SecondaryButton
                link={howItWorksUrl()}
                className={styles['headingVideoLinksRow--learnMoreButton']}
              >
                Learn How it Works
              </SecondaryButton>
            </div>
          </Fragment>
        }
        promoContent={<AboveTheFoldPromo />}
      />

      <HowItWorksSection
        backgroundColor="grey"
        cta={
          <PrimaryButton
            color={PrimaryButton.colors.SECONDARY}
            link={howItWorksUrl()}
          >
            Learn How it Works
          </PrimaryButton>
        }
      />

      <PropertiesBanner />

      <section className={classNames(styles.whyChooseBrickX)}>
        <div
          className={classNames(
            styles.innerContainer,
            styles.whyChooseBrickXInner
          )}
        >
          <Heading align="center">
            <h2>Why Choose BrickX?</h2>
          </Heading>
          <ThreeIllustratedPoints>
            <IllustratedPoint className={styles.illustratedPoint}>
              <img
                className={classNames(styles.illustration)}
                src="/static/img/icon-selection-indicator-with-lightning-bolt.svg"
                alt=""
              />
              <HeadingXSmall className={styles.illustratedPointTitle}>
                <h5>Simplicity</h5>
              </HeadingXSmall>
              <Paragraph>
                Select your BrickX investment and within minutes you can own
                Bricks in a property related investment.
              </Paragraph>
            </IllustratedPoint>
            <IllustratedPoint className={styles.illustratedPoint}>
              <img
                className={classNames(styles.illustration)}
                src="/static/img/icon-open-doorway.svg"
                alt=""
              />
              <HeadingXSmall className={styles.illustratedPointTitle}>
                <h5>Finally - Access to Property Market</h5>
              </HeadingXSmall>
              <Paragraph>
                Feeling locked out of the property market? BrickX provides an
                affordable way to invest.
              </Paragraph>
            </IllustratedPoint>
            <IllustratedPoint className={styles.illustratedPoint}>
              <img
                className={classNames(styles.illustration)}
                src="/static/img/icon-light-bulb-with-house-inside.svg"
                alt=""
              />
              <HeadingXSmall className={styles.illustratedPointTitle}>
                <h5>Property team expertise</h5>
              </HeadingXSmall>
              <Paragraph>
                Investments are hand-picked by our specialist property
                investment team.
                <br />
                <StyledBodylink href={propertyTeamUrl()}>
                  Learn More
                </StyledBodylink>
              </Paragraph>
            </IllustratedPoint>
          </ThreeIllustratedPoints>
        </div>
      </section>

      <FinancialMetricsBar />

      <SubFooter />
    </div>
  );
};

export default withState({
  Component: Home,
  componentDidMount: ({ location }) => {
    location.query.scrollTo && scrollToId(location.query.scrollTo);
  },
  UNSAFE_componentWillReceiveProps: (props, nextProps) =>
    nextProps.location.query.scrollTo &&
    scrollToId(nextProps.location.query.scrollTo),
});

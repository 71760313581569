import { Fragment } from 'react';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  dollar,
  dateWithSlash,
  dollarDecimal,
  number,
} from 'scripts/utilities/formatters';
import InvestmentTypes from 'src/enums/investmentTypes';

const HistoricalPurchase = ({ property }) => {
  const isGeared = property.financials.gearingEffect > 0;

  return (
    <FinancialsPanel
      className="historical-purchase-panel"
      title={
        property.investmentType === InvestmentTypes.property
          ? 'Historical Purchase'
          : 'Historical Drawdown Details'
      }
    >
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Settlement Date'
            : 'Initial Drawdown Date'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right acquisition-date">
          {property.platformSettlementDate::dateWithSlash()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Purchase Price'
            : 'Initial Loan Advance'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right purchase-price">
          {property.investmentType === InvestmentTypes.property
            ? property.financials.purchasePrice::dollar()
            : property.financials.loanAdvance::dollar()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Acquisition Costs'
            : 'Loan Provision Expenses'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right acquisition-cost">
          {property.financials.acquisitionCost::dollar()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Cash Reserve</div>
        <div className="col-xs-5 col-no-padding-left text-right cash-reserve">
          {property.financials.cashReserve::dollar()}
        </div>
      </div>
      {property.investmentType === InvestmentTypes.property && (
        <Fragment>
          <div className="gray-line" />
          <div className="row my-row">
            <div className="col-xs-7 col-no-padding-right bold">
              Initial Debt
            </div>
            <div className="col-xs-5 col-no-padding-left text-right initial-debt">
              {isGeared
                ? property.financials.debtTerms.initialDebt::dollar()
                : '$0'}
            </div>
          </div>
        </Fragment>
      )}
      <div className="black-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          {property.investmentType === InvestmentTypes.property
            ? 'Initial Equity'
            : 'Total Mortgage Trust Capital'}
        </div>
        <div className="col-xs-5 col-no-padding-left text-right initial-equity">
          {property.financials.initialEquity::dollar()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Number of Bricks
        </div>
        <div className="col-xs-5 col-no-padding-left text-right number-bricks">
          {property.financials.numberBricks::number()}
        </div>
      </div>
      <div className="black-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Initial Brick Price
        </div>
        <div className="col-xs-5 col-no-padding-left text-right initial-brick-price">
          {property.financials.initialBrickPrice::dollarDecimal()}
        </div>
      </div>
    </FinancialsPanel>
  );
};

HistoricalPurchase.propTypes = {
  property: propertyPropType.isRequired,
};

export default HistoricalPurchase;

import { connect } from 'react-redux';
import {
  confirmSell as confirmSellAction,
  confirmPurchase as confirmPurchaseAction,
  confirmPurchaseV2 as confirmPurchaseV2Action,
} from 'scripts/redux/actions/market';
import { cancelOrder as cancelOrderAction } from 'src/components/dashboard/pendingOrders/pendingOrdersActions';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { orderBookStatusSelector } from 'scripts/redux/selectors/market';
import { Heading } from 'src/design/styleguide/Headings';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styled from 'styled-components';
import { dollarDecimal, getPlural } from 'src/utils/formats';
import { renderCode } from 'src/settings/properties';

const ConfirmModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
`;

const ConfirmModal = connect(null, {
  cancelOrder: cancelOrderAction,
  confirmSell: confirmSellAction,
  confirmPurchase: confirmPurchaseAction,
  confirmPurchaseV2: confirmPurchaseV2Action,
})(
  ({
    closeThisModal,
    cancelOrder,
    confirmSell,
    confirmPurchase,
    confirmPurchaseV2,
    order,
    editForm,
    orderTypeText,
    fees,
  }) => {
    const _onConfirm = async () => {
      const isCancelled = await cancelOrder(
        editForm.propertyCode,
        editForm.orderId
      );

      if (isCancelled) {
        if (editForm.type === 'pre') {
          await confirmPurchase(
            {
              propertyCode: editForm.propertyCode,
              quantity: editForm.quantity,
              basisPrice: editForm.price,
            },
            true
          );
        } else if (editForm.type === 'buy') {
          await confirmPurchaseV2(editForm.propertyCode, {
            price: editForm.price,
            quantity: editForm.quantity,
            valueOfBricks: editForm.price * editForm.quantity,
            transactionFee: editForm.price * editForm.quantity * fees / 100,
            total: editForm.price * editForm.quantity * (1 + fees / 100),
          });
        } else if (editForm.type === 'sell') {
          await confirmSell(editForm.propertyCode, {
            price: editForm.price,
            quantity: editForm.quantity,
            valueOfBricks: editForm.price * editForm.quantity,
            transactionFee: editForm.price * editForm.quantity * fees / 100,
            total: editForm.price * editForm.quantity * (1 - fees / 100),
          });
        }
        closeThisModal();
      }
    };

    return (
      <ConfirmModalContainer>
        <Heading as="p">
          Are you sure you
          <br />
          want to edit this order?
        </Heading>
        <Spacing top="x-large">
          <Paragraph align="center">
            Updating {renderCode(order.propertyCode)} {orderTypeText}:
            <br />
            {order.quantity}{' '}
            {getPlural({
              number: order.quantity,
              singular: 'Brick',
              plural: 'Bricks',
            })}{' '}
            @ {dollarDecimal(order.price)} ➔ {editForm.quantity}{' '}
            {getPlural({
              number: editForm.quantity,
              singular: 'Brick',
              plural: 'Bricks',
            })}{' '}
            @ {dollarDecimal(editForm.price)} each.
          </Paragraph>
        </Spacing>
        <Spacing top="5x-large">
          <ConfirmButtonContainer>
            <SecondaryButton
              color={SecondaryButton.colors.PRIMARY}
              onClick={closeThisModal}
            >
              No
            </SecondaryButton>
            <Spacing left="x-small">
              <PrimaryButton
                color={PrimaryButton.colors.SECONDARY}
                onClick={() => _onConfirm()}
              >
                Yes, I am sure
              </PrimaryButton>
            </Spacing>
          </ConfirmButtonContainer>
        </Spacing>
      </ConfirmModalContainer>
    );
  }
);

const mapStateToProps = (state) => ({
  ...orderBookStatusSelector(state),
});

const EditConfirmButton = connect(mapStateToProps, {
  showModal: showModalAction,
})(
  ({
    orderBookStatus,
    showModal,
    order,
    orderTypeText,
    editForm,
    handleQuantityChange,
    handlePriceChange,
    fees,
    ...props
  }) => {
    const modalContent = (
      <ConfirmModal
        order={order}
        editForm={editForm}
        orderTypeText={orderTypeText}
        fees={fees}
      />
    );

    const _onClick = async () => {
      if (
        editForm.price === order.price &&
        editForm.quantity === order.quantity
      ) {
        editForm.callback({ ...editForm, orderId: '' });
        return;
      }
      if (!orderBookStatus || !orderBookStatus.enabled) {
        editForm.callback({
          ...editForm,
          hasError: true,
          errorMsg: 'Trading is currently disabled for this property.',
        });
        return;
      }
      handleQuantityChange(editForm.quantity);
      if (editForm.hasError) return;
      if (editForm.type !== 'pre') {
        handlePriceChange(editForm.price);
        if (editForm.hasError) return;
      }
      showModal({
        content: modalContent,
      });
    };

    return (
      <PrimaryButton
        color={
          editForm.type === 'sell'
            ? PrimaryButton.colors.SECONDARY
            : PrimaryButton.colors.ACCENT
        }
        size={PrimaryButton.sizes.EXTRA_SMALL}
        onClick={_onClick}
        disabled={editForm.hasError}
        {...props}
      >
        <span className="fa fa-check" />
      </PrimaryButton>
    );
  }
);

export default EditConfirmButton;

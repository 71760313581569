import { connect } from 'react-redux';
import { cancelOrder as cancelOrderAction } from 'src/components/dashboard/pendingOrders/pendingOrdersActions';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { Heading } from 'src/design/styleguide/Headings';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styled from 'styled-components';
import { dollarDecimal, getPlural, shortReference } from 'src/utils/formats';
import { renderCode } from 'src/settings/properties';

const CancelConfirmationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CancelConfirmationButtonsContainer = styled.div`
  display: flex;
`;

const CancelConfirmationModal = connect(null, {
  cancelOrder: cancelOrderAction,
})(({ closeThisModal, cancelOrder, order, orderTypeText }) => (
  <CancelConfirmationModalContainer>
    <Heading as="p">
      Are you sure you
      <br />
      want to cancel this order?
    </Heading>
    <Spacing top="x-large">
      <Paragraph align="center">
        {orderTypeText} {order.quantity}{' '}
        {getPlural({
          number: order.quantity,
          singular: 'Brick',
          plural: 'Bricks',
        })}{' '}
        in {renderCode(order.propertyCode)} at {dollarDecimal(order.price)} each
        <br />
        Reference: {shortReference(order.orderId)}
      </Paragraph>
    </Spacing>
    <Spacing top="5x-large">
      <CancelConfirmationButtonsContainer>
        <SecondaryButton
          color={SecondaryButton.colors.PRIMARY}
          onClick={closeThisModal}
          testRef="decline-cancel-button"
        >
          No
        </SecondaryButton>
        <Spacing left="x-small">
          <PrimaryButton
            color={PrimaryButton.colors.SECONDARY}
            onClick={() => {
              cancelOrder(order.propertyCode, order.orderId).then(
                closeThisModal
              );
            }}
            testRef="confirm-cancel-button"
          >
            Yes, I am sure
          </PrimaryButton>
        </Spacing>
      </CancelConfirmationButtonsContainer>
    </Spacing>
  </CancelConfirmationModalContainer>
));

const CancelButton = connect(null, { showModal: showModalAction })(
  ({ showModal, order, orderTypeText, ...props }) => {
    const modalContent = (
      <CancelConfirmationModal order={order} orderTypeText={orderTypeText} />
    );
    return (
      <SecondaryButton
        color={SecondaryButton.colors.PRIMARY}
        size={SecondaryButton.sizes.EXTRA_SMALL}
        onClick={() =>
          showModal({
            content: modalContent,
            testRef: 'cancel-confirmation-modal',
          })
        }
        testRef="cancel-order-button"
        {...props}
      >
        <span className="fa fa-trash" />
      </SecondaryButton>
    );
  }
);

export default CancelButton;

export const renderCode = (propertyCode) => {
  // use this only for properties that needs to update propery code, e.g. ADI01 -> ADP01
  //
  // Given the limitation of backend it is infeasible to change the code however due to
  // some reason such as break of regulation we may need to do it.
  const propertyCodeMask = {
    ADI01: 'ADP01',
  };
  return propertyCodeMask[propertyCode] || propertyCode;
};

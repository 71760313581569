import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { smsfHowToInvestId } from 'src/components/landingPages/smsf/Smsf';
import { smsfVideoId } from 'src/utils/videoIds';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';
import Loading from 'src/components/loading/Loading';
import LoggedInForm from 'src/components/landingPages/smsf/forms/LoggedInForm';
import LoggedOutForm from 'src/components/landingPages/smsf/forms/LoggedOutForm';
import Paragraph from 'src/design/styleguide/Paragraph';
import PoppingCard from 'src/design/components/poppingCard/PoppingCard';
import SmsfPageSectionHeading from 'src/components/landingPages/smsf/SmsfPageSectionHeading';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Video from 'src/design/components/video/Video';
import Colors from 'src/themes/brickx/colors';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import { Box, Stack, Typography } from '@mui/material';
import HideComponent from 'src/components/common/HideComponent';

const spaceBetweenNumberedPointsAndPoppingCard = `${gridUnit * 8}px`;

const NumberedPointContainer = styled.div`
  margin-bottom: ${gridUnit * 16}px;

  ${breakpointSmall(`
    flex: 1 0 0px;
    margin-right: ${spaceBetweenNumberedPointsAndPoppingCard};
  `)}
`;

const StyledNumberedPoint = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 0 0px;
  margin-bottom: ${gridUnit * 8}px;
`;

const StyledListItemNumber = styled(ListItemNumber)`
  margin-top: 6px;
  margin-right: ${gridUnit * 4}px;
`;

const TextContainer = styled.div``;

const NumberedPointText = styled(Paragraph)``;

const NumberedPoint = ({ heading, text, color }) => (
  <StyledNumberedPoint>
    <StyledListItemNumber color={color || 'blue'} />
    <TextContainer>
      <Heading fontSize="x-small" align="left">
        {heading}
      </Heading>
      <Spacing top="x-small">
        <NumberedPointText>{text}</NumberedPointText>
      </Spacing>
    </TextContainer>
  </StyledNumberedPoint>
);

const InvestCardContentContainer = styled.div`
  padding: 24px;
`;

const InvestCardContainer = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: ${gridUnit * 16}px;

  ${breakpointSmall(`
    flex: 1 0 0px;
    margin-top: 0;
    margin-left: ${spaceBetweenNumberedPointsAndPoppingCard};
  `)}
`;

const PointsAndCardContainer = styled.div`
  ${breakpointSmall(`
    display: flex;
  `)}
`;

const PointsAndVideoContainer = styled.div`
  flex: 1 0 0px;
`;

const ScrollToElement = styled.div`
  position: absolute;
  top: -48px;

  ${breakpointSmall(`
    top: -230px;
  `)}
`;

const HowToInvest = ({ user, smsfSubmitting, ...props }) => (
  <ContentBlock as="section" width="medium" {...props}>
    <SmsfPageSectionHeading as="h2">How to get started</SmsfPageSectionHeading>

    <PointsAndCardContainer>
      <PointsAndVideoContainer>
        <NumberedPointContainer className={ListItemNumber.counterResetClass}>
          <NumberedPoint
            heading="Simple Sign Up"
            text="Signup on this page, it's simple and easy – The first step to investing your SMSF or Trust with BrickX."
          />
          <NumberedPoint
            heading="Verify your SMSF or Trust"
            text="We will verify your SMSF or Trust details and advise when your BrickX account is ready to go."
          />
          <NumberedPoint
            heading="Deposit Funds"
            text="Login to your BrickX account to deposit funds from your existing SMSF or Trust, and start investing."
          />
        </NumberedPointContainer>
        <Video videoId={smsfVideoId} />
      </PointsAndVideoContainer>

      <Spacing bottom="normal">
        <InvestCardContainer>
          <ScrollToElement id={smsfHowToInvestId()} />
          <PoppingCard withBorderTopFeature borderColor={Colors.blue500}>
            <InvestCardContentContainer>
              <HeadingSmall align="center">
                <h3>Invest in your SMSF or Trust today!</h3>
              </HeadingSmall>
              <Stack spacing={1} mt={5}>
                <PrimaryButton
                  color={PrimaryButton.colors.BLUE}
                  link={'signup/smsf'}
                >
                  Set up <strong>SMSF</strong>
                </PrimaryButton>
                <Box>
                  <Typography textAlign={'center'} fontSize={'2rem'}>
                    or
                  </Typography>
                </Box>
                <PrimaryButton
                  color={PrimaryButton.colors.BLUE}
                  link={'signup/trust'}
                >
                  Set up <strong>Trust</strong>
                </PrimaryButton>
              </Stack>
              <HideComponent>
                {smsfSubmitting ? (
                  <Loading notFullHeight />
                ) : user.loggedIn ? (
                  <LoggedInForm />
                ) : (
                  <LoggedOutForm />
                )}
              </HideComponent>
            </InvestCardContentContainer>
          </PoppingCard>
        </InvestCardContainer>
      </Spacing>
    </PointsAndCardContainer>
  </ContentBlock>
);

export default HowToInvest;

import config from 'config';

const apiRoot = `${config.brickxApiHost}/api`;
const adminRoot = `${config.brickxApiHost}/admin`;

/**
 * properties
 */
export const publishedProperties = () => `${apiRoot}/v2/properties`;
export const publishedProperties2 = () => `${apiRoot}/properties`;
export const smartInvestProperties = () => `${apiRoot}/auto-invest-properties`;
export const property = (propertyCode) => `${publishedProperties2()}/${propertyCode}`;
export const financials = () => `${apiRoot}/financials`;
export const historicalBrickValues = (propertyCode) => `${apiRoot}/financials/${propertyCode}/brick-values`;
export const distributions = () => `${apiRoot}/monthlyDistributions`;
export const financialMetrics = () => `${apiRoot}/financials/metrics`;
export const paidPropertydistributions = (propertyCode) => `${apiRoot}/monthlyDistributions/${propertyCode}/paid`;
export const paidAndNotDistributedPropertydistributions = (propertyCode) => `${apiRoot}/monthlyDistributions/${propertyCode}/paid-and-not-distributed`;
export const propertyViewsUrl = (propertyCode) => `${apiRoot}/properties/${propertyCode}/views`;

export const monthlyUpdates = (propertyCode) => `${property(propertyCode)}/updates`;
export const latestUpdates = (propertyCode) => `${property(propertyCode)}/latestUpdates`;
export const trades = (propertyCode) => `${property(propertyCode)}/trades`;
export const lastNTrades = (propertyCode, limit) => `${property(propertyCode)}/last-n-trades/${limit}`;
export const orders = (propertyCode) => `${property(propertyCode)}/orders`;
export const orderbook = (propertyCode) => `${property(propertyCode)}/orderbook`;
export const propertyInvestors = (propertyCode) => `${property(propertyCode)}/investors`;
export const propertyRemainingBricks = (propertyCode) => `${property(propertyCode)}/remainingBricks`;
export const bricksTransactedLastMonth = (propertyCode) => `${property(propertyCode)}/bricks-transacted-last-month`;
export const investmentMetrics = () => `${publishedProperties2()}/investmentMetrics`;

// #brick-price
export const brickValueAndPrice = (propertyCode) => `${property(propertyCode)}/prices-and-valuations`;
export const marketTrackerPath = (propertyCode) => `${property(propertyCode)}/market-tracker`;

export const purchaseProposal = (propertyCode, quantity) => `${property(propertyCode)}/purchaseProposal/${quantity}`;
export const confirmPurchase = (propertyCode) => `${property(propertyCode)}/confirmPurchase`;
export const confirmPurchaseV2 = (propertyCode) => `${property(propertyCode)}/confirmPurchaseV2`;
export const confirmSell = (propertyCode) => `${property(propertyCode)}/confirmSell`;
export const cancelOrder = (propertyCode, orderId) => `${orders(propertyCode)}/${orderId}/cancel`;

const historicalGrowth = `${apiRoot}/historical-growth`;
export const historicalGrowthMetrics = () => `${historicalGrowth}/growth-metrics`;
export const historicalGrowthData = (area, state, propertyType) => {
  if (propertyType.toUpperCase() === 'MULTIUNIT') {
    return `${historicalGrowth}/data/${area}?state=${state}&propertyType=UNIT`;
  }
  if (propertyType.toUpperCase() === 'MULTIHOUSE') {
    return `${historicalGrowth}/data/${area}?state=${state}&propertyType=HOUSE`;
  }
  if (propertyType.toUpperCase() === 'DEVELOPMENT') { // getting HOUSE data for any DEVELOPMENT properties
    return `${historicalGrowth}/data/${area}?state=${state}&propertyType=HOUSE`;
  }
  if (propertyType.toUpperCase() === 'COMMERCIAL') { // getting HOUSE data for any COMMERCIAL properties
    return `${historicalGrowth}/data/${area}?state=${state}&propertyType=HOUSE`;
  }
  return `${historicalGrowth}/data/${area}?state=${state}&propertyType=${propertyType.toUpperCase()}`;
};
/**
 * Smart invest
 */
export const smartInvestDetailsUrl = (userId) => `${apiRoot}/auto-invest/${userId}`;
export const disableSmartInvestUrl = (userId) => `${apiRoot}/auto-invest/${userId}/disable`;
export const smartInvestSkipAMonthUrl = (userId) => `${config.brickxApiHost}/api/auto-invest/${userId}/skip-a-month`;
export const smartInvestTopUpUrl = (userId) => `${config.brickxApiHost}/api/auto-invest/${userId}/top-up`;
export const smartInvestWithdrawFromSiUrl = (userId) => `${config.brickxApiHost}/api/auto-invest/${userId}/withdraw`;
export const getStoredDirectDebitRequestUrl = (userId) => `${config.brickxApiHost}/api/direct-debit-requests/${userId}`;
export const generateDirectDebitRequestPreviewUrl = (userId) => `${config.brickxApiHost}/api/direct-debit-requests/generate/${userId}`;
export const generateDirectDebitRequestServiceAgreementPreviewUrl = (userId) => `${config.brickxApiHost}/api/direct-debit-request-service-agreements/${userId}`;

/**
 * user
 */
export const profile = (userId) => `${apiRoot}/users/${userId}`;
export const deactivateAccount = (userId) => `${profile(userId)}/deactivateAccount`;
export const updatePassword = (userId) => `${profile(userId)}/update-password`;
export const userAccount = (userId) => `${profile(userId)}/account`;
export const userAccountV2Url = (userId) => `${apiRoot}/v2/users/${userId}/account`;
export const userAccountOverTime = (userId) => `${apiRoot}/v2/users/${userId}/account-historical-values`;
export const emailSubscriptions = (userId) => `${profile(userId)}/emailSubscriptions`;

export const transactions = (userId, year) => `${profile(userId)}/transactions/${year}`;
export const detailedTransactions = (userId) => `${profile(userId)}/detailed-transactions`;
export const detailedTransactionsAsCSV = (userId, plan) => `/api/users/${userId}/detailed-transactions.csv?plan=${plan || 'build_your_own'}`;

export const detailedTransactionsWithLimitV2 = (userId, limit) => `${apiRoot}/v2/users/${userId}/detailed-transactions?limit=${limit}`;
export const detailedTransactionsForYearV2 = (userId, year) => `${apiRoot}/v2/users/${userId}/detailed-transactions/${year}`;

export const detailedTransactionsForYear = (userId, year) => `${profile(userId)}/detailed-transactions/${year}`;
export const detailedTransactionsWithLimit = (userId, limit) => `${profile(userId)}/detailed-transactions?limit=${limit}`;

export const annualIndividualStatement = (userId, year) => `/api/users/${userId}/annualIndividualStatement/${year}`;
export const annualIndividualStatementCsv = (userId, year) => `/api/users/${userId}/annual-individual-statement/${year}.csv`;

export const portfolioStatementDocument = (userId) => `/api/users/${userId}/portfolio-statement`;

export const distributionStatementUrl = (userId, startDate, endDate) => `/api/account/${userId}/distributions-report?startDate=${startDate}&endDate=${endDate}`;

export const updateAddressUrl = (userId) => `${apiRoot}/users/${userId}/update/address`;
export const updateMobileNumberUrl = (userId) => `${apiRoot}/users/${userId}/update/mobile-number`;
export const updateEmailUrl = (userId) => `${apiRoot}/users/${userId}/update/email`;
export const updateBankDetailsUrl = (userId) => `${apiRoot}/users/${userId}/update/bank-details`;

/**
 * registration
 */
const registration = `${apiRoot}/registration`;
// export const signUp = () => `${registration}/signup`;
export const signUp = () => `${registration}/signupv2`; // NOTE: Switch to this after rolling out Minor Backend
export const resendEmail = (userId) => `${registration}/${userId}/verifyemail`;
export const acceptPds = (userId) => `${registration}/${userId}/acceptPds`;
export const verifyToken = (token) => `${registration}/verify/${token}`;
export const tmdWarning = (userId) => `${registration}/tmdw/${userId}`;
export const abnLookup = (abn) => `${apiRoot}/account/abn-verify/${abn}`;
export const acnLookup = (acn) => `${apiRoot}/account/acn-verify/${acn}`;
/**
 * Auth
 */
const auth = `${apiRoot}/auth`;
export const login = () => `${auth}/login`;
export const refreshSession = (userId) => `${auth}/${userId}/refreshSession`;

/**
 * reset password
 */
const requestResetPassword = `${apiRoot}/reset-password`;
export const requestEmailResetPasswordToken = () => `${requestResetPassword}/email-token`;
export const validateResetPasswordToken = () => `${requestResetPassword}/validate-token`;
export const processResetPassword = () => `${requestResetPassword}/process`;

/**
 * account
 */
export const account = (accountId) => `${apiRoot}/account/${accountId}`;
export const mydetails = (accountId) => `${account(accountId)}/mydetails`;
export const myMinorDetails = (accountId) => `${account(accountId)}/mydetails/minor`;
export const mySmsfDetails = (accountId) => `${account(accountId)}/mydetails/smsf`;
export const businessDetails = (accountId) => `${account(accountId)}/business-details`;
export const deposit = (accountId) => `${account(accountId)}/deposit`;
export const depositTransactions = (accountId, depositId) => `${account(accountId)}/deposit/${depositId}/transactions`;
export const depositInfo = (depositId) => `${apiRoot}/account/deposit/${depositId}`;
export const withdraw = (accountId) => `${account(accountId)}/withdraw`;
export const myOrders = (userId) => `${profile(userId)}/orders`;
export const myTrades = (userId) => `${profile(userId)}/trades`;
export const tfnDetails = (userId) => `${profile(userId)}/tfnDetails`;
export const bankDetails = (userId) => `${profile(userId)}/bankDetails`;
export const bankDetailsScreenshotUrl = (userId) => `${apiRoot}/users/${userId}/uploadBankDetailsScreenshot`;
export const bankNameUrl = (bsb) => `${config.brickxApiHost}/banks/bsb/${bsb}`;
export const financialDetails = (userId) => `${profile(userId)}/financialDetails`;
export const isMember = (userId) => `${profile(userId)}/is-member`;
export const bafm = (userId) => `${profile(userId)}/has-bafm`;
export const bpay = (userId) => `${profile(userId)}/bpayDetails`;
export const bpayDepositEmail = (userId) => `${profile(userId)}/bpayDepositEmail`;
export const smsOtp = (accountId) => `${account(accountId)}/otp/sms`;
export const dashboardAlert = (accountId) => `${account(accountId)}/alert`;
export const individualTaxReportUrl = (accountId, year) => `${account(accountId)}/individual-tax-report-url/${year}`;
export const wholesaleInvestorRegister = (accountId) => `${account(accountId)}/wholesale`;
export const smartRenterDetailsUrl = (accountId) => `${account(accountId)}/smart-renter`;

/**
 * market
 */
export const returnsCalculatorStatus = () => `${apiRoot}/market/returnsCalculatorStatus`;
export const marketStatus = () => `${apiRoot}/market/status`;
export const setMarketStatus = () => `${adminRoot}/market/status`;
export const setOrderBookStatus = (propertyCode) => `${adminRoot}/market/${propertyCode}/status`;
export const orderBookStatus = (propertyCode) => `${apiRoot}/properties/${propertyCode}/orderbook-status`;
export const fees = () => `${apiRoot}/fees`;

/**
 * promotions
 */
export const promotions = (userId) => `${apiRoot}/promotions/${userId}`;
export const feeFreePromotionStatus = () => `${apiRoot}/promotions/fee-free/status`;

/**
 * statistics
 */
export const latestStats = () => `${apiRoot}/stats/latest-stats`;
export const recentPropertyTransactions = () => `${apiRoot}/stats/recent-transactions`;

/**
 * customer referrals
 */
// const customerReferral = `${apiRoot}/referrals/customer`;
// export const registerCustomerReferral = `${customerReferral}/register`;
// export const retrieveCustomerReferral = (userId) => `${customerReferral}/${userId}/retrieve`;
// export const shareViaEmails = (userId) => `${customerReferral}/${userId}/shareViaEmails`;
// export const checkCustomerEligibility = (userId) => `${apiRoot}/referrals/eligibility/${userId}`;
export const registerCustomerReferral = ``;
export const retrieveCustomerReferral = (userId) => `${userId}`;
export const shareViaEmails = (userId) => `${userId}`;
export const checkCustomerEligibility = (userId) => `${userId}`;

/**
 * partner referrals
 */
// const partnerReferral = `${apiRoot}/referrals/partner`;
// export const registerPartnerReferral = `${partnerReferral}/register`;
export const registerPartnerReferral = ``;

/**
 * system
 */
export const contact = () => `${apiRoot}/contact`;
export const contactWithAttachments = () => `${apiRoot}/contact-with-attachments`;
export const facebookPageInfo = () => `${apiRoot}/facebook/pageInfo`;
export const maintenanceStatus = `${apiRoot}/maintenance-status`;

/**
 * cms
 * */
const contents = `${apiRoot}/contents`;
export const indexContents = () => `${contents}/index`;
export const media = () => `${contents}/media`;
export const webinar = () => `${contents}/webinar`;
export const keyDates = () => `${contents}/keyDates`;
export const helpfulLinks = () => `${contents}/helpfulLinks`;
export const propertiesRg46 = () => `${contents}/properties/rg46`;

/**
 * new stuff
 * */
export const flowsFirstStepUrl = (flowType) => `${config.brickxApiHost}/users/flows/${flowType}/steps`;
export const flowsNextStepUrl = (flowType, userId) => `${config.brickxApiHost}/users/flows/${flowType}/steps/${userId}`;
export const latestDistributions = () => `${apiRoot}/monthlyLatestDistributions`;
export const mailchimpInterestRegistrationEndpoint = () => `${apiRoot}/mc/register-interest`;

import SecondaryButton from 'src/design/components/button/SecondaryButton';

const EditExitButton = ({ editForm, ...props }) => {
  return (
    <SecondaryButton
      color={
        editForm.type === 'sell'
          ? SecondaryButton.colors.SECONDARY
          : SecondaryButton.colors.ACCENT
      }
      size={SecondaryButton.sizes.EXTRA_SMALL}
      onClick={() => editForm.callback({ ...editForm, orderId: '' })}
      {...props}
    >
      <span className="fa fa-close" />
    </SecondaryButton>
  );
};

export default EditExitButton;

import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  property as propertyPropType,
  account as accountPropType,
  orderAggregated as orderPropType,
  myOrders as myOrdersPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';
import { connect } from 'react-redux';
import { fetchOrders } from 'scripts/redux/actions/market';
import {
  ordersSellSelector,
  ordersBuySelector,
} from 'scripts/redux/selectors/market';
import { accountSelector } from 'scripts/redux/selectors/account';
import OrderBookTrade from 'scripts/components/property/trade/OrderBookTrade';
import TradeProposalFormBuyV2 from 'scripts/components/property/trade/TradeProposalFormBuyV2';
import Trade from 'scripts/constants/Trade';
import { renderCode } from 'src/settings/properties';

const mapStateToProps = (state) => ({
  ...ordersSellSelector(state),
  ...ordersBuySelector(state),
  ...accountSelector(state),
});

@connect(mapStateToProps, {
  fetchOrders,
})
export default class TradePanelBuy extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    ordersSell: PropTypes.arrayOf(orderPropType),
    ordersBuy: PropTypes.arrayOf(orderPropType),
    myOrders: myOrdersPropType.isRequired,
    getTotalBricksOwned: PropTypes.func.isRequired,
    fetchMyAccountInfo: PropTypes.func.isRequired,
    fetchMyOrdersInfo: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    handleClosePanelClick: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
  };

  state = {
    buyForm: {
      quantity: '',
      price: '',
      valueOfBricks: 0,
      transactionFee: 0,
      total: 0,
      hasQuantityError: false,
      hasPriceError: false,
      quantityErrorMsg: '',
      priceErrorMsg: '',
      callback: (updatedbuyForm) => {
        this.setState({ buyForm: updatedbuyForm });
      },
    },
  };

  componentDidMount() {
    const {
      user,
      property,
      fetchMyAccountInfo,
      fetchMyOrdersInfo,
      fetchOrders,
    } = this.props;
    fetchMyAccountInfo(user);
    fetchMyOrdersInfo(user);
    fetchOrders(property.propertyCode);

    this.pollingInterval = setInterval(() => {
      fetchMyAccountInfo(user);
      fetchMyOrdersInfo(user);
      fetchOrders(property.propertyCode);
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  render() {
    const {
      user,
      account,
      property,
      ordersSell,
      ordersBuy,
      myOrders,
      getTotalBricksOwned,
      handleClosePanelClick,
      redirectTo,
    } = this.props;
    const { buyForm } = this.state;

    return (
      <div className="panel-financials-invest-shadow">
        <div className="panel-financials-light-blue-header panel-financials-buy panel-financials-table">
          <div className="header">
            <div className="row">
              <div className="panel-financials-title col-md-8 col-xs-8">
                {`Buy Bricks in ${renderCode(property.propertyCode)}`}
              </div>
              <div className="link col-xs-4 col-md-4 text-right">
                <span className="close-panel" onClick={handleClosePanelClick}>
                  <span>
                    <i className="fa fa-times-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="body wrap-the-col">
            <div className="col-md-5">
              <OrderBookTrade
                account={account}
                property={property}
                tradeType={Trade.TRADE_TYPE.BUY}
                ordersSell={ordersSell}
                ordersBuy={ordersBuy}
              />
            </div>
            <div className="col-md-7">
              <TradeProposalFormBuyV2
                user={user}
                property={property}
                account={account}
                myOrders={myOrders}
                getTotalBricksOwned={getTotalBricksOwned}
                buyForm={buyForm}
                redirectTo={redirectTo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

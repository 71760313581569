import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import {
  aboveBelowValuationHelpUrl,
  propertyVoteUrl,
} from 'src/utils/pageUrls';
import { accountSelector } from 'scripts/redux/selectors/account';
import { bafmSelector } from 'scripts/redux/selectors/bafm';
import {
  brick,
  brickPrice,
  percent,
  monthYear,
} from 'scripts/utilities/formatters';
import { fetchBafm } from 'scripts/redux/actions/bafm';
import {
  fetchOrderBookStatus,
  fetchNumOfBricksTransactedLastMonth,
} from 'scripts/redux/actions/market';
import {
  getBrickValuation,
  getPriceDifferencePercentage,
  isANewProperty,
  // shouldFirstPremiumAlertBeTriggered,
} from 'scripts/utilities/propertyHelper';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';
import {
  myOrdersSelector,
  bricksTransactedLastMonthSelector,
  orderBookStatusSelector,
} from 'scripts/redux/selectors/market';
import {
  property as propertyPropType,
  user as userPropType,
  account as accountPropType,
  bafm as bafmPropType,
  myOrders as myOrdersPropType,
  orderBookStatus as orderBookStatusPropType,
} from 'scripts/constants/PropTypes';
import { sendFirstPremiumAlertEvent } from 'scripts/redux/actions/segment/events/tradeEvents';
import { sendPopupSignupSubmitEvent } from 'scripts/redux/actions/segment/events/loginOrSignupPopupEvents';
import { toggleModal as toggleLoginOrSignupModalAction } from 'src/store/loginOrSignupModalActions';
import BrickValuationBar from 'scripts/components/property/trade/BrickValuationBar';
import Constants from 'scripts/constants/Constants';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import Loading from 'src/components/loading/Loading';
import LowestAvailableBricksPriceBox from 'scripts/components/property/trade/LowestAvailableBricksPriceBox';
import Modal from 'scripts/components/helpers/Modal';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import buyingSellingDisabled from 'scripts/buyingSellingDisabled';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { VOTE_CLOSED_PROPERTIES } from 'src/settings/trading';
import { renderCode } from 'src/settings/properties';

const mapStateToProps = (state) => ({
  ...bafmSelector(state),
  ...accountSelector(state),
  ...myOrdersSelector(state),
  ...bricksTransactedLastMonthSelector(state),
  ...orderBookStatusSelector(state),
});

@connect(mapStateToProps, {
  fetchBafm,
  fetchOrderBookStatus,
  fetchNumOfBricksTransactedLastMonth,
  toggleLoginOrSignupModal: toggleLoginOrSignupModalAction,
})
export default class InvestPanelMarketOpen extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    myOrders: myOrdersPropType.isRequired,
    property: propertyPropType.isRequired,
    orderBookStatus: orderBookStatusPropType,
    bricksTransactedLastMonth: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    bafm: bafmPropType,
    fetchOrderBookStatus: PropTypes.func.isRequired,
    fetchNumOfBricksTransactedLastMonth: PropTypes.func.isRequired,
    fetchBafm: PropTypes.func.isRequired,
    fetchMyOrdersInfo: PropTypes.func.isRequired,
    handleBuyButtonClick: PropTypes.func.isRequired,
    handleSellButtonClick: PropTypes.func.isRequired,
    getTotalBricksOwned: PropTypes.func.isRequired,
    toggleLoginOrSignupModal: PropTypes.func.isRequired,
  };

  state = {
    orderBookEnabled: true,
    sellError: null,
    isUpdatedWithUserInfo: false,
    buyButtonClickedWhileUserLoggedOut: false,
    sellButtonClickedWhileUserLoggedOut: false,
    bricksTransactedInLastMonth: '- Bricks',
  };

  componentDidMount() {
    const {
      user,
      property,
      fetchBafm,
      fetchOrderBookStatus,
      fetchNumOfBricksTransactedLastMonth,
      fetchMyOrdersInfo,
    } = this.props;
    fetchMyOrdersInfo(user);
    fetchBafm();
    fetchOrderBookStatus(property.propertyCode);
    fetchNumOfBricksTransactedLastMonth(property.propertyCode);
    this._updateBricksTransactedInLastMonth(
      this.props.bricksTransactedLastMonth
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isNil(nextProps.bricksTransactedLastMonth) &&
      nextProps.bricksTransactedLastMonth !==
        this.props.bricksTransactedLastMonth
    ) {
      this._updateBricksTransactedInLastMonth(
        nextProps.bricksTransactedLastMonth
      );
    }

    const hasUserBeenUpdated = nextProps.user !== this.props.user;
    if (hasUserBeenUpdated) {
      this.props.fetchMyOrdersInfo(nextProps.user);
      if (isUserLoggedIn(nextProps.user) && !isUserLoggedIn(this.props.user)) {
        this._performActionAfterLoginPopup(nextProps.user);
      }
    }
  }

  componentDidUpdate() {
    const { orderBookStatus } = this.props;
    const orderBookStatusUpdated = !_.isNil(orderBookStatus)
      ? orderBookStatus.enabled !== this.state.orderBookEnabled
      : false;
    if (orderBookStatusUpdated) {
      this._updateOrderBookEnabledValue();
    }
  }

  render() {
    const { user, account, property, getTotalBricksOwned, myOrders } =
      this.props;
    const { bricksTransactedInLastMonth } = this.state;

    const hasBricks = getTotalBricksOwned(account, myOrders) > 0;
    const { brickValuation } = getBrickValuation(property);
    const priceDifferencePercentage = getPriceDifferencePercentage(property);

    const buyButton = () => (
      <PrimaryButton
        testRef="buy-bricks-button"
        color={PrimaryButton.colors.SECONDARY}
        onClick={() => this._handleBuyButtonClick(user)}
        arrow
        fullWidth
        disabled={buyingSellingDisabled}
      >
        BUY BRICKS
      </PrimaryButton>
    );

    const sellButton = () => (
      <PrimaryButton
        testRef="sell-bricks-button"
        color={PrimaryButton.colors.PRIMARY}
        onClick={() => this._handleSellButtonClick(user)}
        arrow
        fullWidth
        disabled={buyingSellingDisabled}
      >
        SELL BRICKS
      </PrimaryButton>
    );

    const voteButton = () => (
      <PrimaryButton
        testRef="vote-button"
        color={PrimaryButton.colors.SECONDARY}
        link={propertyVoteUrl(property.propertyCode)}
        fullWidth
        arrow
      >
        {_.includes(VOTE_CLOSED_PROPERTIES, property.propertyCode)
          ? 'VOTE CLOSED'
          : 'VOTE'}
      </PrimaryButton>
    );

    const actionButtons = (className) => {
      if (property.propertyStatus === 'market_closed') {
        return <Spacing top="small">{voteButton()}</Spacing>;
      }

      return (
        <Fragment>
          {buyButton(classNames('buy-button', className))}
          <Spacing top="x-small">
            {sellButton(classNames('sell-button', className))}
          </Spacing>
        </Fragment>
      );
    };

    return (
      <div className="invest-panel">
        <div className="mobile visible-xs">
          <BrickValuationBar
            property={property}
            showIcon={Constants.TRUE}
            showValuationDate={Constants.TRUE}
          />
          <LowestAvailableBricksPriceBox
            property={property}
            showAboveBelowValuation={Constants.TRUE}
            isMobileVersion={Constants.TRUE}
          />
          {actionButtons()}
          <div className="trade-error">
            {this.state.sellError || <div>&nbsp;</div>}
          </div>
          <p className="bricks-sold-last-month">
            <span className="bricks">{bricksTransactedInLastMonth}</span>
            {` transacted in ${renderCode(
              property.propertyCode
            )} (last 30 days)`}
          </p>
        </div>
        <FinancialsPanel
          className="panel-financials-blue-header panel-financials-invest hidden-xs"
          title={`Invest in ${renderCode(property.propertyCode)}`}
        >
          <LowestAvailableBricksPriceBox
            property={property}
            showAboveBelowValuation
            isMobileVersion={Constants.FALSE}
          />

          {isANewProperty(property) ? (
            <div>
              <div className="row table-row">
                <BrickValuationBar
                  property={property}
                  showIcon={Constants.FALSE}
                  showValuationDate
                />
              </div>
            </div>
          ) : (
            <div className="row table-row">
              {property.financials.lowestAvailableBrickPrice > 0 ? (
                <div>
                  <BrickValuationBar
                    property={property}
                    showIcon={Constants.FALSE}
                    showValuationDate
                  />
                </div>
              ) : (
                <div className="col-md-12 col-xs-12 asterisk-info copy">
                  Check back later to see if any Bricks have been listed for
                  sale. If you already own Bricks in this property, you can
                  click below to place a Sell Order.
                </div>
              )}
            </div>
          )}

          {actionButtons('trade-button')}

          <div className="trade-error">
            {this.state.sellError || <div>&nbsp;</div>}
          </div>
          <p className="bricks-sold-last-month">
            <span className="bricks">{bricksTransactedInLastMonth}</span>
            {` transacted in ${renderCode(
              property.propertyCode
            )} over the last 30 days.`}
          </p>
        </FinancialsPanel>

        <Modal
          ref={(el) => (this.noBricksModal = el)}
          title="No Bricks Currently Available"
          id="no-brickx-modal"
          sizeClass="modal-md"
          body={
            <div>
              <p className="margin-bottom">{`Currently there are 0 Bricks for sale in ${renderCode(
                property.propertyCode
              )}. Check back later to see if any Bricks have been listed.`}</p>
              {!_.isNil(account) && !_.isNil(myOrders) ? (
                <div>
                  {hasBricks && (
                    <div>
                      <p>
                        Already own Bricks? If you already own Bricks in{' '}
                        {renderCode(property.propertyCode)} and would like to
                        place a sell order, click below.
                      </p>
                      <p
                        className="button trade-button sell-button orange-button"
                        onClick={() => {
                          this.noBricksModal.close();
                          this._handleSellButtonClick(user);
                        }}
                      >
                        SELL BRICKS&nbsp;
                        <span className="fa fa-angle-right"></span>
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <Loading />
              )}
            </div>
          }
        />

        <Modal
          ref={(el) => (this.aboveValuationFirstAlertModal = el)}
          title={
            <span className="text-orange">
              <span className="fa fa-exclamation-triangle" /> ATTENTION: Above
              Valuation Alert
            </span>
          }
          id="premium-price-first-alert-modal"
          sizeClass="modal-md"
          onClose={this._handleCancelledAboveValuationButtonClick}
          footer={
            <div>
              <button
                className="button cancel-button right-arrow-button"
                onClick={() => {
                  this.aboveValuationFirstAlertModal.close();
                  this._sendCancelledAboveValuationEvent();
                }}
              >
                <span>CANCEL</span>
              </button>
              <button
                className="button trade-button sell-button orange-button confirm-high-price right-arrow-button"
                onClick={() => {
                  this.aboveValuationFirstAlertModal.close();
                  this._handleProcessAboveValuationButtonClick();
                }}
              >
                I ACKNOWLEDGE, PROCEED
              </button>
            </div>
          }
          body={
            <div>
              <p>Please read the following.</p>
              <ul>
                <li>
                  The current Lowest Available Brick Price for this property is{' '}
                  <span className="text-orange">
                    {property.financials.lowestAvailableBrickPrice::brickPrice()}
                  </span>
                </li>
                <li>
                  The last valuation of a Brick in this property from{' '}
                  {property.financials.latestValuationDate::monthYear()} is{' '}
                  <span className="text-orange">
                    {brickValuation::brickPrice()}
                  </span>
                </li>
              </ul>
              <p>
                If you were to purchase the lowest priced Brick you would be{' '}
                <span className="text-orange">
                  paying {priceDifferencePercentage::percent()} more
                </span>{' '}
                than the last valuation (
                {property.financials.latestValuationDate::monthYear()}). This
                could negatively impact your investment returns.
              </p>
              For more information, please read the short article on{' '}
              <a href={aboveBelowValuationHelpUrl()} target="_blank">
                <u>
                  Understanding Above and Below Valuations on the BrickX
                  Platform
                </u>
              </a>
              .
            </div>
          }
        />
      </div>
    );
  }

  _setOrderBookClosedError = () => {
    const { property } = this.props;
    this.setState({
      sellError: `The Order Book for ${renderCode(
        property.propertyCode
      )} is currently closed`,
    });
  };

  _updateOrderBookEnabledValue = () => {
    this.setState({ orderBookEnabled: this.props.orderBookStatus.enabled });
  };

  _updateBricksTransactedInLastMonth = (bricksTransactedLastMonth) => {
    if (!_.isNil(bricksTransactedLastMonth)) {
      this.setState({
        bricksTransactedInLastMonth: bricksTransactedLastMonth::brick(),
      });
    }
  };

  _handleBuyButtonClick = (user) => {
    const { handleBuyButtonClick, toggleLoginOrSignupModal } =
      this.props;
    const { orderBookEnabled } = this.state;
    if (isUserLoggedIn(user)) {
      if (!orderBookEnabled) {
        this._setOrderBookClosedError();
      // } else if (shouldFirstPremiumAlertBeTriggered(property)) {
      //   setTimeout(() => this.aboveValuationFirstAlertModal.open(), 100); // eslint-disable-line no-magic-numbers
      // } else if (property.financials.lowestAvailableBrickPrice > 0) {
      } else {
        // setTimeout(() => this.noBricksModal.open(), 100); // eslint-disable-line no-magic-numbers
        handleBuyButtonClick();
      }
    } else {
      this.setState({ buyButtonClickedWhileUserLoggedOut: true });
      const trackingProps = {
        launchedFrom: 'Buy Bricks Button',
        propertyCode: this.props.property.propertyCode,
      };
      toggleLoginOrSignupModal({
        trackingProps,
        onSubmit: () => sendPopupSignupSubmitEvent(trackingProps),
      });
    }
  };

  _handleSellButtonClick = (user) => {
    const {
      account,
      myOrders,
      bafm,
      property,
      handleSellButtonClick,
      getTotalBricksOwned,
      toggleLoginOrSignupModal,
    } = this.props;
    if (isUserLoggedIn(user)) {
      const totalBricksOwned = getTotalBricksOwned(account, myOrders);

      if (!this.state.orderBookEnabled) {
        this._setOrderBookClosedError();
      } else if (totalBricksOwned === 0) {
        this.setState({
          sellError: `You do not own any Bricks in ${renderCode(
            property.propertyCode
          )}`,
        });
      } else if (bafm && bafm.hasBafm) {
        this.setState({
          sellError:
            'Cannot sell Bricks: You have deposited using POLi recently and the funds were advanced to your BrickX Wallet while the bank transfer happens in the back ground. As soon as the transfer arrives at BrickX, the restrictions will be lifted from your account.',
        });
      } else {
        handleSellButtonClick();
      }
    } else {
      this.setState({ sellButtonClickedWhileUserLoggedOut: true });
      toggleLoginOrSignupModal();
    }
  };

  _performActionAfterLoginPopup = (user = this.props.user) => {
    const {
      buyButtonClickedWhileUserLoggedOut,
      sellButtonClickedWhileUserLoggedOut,
    } = this.state;

    if (buyButtonClickedWhileUserLoggedOut) {
      this._handleBuyButtonClick(user);
    }
    if (sellButtonClickedWhileUserLoggedOut) {
      this._handleSellButtonClick(user);
    }
    this.setState({ buyButtonClickedWhileUserLoggedOut: false });
  };

  _handleProcessAboveValuationButtonClick = () => {
    const { handleBuyButtonClick } = this.props;
    this.aboveValuationFirstAlertModal.close();
    this._sendProceedAboveValuationEvent();
    handleBuyButtonClick();
  };

  _handleCancelledAboveValuationButtonClick = () => {
    this._sendCancelledAboveValuationEvent();
  };

  _sendProceedAboveValuationEvent = () => {
    sendFirstPremiumAlertEvent(
      SegmentEventTypes.TRADE.PREMIUM_PRICE_ALERTS_ENGAGEMENT.PROCEED
    );
  };

  _sendCancelledAboveValuationEvent = () => {
    sendFirstPremiumAlertEvent(
      SegmentEventTypes.TRADE.PREMIUM_PRICE_ALERTS_ENGAGEMENT.CANCELLED
    );
  };
}

import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import {
  myOrders as myOrdersPropType,
  property as propertyPropType,
  account as accountPropType,
  fees as feesPropType,
} from 'scripts/constants/PropTypes';
import { connect } from 'react-redux';
import { myOrdersSelector, feesSelector } from 'scripts/redux/selectors/market';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { accountSelector } from 'scripts/redux/selectors/account';
import { fetchMyOrders, fetchFees } from 'scripts/redux/actions/market';
import { fetchProperties } from 'scripts/redux/actions/properties';
import { fetchAccountInfo } from 'scripts/redux/actions/account';
import PageLayout from 'src/components/dashboard/common/PageLayout';
import PreOrderPanel from 'src/components/dashboard/pendingOrders/PreOrderPanel';
import BuyOrderPanel from 'src/components/dashboard/pendingOrders/BuyOrderPanel';
import SellOrderPanel from 'src/components/dashboard/pendingOrders/SellOrderPanel';
import { NoOrdersPanel } from 'src/components/dashboard/pendingOrders/NoOrders';

const mapStateToProps = (state) => ({
  ...myOrdersSelector(state),
  ...propertiesSelector(state),
  ...accountSelector(state),
  ...feesSelector(state),
});

@connect(mapStateToProps, {
  fetchMyOrders,
  fetchProperties,
  fetchAccountInfo,
  fetchFees,
})
export default class PendingOrders extends Component {
  static propTypes = {
    myOrders: myOrdersPropType.isRequired,
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    account: accountPropType.isRequired,
    fees: feesPropType.isRequired,
    fetchMyOrders: PropTypes.func.isRequired,
    fetchProperties: PropTypes.func.isRequired,
    fetchAccountInfo: PropTypes.func.isRequired,
    fetchFees: PropTypes.func.isRequired,
  };

  state = {
    editForm: {
      orderId: '',
      type: '',
      propertyCode: '',
      quantity: 0,
      price: 0,
      total: 0,
      hasError: false,
      errorMsg: '',
      callback: (updatedEditForm) => {
        this.setState({ editForm: updatedEditForm });
      },
    },
  };

  componentDidMount() {
    this.props.fetchMyOrders();
    this.props.fetchProperties();
    this.props.fetchAccountInfo();
    this.props.fetchFees();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.myOrders.list, this.props.myOrders.list)) {
      this.props.fetchMyOrders();
      this.props.fetchProperties();
      this.props.fetchAccountInfo();
      this.props.fetchFees();
    }
  }

  render() {
    const { myOrders, properties, account, fees } = this.props;

    return (
      <PageLayout testRef="pending-orders">
        {myOrders.list && myOrders.list.length ? (
          <Fragment>
            <PreOrderPanel
              preOrders={myOrders.pendingPre}
              properties={properties}
              account={account}
              editForm={this.state.editForm}
            />
            <BuyOrderPanel
              buyOrders={myOrders.pendingBuy}
              properties={properties}
              account={account}
              fees={fees.buyFee}
              editForm={this.state.editForm}
            />
            <SellOrderPanel
              sellOrders={myOrders.pendingSell}
              properties={properties}
              account={account}
              fees={fees.sellFee}
              editForm={this.state.editForm}
            />
          </Fragment>
        ) : (
          <NoOrdersPanel />
        )}
      </PageLayout>
    );
  }
}

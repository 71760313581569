import { property as propertyPropType } from 'scripts/constants/PropTypes';
import Highchart from 'src/third-party/highchart/Highchart';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import Formatters from 'scripts/utilities/formattersV2';
import _ from 'lodash';

const colorMap = {
  green: '#63d970',
  red: '#F60221',
  blue: '#05b1fb',
  orange: '#db852e',
};

const CashFlowChart = ({ property }) => {
  return (
    <FinancialsPanel
      className="panel-unit-cash-flow"
      title="Cashflow Breakdown: Annual Forecast"
    >
      <div className="total-expenses hidden-xs hidden-sm gross-rental-income">
        <small>Gross Income:</small>{' '}
        {Formatters.numeric.dollarDecimal(
          property.financials.annualGrossRentalIncome
        )}
      </div>
      <Highchart
        key="chart-cash-flow"
        config={{
          credits: {
            enabled: false,
          },
          chart: {
            plotBackgroundColor: null,
            plotShadow: false,
            type: 'pie',
          },
          title: false,
          tooltip: {
            pointFormatter: function () {
              return `${Formatters.numeric.dollar(
                this.y
              )} (${Formatters.numeric.roundPercentDecimal(this.percentage)}%)`;
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y !== 0) return `${this.key}`;
                },
                distance: 20,
                color: '#444',
                textShadow: 'none',
              },
            },
            pie: {
              slicedOffset: 2,
            },
          },
          series: [
            {
              name: 'Expenses',
              colorByPoint: true,
              data: _.orderBy(
                [
                  {
                    name: 'Net Income',
                    y: property.financials.annualNetRentalIncome,
                    sliced: true,
                    color: colorMap.green,
                  },
                  {
                    name: 'Water Rates',
                    y: property.financials.waterRates,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Council Rates',
                    y: property.financials.councilRates,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Strata Fee',
                    y: property.financials.strata,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Maintenance Allowance',
                    y: property.financials.repairsMaintenance,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Landlord Insurance',
                    y: property.financials.landlordInsurance,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Audit & Valuation Fees',
                    y: property.financials.annualAuditValuationFees,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Property Land Tax',
                    y: property.financials.propertyLandTax,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Other Cash Flow',
                    y: property.financials.otherCashFlow,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Property Management Fee',
                    y: property.financials.annualPropertyManagementFee,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Investment Management Fee',
                    y: property.financials.annualInvestmentManagementFee,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Trustee Fee',
                    y: property.financials.trusteeFee,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Custodian Fee',
                    y: property.financials.custodianFee,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Debt Arrangement Fee',
                    y: property.financials.debtArrangementFee,
                    color: colorMap.blue,
                  },
                  {
                    name: 'Interest Payments',
                    y: property.financials.debtTerms
                      ? property.financials.debtTerms.yearlyInterestPayment
                      : 0,
                    sliced: true,
                    color: colorMap.red,
                  },
                ],
                'y',
                'desc'
              ),
            },
          ],
        }}
      />
      <small className="disclaimer">{`Net Income is forecast based on ${
        property.tenantStatus.tenanted ? 'current' : 'forecasted'
      } income and predicted expenses.`}</small>
    </FinancialsPanel>
  );
};

CashFlowChart.propTypes = {
  property: propertyPropType,
};

export default CashFlowChart;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DynamicNumber from 'react-dynamic-number';
import ReactPlaceholder from 'react-placeholder';
import _ from 'lodash';

import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  sendCalculatorPerPropertyEngagementEvent,
  sendCalculatorPerPropertyClickOnBreakdownEngagementEvent,
} from 'scripts/redux/actions/segment/events/propertiesPageEvents';

import ReturnsCalculatorHoldingPeriodSelector from 'scripts/components/calculators/ReturnsCalculatorHoldingPeriodSelector';
import PropertyReturnsCalculatorBreakdown from 'scripts/components/property/calculator/PropertyReturnsCalculatorBreakdown';
import PropertyReturnsCalculatorReturnsEstimation from 'scripts/components/property/calculator/PropertyReturnsCalculatorReturnsEstimation';
import PropertyReturnsCalculatorReturnsEstimationPlaceholder from 'scripts/components/property/calculator/PropertyReturnsCalculatorReturnsEstimationPlaceholder';
import PropertyReturnsCalculatorDisclaimer from 'scripts/components/property/calculator/PropertyReturnsCalculatorDisclaimer';
import SliderComponent from 'scripts/components/shared/SliderComponent';
import InfoBox from 'scripts/components/shared/InfoBox';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import Modal from 'scripts/components/helpers/ModalV2';
import Popover from 'scripts/components/shared/Popover';

import {
  percent,
  numberDecimal,
  monthsTillNow,
} from 'scripts/utilities/formatters';
import { isMobile } from 'scripts/utilities/helpers';

import Configuration from 'scripts/constants/Configuration';
import Constants from 'scripts/constants/Constants';
import Numbers from 'scripts/constants/Numbers';
import {
  NUM_OF_MONTHS_FOR_AMORTISING,
  BUY_BROKERAGE_PERCENTAGE,
} from 'src/settings/trading';
import { getPropertyValuePopoverText } from 'src/settings/text';
import { renderCode } from 'src/settings/properties';
import InvestmentTypes from 'src/enums/investmentTypes';

const INTEGERS = Numbers.TEN;
const FRACTION = 0;

const SLIDER_MIN_ACR = -10.0;
const SLIDER_MAX_ACR = 20.0;
const SLIDER_STEP_ACR = 0.1;
const SLIDER_MARKS_ACR = {};

const debouncedSendCalculatorPerPropertyEngagementEvent = _.debounce(
  sendCalculatorPerPropertyEngagementEvent,
  Configuration.SYSTEMS.USER_INPUT_DEBOUNCE_TIMEOUT_MS
);

export default class PropertyReturnsCalculator extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    averageAnnualGrowthAmountNumber: PropTypes.number,
    averageAnnualGrowthNumberOfYears: PropTypes.number,
  };

  state = {
    showCalculationResult: false,
    showEstimatedReturns: !!this.props.averageAnnualGrowthAmountNumber,
    annualGrowth: this.props.averageAnnualGrowthAmountNumber,
    investmentAmount: 10000,
    investmentPeriod: 5,
  };

  render() {
    const { property, averageAnnualGrowthNumberOfYears } = this.props;
    const {
      investmentAmount,
      investmentPeriod,
      annualGrowth,
      showEstimatedReturns,
    } = this.state;

    const maximalInvestmentAmountAllowed =
      property.financials.brickPrice *
      property.financials.numberBricks *
      property.maxOwnership *
      (1 + BUY_BROKERAGE_PERCENTAGE);

    const maxOwnershipPercentage = property.maxOwnership;
    const maxOwnershipBricks = property.financials.numberBricks * maxOwnershipPercentage / 100;

    const annualGrowthRate = annualGrowth / Numbers.ONE_HUNDRED;
    const netRentalYieldRate = property.financials.netRentalYield;

    const remainingMonthsOfAmortising = Math.min(
      NUM_OF_MONTHS_FOR_AMORTISING,
      NUM_OF_MONTHS_FOR_AMORTISING -
        property.platformSettlementDate::monthsTillNow()
    );
    const remainingUnamortisedAcqCost =
      property.financials.acquisitionCost *
      (remainingMonthsOfAmortising / NUM_OF_MONTHS_FOR_AMORTISING);
    const currentPropertyValue =
      property.financials.lastPropertyValuation +
      property.financials.liveCashReserve +
      remainingUnamortisedAcqCost;
    const currentPercentOfPropertyAsset =
      property.financials.lastPropertyValuation / currentPropertyValue;
    const currentPercentOfCashReserve =
      property.financials.liveCashReserve / currentPropertyValue;
    const currentPercentOfAcquisitionCost =
      remainingUnamortisedAcqCost / currentPropertyValue;

    const initDebtAmount = property.financials.debtTerms
      ? property.financials.debtTerms.initialDebt
      : 0;
    const currentDebtRate = initDebtAmount
      ? initDebtAmount / currentPropertyValue
      : 0;

    return (
      <FinancialsPanel
        title="Understand Potential Returns"
        className="property-returns-calculator panel-financials-table-icon"
      >
        <div className="disclaimer">
          Find out how key factors can influence returns by changing the inputs.
        </div>

        <div className="property-returns-calculator__annual-growth">
          <div className="property-returns-calculator__title">
            {property.investmentType === InvestmentTypes.property ? (
              <span>
                Estimate the annual change
                <br /> in property value
              </span>
            ) : (
              <span>
                Understand the annual change
                <br /> in investment value.
              </span>
            )}{' '}
            <Popover
              placement="top"
              content={getPropertyValuePopoverText(
                property.propertyType,
                averageAnnualGrowthNumberOfYears
              )}
              data-test-reference="property-calculator-growth-amount-popover"
            />
          </div>
          <div className="property-returns-calculator__annual-growth-input">
            <SliderComponent
              ref={(annualGrowthSlider) => {
                this.annualGrowthSlider = annualGrowthSlider;
              }}
              name="annual-growth-rate"
              minValue={SLIDER_MIN_ACR}
              maxValue={SLIDER_MAX_ACR}
              value={annualGrowth}
              step={SLIDER_STEP_ACR}
              marks={SLIDER_MARKS_ACR}
              updateSliderValueCallBack={this._onAnnualGrowthChange}
              data-test-reference="annual-growth-slider-component"
            />
          </div>
        </div>
        <div className="property-returns-calculator__investment-amount">
          <div className="property-returns-calculator__title">
            I would like to invest
          </div>
          <div className="dollar-input">
            <DynamicNumber
              id="investment-amount"
              name="investment-amount"
              value={investmentAmount}
              separator={'.'}
              integer={INTEGERS}
              fraction={FRACTION}
              positive={Constants.TRUE}
              thousand={Constants.TRUE}
              placeholder="Enter Amount"
              onChange={this._onInvestmentAmountChange}
            />
          </div>
          {maximalInvestmentAmountAllowed < investmentAmount && (
            <div
              id="investment-amount-warning"
              className="property-returns-calculator__maximal-investment-amount-warning info-box-disclosures"
            >
              <InfoBox className="small">
                Your investment amount could be over the maximum ownership (
                {maxOwnershipPercentage::percent()} per property) in this
                property. As per the PDS,{' '}
                {maxOwnershipBricks} Bricks is the
                maximum you can own in each property. (Some properties may be
                exempted, please refer to PDS)
              </InfoBox>
            </div>
          )}
        </div>
        <div className="property-returns-calculator__holding-period">
          <div className="property-returns-calculator__title">
            Holding period
          </div>
          <div className="select-box property-returns-calculator--select-box">
            <ReturnsCalculatorHoldingPeriodSelector
              className="property-returns-calculator--select-box-options"
              investmentPeriod={investmentPeriod}
              updateHoldingPeriodCallback={this._onInvestmentPeriodChange}
            />
          </div>
        </div>
        <div className="property-returns-calculator__estimated-return">
          <ReactPlaceholder
            ready={showEstimatedReturns}
            customPlaceholder={
              <PropertyReturnsCalculatorReturnsEstimationPlaceholder />
            }
            data-test-reference="estimated-returns-placeholder"
          >
            <PropertyReturnsCalculatorReturnsEstimation
              investmentPeriod={investmentPeriod}
              investmentAmount={investmentAmount}
              annualGrowthRate={annualGrowthRate}
              debtRate={currentDebtRate}
              netRentalYieldRate={netRentalYieldRate}
              initialPercentOfPropertyAsset={currentPercentOfPropertyAsset}
              initialPercentOfAcquisitionCost={currentPercentOfAcquisitionCost}
              initialPercentOfCashReserve={currentPercentOfCashReserve}
              remainingMonthsOfAmortising={remainingMonthsOfAmortising}
            />
          </ReactPlaceholder>
        </div>
        {showEstimatedReturns ? (
          <div
            className={`property-returns-calculator__view-breakdown-link property-returns-calculator__view-breakdown-link--enabled`}
            onClick={this._updateShowCalculationResult}
            data-toggle="modal"
            data-target="#modal-property-returns-calculator-breakdown"
          >
            View Return Breakdown >
          </div>
        ) : (
          <div
            className={`property-returns-calculator__view-breakdown-link property-returns-calculator__view-breakdown-link--disabled`}
          >
            View Return Breakdown >
          </div>
        )}
        <PropertyReturnsCalculatorDisclaimer />
        <Modal
          title={
            <span>
              {`${isMobile() ? 'Est.' : 'Estimated'} Returns Breakdown for`}{' '}
              <span className="property-returns-calculator-modal__title-property-code">
                {renderCode(property.propertyCode)}
              </span>
            </span>
          }
          id="modal-property-returns-calculator-breakdown"
          sizeClass="modal-lg text-left"
          onClose={this._updateShowCalculationResult}
          isHidden={!this.state.showCalculationResult}
          body={
            <div className="property-returns-calculator-modal">
              <PropertyReturnsCalculatorBreakdown
                investmentPeriod={investmentPeriod}
                investmentAmount={investmentAmount}
                annualGrowthRate={annualGrowthRate}
                debtRate={currentDebtRate}
                netRentalYieldRate={netRentalYieldRate}
                initialPercentOfPropertyAsset={currentPercentOfPropertyAsset}
                initialPercentOfAcquisitionCost={
                  currentPercentOfAcquisitionCost
                }
                initialPercentOfCashReserve={currentPercentOfCashReserve}
                remainingMonthsOfAmortising={remainingMonthsOfAmortising}
              />
            </div>
          }
          darkBackground
        />
        {/* <Modal
          title={
            <span>
            {`${isMobile() ? 'Est.' : 'Estimated'} Returns Breakdown for`} <span className="property-returns-calculator-modal__title-property-code">{property.propertyCode}</span>
            </span>
          }
          id="modal-property-returns-calculator-breakdown"
          sizeClass="modal-lg text-left"
          onClose={this._updateShowCalculationResult}
          body={
            <div className="property-returns-calculator-modal">
              {showCalculationResult
                ?
                <PropertyReturnsCalculatorBreakdown
                  investmentPeriod={investmentPeriod}
                  investmentAmount={investmentAmount}
                  annualGrowthRate={annualGrowthRate}
                  debtRate={currentDebtRate}
                  netRentalYieldRate={netRentalYieldRate}
                  initialPercentOfPropertyAsset={currentPercentOfPropertyAsset}
                  initialPercentOfAcquisitionCost={currentPercentOfAcquisitionCost}
                  initialPercentOfCashReserve={currentPercentOfCashReserve}
                  remainingMonthsOfAmortising={remainingMonthsOfAmortising}
                />
                :
                <div></div>
              }
            </div>
          }
        /> */}
      </FinancialsPanel>
    );
  }

  _onInvestmentAmountChange = (event) => {
    const updatedAmount = event.target.value.split(',').join('') * 1;
    if (!isNaN(updatedAmount)) {
      this.setState({
        investmentAmount: updatedAmount,
        showEstimatedReturns: true,
      });
      debouncedSendCalculatorPerPropertyEngagementEvent(
        'Amount Invested',
        this.props.property.propertyCode,
        this.state.annualGrowth,
        updatedAmount,
        this.state.investmentPeriod
      );
    }
  };

  _onInvestmentPeriodChange = (investmentPeriod) => {
    this.setState({
      investmentPeriod: investmentPeriod,
      showEstimatedReturns: true,
    });
    sendCalculatorPerPropertyEngagementEvent(
      'Holding Period',
      this.props.property.propertyCode,
      this.state.annualGrowth,
      this.state.investmentAmount,
      investmentPeriod
    );
  };

  _onAnnualGrowthChange = (value) => {
    const updatedAnnualGrowth = (value * 1)::numberDecimal() * 1;
    this.setState({
      annualGrowth: updatedAnnualGrowth,
      showEstimatedReturns: true,
    });

    debouncedSendCalculatorPerPropertyEngagementEvent(
      'Slider',
      this.props.property.propertyCode,
      updatedAnnualGrowth,
      this.state.investmentAmount,
      this.state.investmentPeriod
    );
  };

  _updateShowCalculationResult = () => {
    if (!this.state.showCalculationResult) {
      sendCalculatorPerPropertyClickOnBreakdownEngagementEvent(
        this.props.property.propertyCode,
        this.state.annualGrowth,
        this.state.investmentAmount,
        this.state.investmentPeriod
      );
    }

    this.setState({
      showCalculationResult: !this.state.showCalculationResult,
    });
  };
}

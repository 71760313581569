import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  property as propertyPropType,
  account as accountPropType,
  myOrders as myOrdersPropType,
  user as userPropType,
  sellForm as sellFormPropType,
  tradeProposal as tradeProposalPropType,
  fees as feesPropType,
} from 'scripts/constants/PropTypes';
import { connect } from 'react-redux';
import {
  confirmSell,
  sellDone,
  fetchFees,
} from 'scripts/redux/actions/market';
import {
  tradeProposalSelector,
  feesSelector,
} from 'scripts/redux/selectors/market';
import DynamicNumber from 'react-dynamic-number';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import {
  validateTradeProposalQuantity,
  validateTradeProposalPrice,
} from 'scripts/utilities/tradeHelper';
import { roundTransactionFee } from 'scripts/utilities/helpers';
import { checkUserWithTradePermission } from 'scripts/utilities/userAccountHelper';
import _ from 'lodash';
import {
  brickPrice,
  dollarDecimal,
  brick,
  percent,
} from 'scripts/utilities/formatters';
import Trade from 'scripts/constants/Trade';
import Numbers from 'scripts/constants/Numbers';
import Constants from 'scripts/constants/Constants';
import { LOWEST_SELL_PRICE_PERCENTAGE } from 'src/settings/trading';
import { renderCode } from 'src/settings/properties';

const mapStateToProps = (state) => ({
  ...feesSelector(state),
  ...tradeProposalSelector(state),
});

@connect(mapStateToProps, {
  fetchFees,
  confirmSell,
  sellDone,
})
export default class TradeProposalFormSell extends Component {
  static propTypes = {
    user: userPropType,
    account: accountPropType,
    property: propertyPropType,
    myOrders: myOrdersPropType,
    getTotalBricksOwned: PropTypes.func,
    sellForm: sellFormPropType,
    redirectTo: PropTypes.func,
    fees: feesPropType,
    tradeProposal: tradeProposalPropType,
    fetchFees: PropTypes.func,
    confirmSell: PropTypes.func,
    sellDone: PropTypes.func,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    sellProposalParams: {},
    terms: {
      accepted: false,
      showErrorMessage: false,
    },
    loading: false,
    showPendingOrderLink: false,
    totalBricksOwned: 0,
    totalBricksOwnedLoaded: false,
    totalBricksOnMyPendingSell: 0,
    totalBricksOnMyPendingSellLoaded: false,
  };

  componentDidMount() {
    const { account, myOrders, fetchFees, sellForm } = this.props;
    fetchFees();
    this._buildSellProposalParams(sellForm);
    this._updateTotalBricksOwned(account);
    this._updateTotalBricksOnMyPendingSell(myOrders);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this._updateTotalBricksOwned(this.props.account);
    }

    if (prevProps.myOrders !== this.props.myOrders) {
      this._updateTotalBricksOnMyPendingSell(this.props.myOrders);
    }

    const { property, tradeProposal, sellDone, redirectTo } = this.props;
    if (tradeProposal && tradeProposal.type === Trade.CONFIRMED) {
      sellDone(tradeProposal.data);
      redirectTo(`/investments/${property.propertyCode}/sell-success`);
    }
  }

  render() {
    const {
      property,
      fees: { sellFee, sellFeeFreeEnabled },
    } = this.props;

    const sellFeeForDisplay = sellFeeFreeEnabled ? '0.00' : sellFee ? sellFee.toString() : '-';

    return (
      <div className="place-order-panel">
        <div className="hidden-xs">
          <div className="white-boxes-container">
            <div className="col-inline col-inline-5 col-no-padding-left">
              <div className="white-box">
                <div>You Currently Own</div>
                <div className="sell value total-bricks-owned">
                  {this.state.totalBricksOwnedLoaded
                    ? this.state.totalBricksOwned::brick()
                    : '- Bricks'}{' '}
                  in {renderCode(property.propertyCode)}
                </div>
              </div>
            </div>
            <div className="col-inline col-inline-4 col-no-padding-left">
              <div className="white-box">
                <div>Pending Sell Orders</div>
                <div className="sell value total-bricks-pending-sell">
                  {this.state.totalBricksOnMyPendingSellLoaded
                    ? this.state.totalBricksOnMyPendingSell::brick()
                    : '- Bricks'}
                </div>
              </div>
            </div>
            <div className="col-inline col-inline-3 col-no-padding-left">
              <div className="white-box">
                <div>Available to Sell</div>
                <div className="sell value bricks-available-to-sell">
                  {this.state.totalBricksOwnedLoaded && this.state.totalBricksOnMyPendingSellLoaded
                    ? (this.state.totalBricksOwned - this.state.totalBricksOnMyPendingSell)::brick()
                    : '- Bricks'}
                </div>
              </div>
            </div>
          </div>
          <div className="title sell">Sell your Bricks</div>
        </div>
        <div className="white-boxes-container">
          <div className="container-trade-input">
            <div className="white-box">
              <ReactPlaceholder
                ready={
                  this.state.totalBricksOwnedLoaded &&
                  this.state.totalBricksOnMyPendingSellLoaded
                }
                customPlaceholder={<RectShape color={'#CCCCCD'} style={{ height: 20, marginTop: '12px' }} />}
              >
                Quantity
                <DynamicNumber
                  id="id_sell_quantity"
                  className="quantity-to-be-sell"
                  name="sell_quantity"
                  value={this.state.sellProposalParams.quantity}
                  integer={Numbers.TEN}
                  fraction={Numbers.ZERO}
                  positive={Constants.TRUE}
                  thousand={Constants.FALSE}
                  placeholder="0"
                  onChange={this._onQuantityChange}
                />
              </ReactPlaceholder>
            </div>
          </div>
          <div className="col-inline times-icon">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="container-trade-input">
            <div className="white-box">
              <ReactPlaceholder
                ready={
                  this.state.totalBricksOwnedLoaded &&
                  this.state.totalBricksOnMyPendingSellLoaded
                }
                customPlaceholder={<RectShape color={'#CCCCCD'} style={{ height: 20, marginTop: '12px' }} />}
              >
                Sell price
                <div className="dollar-input">
                  <DynamicNumber
                    id="id_sell_price"
                    className="price-to-be-sell"
                    name="sell_price"
                    value={this.state.sellProposalParams.price}
                    separator={'.'}
                    integer={Numbers.TEN}
                    fraction={Numbers.TWO}
                    positive={Constants.TRUE}
                    thousand={Constants.TRUE}
                    placeholder="0.00"
                    onChange={this._onPriceChange}
                  />
                </div>
              </ReactPlaceholder>
            </div>
          </div>
          <div className="col-inline container-total">
            <div className="white-box">
              <div>You receive</div>
              <div className="sell value order-you-receive">
                {this.state.sellProposalParams.total::brickPrice()}
              </div>
            </div>
          </div>
        </div>

        {this.state.sellProposalParams.hasQuantityError && (
          <div className="row">
            <div className="col-xs-12 error sell-proposal-error sell-proposal-quantity-error">
              {this.state.sellProposalParams.quantityErrorMsg}
              {this.state.showPendingOrderLink && (
                <span>
                  &nbsp;
                  <a href="/account/pending-orders">
                    Manage my Pending Orders
                  </a>
                </span>
              )}
            </div>
          </div>
        )}

        {this.state.sellProposalParams.hasPriceError && (
          <div className="row">
            <div className="col-xs-12 error sell-proposal-error sell-proposal-price-error">
              {this.state.sellProposalParams.priceErrorMsg}
            </div>
          </div>
        )}

        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            {`${this.state.sellProposalParams.quantity} ${this.state.sellProposalParams.quantity < 2 ? 'Brick' : 'Bricks'} @ ${this.state.sellProposalParams.price::dollarDecimal()}`}
          </div>
          <div className="col-xs-5 col-no-padding-left col-right bricks-value">
            {this.state.sellProposalParams.valueOfBricks::dollarDecimal()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            Transaction fee (<span data-test-reference="transaction-fee">{sellFeeForDisplay}%</span>)
          </div>
          <div
            className="col-xs-5 col-no-padding-left col-right transaction-fee-value"
            data-test-reference="total-transaction-fee"
          >
            {(-this.state.sellProposalParams.transactionFee)::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>
            You Receive
          </div>
          <div
            className="col-xs-5 col-no-padding-left col-right order-you-receive"
            data-test-reference="you-recieve"
          >
            {this.state.sellProposalParams.total::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <br />
        <div className="action-buttons">
          <div>
            <div>
              <input
                type="checkbox"
                checked={this.state.terms.accepted}
                id="consent"
                required
                onChange={this._onAcceptTermsChange}
              />
              <label htmlFor="consent">&nbsp;I agree to this transaction</label>
            </div>
            {this.state.terms.showErrorMessage && (
              <span className="error accept-terms-warning">
                Check this box before confirming order.
              </span>
            )}
          </div>
          <button
            className="button salmon-button confirm-sell-button action-full-button right-arrow-button"
            disabled={
              this.state.sellProposalParams.hasPriceError ||
              this.state.sellProposalParams.hasQuantityError ||
              this.state.terms.showErrorMessage ||
              this.state.loading
            }
            onClick={::this._confirmSell}
            data-test-reference="confirm-button"
          >
            CONFIRM SELL ORDER
          </button>
        </div>
      </div>
    );
  }

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { sellForm } = this.props;
    const { totalBricksOwned, totalBricksOnMyPendingSell } = this.state;
    var updatedSellForm = sellForm;

    this.setState({ showPendingOrderLink: false });

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity);

      const numOfBricksCanSell = totalBricksOwned - totalBricksOnMyPendingSell;

      const updatedValueOfBricks = sellForm.price * updatedQuantityInt;
      const transactionFeeRate = this._getTransactionFeeRatio();
      const updatedTransactionFee = (updatedValueOfBricks * transactionFeeRate)::roundTransactionFee();
      const updatedTotal = updatedValueOfBricks - updatedTransactionFee;

      if (
        numOfBricksCanSell === 0 &&
        totalBricksOnMyPendingSell === 0
      ) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg = 'You do not have any Bricks to sell.';
      } else if (
        numOfBricksCanSell === 0 &&
        totalBricksOnMyPendingSell > 0
      ) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg = 'You do not have any Bricks to sell. All of your Bricks are currently listed in a pending sell order.';
        this.setState({ showPendingOrderLink: true });
      } else if (
        updatedQuantityInt > numOfBricksCanSell &&
        totalBricksOnMyPendingSell === 0
      ) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg = `You can only sell ${numOfBricksCanSell::brick()}.`;
      } else if (
        updatedQuantityInt > numOfBricksCanSell &&
        totalBricksOnMyPendingSell > 0
      ) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg = `You can only sell ${numOfBricksCanSell::brick()}, as some of your Bricks are currently listed in a pending sell order.`;
        this.setState({ showPendingOrderLink: true });
      } else {
        updatedSellForm.hasQuantityError = false;
        updatedSellForm.quantityErrorMsg = '';
      }

      updatedSellForm.valueOfBricks = updatedValueOfBricks;
      updatedSellForm.transactionFee = updatedTransactionFee;
      updatedSellForm.total = updatedTotal;
      updatedSellForm.quantity = updatedQuantityInt;
    } else {
      updatedSellForm.hasQuantityError = true;
      updatedSellForm.quantityErrorMsg = 'Enter a valid quantity of Bricks to sell.';
      updatedSellForm.quantity = updatedQuantity;
    }
    this._updateForm(updatedSellForm);
  };

  _onPriceChange = (event) => {
    const updatedPrice = event.target.value.replace(/,/g, '');
    this._handlePriceChange(updatedPrice);
  };

  _handlePriceChange = (updatedPrice) => {
    const { sellForm, property } = this.props;
    var updatedSellForm = sellForm;

    if (validateTradeProposalPrice(updatedPrice)) {
      const updatedPriceFloat = Number.parseFloat(updatedPrice);

      const lowestSellPriceAllowed = (property.financials.brickValue * LOWEST_SELL_PRICE_PERCENTAGE).toFixed(2);
      const brickValueTitle = property.financials.isIndependentValued
        ? 'Latest Brick Valuation'
        : 'Initial Brick Price';

      const updatedValueOfBricks = updatedPriceFloat * sellForm.quantity;
      const transactionFeeRate = this._getTransactionFeeRatio();
      const updatedTransactionFee = (updatedValueOfBricks * transactionFeeRate)::roundTransactionFee();
      const updatedTotal = updatedValueOfBricks - updatedTransactionFee;

      if (updatedPriceFloat < lowestSellPriceAllowed) {
        updatedSellForm.hasPriceError = true;
        updatedSellForm.priceErrorMsg = `Sorry, the minimum price you can list Bricks for sell in ${renderCode(property.propertyCode)} is ${lowestSellPriceAllowed::dollarDecimal()}. (${(1 - LOWEST_SELL_PRICE_PERCENTAGE)::percent()} lower than the ${brickValueTitle})`;
      } else {
        updatedSellForm.hasPriceError = false;
        updatedSellForm.priceErrorMsg = '';
      }

      updatedSellForm.valueOfBricks = updatedValueOfBricks;
      updatedSellForm.transactionFee = updatedTransactionFee;
      updatedSellForm.total = updatedTotal;
      updatedSellForm.price = updatedPrice;
    } else {
      updatedSellForm.hasPriceError = true;
      updatedSellForm.priceErrorMsg = 'Please enter a valid sell price for your Brick(s).';
      updatedSellForm.price = updatedPrice;
    }
    this._updateForm(updatedSellForm);
  };

  _onAcceptTermsChange = () => {
    this.setState({
      terms: {
        accepted: !this.state.terms.accepted,
        showErrorMessage: false,
      },
    });
  };

  _updateTotalBricksOnMyPendingSell = (myOrders) => {
    const { property } = this.props;
    if (_.isEmpty(myOrders.pendingSell)) {
      this.setState({
        totalBricksOnMyPendingSell: 0,
        totalBricksOnMyPendingSellLoaded: true,
      });
    } else {
      const sellOrdersForProperty = _.filter(myOrders.pendingSell, { propertyCode: property.propertyCode });
      const totalBricksOnMyPendingSell = _.reduce(sellOrdersForProperty, (sum, order) => sum + order.quantity, 0);

      this.setState({
        totalBricksOnMyPendingSell: totalBricksOnMyPendingSell,
        totalBricksOnMyPendingSellLoaded: true,
      });
    }
  };

  _updateTotalBricksOwned = (account) => {
    const { getTotalBricksOwned } = this.props;
    const totalBricksOwned = getTotalBricksOwned(account);

    this.setState({
      totalBricksOwned: totalBricksOwned,
      totalBricksOwnedLoaded: true,
    });
  };

  _getTransactionFeeRatio = () => {
    const { fees: { sellFee, sellFeeFreeEnabled } } = this.props;
    return sellFeeFreeEnabled ? 0 : sellFee ? sellFee / 100 : 0;
  };

  async _confirmSell() {
    this.setState({ loading: true });
    const { user, property, confirmSell } = this.props;
    this._handlePriceChange(this.state.sellProposalParams.price);
    this._handleQuantityChange(this.state.sellProposalParams.quantity);
    if (
      this.state.sellProposalParams.hasPriceError ||
      this.state.sellProposalParams.hasQuantityError
    ) {
      this.setState({ loading: false });
      return;
    }
    if (!this.state.terms.accepted) {
      this.setState({
        loading: false,
        terms: {
          showErrorMessage: true,
        },
      });
      return;
    }
    if (checkUserWithTradePermission(user, this.context.router)) {
      const { price, quantity, valueOfBricks, transactionFee, total } = this.state.sellProposalParams;
      await confirmSell(property.propertyCode, {
        price,
        quantity,
        valueOfBricks,
        transactionFee,
        total,
      });
    }
    this.setState({ loading: false });
  }

  _buildSellProposalParams = (updatedSellForm) => {
    this.setState({
      sellProposalParams: {
        quantity: updatedSellForm.quantity,
        price: updatedSellForm.price,
        valueOfBricks: updatedSellForm.valueOfBricks,
        transactionFee: updatedSellForm.transactionFee,
        total: updatedSellForm.total,
        hasQuantityError: updatedSellForm.hasQuantityError,
        hasPriceError: updatedSellForm.hasPriceError,
        quantityErrorMsg: updatedSellForm.quantityErrorMsg,
        priceErrorMsg: updatedSellForm.priceErrorMsg,
      },
    });
  };

  _updateForm = (updatedSellForm) => {
    this._buildSellProposalParams(updatedSellForm);
    updatedSellForm.callback(updatedSellForm);
  };
}

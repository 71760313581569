import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  property as propertyPropType,
  historicalGrowthData as historicalGrowthDataPropType,
} from 'scripts/constants/PropTypes';
import InvestmentTypes from 'src/enums/investmentTypes';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import HistoricalSuburbPerformance from 'scripts/components/property/summary/HistoricalSuburbPerformance';
import HistoricalRBACashRate from 'scripts/components/property/summary/HistoricalRBACashRate';
import {
  historicalGrowthChartHeader,
  historicalGrowthChartSubHeader,
} from 'src/settings/text';

import { historicalRBACashRate } from 'src/settings/financials';

export default class HistoricalSuburbPerformanceInFinancialsPanel extends Component {
  static propTypes = {
    property: propertyPropType,
    historicalGrowthData: historicalGrowthDataPropType,
    propertyTab: PropTypes.string,
  };

  render() {
    const { property, historicalGrowthData } = this.props;

    const chartTitle = historicalGrowthChartHeader(property.propertyType);
    const suburbStatePropertyTypeText =
      property.investmentType === InvestmentTypes.property
        ? historicalGrowthChartSubHeader(property)
        : '';

    return (
      <FinancialsPanel
        title={chartTitle}
        linkText={suburbStatePropertyTypeText}
      >
        {property.investmentType === InvestmentTypes.property ? (
          <HistoricalSuburbPerformance
            property={property}
            historicalGrowthData={historicalGrowthData}
          />
        ) : (
          <HistoricalRBACashRate
            property={property}
            cashRateData={historicalRBACashRate}
          />
        )}
      </FinancialsPanel>
    );
  }
}

import PropTypes from 'prop-types';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  percentDecimal,
  date,
  dollar,
  dollarDecimal,
  month,
  nextMonthYear,
} from 'scripts/utilities/formatters';
import Markdown from 'react-remarkable';
import Constants from 'scripts/constants/Constants';
import _ from 'lodash';
import { renderCode } from 'src/settings/properties';
import { forcastedMonthlyDistribution } from 'src/settings/financials';
import PropertyTypes from 'src/enums/propertyTypes';
import InvestmentTypes from 'src/enums/investmentTypes';

const DevelopmentKeyMetrics = ({ propertyCode }) => {
  const metrics = forcastedMonthlyDistribution[propertyCode];
  if (!metrics) {
    return null;
  }

  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Monthly Distributions: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'RENT PER WEEK'}
          value={metrics.rentPerWeek::dollar()}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET INCOME'}
          value={metrics.estNetIncome::dollar()}
          description={'Per Year - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET RENTAL YIELD'}
          value={metrics.estNetRentailYield::percentDecimal()}
          description={'Annualised - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'EST. GROSS RENTAL YIELD'}
          value={metrics.estGrossRentalYield::percentDecimal()}
          description={'Annualised - Forecasted'}
        />
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          The project is in the development phase. The financial metrics above
          are a forecast view of returns once the project is fully completed &
          tenanted.
        </div>
      </div>
    </FinancialsPanel>
  );
};

const KeyMetrics = ({
  property,
  isPreMarketOpenProperty,
  monthlyDistributions,
  monthlyUpdates,
}) => {
  const title = 'Monthly Distributions: Key Metrics';

  const rentBoxDescription = property.tenantStatus.tenanted
    ? Constants.CURRENTLY_TENANTED
    : Constants.FORECASTED;
  const rentIncomeBoxDescription = property.tenantStatus.tenanted
    ? Constants.CURRENTLY_TENANTED
    : `${Constants.PER_YEAR} - ${Constants.FORECASTED}`;
  const rentYieldBoxDescription = property.tenantStatus.tenanted
    ? Constants.ANNUALISED
    : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;
  const noDistributionPaidBoxTitle =
    property.investmentType === InvestmentTypes.mortgage
      ? 'Est. Gross Interest Yield'
      : property.investmentType === InvestmentTypes.financialAsset
      ? 'Est. Gross Income Yield'
      : 'Est. Gross Rental Yield';
  const noDistributionPaidBoxValue =
    property.financials.grossRentalYield::percentDecimal();
  const noDistributionPaidBoxDescription = property.tenantStatus.tenanted
    ? Constants.ANNUALISED
    : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;

  const noDistributionPaidText = isPreMarketOpenProperty
    ? `The first distribution on ${renderCode(
        property.propertyCode
      )} is estimated to be paid from ${property.platformSettlementDate::nextMonthYear()}`
    : '';

  const lastDistribution = monthlyDistributions[0];

  if (property.propertyType === PropertyTypes.development) {
    return <DevelopmentKeyMetrics propertyCode={property.propertyCode} />;
  }

  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title={`${title}`}
      titleIcon="icn-coins"
    >
      <div className="row">
        {property.investmentType === InvestmentTypes.mortgage ? (
          <FinancialsBox
            className="col-md-3 financials-box-small"
            title={'AVG. MONTHLY INTEREST'}
            value={
              property.investmentType === InvestmentTypes.mortgage
                ? property.financials.monthlyInterestIncome::dollar()
                : property.financials.weeklyRentalIncome::dollar()
            }
            description={'Forecasted'}
          />
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <FinancialsBox
            className="col-md-3 financials-box-small"
            title={'AVG. MONTHLY INCOME'}
            value={property.financials.weeklyRentalIncome::dollar()}
            description={'Forecasted'}
          />
        ) : (
          <FinancialsBox
            className="col-md-3 key-metrics-rent financials-box-small"
            title={'RENT PER WEEK'}
            value={property.financials.weeklyRentalIncome::dollar()}
            description={rentBoxDescription}
          />
        )}
        {property.investmentType === InvestmentTypes.mortgage ? (
          <FinancialsBox
            className="col-md-3 financials-box-small"
            title={'EST. NET INTEREST INCOME'}
            value={property.financials.annualNetRentalIncome::dollar()}
            description={'Annualised - Forecasted'}
          />
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <FinancialsBox
            className="col-md-3 financials-box-small"
            title={'EST. NET INCOME'}
            value={property.financials.annualNetRentalIncome::dollar()}
            description={'Annualised - Forecasted'}
          />
        ) : (
          <FinancialsBox
            className="col-md-3 net-income-per-year financials-box-small"
            title={'ESTIMATED NET INCOME'}
            value={property.financials.annualNetRentalIncome::dollar()}
            description={rentIncomeBoxDescription}
          />
        )}
        {property.investmentType === InvestmentTypes.mortgage ? (
          <FinancialsBox
            className="col-md-3 financials-box-small"
            title={'EST. NET INTEREST INCOME'}
            value={property.financials.netRentalYield::percentDecimal()}
            description={'Forecasted'}
          />
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <FinancialsBox
            className="col-md-3 financials-box-small"
            title={'EST. NET INCOME'}
            value={property.financials.netRentalYield::percentDecimal()}
            description={'Forecasted'}
          />
        ) : (
          <FinancialsBox
            className="col-md-3 net-rental-yield financials-box-small"
            title={'ESTIMATED NET RENTAL YIELD'}
            value={property.financials.netRentalYield::percentDecimal()}
            description={rentYieldBoxDescription}
          />
        )}
        {lastDistribution ? (
          <FinancialsBox
            className="col-md-3 last-distribution-paid financials-box-small"
            title={`${lastDistribution.distributionDate::month()} DISTRIBUTION `}
            value={lastDistribution.amount::dollarDecimal()}
            description={`${
              lastDistribution.amount > 0 ? 'PAID' : 'DATE'
            }: ${lastDistribution.paidDate::date()}`}
          />
        ) : (
          <FinancialsBox
            className="col-md-3 last-distribution-paid financials-box-small"
            title={noDistributionPaidBoxTitle}
            value={noDistributionPaidBoxValue}
            description={noDistributionPaidBoxDescription}
          />
        )}
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          {forcastedMonthlyDistribution[property.propertyCode] ? (
            'The project is in the development phase. The financial metrics above are a forecast view of returns once the project is fully completed & tenanted.'
          ) : !_.isEmpty(monthlyUpdates) ? (
            <Markdown source={monthlyUpdates[0].monthlyDistributionInfo} />
          ) : (
            noDistributionPaidText
          )}
        </div>
      </div>
    </FinancialsPanel>
  );
};

KeyMetrics.propTypes = {
  property: propertyPropType.isRequired,
  isPreMarketOpenProperty: PropTypes.bool.isRequired,
};

export default KeyMetrics;

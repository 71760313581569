import PropTypes from 'prop-types';

import Popover from 'scripts/components/shared/Popover';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  dollar,
  brickPrice,
  date,
  monthYear,
  percent,
} from 'scripts/utilities/formatters';
import { getBrickValuation } from 'scripts/utilities/propertyHelper';

import Copy from 'scripts/constants/Copy.js';
import InvestmentTypes from 'src/enums/investmentTypes';

const LatestValue = ({ property, isPreorderOrComingSoonProperty }) => {
  const isPreOrderOrNotIndependentValuedProperty = isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;
  const title = isPreOrderOrNotIndependentValuedProperty
    ? `Settlement Date:  ${property.platformSettlementDate::date()}`
    : `Independent Valuation: ${property.financials.latestValuationDate::monthYear()}`;

  const latestValueTitle =
    property.investmentType !== InvestmentTypes.property
      ? 'Total Trust Capital'
      : isPreOrderOrNotIndependentValuedProperty
      ? 'Total Purchase Cost'
      : 'Latest Value*';
  const latestValuePopoverText =
    property.investmentType !== InvestmentTypes.property
      ? 'The total Trust Investment Capital and Cash Reserve.'
      : isPreOrderOrNotIndependentValuedProperty
      ? 'The Total Purchase Cost consists of the Property Purchase Cost, Acquisition Costs, and Cash Reserve.'
      : 'The Latest Value consists of the Latest Property Valuation, Unamortised Acquisition Costs, and Cash Reserve.';

  const debtPopoverText = property.investmentType === InvestmentTypes.property
    ? 'The outstanding Debt from any mortgage on this investment.'
    : 'An estimation of the amount of doubtful debt that will need to be written off during a given period.';
  const equityPopoverText = 'The remaining value in this property investment, after Debt.';

  const { brickValuationTitle, brickValuation } = getBrickValuation(property);
  const brickValuePopoverText = isPreOrderOrNotIndependentValuedProperty
    ? Copy.helpText.initialBrickPrice
    : Copy.helpText.brickValuation;

  return (
    <FinancialsPanel
      title={title}
      className="panel-financials-latest-value panel-financials-table-icon"
    >
      <div className="gray-line"></div>
      <div className="row row-with-icon table-row">
        <div className="col-xs-5 col-no-padding-right col-left latest-value-title">
          <div>
            <span className="icn icn-medium icn-house" aria-hidden="true" />
          </div>
          {latestValueTitle}
        </div>
        <div className="col-xs-7 col-no-padding-left col-right latest-value">
          {property.financials.latestValue::dollar()}&nbsp;
          <Popover placement="top" content={latestValuePopoverText} />
        </div>
      </div>
      {((property.investmentType !== InvestmentTypes.property && !isPreOrderOrNotIndependentValuedProperty) || (property.investmentType === InvestmentTypes.property)) && (
        <div className="row row-with-icon table-row">
          <div className="col-xs-5 col-no-padding-right col-left debt-value-title">
            <div>
              <span className="icn icn-medium icn-debt" aria-hidden="true" />
            </div>
            {property.investmentType === InvestmentTypes.property
              ? `Geared (${property.financials.lVR::percent()} Debt)`
              : 'Bad Debt Provision'}
          </div>
          <div className="col-xs-7 col-no-padding-left col-right debt-value">
            {property.financials.debtTerms
              ? property.financials.debtTerms.outstandingDebt::dollar()
              : '$0'}
            &nbsp;
            <Popover placement="top" content={debtPopoverText} />
          </div>
        </div>
      )}
      <div className="black-line"></div>
      <div className="row row-with-icon table-row">
        <div className="col-xs-4 col-no-padding-right col-left equity-value-title">
          <div>
            <span className="icn icn-medium icn-equity" aria-hidden="true" />
          </div>
          Equity
        </div>
        <div className="col-xs-8 col-no-padding-left col-right equity-value">
          {property.financials.equity::dollar()}&nbsp;
          <Popover placement="top" content={equityPopoverText} />
        </div>
      </div>
      <div className="gray-line"></div>
      <div className="row row-with-icon table-row">
        <div className="col-xs-6 col-no-padding-right col-left brick-price-value-title">
          <div>
            <span
              className="icn icn-medium icn-brick-border"
              aria-hidden="true"
            />
          </div>
          {brickValuationTitle}
        </div>
        <div className="col-xs-6 col-no-padding-left col-right text-orange brick-price-value">
          {brickValuation::brickPrice()}&nbsp;
          <span className="text-off-black">
            <Popover placement="top" content={brickValuePopoverText} />
          </span>
        </div>
      </div>
      <div className="gray-line"></div>
      <div className="row">
        <div className="col-xs-12">
          <div className="next-valuation-date">
            {property.financials.isIndependentValued ? (
              <small className="asterisk-info">
                <b>
                  Next Valuation Date: {property.nextValuationDate::date()}.
                </b>
                <br />
                Based on the Latest Independent Property Valuation of{' '}
                {property.financials.lastPropertyValuation::dollar()}. See
                Capital Returns tab for more info.
              </small>
            ) : (
              <small className="asterisk-info">
                <b>
                  Next Valuation Date: {property.nextValuationDate::date()}.
                </b>
                <br />
                Based on the Initial Purchase Cost of{' '}
                {property.financials.purchasePrice::dollar()}. See Capital
                Returns tab for more info.
              </small>
            )}
          </div>
        </div>
      </div>
      {property.nextVotingDate && (
        <div className="row">
          <div className="col-xs-12">
            <div className="next-voting-date">
              <small className="asterisk-info">
                <b>Next Voting Date: {property.nextVotingDate::date()}.</b>
              </small>
            </div>
          </div>
        </div>
      )}
    </FinancialsPanel>
  );
};

LatestValue.propTypes = {
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
  property: propertyPropType.isRequired,
};

export default LatestValue;

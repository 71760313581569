import { dollar, floatToPercentDecimal } from 'src/utils/formats';

export const maximumAustralianTaxAmount = 0.47;
export const refereeGiftAmount = dollar(40);
export const referrerGiftAmount = dollar(30);
export const getTransactionFeeRatio = () => 0.005;
export const maximumLoanToValueRatio = 0.7;

export const securityValue = {
  BXM00: 6647689.06,
  BXM01: 6647689.06,
};

export const forcastedMonthlyDistribution = {
  PPD01: {
    rentPerWeek: 1200,
    estNetIncome: 26982,
    estNetRentailYield: 0.0232,
    estGrossRentalYield: 0.0537,
  },
  EDP01: {
    rentPerWeek: 27068.44,
    estNetIncome: 606762,
    estNetRentailYield: 0.1108,
    estGrossRentalYield: 0.1492,
  },
  LCF01: {
    rentPerWeek: 8064,
    estNetIncome: -87853,
    estNetRentailYield: -0.0115,
    estGrossRentalYield: 0.0292,
  },
};

export const forcastedCapitalReturn = {
  PPD01: {
    completeValue: 2800000,
    capitalCostsAndOutgoings: 2325507,
    developerUplift: 0.2,
    annualisedDeveloperUplift: 0.2,
  },
  EDP01: {
    completeValue: 26538690,
    capitalCostsAndOutgoings: 23486761,
    developerUplift: 0.13,
    annualisedDeveloperUplift: 0.052,
  },
  LCF01: {
    completeValue: 13982500,
    capitalCostsAndOutgoings: 11461825,
    developerUplift: 0.22,
    annualisedDeveloperUplift: 0.22,
  },
  KPI01: {
    completeValue: 3800000,
    capitalCostsAndOutgoings: 3517843,
    developerUplift: 0.0629,
    annualisedDeveloperUplift: 0.0629,
  },
};

export const TotalFundReturn = {
  EDP01: 0.0993,
};

export const historicalNationalGrowthRate10yr = floatToPercentDecimal(0.023);
export const historicalRBACashRate10yr = floatToPercentDecimal(0.01718);

export const historicalRBACashRate = [
  { date: '2025-02-19', cashRate: 4.10 },
  { date: '2024-12-11', cashRate: 4.35 },
  { date: '2024-11-06', cashRate: 4.35 },
  { date: '2024-09-25', cashRate: 4.35 },
  { date: '2024-08-07', cashRate: 4.35 },
  { date: '2024-06-19', cashRate: 4.35 },
  { date: '2024-05-08', cashRate: 4.35 },
  { date: '2024-03-20', cashRate: 4.35 },
  { date: '2024-02-07', cashRate: 4.35 },
  { date: '2023-12-06', cashRate: 4.35 },
  { date: '2023-11-08', cashRate: 4.35 },
  { date: '2023-10-04', cashRate: 4.1 },
  { date: '2023-09-06', cashRate: 4.1 },
  { date: '2023-08-02', cashRate: 4.1 },
  { date: '2023-07-05', cashRate: 4.1 },
  { date: '2023-06-07', cashRate: 4.1 },
  { date: '2023-05-03', cashRate: 3.85 },
  { date: '2023-04-05', cashRate: 3.6 },
  { date: '2023-03-08', cashRate: 3.6 },
  { date: '2023-02-08', cashRate: 3.35 },
  { date: '2022-12-07', cashRate: 3.1 },
  { date: '2022-11-02', cashRate: 2.85 },
  { date: '2022-10-05', cashRate: 2.6 },
  { date: '2022-09-07', cashRate: 2.35 },
  { date: '2022-08-03', cashRate: 1.85 },
  { date: '2022-07-06', cashRate: 1.35 },
  { date: '2022-06-08', cashRate: 0.85 },
  { date: '2022-05-04', cashRate: 0.35 },
  { date: '2022-04-06', cashRate: 0.1 },
  { date: '2022-03-02', cashRate: 0.1 },
  { date: '2022-02-02', cashRate: 0.1 },
  { date: '2021-12-08', cashRate: 0.1 },
  { date: '2021-11-03', cashRate: 0.1 },
  { date: '2021-10-06', cashRate: 0.1 },
  { date: '2021-09-08', cashRate: 0.1 },
  { date: '2021-08-04', cashRate: 0.1 },
  { date: '2021-07-07', cashRate: 0.1 },
  { date: '2021-06-02', cashRate: 0.1 },
  { date: '2021-05-05', cashRate: 0.1 },
  { date: '2021-04-07', cashRate: 0.1 },
  { date: '2021-03-03', cashRate: 0.1 },
  { date: '2021-02-03', cashRate: 0.1 },
  { date: '2020-12-02', cashRate: 0.1 },
  { date: '2020-11-04', cashRate: 0.1 },
  { date: '2020-10-07', cashRate: 0.25 },
  { date: '2020-09-02', cashRate: 0.25 },
  { date: '2020-08-05', cashRate: 0.25 },
  { date: '2020-07-08', cashRate: 0.25 },
  { date: '2020-06-03', cashRate: 0.25 },
  { date: '2020-05-06', cashRate: 0.25 },
  { date: '2020-04-08', cashRate: 0.25 },
  { date: '2020-03-20', cashRate: 0.25 },
  { date: '2020-03-04', cashRate: 0.5 },
  { date: '2020-02-05', cashRate: 0.75 },
  { date: '2019-12-04', cashRate: 0.75 },
  { date: '2019-11-06', cashRate: 0.75 },
  { date: '2019-10-02', cashRate: 0.75 },
  { date: '2019-09-04', cashRate: 1.0 },
  { date: '2019-08-07', cashRate: 1.0 },
  { date: '2019-07-03', cashRate: 1.0 },
  { date: '2019-06-05', cashRate: 1.25 },
  { date: '2019-05-08', cashRate: 1.5 },
  { date: '2019-04-03', cashRate: 1.5 },
  { date: '2019-03-06', cashRate: 1.5 },
  { date: '2019-02-06', cashRate: 1.5 },
  { date: '2018-12-05', cashRate: 1.5 },
  { date: '2018-11-07', cashRate: 1.5 },
  { date: '2018-10-03', cashRate: 1.5 },
  { date: '2018-09-05', cashRate: 1.5 },
  { date: '2018-08-08', cashRate: 1.5 },
  { date: '2018-07-04', cashRate: 1.5 },
  { date: '2018-06-06', cashRate: 1.5 },
  { date: '2018-05-02', cashRate: 1.5 },
  { date: '2018-04-04', cashRate: 1.5 },
  { date: '2018-03-07', cashRate: 1.5 },
  { date: '2018-02-07', cashRate: 1.5 },
  { date: '2017-12-06', cashRate: 1.5 },
  { date: '2017-11-08', cashRate: 1.5 },
  { date: '2017-10-04', cashRate: 1.5 },
  { date: '2017-09-06', cashRate: 1.5 },
  { date: '2017-08-02', cashRate: 1.5 },
  { date: '2017-07-05', cashRate: 1.5 },
  { date: '2017-06-07', cashRate: 1.5 },
  { date: '2017-05-03', cashRate: 1.5 },
  { date: '2017-04-05', cashRate: 1.5 },
  { date: '2017-03-08', cashRate: 1.5 },
  { date: '2017-02-08', cashRate: 1.5 },
  { date: '2016-12-07', cashRate: 1.5 },
  { date: '2016-11-02', cashRate: 1.5 },
  { date: '2016-10-05', cashRate: 1.5 },
  { date: '2016-09-07', cashRate: 1.5 },
  { date: '2016-08-03', cashRate: 1.5 },
  { date: '2016-07-06', cashRate: 1.75 },
  { date: '2016-06-08', cashRate: 1.75 },
  { date: '2016-05-04', cashRate: 1.75 },
  { date: '2016-04-06', cashRate: 2.0 },
  { date: '2016-03-02', cashRate: 2.0 },
  { date: '2016-02-03', cashRate: 2.0 },
  { date: '2015-12-02', cashRate: 2.0 },
  { date: '2015-11-04', cashRate: 2.0 },
  { date: '2015-10-07', cashRate: 2.0 },
  { date: '2015-09-02', cashRate: 2.0 },
  { date: '2015-08-05', cashRate: 2.0 },
  { date: '2015-07-08', cashRate: 2.0 },
  { date: '2015-06-03', cashRate: 2.0 },
  { date: '2015-05-06', cashRate: 2.0 },
  { date: '2015-04-08', cashRate: 2.25 },
  { date: '2015-03-04', cashRate: 2.25 },
  { date: '2015-02-04', cashRate: 2.25 },
  { date: '2014-12-03', cashRate: 2.5 },
  { date: '2014-11-05', cashRate: 2.5 },
  { date: '2014-10-08', cashRate: 2.5 },
  { date: '2014-09-03', cashRate: 2.5 },
  { date: '2014-08-06', cashRate: 2.5 },
  { date: '2014-07-02', cashRate: 2.5 },
  { date: '2014-06-04', cashRate: 2.5 },
  { date: '2014-05-07', cashRate: 2.5 },
  { date: '2014-04-02', cashRate: 2.5 },
  { date: '2014-03-05', cashRate: 2.5 },
  { date: '2014-02-05', cashRate: 2.5 },
  { date: '2013-12-04', cashRate: 2.5 },
  { date: '2013-11-06', cashRate: 2.5 },
  { date: '2013-10-02', cashRate: 2.5 },
  { date: '2013-09-04', cashRate: 2.5 },
  { date: '2013-08-07', cashRate: 2.5 },
  { date: '2013-07-03', cashRate: 2.75 },
  { date: '2013-06-05', cashRate: 2.75 },
  { date: '2013-05-08', cashRate: 2.75 },
  { date: '2013-04-03', cashRate: 3.0 },
  { date: '2013-03-06', cashRate: 3.0 },
  { date: '2013-02-06', cashRate: 3.0 },
  { date: '2012-12-05', cashRate: 3.0 },
  { date: '2012-11-07', cashRate: 3.25 },
  { date: '2012-10-03', cashRate: 3.25 },
  { date: '2012-09-05', cashRate: 3.5 },
  { date: '2012-08-08', cashRate: 3.5 },
  { date: '2012-07-04', cashRate: 3.5 },
  { date: '2012-06-06', cashRate: 3.5 },
  { date: '2012-05-02', cashRate: 3.75 },
  { date: '2012-04-04', cashRate: 4.25 },
  { date: '2012-03-07', cashRate: 4.25 },
  { date: '2012-02-08', cashRate: 4.25 },
  { date: '2011-12-07', cashRate: 4.25 },
  { date: '2011-11-02', cashRate: 4.5 },
  { date: '2011-10-05', cashRate: 4.75 },
  { date: '2011-09-07', cashRate: 4.75 },
  { date: '2011-08-03', cashRate: 4.75 },
  { date: '2011-07-06', cashRate: 4.75 },
  { date: '2011-06-08', cashRate: 4.75 },
  { date: '2011-05-04', cashRate: 4.75 },
  { date: '2011-04-06', cashRate: 4.75 },
  { date: '2011-03-02', cashRate: 4.75 },
  { date: '2011-02-02', cashRate: 4.75 },
  { date: '2010-12-08', cashRate: 4.75 },
  { date: '2010-11-03', cashRate: 4.75 },
  { date: '2010-10-06', cashRate: 4.5 },
  { date: '2010-09-08', cashRate: 4.5 },
  { date: '2010-08-04', cashRate: 4.5 },
  { date: '2010-07-07', cashRate: 4.5 },
  { date: '2010-06-02', cashRate: 4.5 },
  { date: '2010-05-05', cashRate: 4.5 },
  { date: '2010-04-07', cashRate: 4.25 },
  { date: '2010-03-03', cashRate: 4.0 },
  { date: '2010-02-03', cashRate: 3.75 },
  { date: '2009-12-02', cashRate: 3.75 },
  { date: '2009-11-04', cashRate: 3.5 },
  { date: '2009-10-07', cashRate: 3.25 },
  { date: '2009-09-02', cashRate: 3.0 },
  { date: '2009-08-05', cashRate: 3.0 },
  { date: '2009-07-08', cashRate: 3.0 },
  { date: '2009-06-03', cashRate: 3.0 },
  { date: '2009-05-06', cashRate: 3.0 },
  { date: '2009-04-08', cashRate: 3.0 },
  { date: '2009-03-04', cashRate: 3.25 },
  { date: '2009-02-04', cashRate: 3.25 },
  { date: '2008-12-03', cashRate: 4.25 },
  { date: '2008-11-05', cashRate: 5.25 },
  { date: '2008-10-08', cashRate: 6.0 },
  { date: '2008-09-03', cashRate: 7.0 },
  { date: '2008-08-06', cashRate: 7.25 },
  { date: '2008-07-02', cashRate: 7.25 },
  { date: '2008-06-04', cashRate: 7.25 },
  { date: '2008-05-07', cashRate: 7.25 },
  { date: '2008-04-02', cashRate: 7.25 },
  { date: '2008-03-05', cashRate: 7.25 },
  { date: '2008-02-06', cashRate: 7.0 },
  { date: '2007-12-05', cashRate: 6.75 },
  { date: '2007-11-07', cashRate: 6.75 },
  { date: '2007-10-03', cashRate: 6.5 },
  { date: '2007-09-05', cashRate: 6.5 },
  { date: '2007-08-08', cashRate: 6.5 },
  { date: '2007-07-04', cashRate: 6.25 },
  { date: '2007-06-06', cashRate: 6.25 },
  { date: '2007-05-02', cashRate: 6.25 },
  { date: '2007-04-04', cashRate: 6.25 },
  { date: '2007-03-07', cashRate: 6.25 },
  { date: '2007-02-07', cashRate: 6.25 },
  { date: '2006-12-06', cashRate: 6.25 },
  { date: '2006-11-08', cashRate: 6.25 },
  { date: '2006-10-04', cashRate: 6.0 },
  { date: '2006-09-06', cashRate: 6.0 },
  { date: '2006-08-02', cashRate: 6.0 },
  { date: '2006-07-05', cashRate: 5.75 },
  { date: '2006-06-07', cashRate: 5.75 },
  { date: '2006-05-03', cashRate: 5.75 },
  { date: '2006-04-05', cashRate: 5.5 },
  { date: '2006-03-08', cashRate: 5.5 },
  { date: '2006-02-08', cashRate: 5.5 },
  { date: '2005-12-07', cashRate: 5.5 },
  { date: '2005-11-02', cashRate: 5.5 },
  { date: '2005-10-05', cashRate: 5.5 },
  { date: '2005-09-07', cashRate: 5.5 },
  { date: '2005-08-03', cashRate: 5.5 },
  { date: '2005-07-06', cashRate: 5.5 },
  { date: '2005-06-08', cashRate: 5.5 },
  { date: '2005-05-04', cashRate: 5.5 },
  { date: '2005-04-06', cashRate: 5.5 },
  { date: '2005-03-02', cashRate: 5.5 },
  { date: '2005-02-02', cashRate: 5.25 },
  { date: '2004-12-08', cashRate: 5.25 },
  { date: '2004-11-03', cashRate: 5.25 },
  { date: '2004-10-06', cashRate: 5.25 },
  { date: '2004-09-08', cashRate: 5.25 },
  { date: '2004-08-04', cashRate: 5.25 },
  { date: '2004-07-07', cashRate: 5.25 },
  { date: '2004-06-02', cashRate: 5.25 },
  { date: '2004-05-05', cashRate: 5.25 },
  { date: '2004-04-07', cashRate: 5.25 },
  { date: '2004-03-03', cashRate: 5.25 },
  { date: '2004-02-04', cashRate: 5.25 },
  { date: '2003-12-03', cashRate: 5.25 },
  { date: '2003-11-05', cashRate: 5.0 },
  { date: '2003-10-08', cashRate: 4.75 },
  { date: '2003-09-03', cashRate: 4.75 },
  { date: '2003-08-06', cashRate: 4.75 },
  { date: '2003-07-02', cashRate: 4.75 },
  { date: '2003-06-04', cashRate: 4.75 },
  { date: '2003-05-07', cashRate: 4.75 },
  { date: '2003-04-02', cashRate: 4.75 },
  { date: '2003-03-05', cashRate: 4.75 },
  { date: '2003-02-05', cashRate: 4.75 },
  { date: '2002-12-04', cashRate: 4.75 },
  { date: '2002-11-06', cashRate: 4.75 },
  { date: '2002-10-02', cashRate: 4.75 },
  { date: '2002-09-04', cashRate: 4.75 },
  { date: '2002-08-07', cashRate: 4.75 },
  { date: '2002-07-03', cashRate: 4.75 },
  { date: '2002-06-05', cashRate: 4.75 },
  { date: '2002-05-08', cashRate: 4.5 },
  { date: '2002-04-03', cashRate: 4.25 },
  { date: '2002-03-06', cashRate: 4.25 },
  { date: '2002-02-06', cashRate: 4.25 },
  { date: '2001-12-05', cashRate: 4.25 },
  { date: '2001-11-07', cashRate: 4.5 },
  { date: '2001-10-03', cashRate: 4.5 },
  { date: '2001-09-05', cashRate: 4.75 },
  { date: '2001-08-08', cashRate: 5.0 },
  { date: '2001-07-04', cashRate: 5.0 },
  { date: '2001-06-06', cashRate: 5.0 },
  { date: '2001-05-02', cashRate: 5.0 },
  { date: '2001-04-04', cashRate: 5.0 },
  { date: '2001-03-07', cashRate: 5.5 },
  { date: '2001-02-07', cashRate: 5.75 },
  { date: '2000-12-06', cashRate: 6.25 },
  { date: '2000-11-08', cashRate: 6.25 },
  { date: '2000-10-04', cashRate: 6.25 },
  { date: '2000-09-06', cashRate: 6.25 },
  { date: '2000-08-02', cashRate: 6.25 },
  { date: '2000-07-05', cashRate: 6.0 },
  { date: '2000-06-07', cashRate: 6.0 },
  { date: '2000-05-03', cashRate: 6.0 },
  { date: '2000-04-05', cashRate: 5.75 },
  { date: '2000-03-08', cashRate: 5.5 },
  { date: '2000-02-02', cashRate: 5.5 },
  { date: '1999-12-08', cashRate: 5.0 },
  { date: '1999-11-03', cashRate: 5.0 },
  { date: '1999-10-06', cashRate: 4.75 },
  { date: '1999-09-08', cashRate: 4.75 },
  { date: '1999-08-04', cashRate: 4.75 },
  { date: '1999-07-07', cashRate: 4.75 },
  { date: '1999-06-02', cashRate: 4.75 },
  { date: '1999-05-05', cashRate: 4.75 },
  { date: '1999-04-07', cashRate: 4.75 },
  { date: '1999-03-03', cashRate: 4.75 },
  { date: '1999-02-03', cashRate: 4.75 },
  { date: '1998-12-02', cashRate: 4.75 },
  { date: '1998-11-04', cashRate: 5.0 },
  { date: '1998-10-07', cashRate: 5.0 },
  { date: '1998-09-02', cashRate: 5.0 },
  { date: '1998-08-05', cashRate: 5.0 },
  { date: '1998-07-08', cashRate: 5.0 },
  { date: '1998-06-03', cashRate: 5.0 },
  { date: '1998-05-06', cashRate: 5.0 },
  { date: '1998-04-08', cashRate: 5.0 },
  { date: '1998-03-04', cashRate: 5.0 },
  { date: '1998-02-04', cashRate: 5.0 },
  { date: '1997-12-03', cashRate: 5.0 },
  { date: '1997-11-05', cashRate: 5.0 },
  { date: '1997-10-08', cashRate: 5.0 },
  { date: '1997-09-03', cashRate: 5.0 },
  { date: '1997-08-06', cashRate: 5.0 },
  { date: '1997-07-30', cashRate: 5.0 },
  { date: '1997-07-02', cashRate: 5.5 },
  { date: '1997-06-04', cashRate: 5.5 },
  { date: '1997-05-23', cashRate: 5.5 },
  { date: '1997-05-07', cashRate: 6.0 },
  { date: '1997-04-02', cashRate: 6.0 },
  { date: '1997-03-05', cashRate: 6.0 },
  { date: '1997-02-05', cashRate: 6.0 },
  { date: '1996-12-11', cashRate: 6.0 },
  { date: '1996-11-06', cashRate: 6.5 },
  { date: '1996-10-02', cashRate: 7.0 },
  { date: '1996-09-04', cashRate: 7.0 },
  { date: '1996-07-31', cashRate: 7.0 },
  { date: '1996-07-03', cashRate: 7.5 },
  { date: '1996-06-05', cashRate: 7.5 },
  { date: '1996-05-08', cashRate: 7.5 },
  { date: '1996-04-03', cashRate: 7.5 },
  { date: '1996-03-06', cashRate: 7.5 },
  { date: '1996-02-07', cashRate: 7.5 },
  { date: '1995-12-06', cashRate: 7.5 },
  { date: '1995-11-08', cashRate: 7.5 },
  { date: '1995-10-04', cashRate: 7.5 },
  { date: '1995-09-06', cashRate: 7.5 },
  { date: '1995-07-26', cashRate: 7.5 },
  { date: '1995-07-05', cashRate: 7.5 },
  { date: '1995-06-07', cashRate: 7.5 },
  { date: '1995-05-03', cashRate: 7.5 },
  { date: '1995-04-05', cashRate: 7.5 },
  { date: '1995-03-08', cashRate: 7.5 },
  { date: '1995-02-08', cashRate: 7.5 },
  { date: '1994-12-14', cashRate: 7.5 },
  { date: '1994-11-02', cashRate: 6.5 },
  { date: '1994-10-24', cashRate: 6.5 },
  { date: '1994-09-07', cashRate: 5.5 },
  { date: '1994-08-17', cashRate: 5.5 },
  { date: '1994-07-27', cashRate: 4.75 },
  { date: '1994-07-06', cashRate: 4.75 },
  { date: '1994-06-08', cashRate: 4.75 },
  { date: '1994-05-04', cashRate: 4.75 },
  { date: '1994-03-30', cashRate: 4.75 },
  { date: '1994-03-02', cashRate: 4.75 },
  { date: '1994-02-02', cashRate: 4.75 },
  { date: '1993-12-08', cashRate: 4.75 },
  { date: '1993-11-03', cashRate: 4.75 },
  { date: '1993-10-06', cashRate: 4.75 },
  { date: '1993-09-08', cashRate: 4.75 },
  { date: '1993-07-30', cashRate: 4.75 },
  { date: '1993-07-07', cashRate: 5.25 },
  { date: '1993-06-02', cashRate: 5.25 },
  { date: '1993-05-05', cashRate: 5.25 },
  { date: '1993-04-07', cashRate: 5.25 },
  { date: '1993-03-23', cashRate: 5.25 },
  { date: '1993-02-03', cashRate: 5.75 },
  { date: '1992-12-02', cashRate: 5.75 },
  { date: '1992-11-04', cashRate: 5.75 },
  { date: '1992-10-07', cashRate: 5.75 },
  { date: '1992-09-02', cashRate: 5.75 },
  { date: '1992-07-29', cashRate: 5.75 },
  { date: '1992-07-08', cashRate: 5.75 },
  { date: '1992-06-03', cashRate: 6.5 },
  { date: '1992-05-06', cashRate: 6.5 },
  { date: '1992-04-08', cashRate: 7.5 },
  { date: '1992-03-04', cashRate: 7.5 },
  { date: '1992-02-05', cashRate: 7.5 },
  { date: '1992-01-08', cashRate: 7.5 },
  { date: '1991-12-04', cashRate: 8.5 },
  { date: '1991-11-06', cashRate: 8.5 },
  { date: '1991-10-02', cashRate: 9.5 },
  { date: '1991-09-03', cashRate: 9.5 },
  { date: '1991-07-31', cashRate: 10.5 },
  { date: '1991-07-03', cashRate: 10.5 },
  { date: '1991-06-14', cashRate: 10.5 },
  { date: '1991-05-16', cashRate: 10.5 },
  { date: '1991-04-04', cashRate: 11.5 },
  { date: '1991-03-06', cashRate: 12.0 },
  { date: '1991-02-06', cashRate: 12.0 },
  { date: '1990-12-18', cashRate: 12.0 },
  { date: '1990-11-07', cashRate: 13.0 },
  { date: '1990-10-15', cashRate: 13.0 },
  { date: '1990-09-05', cashRate: 14.0 },
  { date: '1990-08-02', cashRate: 14.0 },
  { date: '1990-07-04', cashRate: 15.0 },
  { date: '1990-06-06', cashRate: 15.0 },
  { date: '1990-05-02', cashRate: 15.0 },
  { date: '1990-04-04', cashRate: 15.0 },
  { date: '1990-03-07', cashRate: 16.5 },
  { date: '1990-02-15', cashRate: 16.5 },
  { date: '1990-01-23', cashRate: 17.0 },
];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  property as propertyPropType,
  account as accountPropType,
  myOrders as myOrdersPropType,
  user as userPropType,
  buyForm as buyFormPropType,
  tradeProposal as tradeProposalPropType,
  fees as feesPropType,
} from 'scripts/constants/PropTypes';
import { connect } from 'react-redux';
import {
  confirmPurchaseV2,
  purchaseDoneV2,
  fetchFees,
} from 'scripts/redux/actions/market';
import {
  tradeProposalSelector,
  feesSelector,
} from 'scripts/redux/selectors/market';
import DynamicNumber from 'react-dynamic-number';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import {
  validateTradeProposalQuantity,
  validateTradeProposalPrice,
} from 'scripts/utilities/tradeHelper';
import { roundTransactionFee } from 'scripts/utilities/helpers';
import { checkUserWithTradePermission } from 'scripts/utilities/userAccountHelper';
import _ from 'lodash';
import {
  brickPrice,
  dollarDecimal,
  brick,
  percent,
} from 'scripts/utilities/formatters';
import Trade from 'scripts/constants/Trade';
import Numbers from 'scripts/constants/Numbers';
import Constants from 'scripts/constants/Constants';
import { LOWEST_BUY_PRICE_PERCENTAGE } from 'src/settings/trading';
import { renderCode } from 'src/settings/properties';

const maxNumOfBricksPerProperty = (property) => (property.financials.numberBricks * property.maxOwnership) / 100;
const maxNumOfBricksPerPropertyRatio = (property) => `${property.maxOwnership}%`;

const mapStateToProps = (state) => ({
  ...feesSelector(state),
  ...tradeProposalSelector(state),
});

@connect(mapStateToProps, {
  fetchFees,
  confirmPurchaseV2,
  purchaseDoneV2,
})
export default class TradeProposalFormBuyV2 extends Component {
  static propTypes = {
    user: userPropType,
    account: accountPropType,
    property: propertyPropType,
    myOrders: myOrdersPropType,
    getTotalBricksOwned: PropTypes.func,
    buyForm: buyFormPropType,
    redirectTo: PropTypes.func,
    fees: feesPropType,
    tradeProposal: tradeProposalPropType,
    fetchFees: PropTypes.func,
    confirmPurchaseV2: PropTypes.func,
    purchaseDoneV2: PropTypes.func,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    buyProposalParams: {},
    terms: {
      accepted: false,
      showErrorMessage: false,
    },
    loading: false,
    showPendingOrderLink: false,
    totalBricksOwned: 0,
    totalBricksOwnedLoaded: false,
    totalBricksOnMyPendingBuy: 0,
    totalBricksOnMyPendingBuyLoaded: false,
  };

  componentDidMount() {
    const { account, myOrders, fetchFees, buyForm } = this.props;
    fetchFees();
    this._buildBuyProposalParams(buyForm);
    this._updateTotalBricksOwned(account);
    this._updateTotalBricksOnMyPendingBuy(myOrders);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this._updateTotalBricksOwned(this.props.account);
    }

    if (prevProps.myOrders !== this.props.myOrders) {
      this._updateTotalBricksOnMyPendingBuy(this.props.myOrders);
    }

    const { property, tradeProposal, purchaseDoneV2, redirectTo } = this.props;
    if (tradeProposal && tradeProposal.type === Trade.CONFIRMED) {
      purchaseDoneV2(tradeProposal.data);
      redirectTo(`/investments/${property.propertyCode}/purchase-success`);
    }
  }

  render() {
    const {
      account,
      fees: { buyFee, buyFeeFreeEnabled },
    } = this.props;

    const buyFeeForDisplay = buyFeeFreeEnabled ? '0.00' : buyFee ? buyFee.toString() : '-';

    return (
      <div className="place-order-panel">
        <div className="hidden-xs">
          <div className="white-boxes-container">
            <div className="col-inline col-inline-5 col-no-padding-left">
              <div className="white-box">
                <div>Available to Trade</div>
                <div className="buy value total-funds-owned">
                  {account && account.availableToTradeBalance !== undefined
                    ? account.availableToTradeBalance::dollarDecimal()
                    : '- AUD'} in BYO Wallet
                </div>
              </div>
            </div>
            <div className="col-inline col-inline-4 col-no-padding-left">
              <div className="white-box">
                <div>Pending Buy Orders</div>
                <div className="buy value total-bricks-pending-buy">
                  {this.state.totalBricksOnMyPendingBuyLoaded
                    ? this.state.totalBricksOnMyPendingBuy::brick()
                    : '- Bricks'}
                </div>
              </div>
            </div>
            <div className="col-inline col-inline-3 col-no-padding-left">
              <div className="white-box">
                <div>Pending Buy Funds</div>
                <div className="buy value total-funds-pending-buy">
                  {account && account.blockedAmount !== undefined
                    ? account.blockedAmount::dollarDecimal()
                    : '- AUD'}
                </div>
              </div>
            </div>
          </div>
          <div className="title buy">Buy your Bricks</div>
        </div>
        <div className="white-boxes-container">
          <div className="container-trade-input">
            <div className="white-box">
              <ReactPlaceholder
                ready={
                  this.state.totalBricksOwnedLoaded &&
                  this.state.totalBricksOnMyPendingBuyLoaded
                }
                customPlaceholder={<RectShape color={'#CCCCCD'} style={{ height: 20, marginTop: '12px' }} />}
              >
                Quantity
                <DynamicNumber
                  id="id_buy_quantity_v2"
                  className="quantity-to-be-buy"
                  name="buy_quantity"
                  value={this.state.buyProposalParams.quantity}
                  integer={Numbers.TEN}
                  fraction={Numbers.ZERO}
                  positive={Constants.TRUE}
                  thousand={Constants.FALSE}
                  placeholder="0"
                  onChange={this._onQuantityChange}
                />
              </ReactPlaceholder>
            </div>
          </div>
          <div className="col-inline times-icon">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="container-trade-input">
            <div className="white-box">
              <ReactPlaceholder
                ready={
                  this.state.totalBricksOwnedLoaded &&
                  this.state.totalBricksOnMyPendingBuyLoaded
                }
                customPlaceholder={<RectShape color={'#CCCCCD'} style={{ height: 20, marginTop: '12px' }} />}
              >
                Buy price
                <div className="dollar-input">
                  <DynamicNumber
                    id="id_buy_price_v2"
                    className="price-to-be-buy"
                    name="buy_price"
                    value={this.state.buyProposalParams.price}
                    separator={'.'}
                    integer={Numbers.TEN}
                    fraction={Numbers.TWO}
                    positive={Constants.TRUE}
                    thousand={Constants.TRUE}
                    placeholder="0.00"
                    onChange={this._onPriceChange}
                  />
                </div>
              </ReactPlaceholder>
            </div>
          </div>
          <div className="col-inline container-total">
            <div className="white-box">
              <div>You pay</div>
              <div className="buy value order-you-pay">
                {this.state.buyProposalParams.total::brickPrice()}
              </div>
            </div>
          </div>
        </div>

        {this.state.buyProposalParams.hasQuantityError && (
          <div className="row">
            <div className="col-xs-12 error buy-proposal-error buy-proposal-quantity-error">
              {this.state.buyProposalParams.quantityErrorMsg}
              {this.state.showPendingOrderLink && (
                <span>
                  &nbsp;
                  <a href="/account/pending-orders">
                    Manage my Pending Orders
                  </a>
                </span>
              )}
            </div>
          </div>
        )}

        {this.state.buyProposalParams.hasPriceError && (
          <div className="row">
            <div className="col-xs-12 error buy-proposal-error buy-proposal-price-error">
              {this.state.buyProposalParams.priceErrorMsg}
              {this.state.showPendingOrderLink && (
                <span>
                  &nbsp;
                  <a href="/account/pending-orders">
                    Manage my Pending Orders
                  </a>
                </span>
              )}
            </div>
          </div>
        )}

        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            {`${this.state.buyProposalParams.quantity} ${this.state.buyProposalParams.quantity < 2 ? 'Brick' : 'Bricks'} @ ${this.state.buyProposalParams.price::dollarDecimal()}`}
          </div>
          <div className="col-xs-5 col-no-padding-left col-right bricks-value">
            {(-this.state.buyProposalParams.valueOfBricks)::dollarDecimal()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            Transaction fee (<span data-test-reference="buy-transaction-fee">{buyFeeForDisplay}%</span>)
          </div>
          <div
            className="col-xs-5 col-no-padding-left col-right transaction-fee-value"
            data-test-reference="total-buy-transaction-fee"
          >
            {(-this.state.buyProposalParams.transactionFee)::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>
            You Pay
          </div>
          <div
            className="col-xs-5 col-no-padding-left col-right order-you-pay"
            data-test-reference="you-pay"
          >
            {this.state.buyProposalParams.total::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <br />
        <div className="action-buttons">
          <div>
            <div>
              <input
                type="checkbox"
                checked={this.state.terms.accepted}
                id="consent"
                required
                onChange={this._onAcceptTermsChange}
              />
              <label htmlFor="consent">&nbsp;I agree to this transaction</label>
            </div>
            {this.state.terms.showErrorMessage && (
              <span className="error accept-terms-warning">
                Check this box before confirming order.
              </span>
            )}
          </div>
          <button
            className="button light-blue-button confirm-buy-button action-full-button right-arrow-button"
            disabled={
              this.state.buyProposalParams.hasPriceError ||
              this.state.buyProposalParams.hasQuantityError ||
              this.state.terms.showErrorMessage ||
              this.state.loading
            }
            onClick={::this._confirmPurchase}
            data-test-reference="confirm-buy-button"
          >
            CONFIRM BUY ORDER
          </button>
        </div>
      </div>
    );
  }

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { buyForm, account, property } = this.props;
    const { totalBricksOwned, totalBricksOnMyPendingBuy } = this.state;
    var updatedBuyForm = buyForm;

    this.setState({ showPendingOrderLink: false });

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity);

      const maxNumOfBricksCanBuy = maxNumOfBricksPerProperty(property) - totalBricksOwned - totalBricksOnMyPendingBuy;
      const availableToTradeBalance = account.availableToTradeBalance;

      const updatedValueOfBricks = buyForm.price * updatedQuantityInt;
      const transactionFeeRate = this._getTransactionFeeRatio();
      const updatedTransactionFee = (updatedValueOfBricks * transactionFeeRate)::roundTransactionFee();
      const updatedTotal = updatedValueOfBricks + updatedTransactionFee;

      if (
        maxNumOfBricksCanBuy === 0 &&
        totalBricksOnMyPendingBuy === 0
      ) {
        updatedBuyForm.hasQuantityError = true;
        updatedBuyForm.quantityErrorMsg = `You have reached the maximum number of Bricks you can buy in this property (${maxNumOfBricksPerProperty(property.propertyCode)::brick()}).`;
      } else if (
        maxNumOfBricksCanBuy === 0 &&
        totalBricksOnMyPendingBuy > 0
      ) {
        updatedBuyForm.hasQuantityError = true;
        updatedBuyForm.quantityErrorMsg = `You have reached the maximum number of Bricks you can buy in this property (${maxNumOfBricksPerProperty(property.propertyCode)::brick()}). The remaining Bricks are currently listed in a pending buy order.`;
        this.setState({ showPendingOrderLink: true });
      } else if (
        updatedQuantityInt > maxNumOfBricksCanBuy &&
        totalBricksOnMyPendingBuy === 0
      ) {
        updatedBuyForm.hasQuantityError = true;
        updatedBuyForm.quantityErrorMsg = `You already own ${totalBricksOwned::brick()} in this property, and can only buy ${maxNumOfBricksCanBuy::brick()} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio(property)}, or ${maxNumOfBricksPerProperty(property)::brick()} for this property. Please enter ${maxNumOfBricksCanBuy::brick()} or less to continue your transaction.`;
      } else if (
        updatedQuantityInt > maxNumOfBricksCanBuy &&
        totalBricksOnMyPendingBuy > 0
      ) {
        updatedBuyForm.hasQuantityError = true;
        updatedBuyForm.quantityErrorMsg = `You already own ${totalBricksOwned::brick()} in this property, and can only buy ${maxNumOfBricksCanBuy::brick()} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio(property)}, or ${maxNumOfBricksPerProperty(property)::brick()} for this property. Please enter ${maxNumOfBricksCanBuy::brick()} or less to continue your transaction. The remaining Bricks are currently listed in a pending buy order.`;
        this.setState({ showPendingOrderLink: true });
      } else if (
        updatedTotal > availableToTradeBalance &&
        account.blockedAmount === 0
      ) {
        updatedBuyForm.hasQuantityError = true;
        updatedBuyForm.quantityErrorMsg = `You do not have enough funds to complete this transaction.`;
      } else if (
        updatedTotal > availableToTradeBalance &&
        account.blockedAmount > 0
      ) {
        updatedBuyForm.hasQuantityError = true;
        updatedBuyForm.quantityErrorMsg = `You do not have enough funds to complete this transaction. The remaining funds are currently held in a pending buy order.`;
        this.setState({ showPendingOrderLink: true });
      } else {
        updatedBuyForm.hasQuantityError = false;
        updatedBuyForm.quantityErrorMsg = '';
      }

      updatedBuyForm.valueOfBricks = updatedValueOfBricks;
      updatedBuyForm.transactionFee = updatedTransactionFee;
      updatedBuyForm.total = updatedTotal;
      updatedBuyForm.quantity = updatedQuantityInt;
    } else {
      updatedBuyForm.hasQuantityError = true;
      updatedBuyForm.quantityErrorMsg = 'Enter a valid quantity of Bricks to buy.';
      updatedBuyForm.quantity = updatedQuantity;
    }
    this._updateForm(updatedBuyForm);
  };

  _onPriceChange = (event) => {
    const updatedPrice = event.target.value.replace(/,/g, '');
    this._handlePriceChange(updatedPrice);
  };

  _handlePriceChange = (updatedPrice) => {
    const { buyForm, account, property } = this.props;
    var updatedBuyForm = buyForm;

    if (validateTradeProposalPrice(updatedPrice)) {
      const updatedPriceFloat = Number.parseFloat(updatedPrice);

      const availableToTradeBalance = account.availableToTradeBalance;
      const lowestBuyPriceAllowed = (property.financials.brickValue * LOWEST_BUY_PRICE_PERCENTAGE).toFixed(2);
      const brickValueTitle = property.financials.isIndependentValued
        ? 'Latest Brick Valuation'
        : 'Initial Brick Price';

      const updatedValueOfBricks = updatedPriceFloat * buyForm.quantity;
      const transactionFeeRate = this._getTransactionFeeRatio();
      const updatedTransactionFee = (updatedValueOfBricks * transactionFeeRate)::roundTransactionFee();
      const updatedTotal = updatedValueOfBricks + updatedTransactionFee;

      if (updatedPriceFloat < lowestBuyPriceAllowed) {
        updatedBuyForm.hasPriceError = true;
        updatedBuyForm.priceErrorMsg = `Sorry, the minimum price you can offer to buy Bricks in ${renderCode(property.propertyCode)} is ${lowestBuyPriceAllowed::dollarDecimal()} (${(1 - LOWEST_BUY_PRICE_PERCENTAGE)::percent()} lower than the ${brickValueTitle}).`;
      } else if (
        updatedTotal > availableToTradeBalance &&
        account.blockedAmount === 0
      ) {
        updatedBuyForm.hasPriceError = true;
        updatedBuyForm.priceErrorMsg = `You do not have enough funds to complete this transaction.`;
      } else if (
        updatedTotal > availableToTradeBalance &&
        account.blockedAmount > 0
      ) {
        updatedBuyForm.hasPriceError = true;
        updatedBuyForm.priceErrorMsg = `You do not have enough funds to complete this transaction. The remaining funds are currently held in a pending buy order.`;
        this.setState({ showPendingOrderLink: true });
      } else {
        updatedBuyForm.hasPriceError = false;
        updatedBuyForm.priceErrorMsg = '';
      }

      updatedBuyForm.valueOfBricks = updatedValueOfBricks;
      updatedBuyForm.transactionFee = updatedTransactionFee;
      updatedBuyForm.total = updatedTotal;
      updatedBuyForm.price = updatedPrice;
    } else {
      updatedBuyForm.hasPriceError = true;
      updatedBuyForm.priceErrorMsg = 'Please enter a valid buy price for your Brick(s).';
      updatedBuyForm.price = updatedPrice;
    }
    this._updateForm(updatedBuyForm);
  };

  _onAcceptTermsChange = () => {
    this.setState({
      terms: {
        accepted: !this.state.terms.accepted,
        showErrorMessage: false,
      },
    });
  };

  _updateTotalBricksOnMyPendingBuy = (myOrders) => {
    const { property } = this.props;
    if (_.isEmpty(myOrders.pendingBuy)) {
      this.setState({
        totalBricksOnMyPendingBuy: 0,
        totalBricksOnMyPendingBuyLoaded: true,
      });
    } else {
      const buyOrdersForProperty = _.filter(myOrders.pendingBuy, { propertyCode: property.propertyCode });
      const totalBricksOnMyPendingBuy = _.reduce(buyOrdersForProperty, (sum, order) => sum + order.quantity, 0);

      this.setState({
        totalBricksOnMyPendingBuy: totalBricksOnMyPendingBuy,
        totalBricksOnMyPendingBuyLoaded: true,
      });
    }
  };

  _updateTotalBricksOwned = (account) => {
    const { getTotalBricksOwned } = this.props;
    const totalBricksOwned = getTotalBricksOwned(account);

    this.setState({
      totalBricksOwned: totalBricksOwned,
      totalBricksOwnedLoaded: true,
    });
  };

  _getTransactionFeeRatio = () => {
    const {
      fees: { buyFee, buyFeeFreeEnabled },
    } = this.props;
    return buyFeeFreeEnabled ? 0 : buyFee ? buyFee / 100 : 0;
  };

  async _confirmPurchase() {
    this.setState({ loading: true });
    const { user, property, confirmPurchaseV2 } = this.props;
    this._handlePriceChange(this.state.buyProposalParams.price);
    this._handleQuantityChange(this.state.buyProposalParams.quantity);
    if (
      this.state.buyProposalParams.hasPriceError ||
      this.state.buyProposalParams.hasQuantityError
    ) {
      this.setState({ loading: false });
      return;
    }
    if (!this.state.terms.accepted) {
      this.setState({
        loading: false,
        terms: {
          showErrorMessage: true,
        },
      });
      return;
    }
    if (checkUserWithTradePermission(user, this.context.router)) {
      const { price, quantity, valueOfBricks, transactionFee, total } = this.state.buyProposalParams;
      await confirmPurchaseV2(property.propertyCode, {
        price,
        quantity,
        valueOfBricks,
        transactionFee,
        total,
      });
    }
    this.setState({ loading: false });
  }

  _buildBuyProposalParams = (updatedBuyForm) => {
    this.setState({
      buyProposalParams: {
        quantity: updatedBuyForm.quantity,
        price: updatedBuyForm.price,
        valueOfBricks: updatedBuyForm.valueOfBricks,
        transactionFee: updatedBuyForm.transactionFee,
        total: updatedBuyForm.total,
        hasQuantityError: updatedBuyForm.hasQuantityError,
        hasPriceError: updatedBuyForm.hasPriceError,
        quantityErrorMsg: updatedBuyForm.quantityErrorMsg,
        priceErrorMsg: updatedBuyForm.priceErrorMsg,
      },
    });
  };

  _updateForm = (updatedBuyForm) => {
    this._buildBuyProposalParams(updatedBuyForm);
    updatedBuyForm.callback(updatedBuyForm);
  };
}

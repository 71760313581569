import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import InvestmentTypes from 'src/enums/investmentTypes';

class Menu extends Component {
  render() {
    const { children } = this.props;
    return <ul>{children}</ul>;
  }
}

const MenuItem = ({ iconName, linkTo, lastItem, children }) => (
  <li className={lastItem ? 'last' : ''}>
    <Link to={linkTo} activeClassName="active">
      <div className="icn-container">
        <div className={`icn icn-medium icn-grey ${iconName}`} />
      </div>
      <span className="tab-name">{children}</span>
    </Link>
  </li>
);

MenuItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default class PropertySubheader extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
  };

  render() {
    const { property } = this.props;
    return (
      <div className="property-nav">
        <div className="property-nav-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Menu ref="menu">
                  <MenuItem
                    linkTo={`/investments/${property.propertyCode}/summary`}
                    iconName="icn-house"
                  >
                    Summary
                  </MenuItem>
                  <MenuItem
                    linkTo={`/investments/${property.propertyCode}/distributions`}
                    iconName="icn-coins"
                  >
                    <span className="hidden-xs hidden-sm">Monthly</span>{' '}
                    Distributions
                  </MenuItem>
                  <MenuItem
                    linkTo={`/investments/${property.propertyCode}/returns`}
                    iconName="icn-line-chart"
                  >
                    {property.investmentType === InvestmentTypes.mortgage
                      ? 'Asset Returns'
                      : property.investmentType === InvestmentTypes.financialAsset
                      ? 'Fund Returns'
                      : 'Capital Returns'}
                  </MenuItem>
                  <MenuItem
                    linkTo={`/investments/${property.propertyCode}/details`}
                    iconName="icn-details"
                    lastItem
                  >
                    Details
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

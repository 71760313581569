import { connect } from 'react-redux';
import { fetchOrderBookStatus as fetchOrderBookStatusAction } from 'scripts/redux/actions/market';
import PrimaryButton from 'src/design/components/button/PrimaryButton';

const EditButton = connect(null, {
  fetchOrderBookStatus: fetchOrderBookStatusAction,
})(
  ({
    fetchOrderBookStatus,
    fetchOrders,
    order,
    orderType,
    editForm,
    ...props
  }) => {
    const _onClick = async () => {
      if (orderType === 'pre') {
        await fetchOrders(order.propertyCode);
      }
      await fetchOrderBookStatus(order.propertyCode);
      const updatedEditForm = {
        ...editForm,
        orderId: order.orderId,
        type: orderType,
        propertyCode: order.propertyCode,
        quantity: order.quantity,
        price: order.price,
        total: orderType === 'sell'
          ? order.totalPrice - (order.totalPriceInclFees - order.totalPrice)
          : order.totalPriceInclFees,
        hasError: false,
        errorMsg: '',
      };
      updatedEditForm.callback(updatedEditForm);
    };

    return (
      <PrimaryButton
        color={PrimaryButton.colors.PRIMARY}
        size={PrimaryButton.sizes.EXTRA_SMALL}
        onClick={_onClick}
        {...props}
      >
        <span className="fa fa-edit" />
      </PrimaryButton>
    );
  }
);

export default EditButton;

import { Fragment } from 'react';
import { Route, IndexRoute, IndexRedirect, Redirect } from 'react-router';
import { closeAllModals } from 'src/components/modals/modalActions';
import { showHeaderBar } from 'src/store/headerBarActions';
import {
  loggedInUsersRedirectionInterceptor,
  requireUserLogoutInterceptor,
} from 'scripts/interceptors/authInterceptor';
import { onboardingStepsInterceptor } from 'scripts/interceptors/registrationInterceptor';
import { provideTheme } from 'src/themes/themeUtils';
import { refereePageUrl, refereeNotEligiblePageUrl, privateSyndicationLandingPageUrl, brickxDevelopmentLandingPageUrl, brickxMortgageLandingPageUrl, minorLandingPageUrl, wholesaleLandingPageUrl } from 'src/utils/pageUrls';
import { scrollToTop } from 'src/browser/window';
import { userWithDetailsCompletedInterceptor } from 'scripts/interceptors/userAccountInterceptor';
import AboutUs from 'src/components/aboutUs/AboutUs';
import Account from 'scripts/containers/Account';
import AcornsCampaignTerms from 'scripts/components/terms/referrals/AcornsCampaignTerms';
import AcornsCampaignTermsFeb from 'scripts/components/terms/referrals/AcornsCampaignTermsFeb';
import AnnualIndividualStatement from 'scripts/components/account/documents/AnnualIndividualStatement';
import AnnualStatements from 'scripts/components/account/documents/AnnualStatements';
import App from 'src/components/app/App';
import BpayDetails from 'scripts/components/account/wallet/BpayDetails';
import BuyFirstBrickBonus25Terms from 'scripts/components/terms/BuyFirstBrickBonus25Terms';
import BuyFirstBrickBonus50Terms from 'scripts/components/terms/BuyFirstBrickBonus50Terms';
import BuySuccess from 'src/components/trades/BuySuccess';
import SellSuccess from 'src/components/trades/SellSuccess';
import ChangePassword from 'src/components/settings/ChangePassword';
import Complaints from 'src/components/complaints/Complaints';
import Contact from 'scripts/components/contact/Contact';
import DashboardHome from 'src/components/dashboard/home/DashboardHome';
import DashboardNavigation from 'src/components/dashboard/navigation/DashboardNavigation';
import Deposit from 'scripts/components/account/wallet/Deposit';
import DisableSmartInvest from 'src/components/settings/DisableSmartInvest';
import EnableSmartInvest from 'src/components/settings/EnableSmartInvest';
import FeeFreeDayTerms from 'scripts/components/terms/FeeFreeDayTerms';
import FeeFreeEofy2017 from 'scripts/components/terms/FeeFreeEofy2017';
import FeesPromo2018 from 'src/components/terms/FeesPromo2018';
import FeesPromo2022 from 'src/components/terms/FeesPromo2022';
import Home from 'src/components/landingPages/home/Home';
import HowItWorks from 'src/components/landingPages/howItWorks/HowItWorks';
import IdentityVerification from 'src/components/oldOnboarding/identityVerification/IdentityVerification';
import LatestPds from 'scripts/containers/LatestPds';
import LatestDistributions from 'scripts/containers/LatestDistributions';
import Login from 'src/components/oldOnboarding/login/Login';
import Logout from 'src/components/oldOnboarding/login/Logout';
import Maintenance from 'scripts/containers/Maintenance';
import ManageSmartInvest from 'src/components/settings/ManageSmartInvest';
import MediaEnquiries from 'scripts/components/media/MediaEnquiries';
import MediaPage from 'scripts/components/media/MediaPage';
import MyDocuments from 'scripts/components/account/documents/MyDocuments';
// import MyPendingOrders from 'scripts/components/account/myOrders/MyPendingOrders';
import NoFeesPreOrder from 'src/components/terms/NoFeesPreOrder';
import NoFeesPreOrder2020 from 'src/components/terms/NoFeesPreOrder2020';
import NoFeesPreOrder2020TAR01 from 'src/components/terms/NoFeesPreOrder2020TAR01';
import NoFeesPreOrder2020WYN01 from 'src/components/terms/NoFeesPreOrder2020WYN01';
import NoFeesPreOrder2020CLN02 from 'src/components/terms/NoFeesPreOrder2020CLN02';
import NoFeesPreOrder2021CLN03 from 'src/components/terms/NoFeesPreOrder2021CLN03';
import NoFeesPreOrder2021NML01 from 'src/components/terms/NoFeesPreOrder2021NML01';
import NoFeesPreOrder2021CNG01 from 'src/components/terms/NoFeesPreOrder/CNG01';
import NoFeesPreOrder2021ARN01 from 'src/components/terms/NoFeesPreOrder/ARN01';
import NoFeesPreOrder2022HAW01 from 'src/components/terms/NoFeesPreOrder/HAW01';
import NoFeesPreOrder2022KAN01 from 'src/components/terms/NoFeesPreOrder/KAN01';
import NotFoundPage from 'src/components/landingPages/notFound/NotFoundPage';
import OnboardingQuestions from 'src/components/oldOnboarding/onboardingQuestions/OnboardingQuestions';
import PdsPage from 'scripts/containers/PdsPage';
import PdsPanel from 'scripts/containers/PdsPanel';
import PendingOrders from 'src/components/dashboard/pendingOrders/PendingOrders';
import Portfolio from 'src/components/dashboard/portfolio/Portfolio';
import PreOrderSuccess from 'src/components/trades/PreOrderSuccess';
import PriceAlertsRemoved from 'src/components/priceAlertsRemoved/PriceAlertsRemoved';
import Privacy from 'scripts/components/privacy/Privacy';
import ProductSelection from 'src/components/oldOnboarding/productSelection/ProductSelection';
import Properties from 'scripts/containers/Properties';
import PropertiesIndex from 'scripts/containers/PropertiesIndex';
import Property from 'scripts/containers/Property';
import PropertyDetails from 'scripts/components/property/details/Details';
import PropertyDistributions from 'scripts/components/property/distributions/Distributions';
import PropertyReturns from 'scripts/components/property/returns/Returns';
import PropertyReturnsCalculatorDisclosures from 'scripts/components/terms/PropertyReturnsCalculatorDisclosures';
import PropertySummary from 'scripts/components/property/summary/Summary';
import PropertyTeam from 'src/components/propertyTeam/PropertyTeam';
import RaizPromotionTerms from 'src/components/terms/RaizPromotionTerms';
import ReChangeResults from 'src/components/terms/ReChangeResults';
import RentToBuyApp from 'src/rentToBuy/RentToBuyApp';
import RentToBuyFlow from 'src/rentToBuy/RentToBuyFlow';
import BrickxAgriLandingPageApp from 'src/components/landingPages/brickxAgriculture/BrickxAgriApp';
import BrickxAgriLandingPageFlow from 'src/components/landingPages/brickxAgriculture/BrickxAgriFlow';
import ResetPassword from 'scripts/components/password/ResetPassword';
import ResetPasswordToken from 'scripts/components/password/ResetPasswordToken';
import ReturnsCalculator from 'scripts/components/calculators/ReturnsCalculator';
import ReturnsCalculatorDisclosures from 'scripts/components/terms/ReturnsCalculatorDisclosures';
import SellBricks from 'src/components/dashboard/sellBricks/SellBricks';
import September2018FacebookCompetition from 'scripts/components/terms/September2018FacebookCompetition';
import SessionTimedOutPage from 'src/components/sessionTimeout/sessionTimedOutPage/SessionTimedOutPage';
import Settings from 'src/components/settings/Settings';
import SignUpForm from 'scripts/components/onboardingOld/SignUpForm';
import SmartInvest from 'src/components/landingPages/smartInvest/SmartInvest';
import SmartInvestDetails from 'src/components/oldOnboarding/smartInvestDetails/SmartInvestDetails';
import SmartInvestSkipAMonth from 'src/components/settings/SmartInvestSkipAMonth';
import Smsf from 'src/components/landingPages/smsf/Smsf';
import SmsfSuccess from 'src/components/landingPages/smsf/SmsfSuccess';
import SuccessPoli from 'scripts/components/account/wallet/SuccessPoli';
import SurveyTermsAndConditions from 'scripts/components/terms/SurveyTermsAndConditions';
import SurveyTermsAndConditions2017 from 'scripts/components/terms/SurveyTermsAndConditions2017';
import SurveyTermsAndConditions2018 from 'scripts/components/terms/SurveyTermsAndConditions2018';
import TemporarySurveyTerms from 'src/components/landingPages/temporaryServeyTerms/TemporarySurveyTerms';
import Terms from 'scripts/components/terms/Terms';
import TradeConfirmations from 'scripts/components/account/documents/TradeConfirmations';
// import TradeSucceedSell from 'scripts/components/property/trade/TradeSucceedSell';
import Transactions from 'src/components/transactions/Transactions';
import VedaRetry from 'src/components/oldOnboarding/vedaRetry/VedaRetry';
import Verification from 'scripts/containers/Verification';
import Wallet from 'src/components/dashboard/wallet/Wallet';
import WithdrawComponent from 'scripts/components/account/wallet/WithdrawComponent';
import WithdrawalRequested from 'scripts/components/account/wallet/WithdrawalRequested';
import brickxTheme from 'src/themes/brickx/brickxTheme';
import brickxAgriTheme from 'src/themes/brickxAgri/theme';
import rentToBuyTheme from 'src/themes/rentToBuy/rentToBuyTheme';
import ChangeOfAuditor from 'src/components/terms/ChangeOfAuditor';
import OnboardingTMD from 'src/components/oldOnboarding/onboardingQuestions/TargetMarketDetermination';
import LogoutAndSignupMinor from 'src/components/oldOnboarding/login/LogoutAndSIgnupMinor';
import { themeForBrickXMinorLandingPage, themeForBrickXPrivateSyndicate, themeForBrickXWholesale } from 'src/themes/landingPageThemes';
import BrickXMinorLandingPage from 'src/components/landingPages/brickxMinor/BrickXMinorLandingPage';
import BrickXWholesaleLandingPage from 'src/components/landingPages/WholesaleLandingPage';
import DetailsUpdate from 'scripts/components/account/settings/DetailsUpdate';
import AccountSwitchPage from 'src/components/oldOnboarding/login/AccountSwitchPage/AccountSwitchPage';
import BrickXSyndicateYourOwnLandingPage from 'src/components/landingPages/brickxSyndicateYourOwn/BrickXSyndicateYourOwnLandingPage';
import BrickXDevelopmentsLandingPage from 'src/components/landingPages/brickxDevelopments/BrickXDevelopmentsLandingPage';
import BrickXMortgagesLandingPage from 'src/components/landingPages/brickxMortgages/BrickXMortgagesLandingPage';
import BoVedaFailed from 'src/components/oldOnboarding/boVedaFailed/BoVedaFailed';
import LogoutAndSignupSmsf from 'src/components/oldOnboarding/login/LogoutAndSIgnupSmsf';
import SmsfAndTrustProcessingPage from 'src/components/oldOnboarding/SmsfAndTrustProcessingPage';
import DistributionStatement from 'scripts/components/account/documents/DistributionStatement';

export const getRoutes = (store) => {
  const userLoggedoutRequested = (nextState, replaceState) =>
    requireUserLogoutInterceptor(store, nextState, replaceState);

  const redirectionRequiredForLoggedInUsers = (nextState, replaceState) => loggedInUsersRedirectionInterceptor(store, nextState, replaceState);

  const registrationPagesInterceptor = (nextState, replaceState) =>
    onboardingStepsInterceptor(store, nextState, replaceState);

  const userDetailsCompletedRequired = (nextState, replaceState) => userWithDetailsCompletedInterceptor(store, nextState, replaceState);

  const scrollTopOnUrlChange = (previous, next) => {
    if (previous.location.pathname !== next.location.pathname) {
      scrollToTop();
    }
  };

  return (
    <Fragment>
      <Redirect from="/renttobuy" to="/smart-renter" state={{ statusCode: 301 }} />
      <Redirect from="/rent-to-buy" to="/smart-renter" state={{ statusCode: 301 }} />
      <Redirect from="/avenue" to="/smart-renter" state={{ statusCode: 301 }} />
      <Route path="/smart-renter" component={provideTheme(RentToBuyApp, rentToBuyTheme)}>
        <IndexRoute component={RentToBuyFlow} />
      </Route>
      <Route path={minorLandingPageUrl()} component={provideTheme(BrickXMinorLandingPage, themeForBrickXMinorLandingPage)} />
      <Route path={wholesaleLandingPageUrl()} component={provideTheme(BrickXWholesaleLandingPage, themeForBrickXWholesale)} />
      <Route path={privateSyndicationLandingPageUrl()} component={provideTheme(BrickXSyndicateYourOwnLandingPage, themeForBrickXPrivateSyndicate)} />
      <Route path={brickxDevelopmentLandingPageUrl()} component={provideTheme(BrickXDevelopmentsLandingPage, themeForBrickXPrivateSyndicate)} />
      <Route path={brickxMortgageLandingPageUrl()} component={provideTheme(BrickXMortgagesLandingPage, themeForBrickXPrivateSyndicate)} />
      <Route path="/brickx-agri-293023480923099032038230" component={provideTheme(BrickxAgriLandingPageApp, brickxAgriTheme)} >
        <IndexRoute component={BrickxAgriLandingPageFlow} />
      </Route>
      <Route
        path="/"
        component={provideTheme(App, brickxTheme)}
        onChange={(previous, next) => {
          scrollTopOnUrlChange(previous, next);
          store.dispatch(closeAllModals());
          if (
            next.location.pathname !== refereePageUrl()
            && next.location.pathname !== refereeNotEligiblePageUrl()
          ) {
            store.dispatch(showHeaderBar());
          }
        }}
      >
        <IndexRoute component={Home} />
        <Route path="about" component={AboutUs} />
        <Route path="members-meeting" component={ReChangeResults} />
        <Route path="team" component={PropertyTeam} />
        <Route path="property-team" component={PropertyTeam} />
        <Route path="smart-invest" component={SmartInvest} />
        <Route path="investments" component={Properties}>
          <IndexRoute component={PropertiesIndex} />
          <Route path=":propertyCode" component={Property} >
            <IndexRedirect to={'summary'} />
            <Route path="summary" component={PropertySummary} />
            <Route path="distributions" component={PropertyDistributions} />
            <Route path="returns" component={PropertyReturns} />
            <Route path="details" component={PropertyDetails} />
            <Route path="sell-success" component={SellSuccess} />
            <Route path="purchase-success" component={BuySuccess} />
            <Route path="preorder-success" component={PreOrderSuccess} />
            <Route path="*" component={NotFoundPage} />
          </Route>
          <Route path="*" component={PropertiesIndex} />
        </Route>
        <Route path="account" component={Account} onEnter={userDetailsCompletedRequired}>
          <Route path="" component={DashboardNavigation}>
            <IndexRedirect to="home" />
            <Route path="home" component={DashboardHome} />
            <Route path="portfolio" component={Portfolio} />
            <Route path="portfolio/sell-bricks" component={SellBricks} />
            <Route path="wallet" component={Wallet} />
            <Route path="pending-orders" component={PendingOrders} />
            {/* <Route path="my-pending-orders" component={MyPendingOrders} /> */}
            <Route path="transactions" component={Transactions} />
            <Route path="documents" component={MyDocuments} />
            <Route path="settings" component={Settings} />
          </Route>
          <Route path="documents">
            <Route path="annual-statements/:year" component={AnnualStatements} />
            <Route path="annual-individual-statement/:year" component={AnnualIndividualStatement} />
            <Route path="trade-confirmation" component={TradeConfirmations} />
            <Route path="distribution-statement" component={DistributionStatement} />
          </Route>
          <Route path="settings/enable-si" component={EnableSmartInvest} />
          <Route path="settings/disable-si" component={DisableSmartInvest} />
          <Route path="settings/manage-si" component={ManageSmartInvest} />
          <Route path="settings/skip-a-month" component={SmartInvestSkipAMonth} />
          <Route path="deposit" onEnter={userDetailsCompletedRequired}>
            <IndexRoute component={Deposit} />
            <Route path="success-poli/:transferId" component={SuccessPoli} />
            <Route path="show-bpay" component={BpayDetails} />
          </Route>
          <Route path="withdraw" onEnter={userDetailsCompletedRequired}>
            <IndexRoute component={WithdrawComponent} />
            <Route path="requested" component={WithdrawalRequested} />
          </Route>
          <Route path="password" component={ChangePassword} />
          <Route path="price-alerts" component={PriceAlertsRemoved} />
          <Route path="auto-invest/onboarding/tmd" component={OnboardingTMD} />
          <Route path="auto-invest/onboarding/questions" component={OnboardingQuestions} />
          <Route path="auto-invest/onboarding/pick-a-plan" component={ProductSelection} />
          <Route path="auto-invest/onboarding/details" component={SmartInvestDetails} />
          <Route path="update-details" component={DetailsUpdate} />
          <Route path="*" component={NotFoundPage} />
        </Route>
        <Route path="reset-password/:token" component={ResetPasswordToken} />
        <Route path="reset-password" component={ResetPassword} />
        <Route path="how-it-works" component={HowItWorks} />
        <Route path="contact" component={Contact} />
        <Route path="login" component={Login} onEnter={redirectionRequiredForLoggedInUsers} />
        <Route path="switch-account" component={AccountSwitchPage} />
        <Route path="logout" component={Logout} />
        <Route path="logout-signup-minor" component={LogoutAndSignupMinor} />
        <Route path="logout-signup-smsf" component={LogoutAndSignupSmsf} />
        <Route path="signup" component={(props) => <SignUpForm isSignupPage {...props} />} />
        <Route path="signup/minor" component={(props) => <SignUpForm isSignupPage isMinor {...props} />} />
        <Route path="signup/smsf" component={(props) => <SignUpForm isSignupPage isSmsf {...props} />} />
        <Route path="signup/trust" component={(props) => <SignUpForm isSignupPage isTrust {...props} />} />
        <Route path="verify" component={Verification} onEnter={registrationPagesInterceptor} />
        <Route path="verify/:token" component={Verification} />
        <Route path="accept-pds" component={PdsPanel} onEnter={registrationPagesInterceptor} />
        <Route path="pds" component={PdsPage} />
        <Route path="setup" component={IdentityVerification} onEnter={userDetailsCompletedRequired} />
        <Route path="veda" component={VedaRetry} onEnter={userDetailsCompletedRequired} />
        <Route path="bo-veda" component={BoVedaFailed} onEnter={userDetailsCompletedRequired} />
        <Route path="smsf-and-trust-application-in-review" component={SmsfAndTrustProcessingPage} onEnter={userDetailsCompletedRequired} />
        <Route path="terms">
          <IndexRoute component={Terms} />
          <Route path="buy-first-brick-bonus-25" component={BuyFirstBrickBonus25Terms} />
          <Route path="buy-first-brick-bonus-50" component={BuyFirstBrickBonus50Terms} />
          <Route path="fee-free" component={FeeFreeDayTerms} />
          <Route path="fees-promo-2018" component={FeesPromo2018} />
          <Route path="fees-promo-2022" component={FeesPromo2022} />
          <Route path="no-fees-pre-order-KGT01-2019" component={NoFeesPreOrder} />
          <Route path="no-fees-pre-order-CLN01-2020" component={NoFeesPreOrder2020} />
          <Route path="no-fees-pre-order-TAR01-2020" component={NoFeesPreOrder2020TAR01} />
          <Route path="no-fees-pre-order-WYN01-2020" component={NoFeesPreOrder2020WYN01} />
          <Route path="no-fees-pre-order-CLN02-2020" component={NoFeesPreOrder2020CLN02} />
          <Route path="no-fees-pre-order-CLN03-2021" component={NoFeesPreOrder2021CLN03} />
          <Route path="no-fees-pre-order-NML01-2021" component={NoFeesPreOrder2021NML01} />
          <Route path="no-fees-pre-order-CNG01-2021" component={NoFeesPreOrder2021CNG01} />
          <Route path="no-fees-pre-order-ARN01-2021" component={NoFeesPreOrder2021ARN01} />
          <Route path="no-fees-pre-order-HAW01-2022" component={NoFeesPreOrder2022HAW01} />
          <Route path="no-fees-pre-order-KAN01-2022" component={NoFeesPreOrder2022KAN01} />
          <Route path="eofy2017" component={FeeFreeEofy2017} />
          <Route path="partner-referrals/acorns">
            <IndexRoute component={AcornsCampaignTerms} />
            <Route path="feb" component={AcornsCampaignTermsFeb} />
          </Route>
          <Route path="partner-referrals/raiz">
            <IndexRoute component={RaizPromotionTerms} />
          </Route>
          <Route path="returns-calculator-disclosures" component={ReturnsCalculatorDisclosures} />
          <Route path="property-returns-calculator-disclosures" component={PropertyReturnsCalculatorDisclosures} />
          <Route path="surveyterms2017" component={SurveyTermsAndConditions2017} />
          <Route path="surveyterms2018" component={SurveyTermsAndConditions2018} />
          <Route path="september-2018-facebook-competition" component={September2018FacebookCompetition} />
          <Redirect from="survey-terms-15-december-2017" to="surveyterms2018" />
        </Route>
        <Route path="privacy" component={Privacy} />
        <Route path="complaints" component={Complaints} />
        <Route path="404" component={NotFoundPage} />
        <Route path="surveytermsandconditions" component={SurveyTermsAndConditions} />
        <Route path="media-enquiries" component={MediaEnquiries} />
        <Route path="pds/latest-pds-updates" component={LatestPds} />
        <Route path="monthlyDistributions" component={LatestDistributions} />
        <Route path="returns-calculator" component={ReturnsCalculator} />
        <Route path="media" component={MediaPage} />
        <Route path="maintenance" component={Maintenance} />
        <Route path="logged-out" component={SessionTimedOutPage} onEnter={userLoggedoutRequested} />
        <Route path="survey-terms" component={TemporarySurveyTerms} />
        <Route path="smsf" component={Smsf} />
        <Route path="smsf-success" component={SmsfSuccess} />
        <Route path="disclosure-notice_change-in-auditor" component={ChangeOfAuditor} />
        <Route path="*" component={NotFoundPage} />
      </Route>
    </Fragment>
  );
};

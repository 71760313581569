const PropertyTypes = {
  house: 'house',
  unit: 'unit',
  farm: 'farm',
  offices: 'offices',
  multiunit: 'multiunit',
  multihouse: 'multihouse',
  development: 'development',
  commercial: 'commercial',
  securedDebt: 'secured_debt',
  unsecuredDebt: 'unsecured_debt',
  diversifiedDebt: 'diversified_debt',
  equityFund: 'equity_fund',
  debtFund: 'debt_fund',
  diversifiedFund: 'diversified_fund',
};

export default PropertyTypes;

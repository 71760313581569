import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'scripts/utilities/helpers';
import {
  property as propertyPropType,
  user as userPropType,
  monthlyUpdates as monthlyUpdatesPropType,
  propertyAverageAnnualGrowthMetrics as propertyAverageAnnualGrowthMetricsPropType,
  historicalGrowthData as historicalGrowthDataPropType,
} from 'scripts/constants/PropTypes';
import { sellBricksUrl } from 'src/utils/pageUrls';
import { sendPropertyTabLoad } from 'scripts/redux/actions/segment/events/propertiesPageEvents';
import { userSelector } from 'scripts/redux/selectors/user';
import CapitalReturn from 'scripts/components/property/summary/CapitalReturn';
import CriticalNews from 'scripts/components/property/CriticalNews';
import HistoricalSuburbPerformanceInFinancialsPanel from 'scripts/components/property/summary/HistoricalSuburbPerformanceInFinancialsPanel';
import InvestmentYields from 'scripts/components/property/summary/InvestmentYields';
import LatestValue from 'scripts/components/property/summary/LatestValue';
import Loading from 'src/components/loading/Loading';
import MonthlyPaidDistributions from 'scripts/components/property/summary/MonthlyPaidDistributions';
import MonthlyUpdates from 'scripts/components/property/summary/MonthlyUpdates';
import PrevNextBar from 'scripts/components/property/PrevNextBar';
import PropertyReturnsCalculator from 'scripts/components/property/calculator/PropertyReturnsCalculator';
import PropertySmartInvestSignupDriver from 'src/components/propertySmartInvestSignupDriver/PropertySmartInvestSignupDriver';
import PropertySummaryBanner from 'src/components/promos/placements/PropertySummaryBanner';
import PropertyVideo from 'scripts/components/property/PropertyVideo';
import TopReasons from 'scripts/components/property/summary/TopReasons';
import Trade from 'scripts/constants/Trade';
import TradeComponent from 'scripts/components/property/trade/TradeComponent';
import styles from 'scripts/components/property/summary/Summary.mscss';
import {
  getHistoricalGrowthMetricsHighestNumberOfYearsGrowth,
  getHistoricalGrowthMetricsHighestNumberOfYears,
} from 'scripts/utilities/propertyHelper';
import PropertySummaryMetaTags from 'scripts/components/metatags/PropertySummaryMetaTags';
import HistoricalBrickPricePanel from 'scripts/components/property/summary/HistoricalBrickPricePanel';
import HideComponent from 'src/components/common/HideComponent';
import { renderCode } from 'src/settings/properties';

const isLinkedFromSellBricksPage = (location) =>
  location.state && location.state.from === sellBricksUrl();

const mapStateToProps = (state) => ({
  ...userSelector(state),
});

@connect(mapStateToProps, {})
export default class PropertySummary extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    isPreOrderProperty: PropTypes.bool.isRequired,
    isComingSoonProperty: PropTypes.bool.isRequired,
    isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
    user: userPropType.isRequired,
    monthlyUpdates: monthlyUpdatesPropType,
    propertyAverageAnnualGrowthMetrics:
      propertyAverageAnnualGrowthMetricsPropType,
    historicalGrowthData: historicalGrowthDataPropType,
    location: PropTypes.object,
  };

  state = {
    mode: isLinkedFromSellBricksPage(this.props.location)
      ? Trade.TRADE_MODE.SELL
      : Trade.TRADE_MODE.VIEW,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    sendPropertyTabLoad({
      tabName: 'Summary',
      propertyCode: this.props.property.propertyCode,
    });
  }

  render() {
    const {
      user,
      property,
      isPreOrderProperty,
      isComingSoonProperty,
      isPreorderOrComingSoonProperty,
      monthlyUpdates,
      propertyAverageAnnualGrowthMetrics,
      historicalGrowthData,
    } = this.props;
    let averageAnnualGrowthAmount = '---';
    let averageAnnualGrowthNumberOfYears = '---';
    let averageAnnualGrowthAmountNumber = 0;
    if (propertyAverageAnnualGrowthMetrics) {
      averageAnnualGrowthAmount =
        getHistoricalGrowthMetricsHighestNumberOfYearsGrowth(
          propertyAverageAnnualGrowthMetrics
        );
      averageAnnualGrowthNumberOfYears =
        getHistoricalGrowthMetricsHighestNumberOfYears(
          propertyAverageAnnualGrowthMetrics
        );
      const roundTo2DecimalPlaces = (number) => Math.round(number * 100) / 100;
      averageAnnualGrowthAmountNumber = roundTo2DecimalPlaces(
        parseFloat(averageAnnualGrowthAmount)
      );
    }

    return (
      <div className="property-overview summary">
        <PropertySummaryMetaTags
          property={property}
          url={`https://www.brickx.com/investments/${property.propertyCode}`}
        />

        <div className="container">
          <PropertySummaryBanner property={property} />

          {isMobile() && (
            <div className="visible-xs">
              <div className="row">
                <TradeComponent
                  user={user}
                  property={property}
                  isComingSoonProperty={isComingSoonProperty}
                  isPreOrderProperty={isPreOrderProperty}
                  isPreorderOrComingSoonProperty={
                    isPreorderOrComingSoonProperty
                  }
                  mode={this.state.mode}
                  handleTradeModeChange={this._handleTradeModeChange}
                />
              </div>
            </div>
          )}

          <CriticalNews criticalNews={property.criticalNews} />

          <div className="row">
            {this.state.mode === Trade.TRADE_MODE.VIEW && (
              <div>
                <div className="col-md-4 col-md-offset-0">
                  <InvestmentYields
                    property={property}
                    propertyAverageAnnualGrowthMetrics={
                      propertyAverageAnnualGrowthMetrics
                    }
                  />
                </div>
                <div className="col-md-4">
                  <LatestValue
                    property={property}
                    isPreorderOrComingSoonProperty={
                      isPreorderOrComingSoonProperty
                    }
                  />
                </div>
              </div>
            )}
            {!isMobile() && (
              <div className="non-mobile hidden-xs">
                <TradeComponent
                  user={user}
                  property={property}
                  isComingSoonProperty={isComingSoonProperty}
                  isPreOrderProperty={isPreOrderProperty}
                  mode={this.state.mode}
                  handleTradeModeChange={this._handleTradeModeChange}
                />
              </div>
            )}
          </div>

          <HideComponent>
            <div className="row">
              <div className="col-xs-12">
                <HistoricalBrickPricePanel
                  propertyCode={property.propertyCode}
                />
              </div>
            </div>
          </HideComponent>

          <div className={styles.smartInvestSignupDriver}>
            <PropertySmartInvestSignupDriver
              trackingProps={{
                from: 'Property Details Page',
                propertyCode: property.propertyCode,
              }}
            />
          </div>

          <div className="row flex-row">
            <div className="col-md-4 flex-column">
              {typeof averageAnnualGrowthAmountNumber === 'number' ? (
                <PropertyReturnsCalculator
                  property={property}
                  averageAnnualGrowthAmountNumber={
                    averageAnnualGrowthAmountNumber
                  }
                  averageAnnualGrowthNumberOfYears={
                    averageAnnualGrowthNumberOfYears
                  }
                />
              ) : (
                <Loading notFullHeight />
              )}
            </div>
            <div className="col-md-8 flex-column">
              <HistoricalSuburbPerformanceInFinancialsPanel
                property={property}
                historicalGrowthData={historicalGrowthData}
                propertyTab="Summary"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <MonthlyPaidDistributions property={property} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CapitalReturn
                isPreorderOrComingSoonProperty={isPreorderOrComingSoonProperty}
                property={property}
                averageAnnualGrowthAmount={averageAnnualGrowthAmount}
                averageAnnualGrowthNumberOfYears={
                  averageAnnualGrowthNumberOfYears
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <MonthlyUpdates
                monthlyUpdates={monthlyUpdates}
                isPreorderOrComingSoonProperty={isPreorderOrComingSoonProperty}
              />
            </div>
            <div className="col-md-6">
              <PropertyVideo videoUrl={property.videoUrl} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <TopReasons
                title={`Top Reasons to Invest In ${renderCode(
                  property.propertyCode
                )}`}
                linkText="See Details tab"
                link={`/investments/${property.propertyCode}/details`}
                property={property}
              />
            </div>
          </div>

          <PrevNextBar
            nextTitle="Monthly Distributions"
            nextLink={`/investments/${property.propertyCode}/distributions`}
          />
        </div>
      </div>
    );
  }

  _handleTradeModeChange = (mode) => {
    this.setState({ mode: mode });
  };
}

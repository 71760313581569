import { upperFirst } from 'lodash';
import moment from 'moment-timezone';
import numeral from 'numeral';

const SYDNEY_TIME_ZONE = 'Australia/Sydney';
const formatDate = (date, format) => date ? moment(date).tz(SYDNEY_TIME_ZONE).format(format) : '';
const formatNumber = (number, format) => number || number === 0 ? numeral(number).format(format) : '';

export const shortDate = (date) => formatDate(date, 'DD/MM/YYYY');
export const longDate = (date) => formatDate(date, 'DD MMM YYYY');
export const dateTime = (date) => formatDate(date, 'YYYY-MM-DD HH:mm');

export const dollar = (money) => formatNumber(money, '$0,0');
export const dollarDecimal = (money) => formatNumber(money, '$0,0.00');

export const oneDecimalPlaces = (number) => formatNumber(number, '0.0');
export const twoDecimalPlaces = (number) => formatNumber(number, '0.00');

export const floatToPercent = (float) => formatNumber(float, '0%');
export const floatToPercentOneOrTwoDecimal = (float) => formatNumber(float, '0.0[0]%');
export const floatToPercentDecimal = (float) => formatNumber(float, '0.00%');

export const shortUUID = (value) => (value || '').substring(24);

export const formatAuPhoneNumber = (num) => {
  let number = num;
  if (number.toString().includes('+')) {
    return number;
  }
  if (number[0] === '0') {
    number = number.substring(1);
  }
  return `+61${number}`;
};

export const slugToTitle = (slug) => slug.split('-').map(upperFirst).join(' ');

export const getPlural = ({ number, singular, plural }) =>
  number > 1 ? plural : singular;

export const ordinal = (number) => {
  const firstDigit = `${number}`.slice(-1);
  const secondDigit = `${number}`.slice(-2, -1);

  if (secondDigit === '1') return `${number}th`;

  switch (firstDigit) {
    case '1':
      return `${number}st`;
    case '2':
      return `${number}nd`;
    case '3':
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const getLastTwoDigitsOfYear = (year) => parseInt(`${year}`.slice(-2), 10);
export const yearToFinancialYearRange = (year) => `${getLastTwoDigitsOfYear(year) - 1}-${getLastTwoDigitsOfYear(year)}`;

export const shortReference = (reference) => reference.slice(-12);

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  isAPreOrderOrComingSoonProperty,
  calculateGearingEffect,
  getSortedGrowthMetricsPairs,
} from 'scripts/utilities/propertyHelper';
import {
  percentDecimal,
  percentDecimalWithMinusSign,
  percent,
  dollar,
} from 'scripts/utilities/formatters';
import {
  property as propertyPropType,
  propertyAverageAnnualGrowthMetrics as propertyAverageAnnualGrowthMetricsPropType,
} from 'scripts/constants/PropTypes';
import { whatIsGearingEffectArticleUrl } from 'src/utils/pageUrls';
import FinancialsPanel, {
  RowValue,
  RowContainer,
  SectionTitle,
  RowTitleWithSelector,
  RowTitle,
} from 'scripts/components/shared/FinancialsPanel';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import {
  getGearingEffectPopoverText,
  historicalGrowthTitle,
  historicalSuburbGrowthPopoverText,
  strToTitle,
} from 'src/settings/text';
import { TotalFundReturn } from 'src/settings/financials';
import Formatters from 'scripts/utilities/formattersV2';
import InvestmentTypes from 'src/enums/investmentTypes';
import PropertyTypes from 'src/enums/propertyTypes';
import {
  historicalNationalGrowthRate10yr,
  historicalRBACashRate10yr,
} from 'src/settings/financials';
import { securityValue } from 'src/settings/financials';

const GearingRowContainer = styled(RowContainer)`
  padding-bottom: 0;
`;

class InvestmentYields extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    propertyAverageAnnualGrowthMetrics:
      propertyAverageAnnualGrowthMetricsPropType,
  };

  state = {
    numberOfYearsSelected:
      this.props.propertyAverageAnnualGrowthMetrics &&
      getSortedGrowthMetricsPairs(
        this.props.propertyAverageAnnualGrowthMetrics
      ).reverse()[0][0],
  };

  render() {
    const { property, propertyAverageAnnualGrowthMetrics } = this.props;

    const { numberOfYearsSelected } = this.state;

    const yearsOptions = getSortedGrowthMetricsPairs(
      this.props.propertyAverageAnnualGrowthMetrics
    )
      .reverse()
      .map(([year]) => year);

    const brickPriceAdjective = isAPreOrderOrComingSoonProperty(property)
      ? 'Initial'
      : 'current Lowest Available';
    const netRentalIncomePopoverText =
      property.investmentType === InvestmentTypes.mortgage
        ? `The forecasted Net Interest Yield, based on the ${brickPriceAdjective} Brick Price and weekly interest expected by the BrickX property management team. See full calculations on the Monthly Distributions tab.`
        : property.investmentType === InvestmentTypes.financialAsset
        ? `The forecasted Net Income Yield, based on the ${brickPriceAdjective} Brick Price and weekly income expected by the BrickX property management team. See full calculations on the Monthly Distributions tab.`
        : property.propertyType === PropertyTypes.development
        ? 'Estimated net rental yield for a development fund is 0% during the development phase. All fund and holding costs incurred during the development phase are accounted for in the objective capital growth rate.'
        : property.tenantStatus.tenanted
        ? `The annualised Net Rental Yield, based on the ${brickPriceAdjective} Brick Price. See full calculation on Monthly Distributions tab.`
        : `The forecasted Net Rental Yield, based on the ${brickPriceAdjective} Brick Price and weekly rent expected by the BrickX property management team. See full calculations on the Monthly Distributions tab.`;
    const developmentObjectiveCapitalGrowthRatePopoverText =
      'Objective capital growth rate is calculated as the estimated value of the developed property, divided by all costs incurred by the fund during the development period.';
    const totalFundReturnPopoverText =
      'Total fund return is calculated as the total income generated by the fund less the total costs of the fund divided by the forecast investment horizon for all assets of the Trust.';

    const numberOfYearsInteger = parseInt(numberOfYearsSelected, 10);
    const yearsOrYear = numberOfYearsInteger > 1 ? 'years' : 'year';
    const historicalSuburbGrowthPopover = historicalSuburbGrowthPopoverText(
      property.propertyType,
      numberOfYearsInteger,
      yearsOrYear
    );
    const gearingEffectPopoverText = getGearingEffectPopoverText(
      property.propertyType
    );
    const historicalGrowthOverSelectedYears = _.get(
      propertyAverageAnnualGrowthMetrics,
      [numberOfYearsSelected]
    );
    const averageAnnualGrowthPercent =
      typeof historicalGrowthOverSelectedYears === 'number'
        ? historicalGrowthOverSelectedYears::percentDecimalWithMinusSign()
        : '---';

    const gearingEffect = calculateGearingEffect(
      historicalGrowthOverSelectedYears,
      property.financials.lVR
    );

    let gearingEffectPercent = '---';
    if (!isNaN(gearingEffect)) {
      gearingEffectPercent = gearingEffect::percentDecimal();
    }

    return (
      <FinancialsPanel
        title={
          property.propertyType === PropertyTypes.development
            ? 'Investment Info - Development Phase'
            : property.propertyType === PropertyTypes.securedDebt
            ? 'Investment Info - Secured Debt'
            : property.propertyType === PropertyTypes.unsecuredDebt
            ? 'Investment Info - Unsecured Debt'
            : property.propertyType === PropertyTypes.diversifiedDebt
            ? 'Investment Info - Diversified Debt'
            : property.propertyType === PropertyTypes.equityFund
            ? 'Investment Info - Equity Fund'
            : property.propertyType === PropertyTypes.debtFund
            ? 'Investment Info - Debt Fund'
            : property.propertyType === PropertyTypes.diversifiedFund
            ? 'Investment Info - Diversified Fund'
            : 'Investment Info'
        }
        className="panel-financials-investment-yield panel-financials-table-icon"
      >
        <SectionTitle
          title={
            property.investmentType === InvestmentTypes.mortgage
              ? 'Interest Distribution Info'
              : property.investmentType === InvestmentTypes.financialAsset
              ? 'Income Distribution Info'
              : 'Rental Distribution Info'
          }
          icon="coins"
          noPaddingTop
        />
        <div className="gray-line"></div>

        <RowContainer>
          <RowTitle
            title={
              property.investmentType === InvestmentTypes.mortgage
                ? 'Est. Net Interest Yield'
                : property.investmentType === InvestmentTypes.financialAsset
                ? 'Est. Net Income Yield'
                : 'Est. Net Rental Yield '
            }
          />
          <RowValue
            className="net-rental-yield"
            value={
              <span>
                {property.propertyType === PropertyTypes.development
                  ? '0%'
                  : property.financials.netRentalYield::percentDecimal()}{' '}
                <small style={{ fontSize: '18px' }}>p.a.</small>
              </span>
            }
            popoverText={netRentalIncomePopoverText}
          />
        </RowContainer>
        <div className="black-line"></div>

        <SectionTitle
          title={
            property.investmentType === InvestmentTypes.mortgage
              ? 'Security Info'
              : 'Capital Returns Info'
          }
          icon="graph"
        />
        <div className="gray-line"></div>

        {property.investmentType === InvestmentTypes.mortgage ? (
          <Fragment>
            <RowContainer>
              <RowTitle title="Security Value" />
              <RowValue
                className="security-value"
                value={
                  <span>{securityValue[property.propertyCode]::dollar()}</span>
                }
              />
            </RowContainer>
            <div className="gray-line"></div>
          </Fragment>
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <Fragment>
            <RowContainer>
              <RowTitle
                title={
                  property.propertyType === PropertyTypes.equityFund
                    ? '10yr Historical National Growth'
                    : '10yr Historical RBA Cash Rate'
                }
              />
              <RowValue
                className="historical-growth"
                value={
                  <span>
                    {property.propertyType === PropertyTypes.equityFund
                      ? historicalNationalGrowthRate10yr::percentDecimal()
                      : historicalRBACashRate10yr::percentDecimal()}{' '}
                    <small style={{ fontSize: '18px' }}>p.a.</small>
                  </span>
                }
              />
            </RowContainer>
            <div className="gray-line"></div>
          </Fragment>
        ) : (
          <Fragment>
            <RowContainer>
              <RowTitleWithSelector
                title={`${strToTitle(
                  historicalGrowthTitle(property.propertyType)
                )}*`}
                options={yearsOptions.map((numberOfYears) => ({
                  text: numberOfYears,
                  value: numberOfYears,
                }))}
                selected={numberOfYearsSelected}
                onChange={(selected) =>
                  this.setState({ numberOfYearsSelected: selected })
                }
                selectAboveTitle
                testRef="historical-growth-selector"
              />
              <RowValue
                className="historical-suburb-growth"
                value={
                  <span>
                    {averageAnnualGrowthPercent}{' '}
                    <small style={{ fontSize: '18px' }}>p.a.</small>
                  </span>
                }
                popoverText={historicalSuburbGrowthPopover}
              />
            </RowContainer>
            <div className="gray-line"></div>
          </Fragment>
        )}

        {property.propertyType === PropertyTypes.development && (
          <Fragment>
            <RowContainer>
              <RowTitle
                title={
                  <span>
                    Development Objective
                    <br />
                    Capital Growth Rate
                  </span>
                }
              />
              <RowValue
                className="forecast-capital-growth"
                value={
                  <span>
                    {Formatters.numeric.percentDecimal(
                      property.financials.objectiveCapitalGrowthRate
                    )}{' '}
                    <small style={{ fontSize: '18px' }}>p.a.</small>
                  </span>
                }
                popoverText={developmentObjectiveCapitalGrowthRatePopoverText}
              />
            </RowContainer>
            <div className="gray-line"></div>
          </Fragment>
        )}

        {property.investmentType === InvestmentTypes.property ? (
          <Fragment>
            <GearingRowContainer>
              <RowTitle
                title={`Gearing Effect (${property.financials.lVR::percent()} Debt)`}
              />
              <RowValue
                className="gearing-effect"
                value={gearingEffectPercent}
                popoverText={gearingEffectPopoverText}
              />
            </GearingRowContainer>
            <Spacing top="none" bottom="normal">
              <small className="asterisk-info">
                Learn more about how gearing works{' '}
                <Styledlink
                  href={whatIsGearingEffectArticleUrl()}
                  target="_blank"
                >
                  here
                </Styledlink>
                .
              </small>
            </Spacing>
          </Fragment>
        ) : (
          <Fragment>
            <RowContainer>
              <RowTitle title="Current Loan to Value Ratio" />
              <RowValue
                className="lvr"
                value={
                  <span>
                    {(property.financials.liveLoanAdvance /
                      securityValue[property.propertyCode])::percentDecimal()}
                  </span>
                }
              />
            </RowContainer>
          </Fragment>
        )}

        {TotalFundReturn[property.propertyCode] && (
          <Fragment>
            <div className="gray-line"></div>
            <RowContainer>
              <RowTitle title={<span>Total Fund Return</span>} />
              <RowValue
                className="total-fund-return"
                value={
                  <span>
                    {Formatters.numeric.percentDecimal(
                      TotalFundReturn[property.propertyCode]
                    )}
                  </span>
                }
                popoverText={totalFundReturnPopoverText}
              />
            </RowContainer>
          </Fragment>
        )}

        <div className="black-line"></div>
        <div className="row">
          <div className="col-xs-12">
            <small className="asterisk-info">
              *Past performance does not indicate future performance.
            </small>
            <small className="asterisk-info">
              Neither income nor capital returns are guaranteed.
            </small>
          </div>
        </div>
      </FinancialsPanel>
    );
  }
}

export default InvestmentYields;

import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ordersBuySelector } from 'scripts/redux/selectors/market';
import { fetchOrders as fetchOrdersAction } from 'scripts/redux/actions/market';
import PropTypes from 'prop-types';
import {
  order as orderPropType,
  property as propertyPropType,
  account as accountPropType,
  editForm as editFormPropType,
} from 'scripts/constants/PropTypes';
import { longDate, shortReference } from 'src/utils/formats';
import { brick, dollarDecimal } from 'scripts/utilities/formatters';
import {
  getTotalPreOrders,
  getTotalQuantityOfBricks,
  getPropertyFromOrder,
} from 'src/utils/orders';
import { validateTradeProposalQuantity } from 'scripts/utilities/tradeHelper';
import { NoPreOrders } from 'src/components/dashboard/pendingOrders/NoOrders';
import Loading from 'src/components/loading/Loading';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import EditButton from 'src/components/dashboard/pendingOrders/EditButton';
import EditExitButton from 'src/components/dashboard/pendingOrders/EditExitButton';
import EditConfirmButton from 'src/components/dashboard/pendingOrders/EditConfirmButton';
import CancelButton from 'src/components/dashboard/pendingOrders/CancelButton';
import {
  BodyTableRow,
  ColumnHeading,
  FooterTableData,
  SecondColumn,
  StickyHeader,
  Table,
  TableData,
} from 'src/design/components/table/Table';
import { renderCode } from 'src/settings/properties';
import DynamicNumber from 'react-dynamic-number';
import Numbers from 'scripts/constants/Numbers';
import Constants from 'scripts/constants/Constants';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const mapStateToProps = (state) => ({
  ...ordersBuySelector(state),
});

@connect(mapStateToProps, {
  fetchOrders: fetchOrdersAction,
})
export default class PreOrderPanel extends Component {
  static propTypes = {
    preOrders: PropTypes.arrayOf(orderPropType),
    properties: PropTypes.arrayOf(propertyPropType),
    account: accountPropType,
    editForm: editFormPropType,
    ordersBuy: PropTypes.arrayOf(orderPropType),
    fetchOrders: PropTypes.func.isRequired,
  };

  render() {
    const { preOrders, properties, editForm, fetchOrders } = this.props;
    const today = new Date();

    return !properties.length ? (
      <Loading />
    ) : (
      <WidgetPanel title="Pending Pre Orders" testRef="pre-orders-panel">
        {preOrders.length ? (
          <Table withStickyHeader centeredRows>
            <thead>
              <tr>
                <StickyHeader>
                  <ColumnHeading>Property</ColumnHeading>
                </StickyHeader>
                <SecondColumn>
                  <ColumnHeading>
                    Date
                    <br />
                    Ordered
                  </ColumnHeading>
                </SecondColumn>
                <ColumnHeading>
                  Settlement
                  <br />
                  Date
                </ColumnHeading>
                <ColumnHeading>
                  Quantity
                  <br />
                  of Bricks
                </ColumnHeading>
                <ColumnHeading>
                  Initial
                  <br />
                  Brick Price
                </ColumnHeading>
                <ColumnHeading>
                  Order
                  <br />
                  total
                </ColumnHeading>
                <ColumnHeading>Reference</ColumnHeading>
                <ColumnHeading />
              </tr>
            </thead>
            <tbody>
              {preOrders.map((order) =>
                order.orderId === editForm.orderId ? (
                  <Fragment key={`edit-${order.orderId}`}>
                    <BodyTableRow key={order.orderId}>
                      <StickyHeader>
                        <TableData>
                          {renderCode(editForm.propertyCode)}
                        </TableData>
                      </StickyHeader>
                      <SecondColumn>
                        <TableData>
                          <b style={{ color: '#3687de' }}>{longDate(today)}</b>
                        </TableData>
                      </SecondColumn>
                      <TableData>
                        {longDate(
                          getPropertyFromOrder(properties, order)
                            .platformSettlementDate
                        )}
                      </TableData>
                      <TableData>
                        <DynamicNumber
                          name="edit_quantity"
                          value={editForm.quantity}
                          integer={Numbers.TEN}
                          fraction={Numbers.ZERO}
                          positive={Constants.TRUE}
                          thousand={Constants.FALSE}
                          placeholder="0"
                          onChange={this._onQuantityChange}
                          style={{ backgroundColor: 'white' }}
                        />
                      </TableData>
                      <TableData>{dollarDecimal(order.price)}</TableData>
                      <TableData>
                        <b style={{ color: '#3687de' }}>
                          {dollarDecimal(editForm.total)}
                        </b>
                      </TableData>
                      <TableData>-</TableData>
                      <TableData>
                        <ButtonContainer>
                          <EditConfirmButton
                            order={order}
                            orderTypeText="Pre Order"
                            editForm={editForm}
                            handleQuantityChange={this._handleQuantityChange}
                            className="leftButton"
                            disable={editForm.hasError}
                          />
                          <EditExitButton
                            editForm={editForm}
                            className="rightButton"
                          />
                        </ButtonContainer>
                      </TableData>
                    </BodyTableRow>
                    {editForm.hasError && (
                      <BodyTableRow>
                        <TableData colSpan="100%" className="text-danger">
                          <b>{editForm.errorMsg}</b>
                        </TableData>
                      </BodyTableRow>
                    )}
                  </Fragment>
                ) : (
                  <BodyTableRow key={order.orderId} testRef="row">
                    <StickyHeader>
                      <TableData testRef="property-code-cell">
                        {renderCode(order.propertyCode)}
                      </TableData>
                    </StickyHeader>
                    <SecondColumn>
                      <TableData testRef="date-cell">
                        {longDate(order.date)}
                      </TableData>
                    </SecondColumn>
                    <TableData testRef="settlement-date-cell">
                      {longDate(
                        getPropertyFromOrder(properties, order)
                          .platformSettlementDate
                      )}
                    </TableData>
                    <TableData testRef="quantity-of-bricks-cell">
                      {order.quantity}
                    </TableData>
                    <TableData testRef="initial-brick-price-cell">
                      {dollarDecimal(order.price)}
                    </TableData>
                    <TableData testRef="order-total-cell">
                      {dollarDecimal(order.totalPriceInclFees)}
                    </TableData>
                    <TableData testRef="reference-cell">
                      {shortReference(order.orderId)}
                    </TableData>
                    <TableData>
                      <ButtonContainer>
                        <EditButton
                          fetchOrders={fetchOrders}
                          order={order}
                          orderType="pre"
                          editForm={editForm}
                          className="leftButton"
                        />
                        <CancelButton
                          order={order}
                          orderTypeText="Pre Order"
                          className="rightButton"
                        />
                      </ButtonContainer>
                    </TableData>
                  </BodyTableRow>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <StickyHeader>
                  <FooterTableData>Total</FooterTableData>
                </StickyHeader>
                <SecondColumn>
                  <FooterTableData>-</FooterTableData>
                </SecondColumn>
                <FooterTableData>-</FooterTableData>
                <FooterTableData testRef="total-quantity-of-bricks-cell">
                  {getTotalQuantityOfBricks(preOrders)}
                </FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData testRef="total-cell">
                  {dollarDecimal(getTotalPreOrders(preOrders))}
                </FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData>-</FooterTableData>
              </tr>
            </tfoot>
          </Table>
        ) : (
          <NoPreOrders />
        )}
      </WidgetPanel>
    );
  }

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { preOrders, properties, account, editForm, ordersBuy } = this.props;
    var updatedEditForm = { ...editForm };

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity);

      const property = getPropertyFromOrder(properties, editForm);
      const order = preOrders.find((_order) => _order.orderId === editForm.orderId);

      const totalBricksOnMyPendingBuy = preOrders.reduce((acc, _order) => _order.propertyCode === property.propertyCode ? acc + _order.quantity : acc, 0);
      const maxNumOfBricksPerProperty = (property.financials.numberBricks * property.maxOwnership) / 100;
      const maxNumOfBricksPerPropertyRatio = `${property.maxOwnership}%`;
      const maxNumOfBricksCanBuy = maxNumOfBricksPerProperty - totalBricksOnMyPendingBuy + order.quantity;

      const numOfBricksReserved = ordersBuy.reduce((sum, _order) => sum + _order.quantity, 0);
      const numOfBricksAvailable = property.financials.numberBricks - numOfBricksReserved + order.quantity;

      const availableToTradeBalance = account.availableToTradeBalance + order.totalPriceInclFees;

      const updatedTotal = updatedEditForm.price * updatedQuantityInt;

      if (updatedQuantityInt > numOfBricksAvailable) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `There ${numOfBricksAvailable === 1 ? 'is' : 'are'} only ${numOfBricksAvailable::brick()} available.`;
      } else if (updatedQuantityInt > maxNumOfBricksCanBuy) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `You can only pre order ${maxNumOfBricksCanBuy} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio}, or ${maxNumOfBricksPerProperty::brick()} for this property.`;
      } else if (updatedTotal > availableToTradeBalance) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `You have insufficient funds to complete this transaction. Please deposit more funds to your account.`;
      } else {
        updatedEditForm.hasError = false;
        updatedEditForm.errorMsg = '';
      }

      updatedEditForm.total = updatedTotal;
      updatedEditForm.quantity = updatedQuantityInt;
    } else {
      updatedEditForm.hasError = true;
      updatedEditForm.errorMsg = `Enter a valid quantity of Bricks to Pre-Order.`;
      updatedEditForm.quantity = updatedQuantity;
    }
    updatedEditForm.callback(updatedEditForm);
  };
}

import { createAction, handleActions } from 'redux-actions';

import FinancialsService from 'scripts/services/FinancialsService';

const HISTORICAL_BRICK_VALUES = 'scripts/financials/HISTORICAL_BRICK_VALUES';
const FINANCIAL_METRICS = 'scripts/financials/FINANCIAL_METRICS';

const historicalBrickValues = createAction(HISTORICAL_BRICK_VALUES);
const financialMetrics = createAction(FINANCIAL_METRICS);

export function fetchHistoricalBrickValues(propertyCode) {
  return async function (dispatch) {
    const payload = await FinancialsService.getHistoricalBrickValues(
      propertyCode
    );
    return dispatch(historicalBrickValues(payload));
  };
}

export function fetchFinancialMetrics() {
  return async function (dispatch) {
    const payload = await FinancialsService.getFinancialMetrics();
    return dispatch(financialMetrics(payload));
  };
}

const reducer = handleActions(
  {
    [HISTORICAL_BRICK_VALUES]: (state, action) => ({
      historicalBrickValues: action.payload,
    }),
    [FINANCIAL_METRICS]: (state, action) => ({
      metrics: action.payload,
    }),
  },
  {
    historicalBrickValues: null,
    metrics: null,
  }
);

export default reducer;

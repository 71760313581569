// All trading related configurations
import { getTransactionFeeRatio } from './financials';

export const BUY_BROKERAGE_PERCENTAGE = getTransactionFeeRatio();
export const SELL_BROKERAGE_PERCENTAGE = getTransactionFeeRatio();
export const MAXIMUM_PRICE_DEVIATION_PERCENTAGE = 0.1;
export const LOWEST_SELL_PRICE_PERCENTAGE = 0.5;
export const HIGHEST_SELL_PRICE_PERCENTAGE = 1.5;
export const LOWEST_BUY_PRICE_PERCENTAGE = 0.5;
export const HIGHEST_BUY_PRICE_PERCENTAGE = 1.5;
export const DEFAULT_TOTAL_BRICKS_PER_PROPERTY = 10000;
export const TRADE_SUCCEED_SOCIAL_SHARE_THRESHOLD = 0.1;
export const PREMIUM_PRICE_FIRST_LIMIT = 0.05;
export const PREMIUM_PRICE_SECOND_LIMIT = 0.1;
export const NUM_OF_MONTHS_FOR_AMORTISING = 60;
export const VOTE_CLOSED_PROPERTIES = ['BLM01', 'PML01', 'PRA01'];
/* Some data for SOLD PROPERTIES */
export const SOLD_PROPERTIES = {
  BRW01: {
    soldPrice: 1325000,
    finalBrickPrice: 80.15,
  },
  BLM01: {
    soldPrice: 2430000,
    finalBrickPrice: 180.38,
  },
  PML01: {
    soldPrice: 1550000,
    finalBrickPrice: 150.58,
  },
  WYN01: {
    soldPrice: 532000,
    finalBrickPrice: 29.69,
  },
  TAR01: {
    soldPrice: 800000,
    finalBrickPrice: 49.77,
  },
  CLN01: {
    soldPrice: 930000,
    finalBrickPrice: 57.24,
  },
  CLN02: {
    soldPrice: 875000,
    finalBrickPrice: 55.58,
  },
  CLN03: {
    soldPrice: 900000,
    finalBrickPrice: 56.35,
  },
};

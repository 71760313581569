import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classNames from 'classnames';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import Modal from 'scripts/components/helpers/Modal';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Tooltip,
  Typography,
  colors,
} from '@mui/material';

import Colors from 'src/themes/brickx/colors';

import Formatters from 'scripts/utilities/formattersV2';
import HistoricalBrickPricePanel from 'scripts/components/property/summary/HistoricalBrickPricePanel';
import { MdCircle, MdIosShare, MdOutlineAreaChart } from 'react-icons/md';
import { isMarketOpenProperty } from 'src/utils/property';
import { renderCode } from 'src/settings/properties';
import { APIR_CODE_POPOVER_TEXT } from 'src/components/portfolioDetails/PopoverText';

import Popover from 'scripts/components/shared/Popover';
import InvestmentTypes from 'src/enums/investmentTypes';
import { propertiesUrl } from 'src/utils/pageUrls';

const SharePropertyLayout = styled.div`
  padding: 0;
  h1 {
    font-size: 3rem;
  }

  .property-image-wrappar {
    transform: scale(1.2);
    margin-bottom: 40px;
    width: 100%;
    height: 250px;
    background-image: url('${(props) => props.backgroundImage}');
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    .property-image-center-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #1c1c1c9f;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
    overflow: auto;

    .button-group {
      grid-template-columns: repeat(2, 1fr);
    }

    h1 {
      font-size: 2.5rem;
    }
  }
`;

const socialLinkGenerator = (social, link) => {
  return {
    facebook: `https://www.facebook.com/sharer.php?u=${link}`,
    twitter: `https://twitter.com/intent/tweet?url=${link}&text=&via=brickx`,
    linkedin: `https://www.linkedin.com/shareArticle?url=${link}`,
    whatsapp: `whatsapp://send?text=${link}`,
    email: `mailto:?subject=Check out This BrickX Property!&body=Check out this Brickx Property: ${link}%20`,
  }[social];
};

const InvestmentTypeBadge = ({ investmentType }) => {
  const investmentTypeConfig = {
    secured_debt: { label: 'Secured', background: Colors.blue900 },
    unsecured_debt: { label: 'Unsecured', background: Colors.greenDark },
    diversified_debt: { label: 'Diversified', background: Colors.yellow600 },
    equity_fund: { label: 'Equity', background: Colors.blue900 },
    debt_fund: { label: 'Debt', background: Colors.greenDark },
    diversified_fund: { label: 'Diversified', background: Colors.yellow600 },
  };
  const config = investmentTypeConfig[investmentType];

  if (!config) return null;
  return (
    <Chip
      size="small"
      label={config.label}
      sx={{
        background: config.background,
        borderRadius: 1,
        pb: 0.25,
        px: 0.25,
        fontSize: 14,
        fontWeight: 500,
        color: Colors.white,
      }}
    />
  );
};

const TenantBadge = ({ tenantStatus }) => {
  if (!tenantStatus.tenanted) {
    return (
      <Chip
        size="small"
        icon={<MdCircle />}
        label={'vacant'}
        sx={{
          background: colors.grey[50],
          borderRadius: 1,
          py: 0,
          px: 1,
          fontSize: 14,
          svg: {
            width: 12,
            fill: colors.grey[500],
          },
        }}
      />
    );
  }

  return (
    <Tooltip
      title={
        <Typography>
          {tenantStatus.leaseTerm === 'fixed'
            ? `Fixed: ${tenantStatus.leaseStartDate} ~ ${tenantStatus.rentContractExpiryDate}`
            : 'Month to Month'}
        </Typography>
      }
      placement="top"
    >
      <Chip
        size="small"
        icon={<MdCircle />}
        label={'tenanted'}
        sx={{
          background: colors.green[50],
          borderRadius: 1,
          fontSize: 14,
          svg: {
            width: 12,
            fill: colors.green[500],
          },
        }}
      />
    </Tooltip>
  );
};

const investmentTypeLabels = {
  mortgage: 'Mortgages',
  property: 'Properties',
  financial_asset: 'Financial Assets',
};

export default class PropertyBreadcrumbs extends Component {
  state = {
    showModal: false,
    linkCopySuccess: '',
    showMarketTracker: false,
  };

  static propTypes = {
    property: propertyPropType.isRequired,
    isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
    adjacentProperties: PropTypes.arrayOf(propertyPropType).isRequired,
  };

  copyToClipboard = (link) => {
    try {
      navigator.clipboard.writeText(link);
      this.setState({ linkCopySuccess: 'Copied!' });
    } catch (e) {
      // console.log(e);
    }
  };

  render() {
    const { property, isPreorderOrComingSoonProperty } = this.props;
    const { showModal, showMarketTracker } = this.state;

    const propertyImage = (
      _.first(property.propertyImages) || { optimizedUrl: '' }
    ).optimizedUrl;
    const linkToShare = `https://www.brickx.com/investments/${property.propertyCode}/summary`;

    return (
      <div ref="container" className="property-breadcrumbs">
        <Modal
          id="modal-share-property"
          modalClassName={classNames('expanding-carousel__modal', {
            'expanding-carousel__modal--shown': showModal,
          })}
          sizeClass="modal-lg text-left"
          body={
            <SharePropertyLayout backgroundImage={propertyImage}>
              <div className="property-image-wrappar">
                <div className="property-image-center-logo">
                  <img src="/static/img/bx-logo-light.svg" alt="" width={200} />
                  <Typography variant="h6">
                    Share <strong>{renderCode(property.propertyCode)}</strong>{' '}
                    on:
                  </Typography>
                </div>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<i className="fa fa-facebook"></i>}
                    onClick={() =>
                      window.open(socialLinkGenerator('facebook', linkToShare))
                    }
                  >
                    Facebook
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<i className="fa fa-twitter"></i>}
                    onClick={() =>
                      window.open(socialLinkGenerator('twitter', linkToShare))
                    }
                  >
                    Twitter
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<i className="fa fa-linkedin"></i>}
                    onClick={() =>
                      window.open(socialLinkGenerator('linkedin', linkToShare))
                    }
                  >
                    linkedin
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<i className="fa fa-whatsapp"></i>}
                    onClick={() =>
                      window.open(socialLinkGenerator('whatsapp', linkToShare))
                    }
                  >
                    whatsapp
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<i className="fa fa-envelope"></i>}
                    onClick={() =>
                      window.open(socialLinkGenerator('email', linkToShare))
                    }
                  >
                    email
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<i className="fa fa-link"></i>}
                    onClick={() => this.copyToClipboard(linkToShare)}
                  >
                    {this.state.linkCopySuccess || 'Copy Link'}
                  </Button>
                </Grid>
              </Grid>
            </SharePropertyLayout>
          }
          footer={
            <div>
              <Button
                onClick={() =>
                  this.setState({ showModal: false, linkCopySuccess: '' })
                }
              >
                Close
              </Button>
            </div>
          }
          hideHeader
          hideCloseIcon
        />
        <Box p={2}>
          <Box className="container">
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent={'space-between'}
            >
              <Box>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Stack direction={'row'} spacing={1}>
                    <Box>
                      <Typography
                        fontWeight={'bold'}
                        sx={{
                          color: Colors.primary,
                          '& a': {
                            color: Colors.primary,
                          },
                        }}
                      >
                        <Link to={propertiesUrl()}>
                          {investmentTypeLabels[property.investmentType]}
                        </Link>{' '}
                        / {renderCode(property.propertyCode)}
                        {property.apirCode && (
                          <Fragment>
                            {' - '}
                            <a
                              href="https://www.apir.com.au/search/product"
                              onClick={() =>
                                this.copyToClipboard(property.apirCode)
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {property.apirCode}
                            </a>{' '}
                            <Popover
                              placement="bottom"
                              content={APIR_CODE_POPOVER_TEXT}
                              color="onDarkSurfaceContrast"
                            />
                          </Fragment>
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Typography variant="h5" fontWeight={'bold'}>
                    {renderCode(property.propertyCode)}
                  </Typography>
                </Stack>
                <Typography variant="body1">
                  {property.address}, {property.suburb} {property.state}{' '}
                  {property.postalCode}
                </Typography>
                <Stack
                  mt={1}
                  direction={'row'}
                  spacing={1}
                  sx={{ maxHeight: '25px' }}
                >
                  {property.investmentType === InvestmentTypes.property ? (
                    <TenantBadge tenantStatus={property.tenantStatus} />
                  ) : (
                    <InvestmentTypeBadge
                      investmentType={property.propertyType}
                    />
                  )}
                  {isMarketOpenProperty(property) && (
                    <Button
                      startIcon={<MdOutlineAreaChart />}
                      size="small"
                      variant={showMarketTracker ? 'contained' : 'outlined'}
                      onClick={() =>
                        this.setState({
                          showMarketTracker: !showMarketTracker,
                        })
                      }
                    >
                      MARKET TRACKER
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={<MdIosShare />}
                    size="small"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    SHARE
                  </Button>
                </Stack>
              </Box>
              <Box>
                <Stack
                  alignItems={'center'}
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'block',
                    },
                    textAlign: 'center',
                  }}
                >
                  {!isPreorderOrComingSoonProperty ? (
                    <Typography variant="body1">
                      {property.investmentMetrics
                        ? Formatters.numeric.number(
                            property.investmentMetrics.investors
                          )
                        : ''}{' '}
                      Investors
                    </Typography>
                  ) : (
                    <Typography variant="body1">0 Investors</Typography>
                  )}

                  <Typography variant="h3" color="primary" fontWeight={'800'}>
                    {isPreorderOrComingSoonProperty
                      ? Formatters.numeric.dollar(
                          property.financials.initialBrickPrice
                        )
                      : Formatters.numeric.dollar(
                          property.financials.lowestAvailableBrickPrice
                        )}
                  </Typography>
                  <Typography variant="caption">
                    {isPreorderOrComingSoonProperty
                      ? 'Initial Brick Price'
                      : 'Brick Price'}
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            {showMarketTracker && (
              <Box mt={2}>
                <HistoricalBrickPricePanel
                  propertyCode={property.propertyCode}
                />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  }
}

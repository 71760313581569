import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import _ from 'lodash';
import Formatters from 'scripts/utilities/formattersV2';
import PropertyTypes from 'src/enums/propertyTypes';

const extra = (property) => {
  if (
    _.includes(
      ['CLN01', 'CLN02', 'CLN03', 'TAR01', 'WYN01'],
      property.propertyCode
    )
  ) {
    return (
      <div>
        <small>Excluding performance fee</small>
      </div>
    );
  }
};

const EstNetRentalYieldStat = ({ property }) => {
  const labels = {
    secured_debt: 'Est. Net Interest Income',
    unsecured_debt: 'Est. Net Interest Income',
    diversified_debt: 'Est. Net Interest Income',
    equity_fund: 'Est. Net Income',
    debt_fund: 'Est. Net Income',
    diversified_fund: 'Est. Net Income',
    development: 'Dev. Objective Capital Growth Rate',
    default: 'Est. Net Rental Yield',
  };

  const { propertyType, financials } = property;
  const label = labels[propertyType] || labels.default;
  const valueKey = propertyType === PropertyTypes.development ? 'objectiveCapitalGrowthRate' : 'netRentalYield';

  return (
    <PropertyCardStat
      label={<span>{label} {extra(property)}</span>}
      value={
        <span>
          {Formatters.numeric.percentDecimal(financials[valueKey])} <small>p.a.</small>
        </span>
      }
      valueProps={{ 'data-test-reference': 'net-rental-yield' }}
    />
  );
};

export default EstNetRentalYieldStat;

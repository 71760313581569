import classNames from 'classnames';
import styled from 'styled-components';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import styles from 'src/design/components/button/Button.mscss';

const StyledButton = styled.button.attrs(testRefToDataTestReference)``;

const colors = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ACCENT: 'ACCENT',
  DARK_CONTRAST: 'DARK_CONTRAST',
  ALTERNATIVE: 'ALTERNATIVE',
  YOLK: 'YOLK',
  BLUE: 'BLUE',
  PURPLE: 'PURPLE',
};

const sizes = {
  EXTRA_SMALL: 'EXTRA_SMALL',
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
};

const getColor = (colorType) =>
  ({
    [colors.PRIMARY]: 'charcoal',
    [colors.SECONDARY]: 'primary',
    [colors.ACCENT]: 'azure',
    [colors.DARK_CONTRAST]: 'white',
    [colors.ALTERNATIVE]: 'greenDark',
    [colors.YOLK]: 'yellow500',
    [colors.BLUE]: 'blue600',
    [colors.PURPLE]: 'purple',
  }[colorType] || getColor(colors.PRIMARY));

const Button = ({
  className,
  disabled,
  fullWidth,
  arrow,
  loading,
  loadingText,
  size,
  responsiveSizes = {},
  link,
  onClick: onClickProp,
  children,
  testRef,
  preventDefault,
  ...otherProps
}) => {
  const sizeClassMap = {
    [styles.extraSmallSize]:
      size === sizes.EXTRA_SMALL || responsiveSizes.base === sizes.EXTRA_SMALL,
    [styles.smallSize]:
      size === sizes.SMALL || responsiveSizes.base === sizes.SMALL,
    [styles.normalSize]:
      size === sizes.NORMAL || responsiveSizes.base === sizes.NORMAL,

    [styles['extraSmallSize--breakpoint-small']]:
      responsiveSizes.small === sizes.EXTRA_SMALL,
    [styles['smallSize--breakpoint-small']]:
      responsiveSizes.small === sizes.SMALL,
    [styles['normalSize--breakpoint-small']]:
      responsiveSizes.small === sizes.NORMAL,

    [styles['extraSmallSize--breakpoint-medium']]:
      responsiveSizes.medium === sizes.EXTRA_SMALL,
    [styles['smallSize--breakpoint-medium']]:
      responsiveSizes.medium === sizes.SMALL,
    [styles['normalSize--breakpoint-medium']]:
      responsiveSizes.medium === sizes.NORMAL,

    [styles['extraSmallSize--breakpoint-large']]:
      responsiveSizes.large === sizes.EXTRA_SMALL,
    [styles['smallSize--breakpoint-large']]:
      responsiveSizes.large === sizes.SMALL,
    [styles['normalSize--breakpoint-large']]:
      responsiveSizes.large === sizes.NORMAL,
  };

  const classList = classNames(styles.button, className, {
    [styles.fullWidth]: fullWidth,
    [styles.addArrow]: arrow,
    [styles.pulsing]: loading,
    ...sizeClassMap,
  });

  const text = loading && loadingText ? loadingText : children;

  const onClick = (e, ...args) => {
    preventDefault && e.preventDefault();
    return onClickProp && onClickProp(e, ...args);
  };

  return link ? (
    <StyledBodylink
      className={classList}
      href={disabled ? undefined : link}
      onClick={onClick}
      disabled={disabled}
      testRef={testRef}
      {...otherProps}
    >
      {text}
    </StyledBodylink>
  ) : (
    <StyledButton
      className={classList}
      disabled={disabled}
      onClick={onClick}
      testRef={testRef}
      {...otherProps}
    >
      {text}
    </StyledButton>
  );
};

export default Button;

export { sizes, colors, getColor };

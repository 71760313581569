import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import endsWith from 'lodash/endsWith';
import {
  property as propertyPropType,
  user as userPropType,
  monthlyUpdates as monthlyUpdatesPropType,
  averageAnnualGrowthMetrics as averageAnnualGrowthPropType,
  historicalGrowthData as historicalGrowthDataPropType,
} from 'scripts/constants/PropTypes';
import {
  propertiesSelector,
  propertySelector,
  adjacentPropertiesSelector,
} from 'scripts/redux/selectors/properties';
import { userSelector } from 'scripts/redux/selectors/user';
import { monthlyUpdatesSelector } from 'scripts/redux/selectors/monthlyUpdates';
import { historicalGrowthDataSelector } from 'scripts/redux/selectors/historicalGrowth';
import {
  fetchHistoricalGrowthData,
  clearHistoricalGrowthData,
} from 'scripts/redux/actions/historicalGrowth';
import { fetchProperty } from 'scripts/redux/actions/properties';
import { fetchMonthlyUpdates } from 'scripts/redux/actions/monthlyUpdates';
import PropertyBreadcrumbs from 'scripts/components/property/Breadcrumbs';
import PropertyHero from 'scripts/components/property/Hero';
import PropertySubheader from 'scripts/components/property/Subheader';
import Loading from 'src/components/loading/Loading';
import {
  isAPreOrderProperty,
  isAComingSoonProperty,
  isAPreOrderOrComingSoonProperty,
  isAPreMarketOpenProperty,
} from 'scripts/utilities/propertyHelper';
import { getHistoricalGrowthMetricsForProperty } from 'scripts/utilities/propertyHelper';
import PropertyMetaTags from 'scripts/components/metatags/PropertyMetaTags';
import history from 'src/browser/history';
import _ from 'lodash';

const mapStateToProps = (state) => ({
  ...propertiesSelector(state),
  ...propertySelector(state),
  ...adjacentPropertiesSelector(state),
  ...userSelector(state),
  ...monthlyUpdatesSelector(state),
  ...historicalGrowthDataSelector(state),
});

@connect(mapStateToProps, {
  fetchMonthlyUpdates,
  fetchProperty,
  fetchHistoricalGrowthData,
  clearHistoricalGrowthData,
})
export default class Property extends Component {
  static propTypes = {
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    property: propertyPropType,
    averageAnnualGrowthMetrics: averageAnnualGrowthPropType,
    historicalGrowthData: historicalGrowthDataPropType,
    adjacentProperties: PropTypes.arrayOf(propertyPropType),
    location: PropTypes.object.isRequired,
    user: userPropType.isRequired,
    monthlyUpdates: monthlyUpdatesPropType,
    params: PropTypes.object,
    fetchHistoricalGrowthData: PropTypes.func.isRequired,
    clearHistoricalGrowthData: PropTypes.func.isRequired,
    fetchMonthlyUpdates: PropTypes.func.isRequired,
    fetchProperty: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { properties, fetchMonthlyUpdates, location } = this.props;
    const propertyCode = this.props.params.propertyCode;
    fetchMonthlyUpdates(propertyCode);
    this._fetchProperty(properties);
    if (location.pathname.split('/').length === 3) {
      if (_.includes([], propertyCode)) {
        history.push(`/investments/${propertyCode}/details`);
      } else {
        history.push(`/investments/${propertyCode}/summary`);
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      !isEmpty(nextProps.properties) &&
      nextProps.properties !== this.props.properties
    ) {
      this._fetchProperty(nextProps.properties);
    }

    if (nextProps.property && nextProps.property !== this.props.property) {
      const { clearHistoricalGrowthData, fetchHistoricalGrowthData } =
        this.props;
      clearHistoricalGrowthData();
      fetchHistoricalGrowthData(
        nextProps.property.historicalGrowthKey,
        nextProps.property.state,
        nextProps.property.propertyType.toUpperCase()
      );
    }
  }

  _fetchProperty = (properties) => {
    if (!isEmpty(properties)) {
      const { fetchProperty } = this.props;
      const propertyCode = this.props.params.propertyCode;
      fetchProperty(propertyCode, properties);
    }
  };

  render() {
    const {
      params: { propertyCode },
      property,
      monthlyUpdates,
      adjacentProperties,
      user,
      location,
      averageAnnualGrowthMetrics,
      historicalGrowthData,
      children,
    } = this.props;

    const isPropertyLoaded = property && property.propertyCode === propertyCode;
    if (!isPropertyLoaded) {
      return (
        <div>
          <PropertyMetaTags
            propertyCode={propertyCode}
            url={`https://www.brickx.com/investments/${propertyCode}`}
          />
          <Loading />
        </div>
      );
    }

    const isPreOrderProperty = isAPreOrderProperty(property);
    const isComingSoonProperty = isAComingSoonProperty(property);
    const isPreorderOrComingSoonProperty =
      isAPreOrderOrComingSoonProperty(property);
    const isPreMarketOpenProperty = isAPreMarketOpenProperty(property);
    const propertyAverageAnnualGrowthMetrics =
      getHistoricalGrowthMetricsForProperty(
        averageAnnualGrowthMetrics,
        property
      );

    return (
      <div className="property">
        <PropertyBreadcrumbs
          property={property}
          isPreorderOrComingSoonProperty={isPreorderOrComingSoonProperty}
          adjacentProperties={adjacentProperties}
        />
        {!(
          endsWith(location.pathname, 'purchase-success') ||
          endsWith(location.pathname, 'preorder-success') ||
          endsWith(location.pathname, 'sell-success')
        ) ? (
          <div>
            <PropertyHero
              property={property}
              key={`hero-${property.propertyCode}`}
            />
            <PropertySubheader property={property} />
          </div>
        ) : null}
        {children &&
          React.cloneElement(children, {
            property,
            isPreOrderProperty,
            isComingSoonProperty,
            isPreorderOrComingSoonProperty,
            isPreMarketOpenProperty,
            user,
            monthlyUpdates,
            propertyAverageAnnualGrowthMetrics,
            historicalGrowthData,
          })}
      </div>
    );
  }
}

export default {
  SortBy: {
    Newest: {
      displayName: 'Newest to BrickX',
      propertyName: 'platformSettlementDate',
      sortingWay: 'desc',
    },
    Discount: {
      displayName: 'Below Brick Valuation',
      propertyName: 'financials.discountOnBrickValue',
      sortingWay: 'asc',
    },
    BrickPrice: {
      displayName: 'Lowest Brick Price',
      propertyName: 'financials.brickPrice',
      sortingWay: 'asc',
    },
    Debt: {
      displayName: 'Loan to Value Ratio',
      propertyName: 'financials.lVR',
      sortingWay: 'desc',
    },
    LoanTerm: {
      displayName: 'Term to Maturity',
      propertyName: 'financials.debtTerms.loanTerms',
      sortingWay: 'asc',
    },
    NetIncome: {
      displayName: 'Net Income',
      propertyName: 'financials.netRentalYield',
      sortingWay: 'desc',
    },
    HistoricalSuburbGrowth: {
      displayName: 'Historical Suburb Growth',
      sortingWay: 'desc',
    },
    LaunchDateTime: {
      displayName: 'Launch Date',
      propertyName: 'launchDateTime',
      sortingWay: 'desc',
    },
  },
};

import _ from 'lodash';
import moment from 'moment';

import { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { MY_TRADE_CONFIRMATION_META } from 'scripts/metadata/account';
import { AgGridReact } from 'ag-grid-react';
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import { userSelector } from 'scripts/redux/actions/user';
import MarketService from 'scripts/services/MarketService';
import { isMobile } from 'scripts/utilities/helpers';
import Loading from 'src/components/loading/Loading';
import Colors, { onSurfaceContrast800 } from 'src/themes/brickx/colors';
import { dollarDecimal, shortUUID } from 'src/utils/formats';
import styled from 'styled-components';
import { renderCode } from 'src/settings/properties';
import { propertiesUrl } from 'src/utils/pageUrls';

const AgGridContainer = styled.div`
  height: 80vh;
  font-family: var(--bx-font---theme);
  font-size: 1.4rem;
  .ag-row {
    color: ${onSurfaceContrast800};
    a {
      color: ${onSurfaceContrast800};
    }
  }

  @media screen and (max-width: 767px) {
    .ag-row {
      font-size: 1.3rem;
      color: ${onSurfaceContrast800};
      a {
        color: ${onSurfaceContrast800};
      }
    }
    .ag-cell {
      padding-left: 5px;
    }
    .ag-header-cell {
      padding: 5px;
    }
  }
  .widget-icon {
    font-size: 2rem;
    color: ${Colors.primary};
  }
`;

const MyTradesTable = ({ myTrades }) => {
  const isMobile_ = isMobile();

  const agGridProps = {
    rowData: _.orderBy(myTrades || [], 'date', 'desc'),
    columnDefs: [
      {
        field: 'date',
        valueFormatter: ({ value }) =>
          moment(value).format('DD MMM YYYY HH:mm'),
        width: 180,
        filter: true,
      },
      {
        width: 120,
        field: 'propertyCode',
        headerName: 'Property',
        filter: true,
        cellRenderer: ({ value }) => (
          <a href={`/investments/${value}`} target="_blank">
            <strong>{renderCode(value)}</strong>
          </a>
        ),
      },
      {
        headerName: 'BUY/SELL',
        width: 130,
        field: 'orderSide',
        filter: true,
        cellRenderer: ({ value }) => {
          if (value === 'buy') {
            return (
              <span
                style={{
                  borderColor: Colors.green,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  padding: '0px 8px',
                  borderRadius: '5px',
                  color: Colors.green,
                }}
              >
                <strong>BUY</strong>
              </span>
            );
          }
          return (
            <span
              style={{
                borderColor: Colors.red,
                borderWidth: '2px',
                borderStyle: 'solid',
                padding: '0px 8px',
                borderRadius: '5px',
                color: Colors.red,
              }}
            >
              <strong>SELL</strong>
            </span>
          );
        },
      },
      {
        width: 120,
        field: 'quantity',
      },
      {
        width: 120,
        field: 'price',
        valueFormatter: ({ value }) => dollarDecimal(value),
      },
      {
        width: 200,
        headerName: 'Reference',
        filter: true,
        field: 'id',
        valueFormatter: ({ value }) => shortUUID(value),
      },
      {
        width: 250,
        headerName: 'Download PDF Certificate',
        field: 'id',
        cellRenderer: ({ value }) => (
          <a href={`/document/tradecertificate/${value}`} target="_blank">
            <div className="widget-icon">
              <BsFileEarmarkArrowDownFill />
            </div>
          </a>
        ),
      },
    ],
    pagination: true,
    paginationPageSize: 30,
    rowHeight: 40,
    suppressMovableColumns: true,
  };

  const agGridPropsMobile = {
    rowData: _.orderBy(myTrades || [], 'date', 'desc'),
    columnDefs: [
      {
        field: 'date',
        valueFormatter: ({ value }) =>
          moment(value).format('DD MMM YYYY HH:mm'),
        width: 140,
        resizable: true,
      },
      {
        width: 180,
        field: 'propertyCode',
        headerName: '',
        filter: true,
        cellRenderer: ({ data }) => {
          return (
            <span>
              {data.orderSide}: {renderCode(data.propertyCode)} -{' '}
              {data.quantity} {' x '} {dollarDecimal(data.price)}
            </span>
          );
        },
      },
      {
        width: 50,
        headerName: 'PDF',
        field: 'id',
        cellRenderer: ({ value }) => (
          <a href={`/document/tradecertificate/${value}`} target="_blank">
            <div className="widget-icon">
              <BsFileEarmarkArrowDownFill />
            </div>
          </a>
        ),
      },
    ],
    pagination: true,
    paginationPageSize: 30,
    rowHeight: 30,
    headerHeight: 30,
  };

  return (
    <div className="panel panel-default panel-trades">
      <AgGridContainer className="ag-theme-alpine">
        <AgGridReact {...(!isMobile_ ? agGridProps : agGridPropsMobile)} />
      </AgGridContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...userSelector(state),
});

const TradeConfirmations = ({ user }) => {
  const [trades, setTrades] = useState(null);

  const Actions = {
    fetchMyTrades: async () => {
      if (user) {
        const trades = await MarketService.getMyTrades(user.id);
        setTrades(trades);
      }
    },
  };

  useEffect(() => {
    Actions.fetchMyTrades();
  }, []);

  return !trades ? (
    <Loading />
  ) : (
    <div className="page-trades">
      <DocumentMeta {...MY_TRADE_CONFIRMATION_META} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>
              <strong>My Transaction Confirmations</strong>
            </h1>
            <MyTradesTable
              myTrades={trades}
              emptyMessage={
                <div className="card-cta">
                  <h4>You do not have any transactions.</h4>
                  <p>To place an order, follow the link below.</p>
                  <Link to={propertiesUrl()} className="button orange-button">
                    Place Order
                  </Link>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, {})(TradeConfirmations);

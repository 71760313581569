/* eslint-disable */
import styled from 'styled-components';
import { Heading, SubHeading } from 'src/design/styleguide/Headings';
import {
  RegistrationWidgetAtf,
  RegistrationWidgetBottom,
} from 'src/components/landingPages/brickxAgriculture/RegistrationWidget';
import {
  breakpoint2xSmall,
  // breakpointLarge,
  breakpointSmall,
} from 'src/design/styleguide/common/breakpoints';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridUnit,
  // sideGutter,
} from 'src/design/styleguide/common/measurements';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import FinePrintParagraph from 'src/design/styleguide/FinePrintParagraph';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import Meta from 'src/components/meta/Meta';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import withState from 'src/decorators/withState';
import RotatingIcon from 'src/design/components/icon/RotatingIcon';

const AccentHeading = styled(Text).attrs({
  color: 'primary',
  fontWeight: 'regular',
})`
  ${breakpoint2xSmall(`
    margin-right: -30px;
  `)}
`;

const AccentHeadingBreak = styled.br`
  ${breakpoint2xSmall(`
    display: none;
  `)}
`;

const BigSection = (props) => (
  <ContentBlock
    as="section"
    topPadding="x-large"
    bottomPadding="x-large"
    {...props}
  />
);

const SectionHeading = ({ children, ...props }) => (
  <Spacing bottom="5x-large">
    <Heading {...props}>{children}</Heading>
  </Spacing>
);

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(`
    display: block;
  `)}
`;

const HeaderImageContainer = styled.div`
  order: 1;
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  ${breakpointSmall(`
    float: right;
    max-width: none;
    width: 50%;
    padding: 32px 0 0 64px;
  `)}
`;

const HeaderParagraph = styled(Paragraph)`
  ${breakpointSmall(`
    max-width: 500px;
  `)}
`;

// const WhyReasonsContainer = styled.div`
//   display: flex;
//   flex-wrap: nowrap;
//   justify-content: space-around;

//   ${breakpointLarge(`
//     justify-content: space-between;
//   `)}
// `;

// const IconTextContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   max-width: 350px;
//   padding: 0 ${sideGutter};

//   &:last-child {
//     margin-bottom: 0;
//   }
// `;

// const StyledIcon = styled(Icon)`
//   color: ${getThemeColor("primary")};
//   font-size: 50px;
// `;

// const IconText = ({ title, text, icon }) => (
//   <Spacing bottom="2x-large">
//     <IconTextContainer>
//       <StyledIcon type={icon} />
//       <Spacing bottom="x-small">
//         <SubHeading as="h3" fontSize="x-small">
//           {title}
//         </SubHeading>
//       </Spacing>
//       <Paragraph fontSize="2x-small" align="center">{text}</Paragraph>
//     </IconTextContainer>
//   </Spacing>
// );

const StyledRegistrationWidgetBottom = styled(RegistrationWidgetBottom)`
  max-width: 575px;
  margin-left: auto;
`;

const FaqsContainer = styled.ul`
  display: grid;
  grid-column-gap: ${gridUnit * 12}px;
  padding: 0;
  margin: 0;

  ${breakpointSmall(`
    grid-template-columns: 1fr 1fr;
  `)}
`;

const FaqContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${getThemeColor('onDarkSurfacePrimary')};
  }

  &:hover {
    cursor: pointer;
  }
`;

const FaqSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: ${(props) => (props.expanded ? '#4a4a4e' : '')};
  &:hover {
    background-color: #4a4a4e;
    cursor: pointer;
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexBox i{
    font-weight: 100;
  }
`;

const FaqSection = withState({
  mapPropsToInitialState: () => ({ expanded: false }),
  Component: ({ title, content, state: { expanded }, setState }) => (
    <Spacing bottom="x-large">
      <FaqSectionContainer expanded={expanded}>
        <SubHeading
          onClick={() => setState({ expanded: !expanded })}
          color="light"
          align="left"
        >
          <div className="flexBox">
            <div tyle={{ marginRight: '10px' }}>{title}</div>
            <RotatingIcon
              type="chevron_down"
              rotated={expanded} />
          </div>
        </SubHeading>
        <Spacing top="x-small">
          {expanded ? (
            <div>
              <br />
              <br/>
              {content}
            </div>
          ) : (
            ''
          )}
        </Spacing>
      </FaqSectionContainer>
    </Spacing>
  ),
});

const Faq = withState({
  mapPropsToInitialState: () => ({ expanded: false }),
  Component: ({ question, answer, state: { expanded }, setState }) => (
    <Spacing bottom="x-large">
      <FaqContainer
        expanded={expanded}
        onClick={() => setState({ expanded: !expanded })}
      >
        <div className="flexBox">
          <div style={{ marginRight: '10px' }}>{question}</div>
          <RotatingIcon
            type="chevron_down"
            rotated={expanded}
            data-test-reference="caret-icon"
          />
        </div>
        <Spacing top="x-small">
          {expanded ? (
            <div>
              <br />
              {answer}
            </div>
          ) : (
            ''
          )}
        </Spacing>
      </FaqContainer>
    </Spacing>
  ),
});

const QuestionText = ({ children, ...props }) => (
  <Heading
    as="h3"
    align="left"
    color="onDarkSurfacePrimary"
    fontSize="x-small"
    {...props}
  >
    {children}
  </Heading>
);

const AnswerText = ({ children, ...props }) => (
  <Paragraph color="light" fontSize="2x-small" fontWeight="medium" {...props}>
    {children}
  </Paragraph>
);

const HowReasonsContainer = styled.div``;

const HorizontalImageText = ({
  title,
  text,
  src,
  alt,
}) => (
  <Spacing bottom="3x-large">
    <VerticalIconTextContainer>
      <ImageContainer>
        <Image src={src} alt={alt}/>
      </ImageContainer>

      <Spacing left="large">
        <div>
          <Spacing bottom="small">
            <SubHeading align="center" as="h3" fontSize="x-small">{title}</SubHeading>
          </Spacing>

          <Paragraph>{text}</Paragraph>
        </div>
      </Spacing>
    </VerticalIconTextContainer>
  </Spacing>
);

const VerticalIconTextContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 70px;
  margin-bottom: 20px;
`;

const FaqList = [
  {
    s: 'What is BrickX Agri ?',
    list: [
      {
        q: 'What is BrickX and how does it work?',
        a: (
          <span>
            BrickX is an online marketplace for syndicated investments of
            various types of real estate, including farmland. We provide
            investors access to quality Australian farmland investments. <br />
            <br /> Through the BrickX platform, investors have a single platform
            to browse investments, review due diligence materials, and invest
            alongside cornerstone investors, all securely online. Properties are
            held in individual unit trust of 10,000 units.
          </span>
        ),
      },
      {
        q: 'Who is the BrickX team?',
        a: (
          <span>
            We are a team of professionals with experience in farmland, real
            estate, technology, funds management and finance. Our team and
            partners are cross-industry professionals’ years of experience
            across farmland investing, agriculture, and real estate in
            Australia. You can learn more about us{' '}
            <a href="/team">here</a>.
          </span>
        ),
      },
      {
        q:
          'Under what regulatory framework does BrickX offer these investments?',
        a:
          'BrickX Financial Services Limited, (ABN 67 616 118 088) operates under the Australian Financial Services License 494878.',
      },
      {
        q: 'How are the properties selected?',
        a: (
          <span>
            The BrickX property team review a wide range of agricultural
            investments from across the country. After due diligence is
            complete, selected properties which meet BrickX’s investment
            criteria are offered on the platform for members to invest. <br />
            <br /> The investment criteria are based on a factor which include
            such things as the property characteristics, independent valuations,
            municipality capital growth and yield returns. This information will
            be shared with the platform members via the additional disclosure
            documents.
          </span>
        ),
      },
      {
        q: 'Is there a minimum investment?',
        a: (
          <span>
            Yes, the minimum initial investment amount is $250. <br />
            <br /> To use Build My Own portfolio, the minimum initial deposit is
            $250 and the minimum investment is the lowest available price of one
            Brick.
          </span>
        ),
      },
      {
        q: 'What types of agriculture investments will I see on BrickX?',
        a: (
          <span>
            BrickX features direct investments in farmland. We typically target
            permanent and annual crops (e.g. stone and pome fruit, tree nuts,
            citrus), focusing on locations with the strong water availability,
            high-grade soils, and within the networks of preferred operators.
            However, we review properties across the country and across
            different agricultural industries in order to provide an array of
            opportunities to meet our clients' diverse preferences and needs.
          </span>
        ),
      },
      {
        q: 'What happens at the end of the lease term?',
        a:
          'At the conclusion of the lease term, the process to continue or liquidate will be followed as per the requirements stated in the addition disclosure documents of the particular property.',
      },
    ],
  },
  {
    s: 'Who Can Invest in BrickX ?',
    list: [
      {
        q: 'Who can invest?',
        a: (
          <span>
            In order to become a member and invest with BrickX you must be:{' '}
            <br />
            <br />{' '}
            <ul>
              {' '}
              <li>Aged over 18</li> <li>An Australia resident</li>
            </ul>{' '}
            <br />
            <br /> BrickX is authorised to provide our property investment
            platform to Australian residents only. You must live in Australia,
            at an address which can be verified and also hold an Australian Tax
            File Number. <br />
            <br /> You don't necessarily need to be a permanent resident, as
            long as you live here and fill out an Australian Tax return you are
            able to invest with BrickX. Unfortunately, we don't have the
            facility to open 'Children's' accounts just yet, but we may develop
            this functionality in the future.
          </span>
        ),
      },
      {
        q: 'Can I invest funds through an SMSF?',
        a:
          'Yes. You can invest with funds via an SMSF in both Smart Invest and Build My Own. For more information visit our SMSF page or contact us.',
      },
      {
        q: 'Will BrickX invest alongside investors?',
        a:
          'BrickX principals will be able to invest alongside investors in each offering. ',
      },
      {
        q: 'How do I make my first investment?',
        a: (
          <span>
            First, you'll need to set up your BrickX account. To begin, click
            the "Sign Up" button on the <a href="/">homepage</a>. After
            completing your investor profile, you'll be able to participate in
            an investment offering. To make your first investment, log in to
            your BrickX account, visit the Properties page, and then click the
            "View Investment" button. Following that, choose “Buy Bricks”, enter
            your number of bricks and then click "Confirm Purchase".
          </span>
        ),
      },
    ],
  },
  {
    s: "Benefits of BrickX Agri",
    list: [
      {
        q: "Benefits of using BrickX?",
        a:
          "BrickX makes it easy for investors of all sizes to invest in farmland without having to do their own due diligence and farm management. We utilize the BrickX platforms cutting edge technology combined our networks and experience and time to source only the best farmland investments and allow investors to invest in farmland with affordable investment bites.",
      },
      {
        q: "Does BrickX function as a Real Estate Investment Trust (REIT)?",
        a:
          "BrickX is not a Real Estate Investment Trust (REIT). BrickX properties are held in individual trusts. You select the individual farmland trust you invest in, providing you the choice of agricultural industry, location and specific property to invest in. You pick and choose asset by asset, retaining full control of where your money gets invested. You own units of the trust directly secured by the ownership of a particular farmland asset.",
      },
      {
        q: "What type of returns can I earn from investing in Bricks?",
        a: (
          <span>
            Brick Owners can potentially earn two types of returns: <br />
            <br /> <b>Capital returns:</b> <br />
            <br />{" "}
            <span>
              You have the potential to earn capital returns from the sale of
              your Bricks and/or the sale of the property. Capital returns are
              based on the difference between what you paid for the Brick and
              what you sell it for, which can either be positive or negative.
              Agriculture properties are independently valued once a year to
              help give guidance on Brick price movements.
            </span>{" "}
            <br />
            <br /> <b>Rental income:</b> <br />
            <br />{" "}
            <span>
              Each month/quarterly, earn your share of any net rental income
              from your Brick holdings. We call these "distributions", and they
              are paid directly into your Wallet.
            </span>{" "}
            <br />
            <br />{" "}
            <span>
              <b>Note</b>: You must own Bricks at 11:59pm on the last day of the
              month to be eligible for that month's distribution.
            </span>{" "}
          </span>
        ),
      },
    ],
  },
  {
    s: "Risk Management",
    list: [
      {
        q: "Are Agriculture investments on BrickX risky?",
        a:
          "Like all forms of investing, farmland involves risk. As an investor, you must be prepared for the potential loss of some or all of your investment. Please review the risks under the associated disclosure documents. If the potential loss will render you unable to survive financially, or you are unwilling to accept the potential loss of capital you have invested, we do not recommend you invest with us. At BrickX we understand the value of due diligence, and internal processors that control our risk. We are committed to offering only investments that have been approved by our experienced team and meet our stringent investment criteria.",
      },
      {
        q: "What are the risks and how are they mitigated?",
        a: (
          <span>
            The most common risks, like other real-estate investments are
            overpaying for land, or selecting the wrong operator-partners. We
            mitigate these risks by being conservative in acquisition process
            and ensuring value-based acquisitions, along with only partnering
            with highly experienced farmers and farmland investment managers.{" "}
            <br />
            <br /> At the same time, the allocation of substantial amounts of
            capital for investment in farmland and farming-related properties or
            significant competition for income-producing real estate may inflate
            the purchase prices for such assets. If we were to acquire
            properties in an inflated environment, it is possible that the value
            of the properties may decrease in value, perhaps significantly, to
            below the amount we paid. Other unforeseen factors, like extreme
            weather changes, government regulation and policy shifts or consumer
            demand shifts, could impede our ability to resell the property at a
            profit.{" "}
          </span>
        ),
      },
      {
        q: "Market Liquidity",
        a: (
          <span>
            Farmland assets financial performance is best measured over a medium
            to longer term holding period. Farmland is usually a buy and hold
            proposition, the underlying asset liquidity is tied to the sale of
            the asset. <br />
            <br />
            However, the BrickX marketplace offers investors in any property the
            opportunity to place their ownership of Bricks on the platform for
            sale at any time, choosing your price to offer your bricks for sale.{" "}
            <br />
            <br /> While the underlying assets may have a 5-7 year investment
            horizon, investments may be difficult to sell quickly. As a result,
            our ability to promptly sell properties in response to changing
            economic, financial, and investment conditions may be limited.
            Return of capital and realization of gains, if any, from an
            investment generally will occur upon disposition or refinancing of
            the underlying property. BrickX may be unable to realize investment
            objectives by sale, other disposition or refinancing at attractive
            prices within any given period of time or may otherwise be unable to
            complete any exit strategy.
          </span>
        ),
      },
      {
        q:
          "How liquid are my investments on BrickX? What if I need to sell early?",
        a: (
          <span>
            Every portfolio or property has its estimated liquidity horizon, at
            the end of which the portfolio or property will be sold on a
            best-efforts basis using experienced advisors, valuers and agents.{" "}
            <br />
            <br />
            However, you may offer to sell your Bricks at any time via BrickX
            marketplace, providing liquidity for you should you require to
            liquidate your investment.
          </span>
        ),
      },
      {
        q: "Financial Leveraging",
        a:
          "Leverage adds risk to an investment for the same reason it increases the potential reward. If a property cannot meet its future debt service obligations, investors will risk losing the farm that may be pledged to secure the obligations. An increase in the degree of leverage also could make an investment offering more vulnerable to a downturn in business or the economy generally.",
      },
      {
        q: "Agricultural Market Lease",
        a: (
          <span>
            Quality farmland in Australia has a near-zero vacancy rate and we
            believe that farm operators will continue to compete strongly for
            farmland, even during periods of variable profitability due to the
            scarcity of quality farmland available to rent. <br />
            <br />
            In particular, due to the relatively high fixed costs associated
            with farming operations (including equipment, labor and knowledge),
            farm operators often will rent additional acres of farmland when it
            becomes available in order to allocate their fixed costs over more
            acres, thereby making their farming operations more profitable on a
            per-acre basis. <br />
            <br />
            In our experience, leading farm operators will aggressively pursue
            quality rental opportunities in close proximity to their existing
            operations when they arise.
          </span>
        ),
      },
      {
        q: "Weather Conditions",
        a:
          "Annual crops and permanent crops are vulnerable to adverse weather conditions, including windstorms, floods, drought and temperature extremes, which are quite common but difficult to predict. Unfavourable growing conditions can reduce both crop size and crop quality. Seasonal factors, including supply and consumer demand, may also affect the crops grown by our tenants. In extreme cases, entire harvests may be lost in some geographic areas. Also, crops are vulnerable to crop disease, pests, and other contaminants. Damages to tenants' crops from crop disease and pests may vary in severity and effect, depending on the stage of production at the time of infection or infestation, the type of treatment applied and climatic conditions. The costs to control these infestations vary depending on the severity of the damage and the extent of the plantings affected. These infestations can increase the costs and decrease the revenues of our tenants. Tenants may also incur losses from products, fines, or litigation. It is difficult to predict the occurrence or severity of such, fines, or litigation as well as their impact upon our tenants.",
      },
      {
        q: "Who is responsible for decisions?",
        a:
          "Each investment offered is managed by BrickX. These rights include, but are not limited to, decisions regarding the sale and leasing and capital investment decisions. The rules regarding profit/loss distributions, minimum sale price, and financial reporting are always outlined upfront in the additional disclosure documents. ",
      },
      {
        q: "What diligence materials are provided?",
        a:
          "The investment team at BrickX completes due diligence on all investments prior to listing offerings on the platform. For each investment offering BrickX provides an investment summary and additional disclosure documents which should be reviewed before undertaking any investment.",
      },
      {
        q: "How do you perform due diligence on investment?",
        a:
          "BrickX’s team of staff and network of partners and are constantly reviewing purchase opportunities of farms in prime production regions across Australia. BrickX’s due diligence process includes confirming farm history of management, production, water rights, soil type, 3rd party valuations, environmental assessment, title searches, along with financial analysis and lessee counterparty vetting. ",
      },
    ],
  },
];

const BrickxAgriHome = (props) => (
  <div data-test-reference="rent-to-buy-home-page">
    <Meta
      title="Agricultural | BrickX"
      description="BrickX Agricultural!"
      keywords="brickx,agricultural,farm,land"
    />

    <ContentSection as="section">
      <ContentContainer topPadding="small" bottomPadding="small">
        <HeaderContent>
          <Spacing top={{ base: "2x-large", breakpointSmall: "none" }}>
            <HeaderImageContainer>
              <Image
                // src="static/img/illustration-rent-to-buy-waitlist.svg"
                src="https://images.unsplash.com/photo-1556194102-2cafb3dc216b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=976&q=80"
                alt="brickx agriculture"
              />
            </HeaderImageContainer>
          </Spacing>

          <Heading align="left">
            <AccentHeading>
              Introducing
              <AccentHeadingBreak /> BrickX Agriculture
            </AccentHeading>
            <br />
            Invest in Australian agriculture assets!
          </Heading>

          <Spacing top="normal">
            <HeaderParagraph>
              BrickX will be releasing new rural & farm investments in Q2 2021!
            </HeaderParagraph>
          </Spacing>

          <Spacing bottom="normal">
            <HeaderParagraph>
              See below Frequently Asked Questions, and <strong>register your interest</strong> to receive more information.
            </HeaderParagraph>
          </Spacing>

          <RegistrationWidgetAtf {...props} />
        </HeaderContent>
      </ContentContainer>
    </ContentSection>

    <BigSection
      id="why-agri"
      backgroundColor="lightgrey"
      width="small"
    >
      <SectionHeading>Why Agri?</SectionHeading>

      <HowReasonsContainer>
        <HorizontalImageText src="/static/img/brickx-agri/profits.svg" alt="icon hand pointing at house" title="Larger investment opportunities" text="BrickX reduces the financial commitment required to access agriculture property investments, with high purchase prices a common barrier to entry."/>
        <HorizontalImageText src="/static/img/brickx-agri/team.svg" alt="icon house with dollar sign" title="Specialist expertise & asset managers" text="Leverage the specialist skills, knowledge and network of BrickX’s experienced team of investment professionals, to source, acquire and manage the agriculture assets."/>
        <HorizontalImageText src="/static/img/brickx-agri/money.svg" alt="icon truck carrying stuff" title="Passive investment" text="BrickX allows investors to be entirely passive, with no management or operational responsibilities. BrickX provides reporting and distributions deposited directly into investor’s BrickX accounts."/>
        <HorizontalImageText src="/static/img/brickx-agri/finance.svg" alt="icon pie chart" title="Portfolio diversification" text="BrickX provides investors greater access to a broader range of quality agriculture investments, plus the flexibility to individually tailor investment allocations and portfolios."/>
        <HorizontalImageText src="/static/img/brickx-agri/save-money.svg" alt="icon house with a tick" title="Real liquidity" text="BrickX marketplace increases the liquidity for investments units over the life of your investments. You are free to sell your interests in an investment at any time."/>
      </HowReasonsContainer>

      <FinePrintParagraph>** T&Cs apply.</FinePrintParagraph>

    </BigSection>

    <BigSection id="brickx-agri-faqs" backgroundColor="charcoal" width="large">
      <SectionHeading color="light">FAQs</SectionHeading>
      {FaqList.map((sec, idx) => (
        <FaqSection
          key={idx}
          title={sec.s}
          content={
            <FaqsContainer>
              {sec.list.map((ele, seq) => (
                <Faq
                  key={seq}
                  question={<QuestionText>{ele.q}</QuestionText>}
                  answer={<AnswerText>{ele.a}</AnswerText>}
                />
              ))}
            </FaqsContainer>
          }
        />
      ))}
    </BigSection>

    <BigSection
      backgroundImage="https://www.gostudy.com.au/wp-content/uploads/2019/03/esperienza-in-farm-fattoria-australia.jpg"
      overlayOpacity="0"
    >
      <StyledRegistrationWidgetBottom {...props} />
    </BigSection>
  </div>
);

export default BrickxAgriHome;

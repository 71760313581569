/* eslint-disable */
import { Field, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { BsFillBriefcaseFill, BsPersonCircle } from 'react-icons/bs';
import RegistrationService from 'scripts/services/RegistrationService';
import { IconCheckbox } from 'src/bxDesign/form/IconCheckbox';
import AddressInput from 'src/design/components/addressInput/AddressInputV2';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import DateOfBirth from 'src/design/components/dateOfBirth/DateOfBirth';
import FileUpload from 'src/design/components/fileUpload/FileUpload';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import FormSelect from 'src/design/components/formSelect/FormSelect';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Colors from 'src/themes/brickx/colors';
import { themeForSmsfAccount } from 'src/themes/landingPageThemes';
import { cleanString } from 'src/utils/textutils';
import { validateTfn } from 'src/utils/validation';
import styled from 'styled-components';

export const SMSFTYPES = {
  INDIVIDUAL: 'individual',
  CORPORATE: 'company',
};

const Layout = styled.div`
  .formItemGroup {
    padding: 15px 0;
  }
  .formItemGroup {
    label {
      display: block;
      font-size: 22px;
      margin-bottom: 10px;
    }
    ul {
      li {
        font-size: 20px;
      }
    }
  }

  .formItemGroupInsideRow {
    margin: 15px 0;
    input[type='checkbox'] {
      margin-right: 10px;
    }
  }

  .noteSection {
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    padding: 20px 0;
    & > div {
      margin-right: 10px;
    }
  }

  h3,
  h4 {
    border-left: solid 4px var(--bx-color---blue-600);
    color: var(--bx-color---blue-600);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .error {
    text-transform: i;
  }

  p.question {
    margin-bottom: 20px;
    font-size: 22px;
  }

  table {
    width: 100%;
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      td {
        padding: 5px;
        min-width: 150px;
        font-size: 18px;
      }
    }
  }

  .note {
    color: #888;
  }
`;

const TwoButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

// Utils
const addressFormatter = (addressReport) => {
  return `${addressReport.companyAddressStreetNumber} ${addressReport.companyAddressStreetName} ${addressReport.companyAddressStreetType}, ${addressReport.companyAddressSuburb} ${addressReport.companyAddressState} ${addressReport.companyAddressPostcode}`;
};
const formatTrustName = ({
  userData,
  formData,
  trusteeType,
  abnLookupResult,
  acnLookupResult,
}) => {
  if (trusteeType === SMSFTYPES.INDIVIDUAL) {
    let listOfTrusteeNames = [
      `${userData.givenName} ${userData.middleName} ${userData.familyName}`,
    ];
    if (formData.numOfAdditionalIdChecks > 0) {
      for (let idx of [...Array(formData.numOfAdditionalIdChecks).keys()]) {
        if (!formData['add-' + (idx + 1) + '-is-trustee']) continue;
        listOfTrusteeNames.push(
          `${formData['add-' + (idx + 1) + '-firstname']} ${
            formData['add-' + (idx + 1) + '-middlename']
          } ${formData['add-' + (idx + 1) + '-lastname']}`
        );
      }
    }
    let trusteesPart =
      listOfTrusteeNames.length === 1
        ? listOfTrusteeNames[0]
        : _.take(listOfTrusteeNames, listOfTrusteeNames.length - 1).join(', ') +
          ' and ' +
          _.last(listOfTrusteeNames);
    let fullName = cleanString(
      trusteesPart +
        ' ATF ' +
        abnLookupResult.trustName.replace(/the trustee for /gi, '')
    );
    return fullName;
  }
  if (trusteeType === SMSFTYPES.CORPORATE) {
    const namePart = acnLookupResult.companyName;
    const fullName = cleanString(
      `${namePart} ATF ${abnLookupResult.trustName.replace(
        /the trustee for /gi,
        ''
      )}`
    );
    return fullName;
  }
};

/**
 * 
 * ABN result format:
 * entityTypeInd: "PRV"
    entityTypeText: "Australian Private Company"
    postcode: "3008"
    state: "VIC"
    status: "ACT"
    statusFrom: "2008-08-01"
    trustName: "Grant Thornton Audit Pty Ltd"

    ACN RESULT FORMAT:

    asicStatus: "REGD"
    asicType: "APTY"
    companyAddressPostcode: "3000"
    companyAddressState: "VIC"
    companyAddressStreetName: "COLLINS"
    companyAddressStreetNumber: "101"
    companyAddressStreetType: ""
    companyAddressSuburb: "MELBOURNE"
    companyName: "THE BRICK EXCHANGE PTY LTD"
    companyType: "P/L"
 * 
 */

const HelpLink = () => (
  <Spacing top="normal">
    <div>
      <a
        href="mailto:info@brickx.com?subject=Contact Us: SMSF and Trust Onboarding Questions"
        target="_blank"
      >
        Need Help?
      </a>
    </div>
  </Spacing>
);

const AdditionalIdCheckComponent = ({
  isTrust,
  trusteeType,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  const numOfAdditionalIdChecks = values.numOfAdditionalIdChecks;

  const requirementText = ({ isTrust, trusteeType }) => {
    if (!isTrust && trusteeType === SMSFTYPES.INDIVIDUAL)
      return [
        <li>
          Any individual trustee of the SMSF has a beneficial holding in the
          SMSF that is <strong>25% or greater</strong>
        </li>,
        <li>
          Any beneficiaries with a holding of <strong>25% or greater</strong> in
          the SMSF (other than those persons identified in point 1 above)
        </li>,
      ];
    if (!isTrust && trusteeType === SMSFTYPES.CORPORATE)
      return [
        <li>
          <strong>All Directors</strong> of the corporate trustee entity
        </li>,
        <li>
          Any shareholder with a shareholding of <strong>25% or greater</strong>{' '}
          in the Coporate Trustee entity
        </li>,
        <li>
          Any beneficiaries with a holding of <strong>25% or greater</strong> in
          the SMSF other than those listed in the above 2 points
        </li>,
      ];
    if (isTrust && trusteeType === SMSFTYPES.INDIVIDUAL)
      return [
        <li>
          Any individual trustee of the Trust has a beneficial holding in the
          Trust that is <strong>25% or greater</strong>.
        </li>,
        <li>
          Any beneficiaries with a holding of <strong>25% or greater</strong> in
          the Trust (other than those persons identified in point 1 above).
        </li>,
      ];
    if (isTrust && trusteeType === SMSFTYPES.CORPORATE)
      return [
        <li>All Directors of the corporate trustee entity</li>,
        <li>
          Any shareholder with a shareholding of <strong>25% or greater</strong>{' '}
          in the Coporate Trustee entity
        </li>,
        <li>
          Any beneficiaries with a holding of <strong>25% or greater</strong> in
          the Trust other than those listed in the above 2 points
        </li>,
      ];
  };

  return (
    <div className="formItemGroup">
      <h3>Additional ID Check</h3>
      <label>We need to verify identification for:</label>
      <p>
        <ul>{requirementText({ isTrust, trusteeType })}</ul>
      </p>

      <div className="formItemGroup">
        <label htmlFor="numOfAddTrustees">
          {trusteeType === SMSFTYPES.INDIVIDUAL
            ? 'How many additional trustees and/or beneficiaries?'
            : 'How many additional directors, shareholders and beneficiaries?'}
        </label>
        <FormSelect
          name="numOfAdditionalIdChecks"
          id="numOfAdditionalIdChecks"
          onChange={(v) =>
            setFieldValue('numOfAdditionalIdChecks', Number.parseInt(v))
          }
          value={numOfAdditionalIdChecks}
          defaultValue={numOfAdditionalIdChecks}
        >
          {[...Array(6).keys()].map((idx) => (
            <option value={idx}>{idx}</option>
          ))}
        </FormSelect>
      </div>

      <div className="formItemGroup">
        {[...Array(numOfAdditionalIdChecks).keys()].map((idx) => (
          <div key={idx} className="formItemGroup">
            <h4>Addition #{idx + 1}</h4>
            <div className="row">
              <div className="formItemGroupInsideRow col-md-12">
                <label htmlFor={`add-${idx + 1}-firstname`}>Given Name:</label>
                <input
                  className="large"
                  id={`add-${idx + 1}-firstname`}
                  placeholder=""
                  type="text"
                  value={values[`add-${idx + 1}-firstname`]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="formItemGroupInsideRow col-md-12">
                <label htmlFor={`add-${idx + 1}-middlename`}>
                  Middle Name: (Optional)
                </label>
                <input
                  className="large"
                  id={`add-${idx + 1}-middlename`}
                  placeholder=""
                  type="text"
                  value={values[`add-${idx + 1}-middlename`]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="formItemGroupInsideRow col-md-12">
                <label htmlFor={`add-${idx + 1}-lastname`}>Family Name:</label>
                <input
                  className="large"
                  id={`add-${idx + 1}-lastname`}
                  placeholder=""
                  type="text"
                  value={values[`add-${idx + 1}-lastname`]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="formItemGroupInsideRow col-md-12">
                <label htmlFor={`add-${idx + 1}-dateofbirth`}>
                  Date of Birth:
                </label>
                <DateOfBirth
                  {...{
                    day: {
                      value: values[`add-${idx + 1}-dob-day`],
                      onChange: (value) => {
                        setFieldValue(`add-${idx + 1}-dob-day`, value);
                      },
                    },
                    month: {
                      value: values[`add-${idx + 1}-dob-month`],
                      onChange: (value) => {
                        setFieldValue(`add-${idx + 1}-dob-month`, value);
                      },
                    },
                    year: {
                      value: values[`add-${idx + 1}-dob-year`],
                      onChange: (value) => {
                        setFieldValue(`add-${idx + 1}-dob-year`, value);
                      },
                    },
                  }}
                />
              </div>
              <div className="formItemGroupInsideRow col-md-12">
                <label htmlFor={`add-${idx + 1}-address`}>
                  Residential Address:
                </label>
                <AddressInput
                  value={values[`add-${idx + 1}-address`]}
                  onChange={(value) => {
                    setFieldValue(`add-${idx + 1}-address`, value);
                  }}
                  onSuggestionSelect={(harmonyAddressObject) => {
                    setFieldValue(
                      `add-${idx + 1}-address`,
                      harmonyAddressObject.fullAddress
                    );
                  }}
                />
              </div>
              <div className="formItemGroupInsideRow col-md-12">
                <label htmlFor={`add-${idx + 1}-address`}>Are they a:</label>

                {trusteeType === SMSFTYPES.INDIVIDUAL && (
                  <FormCheckbox
                    customColor={themeForSmsfAccount.colors.primary}
                    checked={values[`add-${idx + 1}-is-trustee`]}
                    onChange={(v) => {
                      setFieldValue(`add-${idx + 1}-is-trustee`, v);
                    }}
                  >
                    Trustee
                  </FormCheckbox>
                )}

                {trusteeType === SMSFTYPES.INDIVIDUAL && (
                  <FormCheckbox
                    customColor={themeForSmsfAccount.colors.primary}
                    checked={values[`add-${idx + 1}-is-bo`]}
                    onChange={(v) => {
                      setFieldValue(`add-${idx + 1}-is-bo`, v);
                      setFieldValue(`add-${idx + 1}-is-shareholder`, false);
                      setFieldValue(`add-${idx + 1}-is-director`, false);
                    }}
                  >
                    Beneficiary
                  </FormCheckbox>
                )}

                {trusteeType === SMSFTYPES.CORPORATE && (
                  <FormCheckbox
                    customColor={themeForSmsfAccount.colors.primary}
                    checked={values[`add-${idx + 1}-is-director`]}
                    onChange={(v) => {
                      setFieldValue(`add-${idx + 1}-is-director`, v);
                      // setFieldValue(`add-${idx + 1}-is-trustee`, false);
                      // setFieldValue(`add-${idx + 1}-is-shareholder`, false);
                      setFieldValue(`add-${idx + 1}-is-bo`, false);
                    }}
                  >
                    Director of the corporate trustee
                  </FormCheckbox>
                )}

                {trusteeType === SMSFTYPES.CORPORATE && (
                  <FormCheckbox
                    customColor={themeForSmsfAccount.colors.primary}
                    checked={values[`add-${idx + 1}-is-shareholder`]}
                    onChange={(v) => {
                      setFieldValue(`add-${idx + 1}-is-shareholder`, v);
                      // setFieldValue(`add-${idx + 1}-is-trustee`, false);
                      setFieldValue(`add-${idx + 1}-is-bo`, false);
                      // setFieldValue(`add-${idx + 1}-is-director`, false);
                    }}
                  >
                    Shareholder of the corporate trustee{' '}
                    <small>
                      (with a shareholding in the company of 25% or greater)
                    </small>
                  </FormCheckbox>
                )}

                {trusteeType === SMSFTYPES.CORPORATE && (
                  <FormCheckbox
                    customColor={themeForSmsfAccount.colors.primary}
                    checked={values[`add-${idx + 1}-is-bo`]}
                    onChange={(v) => {
                      setFieldValue(`add-${idx + 1}-is-bo`, v);
                      setFieldValue(`add-${idx + 1}-is-trustee`, false);
                      setFieldValue(`add-${idx + 1}-is-shareholder`, false);
                      setFieldValue(`add-${idx + 1}-is-director`, false);
                    }}
                  >
                    Beneficiary{' '}
                    <small>
                      (with an interest in the Trust of 25% or greater)
                    </small>
                  </FormCheckbox>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// ---------

const TrustDeedUpload = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="formItemGroup">
      <h3>Upload your trust deed</h3>
      <label>
        Please upload your Document(s)
        <ul>
          <li>Deed of Trust</li>
          <li>Any trust deed variations</li>
          <li>Certficate of incorporation</li>
        </ul>
        <br />
        <small>(PDF format only - upload in one file less than 10MB)</small>
      </label>
      <FileUpload
        ctaText="Select File"
        onChange={(file) => {
          if (file) {
            const r = new FileReader();
            r.readAsDataURL(file);
            r.onloadend = (e) => {
              const base64Str = e.target.result.split(',')[1];
              setFieldValue('trustDeedDocumentTitle', file.name);
              setFieldValue('trustDeedDocumentAsString', base64Str);
            };
          }
        }}
        accept=".pdf"
      />
    </div>
  );
};

/**
 *
 * SMSF and Trust Onboarding Form
 *
 * @param {*} param0
 * @returns
 */

const SmsfAndTrustOnboarding = ({
  userData,
  setSmsfDetails,
  onSubmit,
  identityVerificationFormErrors,
  isTrust,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrorMsg] = useState(null);
  const [trusteeType, settrusteeType] = useState(SMSFTYPES.INDIVIDUAL);
  const [formData, setFormData] = useState({});
  const [abnLookupResult, setAbnLookupResult] = useState({});
  const [acnLookupResult, setAcnLookupResult] = useState({});
  const [step, setStep] = useState(0); // 0. edit 1. confirm 2. confirmed
  // individual only
  const [isSoleTrustee, setisSoleTrustee] = useState(true);
  const [formValues, setFormValues] = useState(null);

  const validateAbnFormat = (abn) => `${abn}`.length === 11;
  const validateSMSFAbn = (payload) => payload.entityTypeInd === 'SMF';

  const onConfirm = async (formData) => {
    let abnResult = {};
    let acnResult = {};
    if (!validateAbnFormat(formData.abn)) {
      setErrorMsg('Please provide a valid ABN');
      return;
    }
    if (errMsg) {
      setErrorMsg(null);
    }
    setLoading(true);
    if (trusteeType === SMSFTYPES.CORPORATE) {
      const enteredAcn = formData.acn;
      acnResult = await RegistrationService.verifyAcn(enteredAcn).catch(
        (e) => console.log(e) // eslint-disable-line no-console
      );
      if (!acnResult) {
        setErrorMsg('The entered ACN can not be found.');
        setLoading(false);
        return;
      }
      setAcnLookupResult(acnResult);
    }
    const enteredAbn = formData.abn;
    abnResult = await RegistrationService.verifyAbn(enteredAbn).catch(
      (e) => console.log(e) // eslint-disable-line no-console
    );
    if (!abnResult) {
      setErrorMsg('ABN provided cannot be found, please check again.');
      setLoading(false);
      return;
    }
    if (!isTrust && !validateSMSFAbn(abnResult)) {
      setErrorMsg('This ABN is NOT associated with a SMSF entity.');
      setLoading(false);
      return;
    }
    setAbnLookupResult(abnResult);
    const finalFormData = {
      ...formData,
      isSoleTrustee,
      trusteeType,
    };
    setFormData(finalFormData);
    const trustName = formatTrustName({
      userData,
      formData,
      trusteeType,
      abnLookupResult: abnResult,
      acnLookupResult: acnResult,
    });
    const formatDob = (day, month, year) => {
      return moment(`${year}-${month}-${day}`).format('DD-MM-YYYY');
    };
    if (trusteeType === SMSFTYPES.INDIVIDUAL) {
      const smsfDetails = {
        tfn: formData.tfn,
        abn: formData.abn,
        trustName: trustName,
        trusteeType: trusteeType,
        trustDeedDocumentTitle: formData.trustDeedDocumentTitle,
        trustDeedDocumentAsString: formData.trustDeedDocumentAsString,
        listOfBeneficiaries: formData.numOfAdditionalIdChecks
          ? [...Array(formData.numOfAdditionalIdChecks).keys()].map((i) => ({
              firstName: formData[`add-${i + 1}-firstname`],
              middleName: formData[`add-${i + 1}-middlename`] || '',
              lastName: formData[`add-${i + 1}-lastname`],
              dateOfBirth: formatDob(
                formData[`add-${i + 1}-dob-day`],
                formData[`add-${i + 1}-dob-month`],
                formData[`add-${i + 1}-dob-year`]
              ),
              fullAddress: formData[`add-${i + 1}-address`],
              isTrustee: formData[`add-${i + 1}-is-trustee`] === true,
              isDirector: false,
              isShareholder: false,
              isBeneficiary: formData[`add-${i + 1}-is-bo`] === true,
            }))
          : [],
      };
      if (setSmsfDetails) setSmsfDetails(smsfDetails);
    }
    if (trusteeType === SMSFTYPES.CORPORATE) {
      const smsfDetails = {
        tfn: formData.tfn,
        abn: formData.abn,
        trustName: trustName,
        trusteeType: trusteeType,
        acn: formData.acn,
        trustDeedDocumentTitle: formData.trustDeedDocumentTitle,
        trustDeedDocumentAsString: formData.trustDeedDocumentAsString,
        street: cleanString(
          `${acnResult.companyAddressStreetNumber} ${acnResult.companyAddressStreetName} ${acnResult.companyAddressStreetType}`
        ),
        suburb: acnResult.companyAddressSuburb,
        state: acnResult.companyAddressState,
        isDirector: formData.isDirector,
        corpTrusteeName: acnResult.companyName,
        corpTrusteeAddress: addressFormatter(acnResult),
        listOfBeneficiaries: formData.numOfAdditionalIdChecks
          ? [...Array(formData.numOfAdditionalIdChecks).keys()].map((i) => ({
              firstName: formData[`add-${i + 1}-firstname`],
              middleName: formData[`add-${i + 1}-middlename`] || '',
              lastName: formData[`add-${i + 1}-lastname`],
              dateOfBirth: formatDob(
                formData[`add-${i + 1}-dob-day`],
                formData[`add-${i + 1}-dob-month`],
                formData[`add-${i + 1}-dob-year`]
              ),
              fullAddress: formData[`add-${i + 1}-address`],
              isTrustee: false,
              isDirector: formData[`add-${i + 1}-is-director`] === true,
              isShareholder: formData[`add-${i + 1}-is-shareholder`] === true,
              isBeneficiary: formData[`add-${i + 1}-is-bo`] === true,
            }))
          : [],
        postcode: acnResult.companyAddressPostcode,
      };
      if (setSmsfDetails) setSmsfDetails(smsfDetails);
    }
    setFormValues(formData);
    setStep(1);
    setLoading(false);
    window.scrollTo(0, 0);
  };

  if (step === 0) {
    return (
      <Layout>
        <Formik
          initialValues={
            formValues || {
              trustName: '',
              abn: '',
              acn: '',
              tfn: '',
              trustDeedDocumentTitle: '',
              trustDeedDocumentAsString: '',
              numOfAddTrustees: 1,
              numOfAdditionalIdChecks: 0,
              isConfirmed: false,
              isDirector: false,
            }
          }
          onSubmit={async (values) => {
            onConfirm(values);
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;
            const isFormValid =
              values.abn !== '' &&
              values.trustDeedDocumentTitle !== '' &&
              values.trustDeedDocumentAsString !== '' &&
              !errors.tfn &&
              (!isSoleTrustee
                ? values.numOfAddTrustees > 0 &&
                  _.every(
                    [...Array(values.numOfAddTrustees).keys()].map(
                      (i) =>
                        values[`addTrustee${i + 1}`] &&
                        values[`addTrustee${i + 1}`] !== ''
                    )
                  )
                : true) &&
              (trusteeType === SMSFTYPES.CORPORATE
                ? values.acn !== '' && values.isConfirmed
                : true) &&
              (values.numOfAdditionalIdChecks > 0
                ? _.every(
                    [...Array(values.numOfAdditionalIdChecks).keys()].map(
                      (i) =>
                        values[`add-${i + 1}-firstname`] &&
                        values[`add-${i + 1}-firstname`] !== '' &&
                        values[`add-${i + 1}-lastname`] &&
                        values[`add-${i + 1}-lastname`] !== '' &&
                        values[`add-${i + 1}-address`] &&
                        values[`add-${i + 1}-address`] !== '' &&
                        values[`add-${i + 1}-dob-day`] &&
                        values[`add-${i + 1}-dob-day`] !== '' &&
                        values[`add-${i + 1}-dob-month`] &&
                        values[`add-${i + 1}-dob-month`] !== '' &&
                        values[`add-${i + 1}-dob-year`] &&
                        values[`add-${i + 1}-dob-year`] !== ''
                    )
                  )
                : true);
            return (
              <form onSubmit={handleSubmit}>
                {/* <div className="formItemGroup">
                  <label htmlFor="tfn">
                    Full name of trust:
                    <br />
                    <span style={{ fontSize: '1.5rem' }}>
                      e.g. "John SMSF" or "John & Sarah as Trustees for John
                      Family Trust"
                    </span>
                  </label>
                  <Field className="large" type="text" name="trustName" />
                  {errors.trustName && touched.trustName && (
                    <Spacing bottom="normal" top="normal">
                      <InfoBox>{errors.trustName}</InfoBox>
                    </Spacing>
                  )}
                </div> */}

                <div className="formItemGroup">
                  <label htmlFor="tfn">
                    Australian <strong>Tax File Number</strong> of your{' '}
                    {isTrust ? 'Trust' : 'SMSF'}:
                  </label>
                  <Field
                    className="large"
                    type="text"
                    name="tfn"
                    validate={validateTfn}
                  />
                  {errors.tfn && touched.tfn && (
                    <Spacing bottom="normal" top="normal">
                      <InfoBox>{errors.tfn}</InfoBox>
                    </Spacing>
                  )}
                </div>

                <div className="formItemGroup">
                  <label htmlFor="abn">
                    Please enter the ABN of your {isTrust ? 'Trust' : 'SMSF'}:
                  </label>
                  <input
                    className="large"
                    id="abn"
                    placeholder="Enter a valid Australian Business Number (ABN)"
                    type="number"
                    value={values.abn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="formItemGroup">
                  <label>Is the trustee an individual(s) or a company?</label>
                  <div className="checkbox-group">
                    <IconCheckbox
                      colorSuite="blue"
                      icon={<BsPersonCircle />}
                      text={SMSFTYPES.INDIVIDUAL}
                      isSelected={trusteeType === SMSFTYPES.INDIVIDUAL}
                      onClick={() => settrusteeType(SMSFTYPES.INDIVIDUAL)}
                    />
                    <IconCheckbox
                      colorSuite="blue"
                      icon={<BsFillBriefcaseFill />}
                      text={SMSFTYPES.CORPORATE}
                      isSelected={trusteeType === SMSFTYPES.CORPORATE}
                      onClick={() => settrusteeType(SMSFTYPES.CORPORATE)}
                    />
                  </div>
                </div>

                {/* === CORPORATE FLOW === */}
                {trusteeType === SMSFTYPES.CORPORATE && (
                  <Fragment>
                    <div className="formItemGroup">
                      <FormCheckbox
                        customColor={Colors.blue500}
                        checked={values.isDirector}
                        onChange={(v) => setFieldValue('isDirector', v)}
                      >
                        Are you director of the corporate trustee?
                      </FormCheckbox>
                    </div>
                    <div className="formItemGroup">
                      <label htmlFor="acn">Company ACN:</label>
                      <input
                        className="large"
                        id="acn"
                        placeholder="Enter a valid Australian Company Number (ACN)"
                        type="number"
                        value={values.acn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="formItemGroup">
                      <FormCheckbox
                        customColor={Colors.blue500}
                        checked={values.isConfirmed}
                        onChange={(v) => setFieldValue('isConfirmed', v)}
                      >
                        I confirm all directors, shareholders and beneficiaries
                        are Australian resident tax payers.
                      </FormCheckbox>
                    </div>
                    {!values.isConfirmed && (
                      <Spacing bottom="normal" top="normal">
                        <div className="note">
                          NOTE: The Trustee Company, Shareholders and Beneficial
                          Owners must all be tax resident in Australia to invest
                          with BrickX
                        </div>
                      </Spacing>
                    )}
                  </Fragment>
                )}

                {/* Additional ID Checks */}

                <AdditionalIdCheckComponent
                  isTrust={isTrust}
                  trusteeType={trusteeType}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TrustDeedUpload
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <div className="formItemGroup">
                  Note: Pressing NEXT means that you agree that BrickX may
                  perform the relevant regulatory searches of your investment
                  entity.
                </div>

                <div className="formItemGroup">
                  {errMsg && (
                    <Spacing bottom="normal">
                      <InfoBox critical>{errMsg}</InfoBox>
                    </Spacing>
                  )}
                  {isLoading && (
                    <Spacing bottom="normal">
                      <InfoBox>We're verifying your details...</InfoBox>
                    </Spacing>
                  )}
                  <PrimaryButton
                    disabled={!isFormValid}
                    color={PrimaryButton.colors.BLUE}
                    fullWidth
                    arrow
                  >
                    NEXT
                  </PrimaryButton>
                </div>
              </form>
            );
          }}
        </Formik>
      </Layout>
    );
  } else if (step === 1) {
    return (
      <Layout>
        <p>
          <strong>Please Confirm your Details:</strong>
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td>Trust Name</td>
              <td>
                <strong>
                  {formatTrustName({
                    userData,
                    formData,
                    trusteeType,
                    abnLookupResult,
                    acnLookupResult,
                  })}
                </strong>
              </td>
            </tr>
            <tr>
              <td>Trustee Type</td>
              <td>
                <strong>{trusteeType}</strong>
              </td>
            </tr>
            <tr>
              <td>ABN</td>
              <td>
                <strong>{formData.abn}</strong>
              </td>
            </tr>
            <tr>
              <td>ABN Entity Name</td>
              <td>
                <strong>{abnLookupResult.trustName}</strong>
              </td>
            </tr>

            {trusteeType === SMSFTYPES.CORPORATE && (
              <Fragment>
                <tr>
                  <td>ACN</td>
                  <td>
                    <strong>{formData.acn}</strong>
                  </td>
                </tr>
                <tr>
                  <td>ACN Entity Name</td>
                  <td>
                    <strong>{acnLookupResult.companyName}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Company Registered Address</td>
                  <td>
                    <strong>{addressFormatter(acnLookupResult)}</strong>
                  </td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </table>
        <br />
        <TwoButtonGroup>
          <PrimaryButton onClick={() => setStep(0)}>Not correct?</PrimaryButton>
          <PrimaryButton
            onClick={() => {
              onSubmit();
            }}
            color={PrimaryButton.colors.BLUE}
            arrow
          >
            CONFIRM
          </PrimaryButton>
        </TwoButtonGroup>
        <HelpLink />
      </Layout>
    );
  }
};

export default SmsfAndTrustOnboarding;

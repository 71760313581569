import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popover from 'scripts/components/shared/Popover';
import classNames from 'classnames';
import ReactPlaceholder from 'react-placeholder';
import _ from 'lodash';
import {
  account as accountPropType,
  property as propertyPropType,
  orderAggregated as orderPropType,
} from 'scripts/constants/PropTypes';
import RecentlySold from 'scripts/components/property/RecentlySold';
import BrickValuationBar from 'scripts/components/property/trade/BrickValuationBar';
import OrderBookTableBodyPlaceholder from 'scripts/components/placeholders/trade/OrderBookTableBodyPlaceholder';
import { scrollTo } from 'scripts/utilities/helpers';
import {
  sendRecentTransactionsTabClickEvent,
  sendBricksAvailableTabClickEvent,
  sendSeeLessAvailableBricksLinkClickEvent,
  sendSeeMoreAvailableBricksLinkClickEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';
import Constants from 'scripts/constants/Constants';
import Configuration from 'scripts/constants/Configuration';
import {
  brickPrice,
  number,
  percentDecimalWithSign,
} from 'scripts/utilities/formatters';
import { renderCode } from 'src/settings/properties';

export default class OrderBookTrade extends Component {
  static propTypes = {
    account: accountPropType,
    property: propertyPropType.isRequired,
    tradeType: PropTypes.string.isRequired,
    ordersSell: PropTypes.arrayOf(orderPropType),
    ordersBuy: PropTypes.arrayOf(orderPropType),
  };

  state = {
    showAllOrders: false,
    tabsArr: [true, false],
  };

  render() {
    const { account, property, tradeType, ordersSell, ordersBuy } = this.props;

    const isIndependentValued = !property.financials.isIndependentValued;
    const brickValueText = isIndependentValued
      ? 'Initial Brick Price'
      : 'Brick Valuation';
    const discountPopoverText = `The percent difference between the Brick Price and the ${brickValueText}. Buying below the ${brickValueText} is considered Below Valuation, and is represented by -.  Buying above the ${brickValueText} is considered Above Valuation, and is represented by +.`;

    const numOfSellOrders = (ordersSell && ordersSell.length) || 0;
    const numOfBuyOrders = (ordersBuy && ordersBuy.length) || 0;
    const numOfOrders = Math.max(numOfSellOrders, numOfBuyOrders);

    const noBricksAvailable = ordersSell && ordersBuy && numOfOrders === 0;
    return (
      <div className="table-orders">
        <div>
          <BrickValuationBar
            property={property}
            showIcon={Constants.TRUE}
            showValuationDate={Constants.TRUE}
          />
          <div className="nav-tabs">
            <div
              className={classNames('tab order-book-tab', { active: this.state.tabsArr[0] })}
              onClick={() => this._onTabClick(0)}
            >
              <b>Order Book</b>
            </div>
            <div
              className={classNames('tab recently-sold-tab', {
                active: this.state.tabsArr[1],
              })}
              onClick={() => this._onTabClick(1)}
            >
              <b>Recent Trades</b>
            </div>
          </div>
          <div
            className={classNames('orders-trades-tab', {
              active: this.state.tabsArr[0],
            })}
          >
            <table className="table table-bordered table-striped table-hover bricks-available">
              <thead>
                <tr>
                  <th>Qty</th>
                  <th style={{ color: 'green' }}>↑Bids</th>
                  <th colSpan="2" className="text-center bricks-discount-col">
                    -/+ &nbsp;
                    <Popover placement="top" content={discountPopoverText} />
                  </th>
                  <th style={{ color: 'red' }}>↓Asks</th>
                  <th>Qty</th>
                </tr>
              </thead>

              {noBricksAvailable ? (
                <tbody>
                  <tr>
                    <td className="text-center no-bricks" colSpan="6">
                      Sorry, there are 0 Bricks currently available in {renderCode(property.propertyCode)}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <ReactPlaceholder
                  ready={!!(ordersSell && ordersBuy && account)}
                  customPlaceholder={<OrderBookTableBodyPlaceholder />}
                >
                  <tbody>
                    {(() => {
                      const rows = [];
                      for (let index = 0; index < Math.min(numOfOrders, (this.state.showAllOrders ? Configuration.MAXIMUM_NUM_TRANSACTIONS_SHOWN : Configuration.DEFAULT_NUM_TRANSACTIONS_SHOWN)); index++) {
                        const sellOrder = ordersSell[index] || {};
                        const buyOrder = ordersBuy[index] || {};

                        rows.push(
                          <tr key={index}>
                            {buyOrder.quantity ? (
                              <Fragment>
                                <td className={`text-left buy-order-${index}-quantity ${!index && 'bg-success'}`}>
                                  {buyOrder.quantity::number()}
                                </td>
                                <td className={`text-left buy-order-${index}-brick-price ${!index && 'bg-success'}`}>
                                  <span style={{ color: 'green' }}>{buyOrder.price::brickPrice()}</span>
                                </td>
                                <td className={`text-left buy-order-${index}-discount ${!index && 'bg-success'} bricks-discount-col`}>
                                  {((buyOrder.price - property.financials.brickValue) / property.financials.brickValue)::percentDecimalWithSign()}
                                </td>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <td />
                                <td />
                                <td className="bricks-discount-col"/>
                              </Fragment>
                            )}
                            {sellOrder.quantity ? (
                              <Fragment>
                                <td className={`text-left sell-order-${index}-discount ${!index && 'bg-danger'} bricks-discount-col`}>
                                  {((sellOrder.price - property.financials.brickValue) / property.financials.brickValue)::percentDecimalWithSign()}
                                </td>
                                <td className={`text-left sell-order-${index}-brick-price ${!index && 'bg-danger'}`}>
                                  <span style={{ color: 'red' }}>{sellOrder.price::brickPrice()}</span>
                                </td>
                                <td className={`text-left sell-order-${index}-quantity ${!index && 'bg-danger'}`}>
                                  {sellOrder.quantity::number()}
                                </td>
                              </Fragment>
                              ) : (
                              <Fragment>
                                <td className="bricks-discount-col"/>
                                <td />
                                <td />
                              </Fragment>
                            )}
                          </tr>
                        );
                      }
                      return rows;
                    })()}
                    {numOfOrders > Configuration.DEFAULT_NUM_TRANSACTIONS_SHOWN && (
                      <tr key={numOfOrders + 1}>
                        <td
                          className="show-button"
                          colSpan="6"
                          onClick={this._expand}
                        >
                          {this.state.showAllOrders ? '- Show Less' : '+ Show More'}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </ReactPlaceholder>
              )}
            </table>
          </div>

          <div
            className={classNames('orders-trades-tab', {
              active: this.state.tabsArr[1],
            })}
          >
            <RecentlySold
              property={property}
              isPreOrderProperty={false}
              tradeType={tradeType}
              account={account}
            />
          </div>
        </div>
      </div>
    );
  }

  _resetTabs = () => {
    this.setState({
      tabsArr: [false, false],
    });
  };

  _onTabClick = (index) => {
    this._resetTabs();
    const { tabsArr } = this.state;
    this.setState({
      tabsArr: _.map(tabsArr, function (n, i) {
        return i === index ? true : false;
      }),
    });

    const { account, property, tradeType } = this.props;
    if (index === 0) {
      sendBricksAvailableTabClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    } else if (index === 1) {
      sendRecentTransactionsTabClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    }
  };

  _expand = (e) => {
    e.preventDefault();
    const { account, property, tradeType } = this.props;
    if (this.state.showAllOrders) {
      scrollTo('.property-nav');
      sendSeeLessAvailableBricksLinkClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    } else {
      sendSeeMoreAvailableBricksLinkClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    }
    this.setState({
      showAllOrders: !this.state.showAllOrders,
    });
  };
}

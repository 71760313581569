import styled from 'styled-components';
import { breakpointLarge } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { smsfUrl } from 'src/utils/pageUrls';
import Paragraph from 'src/design/styleguide/Paragraph';
import PoppingCard from 'src/design/components/poppingCard/PoppingCard';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { themeForBrickXMinorLandingPage } from 'src/themes/landingPageThemes';
import Colors from 'src/themes/brickx/colors';
// import { getBrandColor } from 'src/themes/themeUtils';

const PromoPoppingCard = styled(PoppingCard)`
  padding: ${gridUnit * 7}px;
  ${(p) => p.borderColor && `border-top-color: ${p.borderColor};`}
  ${breakpointLarge(`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `)}
`;

const CtaButton = styled(SecondaryButton).attrs({
  size: SecondaryButton.sizes.EXTRA_SMALL,
})`
  margin-top: 24px;
  min-width: 135px;

  ${breakpointLarge(`
    margin-top: 0;
    margin-left: 28px;
    white-space: nowrap;
  `)}
`;

// const Tag = styled.span`
//   font-size: 1.6rem;
//   font-weight: bold;
//   background-color: var(--bx-color---primary);
//   color: #fff;
//   border-radius: 6px;
//   padding: 2px 6px;
// `;

const AboveTheFoldPromo = () => {
  const promoCardList = [
    // {
    //   title: (
    //     <span>
    //       <Tag>NEW</Tag> Pre-orders in our{' '}
    //       <strong>Disability Housing property</strong> now open!{' '}
    //       <Styledlink href="/investments/KAN01" target="_blank">
    //         pre-order bricks now
    //       </Styledlink>
    //     </span>
    //   ),
    //   link: '',
    //   withBorderTopFeature: true,
    //   isExternal: true,
    // },
    // {
    //   title: (
    //     <span>
    //       <Tag>PROMO</Tag> No transaction fees when you buy or sell Bricks until
    //       31 December.{' '}
    //       <Styledlink href="/terms/fees-promo-2022" target="_blank">
    //         T&Cs apply
    //       </Styledlink>
    //     </span>
    //   ),
    //   link: '',
    //   withBorderTopFeature: true,
    //   isExternal: true,
    // },
    {
      title: (
        <span>
          Introducing <strong>BrickX for Minors</strong>
        </span>
      ),
      link: '/minors',
      trackingDescription: 'minors cta',
      testRef: 'brickx-minors-cta',
      withBorderTopFeature: true,
      color: themeForBrickXMinorLandingPage.colors.primary,
      isExternal: true,
    },
    {
      title: (
        <span>
          Diversify your <strong>SMSF or Trust</strong> portfolio with BrickX
        </span>
      ),
      link: smsfUrl(),
      trackingDescription: 'above the fold smsf cta',
      testRef: 'above-the-fold-smsf-cta',
      withBorderTopFeature: true,
      color: Colors.blue600,
    },
  ];
  return (
    <Spacing top="normal">
      <div data-test-reference="above-the-fold-promo">
        {promoCardList.map((ele, idx) => (
          <Spacing top="normal" key={idx}>
            <PromoPoppingCard
              withBorderTopFeature={ele.withBorderTopFeature}
              borderColor={ele.color}
            >
              <Paragraph>{ele.title}</Paragraph>
              {ele.link && (
                <CtaButton
                  link={ele.link}
                  trackingDescription={ele.trackingDescription}
                  testRef={ele.testRef}
                  {...(ele.isExternal && { target: '_blank' })}
                >
                  Learn More
                </CtaButton>
              )}
            </PromoPoppingCard>
          </Spacing>
        ))}
      </div>
    </Spacing>
  );
};

export default AboveTheFoldPromo;

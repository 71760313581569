import request from 'scripts/services/request';
import {
  paidAndNotDistributedPropertydistributions as paidAndNotDistributedPropertydistributionsUrl,
  publishedProperties as publishedPropertiesUrl,
  financials as financialsUrl,
  paidPropertydistributions as paidPropertydistributionsUrl,
  monthlyUpdates as monthlyUpdatesUrl,
  latestUpdates as latestUpdatesUrl,
  propertyViewsUrl,
  smartInvestProperties as smartInvestPropertiesUrl,
  distributions,
  brickValueAndPrice,
  marketTrackerPath,
} from 'scripts/services/api';
import { withCredentials } from 'scripts/services/helpers';

class PropertyService {
  getPublishedProperties() {
    return request(publishedPropertiesUrl(), {
      ...withCredentials,
    });
  }

  getAllFinancials() {
    return request(financialsUrl());
  }

  getMonthlyDistributions(propertyCode) {
    return request(paidAndNotDistributedPropertydistributionsUrl(propertyCode));
  }

  getMonthlyPaidDistributions(propertyCode) {
    return request(paidPropertydistributionsUrl(propertyCode));
  }

  getAllMonthlyDistributions() {
    return request(distributions());
  }

  getMonthlyUpdates(propertyCode) {
    return request(monthlyUpdatesUrl(propertyCode));
  }

  getLatestMonthlyUpdates(propertyCode) {
    return request(latestUpdatesUrl(propertyCode));
  }

  getNumberOfViews(propertyCode) {
    return request(propertyViewsUrl(propertyCode));
  }

  getSmartInvestProperties() {
    return request(smartInvestPropertiesUrl());
  }

  getHistoricalPriceAndValue(propertyCode) {
    return request(brickValueAndPrice(propertyCode));
  }

  getMarketTrackerData(propertyCode) {
    return request(marketTrackerPath(propertyCode));
  }
}

export default new PropertyService();

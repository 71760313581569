import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  order as orderPropType,
  property as propertyPropType,
  account as accountPropType,
  editForm as editFormPropType,
} from 'scripts/constants/PropTypes';
import { LOWEST_BRICK_PRICE_POPOVER_TEXT } from 'src/components/dashboard/portfolio/helpText';
import { timeToNow } from 'src/utils/time';
import { longDate, shortReference } from 'src/utils/formats';
import { brick, dollarDecimal, percent } from 'scripts/utilities/formatters';
import {
  getTotalQuantityOfBricks,
  getTotalSellOrders,
  getAmountYouReceiveOnSale,
  getPropertyFromOrder,
} from 'src/utils/orders';
import {
  validateTradeProposalQuantity,
  validateTradeProposalPrice,
} from 'scripts/utilities/tradeHelper';
import Loading from 'src/components/loading/Loading';
import Popover from 'scripts/components/shared/Popover';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import EditButton from 'src/components/dashboard/pendingOrders/EditButton';
import EditExitButton from 'src/components/dashboard/pendingOrders/EditExitButton';
import EditConfirmButton from 'src/components/dashboard/pendingOrders/EditConfirmButton';
import CancelButton from 'src/components/dashboard/pendingOrders/CancelButton';
import { NoSellOrders } from 'src/components/dashboard/pendingOrders/NoOrders';
import {
  BodyTableRow,
  ColumnHeading,
  FooterTableData,
  SecondColumn,
  StickyHeader,
  Table,
  TableData,
} from 'src/design/components/table/Table';
import { renderCode } from 'src/settings/properties';
import DynamicNumber from 'react-dynamic-number';
import Numbers from 'scripts/constants/Numbers';
import Constants from 'scripts/constants/Constants';
import { LOWEST_SELL_PRICE_PERCENTAGE } from 'src/settings/trading';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default class SellOrderPanel extends Component {
  static propTypes = {
    sellOrders: PropTypes.arrayOf(orderPropType),
    properties: PropTypes.arrayOf(propertyPropType),
    account: accountPropType.isRequired,
    fees: PropTypes.number,
    editForm: editFormPropType,
  };

  render() {
    const { sellOrders, properties, fees, editForm } = this.props;
    const today = new Date();

    return !properties.length ? (
      <Loading />
    ) : (
      <WidgetPanel title="Pending Sell Orders" testRef="sell-orders-panel">
        {sellOrders.length ? (
          <Table withStickyHeader centeredRows>
            <thead>
              <tr>
                <StickyHeader>
                  <ColumnHeading>Property</ColumnHeading>
                </StickyHeader>
                <SecondColumn>
                  <ColumnHeading>
                    Date
                    <br />
                    Placed
                  </ColumnHeading>
                </SecondColumn>
                <ColumnHeading>
                  Time
                  <br />
                  Listed
                </ColumnHeading>
                <ColumnHeading>
                  Quantity
                  <br />
                  of Bricks
                </ColumnHeading>
                <ColumnHeading>
                  Brick
                  <br />
                  Sell Price
                </ColumnHeading>
                <ColumnHeading>
                  You
                  <br />
                  Receive
                  <Popover
                    placement="top"
                    content={`The total amount you will receive from this sell order, after subtracting the ${fees}% selling fee.`}
                    data-test-reference="you-receive-amount-popover"
                  />
                </ColumnHeading>
                <ColumnHeading>
                  Best
                  <br />
                  Ask
                  <Popover
                    placement="top"
                    content={LOWEST_BRICK_PRICE_POPOVER_TEXT}
                    testRef="lowest-available-brick-price-popover"
                  />
                </ColumnHeading>
                <ColumnHeading>Reference</ColumnHeading>
                <ColumnHeading />
              </tr>
            </thead>
            <tbody>
              {sellOrders.map((order) =>
                order.orderId === editForm.orderId ? (
                  <Fragment key={`edit-${order.orderId}`}>
                    <BodyTableRow key={order.orderId}>
                      <StickyHeader>
                        <TableData>
                          {renderCode(editForm.propertyCode)}
                        </TableData>
                      </StickyHeader>
                      <SecondColumn>
                        <TableData>
                          <b style={{ color: '#ff8282' }}>{longDate(today)}</b>
                        </TableData>
                      </SecondColumn>
                      <TableData>
                        <b style={{ color: '#ff8282' }}>{Numbers.ZERO}</b>
                      </TableData>
                      <TableData>
                        <DynamicNumber
                          name="edit_quantity"
                          value={editForm.quantity}
                          integer={Numbers.TEN}
                          fraction={Numbers.ZERO}
                          positive={Constants.TRUE}
                          thousand={Constants.FALSE}
                          placeholder="0"
                          onChange={this._onQuantityChange}
                          style={{ backgroundColor: 'white' }}
                        />
                      </TableData>
                      <TableData>
                        <div className="dollar-input">
                          <DynamicNumber
                            name="edit_price"
                            value={editForm.price}
                            separator={'.'}
                            integer={Numbers.TEN}
                            fraction={Numbers.TWO}
                            positive={Constants.TRUE}
                            thousand={Constants.TRUE}
                            placeholder="0.00"
                            onChange={this._onPriceChange}
                            style={{ backgroundColor: 'white' }}
                          />
                        </div>
                      </TableData>
                      <TableData>
                        <b style={{ color: '#ff8282' }}>
                          {dollarDecimal(editForm.total)}
                        </b>
                      </TableData>
                      <TableData>
                        {dollarDecimal(
                          getPropertyFromOrder(properties, order).financials
                            .lowestAvailableBrickPrice
                        )}
                      </TableData>
                      <TableData>-</TableData>
                      <TableData>
                        <ButtonContainer>
                          <EditConfirmButton
                            order={order}
                            orderTypeText="Sell Order"
                            editForm={editForm}
                            handleQuantityChange={this._handleQuantityChange}
                            handlePriceChange={this._handlePriceChange}
                            fees={fees}
                            className="leftButton"
                            disable={editForm.hasError}
                          />
                          <EditExitButton
                            editForm={editForm}
                            className="rightButton"
                          />
                        </ButtonContainer>
                      </TableData>
                    </BodyTableRow>
                    {editForm.hasError && (
                      <BodyTableRow>
                        <TableData colSpan="100%" className="text-danger">
                          <b>{editForm.errorMsg}</b>
                        </TableData>
                      </BodyTableRow>
                    )}
                  </Fragment>
                ) : (
                  <BodyTableRow key={order.orderId} testRef="row">
                    <StickyHeader>
                      <TableData testRef="property-code-cell">
                        {renderCode(order.propertyCode)}
                      </TableData>
                    </StickyHeader>
                    <SecondColumn>
                      <TableData testRef="date-cell">
                        {longDate(order.date)}
                      </TableData>
                    </SecondColumn>
                    <TableData testRef="days-listed-cell">
                      {timeToNow(order.date)}
                    </TableData>
                    <TableData testRef="quantity-of-bricks-cell">
                      {order.quantity}
                    </TableData>
                    <TableData testRef="brick-sell-price-cell">
                      {dollarDecimal(order.price)}
                    </TableData>
                    <TableData testRef="you-receive-amount-cell">
                      {dollarDecimal(getAmountYouReceiveOnSale(order))}
                    </TableData>
                    <TableData testRef="lowest-available-brick-price-cell">
                      {dollarDecimal(
                        getPropertyFromOrder(properties, order).financials
                          .lowestAvailableBrickPrice
                      )}
                    </TableData>
                    <TableData testRef="reference-cell">
                      {shortReference(order.orderId)}
                    </TableData>
                    <TableData>
                      <ButtonContainer>
                        <EditButton
                          order={order}
                          orderType="sell"
                          editForm={editForm}
                          className="leftButton"
                        />
                        <CancelButton
                          order={order}
                          orderTypeText="Sell"
                          className="rightButton"
                        />
                      </ButtonContainer>
                    </TableData>
                  </BodyTableRow>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <StickyHeader>
                  <FooterTableData>Total</FooterTableData>
                </StickyHeader>
                <SecondColumn>
                  <FooterTableData>-</FooterTableData>
                </SecondColumn>
                <FooterTableData>-</FooterTableData>
                <FooterTableData testRef="total-quantity-of-bricks-cell">
                  {getTotalQuantityOfBricks(sellOrders)}
                </FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData testRef="total-cell">
                  {dollarDecimal(getTotalSellOrders(sellOrders))}
                </FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData>-</FooterTableData>
                <FooterTableData>-</FooterTableData>
              </tr>
            </tfoot>
          </Table>
        ) : (
          <NoSellOrders />
        )}
      </WidgetPanel>
    );
  }

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { sellOrders, properties, account, fees, editForm } = this.props;
    var updatedEditForm = { ...editForm };

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity);

      const property = getPropertyFromOrder(properties, editForm);
      const holding = account.portfolio.records.find((record) => record.propertyCode === property.propertyCode);

      const totalBricksOwned = holding ? holding.totalBricks : 0;
      const totalBricksOnMyPendingSell = sellOrders.reduce((acc, _order) => _order.propertyCode === property.propertyCode && _order.orderId !== editForm.orderId ? acc + _order.quantity : acc, 0);
      const numOfBricksCanSell = totalBricksOwned - totalBricksOnMyPendingSell;

      if (updatedQuantityInt > numOfBricksCanSell) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `You can only sell up to ${numOfBricksCanSell::brick()}.`;
      } else {
        updatedEditForm.hasError = false;
        updatedEditForm.errorMsg = '';
      }

      updatedEditForm.total = updatedEditForm.price * updatedQuantity * (1 - fees / 100);
      updatedEditForm.quantity = updatedQuantityInt;
    } else {
      updatedEditForm.hasError = true;
      updatedEditForm.errorMsg = 'Enter a valid quantity of Bricks to sell.';
      updatedEditForm.quantity = updatedQuantity;
    }
    updatedEditForm.callback(updatedEditForm);
  };

  _onPriceChange = (event) => {
    const updatedPrice = event.target.value.replace(/,/g, '');
    this._handlePriceChange(updatedPrice);
  };

  _handlePriceChange = (updatedPrice) => {
    const { properties, fees, editForm } = this.props;
    var updatedEditForm = { ...editForm };

    if (validateTradeProposalPrice(updatedPrice)) {
      const updatedPriceFloat = Number.parseFloat(updatedPrice);

      const property = getPropertyFromOrder(properties, editForm);

      const lowestSellPriceAllowed = (property.financials.brickValue * LOWEST_SELL_PRICE_PERCENTAGE).toFixed(2);
      const brickValueTitle = property.financials.isIndependentValued
        ? 'Latest Brick Valuation'
        : 'Initial Brick Price';

      if (updatedPriceFloat < lowestSellPriceAllowed) {
        updatedEditForm.hasError = true;
        updatedEditForm.errorMsg = `Sorry, the minimum price you can list Bricks for sell in ${renderCode(property.propertyCode)} is ${lowestSellPriceAllowed::dollarDecimal()}. (${(1 - LOWEST_SELL_PRICE_PERCENTAGE)::percent()} lower than the ${brickValueTitle})`;
      } else {
        updatedEditForm.hasError = false;
        updatedEditForm.errorMsg = '';
      }

      updatedEditForm.total = updatedPriceFloat * updatedEditForm.quantity * (1 - fees / 100);
      updatedEditForm.price = updatedPrice;
    } else {
      updatedEditForm.hasError = true;
      updatedEditForm.errorMsg = 'Please enter a valid sell price for your Brick(s).';
      updatedEditForm.price = updatedPrice;
    }
    updatedEditForm.callback(updatedEditForm);
  };
}

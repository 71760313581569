import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import { breakpoint2xSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { pdsUrl } from 'src/utils/pageUrls';
import { trackInvestmentTermQuestion } from 'src/tracking/onboarding';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const LightbulbIcon = styled(Image).attrs({
  src: '/static/img/icon-light-bulb-with-house-inside.svg',
  alt: 'illustrated graph going up and down',
})`
  display: block;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint2xSmall(`
    flex-direction: row;
    justify-content: center;
  `)}
`;

const CancelButton = styled(PrimaryButton).attrs({
  color: PrimaryButton.colors.SECONDARY,
  testRef: 'cancel-button',
})`
  margin-bottom: ${gridUnit * 4}px;

  ${breakpoint2xSmall(`
    margin-bottom: 0;
    margin-right: ${gridUnit * 4}px;
  `)}
`;

const ContinueButton = styled(SecondaryButton).attrs({
  color: SecondaryButton.colors.PRIMARY,
  testRef: 'continue-anyway-button',
})``;

const MainParagraph = styled(Paragraph)`
  font-size: ${gridUnit * 6}px;
  .bullet {
    color: #222;
    margin-top: 10px;
  }
`;

const TMDWarningPopup = ({ closeThisModal, onContinue }) => (
  <ContentContainer size="small">
    <LightbulbIcon />

    <Spacing top="normal">
      <Heading>Attention!</Heading>
    </Spacing>

    <Spacing top="large">
      <MainParagraph align="center">
        You have answered one or more of the previous questions in a manner which suggests the BrickX Platform may not be suitable for you. Before proceeding with your application, and before proceeding with any acquisition of Bricks from time to time, we recommend you do each of the following:
        <ul className="bullet">
          1. Review the Target Market Determination for the product which can be found <Styledlink href={pdsUrl()}>here</Styledlink> or on the BrickX website at https://www.brickx.com/pds.
        </ul>
        <ul className="bullet">
          2. Review the Product Disclosure Statement. In particular, the risk disclosures which can be found <Styledlink href={pdsUrl()}>here</Styledlink> or on the BrickX website at https://www.brickx.com/pds.
        </ul>
        <ul className="bullet">
          3. Seek independent advice from a financial adviser.
        </ul>
      </MainParagraph>
    </Spacing>
    <Spacing top="x-large">
      <ButtonsContainer>
        <CancelButton
          onClick={() => {
            trackInvestmentTermQuestion('cancel modal');
            closeThisModal();
          }}
        >
          Cancel
        </CancelButton>

        <ContinueButton
          onClick={() => {
            closeThisModal();
            onContinue();
          }}
        >
          Continue Anyway
        </ContinueButton>
      </ButtonsContainer>
    </Spacing>
  </ContentContainer>
);

export default TMDWarningPopup;

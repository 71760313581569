export default {
  TEN: 10,
  ONE: 1,
  TWO: 2,
  ZERO: 0,
  NEGATIVE_INFINITY: -1000000000,
  ONE_HUNDRED: 100,
  TWO_HUNDREDS: 200,
  ONE_MILLION: 1000000,
};

import React, { Fragment } from 'react';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  AccentHeadingLight,
  BigSection,
  FaqRow,
  HeaderContent,
  HeaderParagraph,
  LandingPageLayout,
  NumberedPoint,
  SectionHeading,
  SocialLinkIcon,
  SocialLinksContainer,
  SocialLinkShareLabel,
  StaticIconText,
  WhyReasonsContainer,
} from 'src/components/landingPages/common/design/PageContent';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import { Heading } from 'src/design/styleguide/Headings';
import Header from 'src/design/components/header/Header';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import {
  aboutUrl,
  brickxDevelopmentLandingPageUrl,
  getFullImageAssetUrl,
} from 'src/utils/pageUrls';
import { scrollToId } from 'src/browser/window';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import { connect } from 'react-redux';
import Footer from 'src/components/footer/Footer';
import Text from 'src/design/styleguide/text/Text';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';

const BrickXDevelopmentsLandingPageHeader = connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(({ menuOpen, toggleMenu, closeMenu }) => (
  <Header
    title="Developments"
    logo={<BrickxLogoDark />}
    mobileMenuOpen={menuOpen}
    onLogoClick={closeMenu}
    onHamburgerIconClick={toggleMenu}
  >
    <TopLevelMenuItem
      onClick={() => scrollToId('what-it-is')}
      text="What is BrickX Developments?"
    />
    <TopLevelMenuItem
      onClick={() => scrollToId('why')}
      text="Why BrickX Developments?"
    />
    <TopLevelMenuItem onClick={() => scrollToId('faqs')} text="FAQs" />
    <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal />
  </Header>
));

const BrickXDevelopmentsLandingPageBody = () => {
  const pagePublicLink = `https://www.brickx.com${brickxDevelopmentLandingPageUrl()}`;
  return (
    <Fragment>
      <ContentBlock
        overlayOpacity="0.8"
        overlayColor="charcoal"
        backgroundImage={getFullImageAssetUrl('/bg/brickx-developments.jpeg')}
      >
        <ContentSection as="section">
          <ContentContainer topPadding="x-large" bottomPadding="x-large">
            <HeaderContent>
              <Heading align="left">
                <AccentHeadingLight>
                  Introducing
                  <br />
                  <strong>BrickX Developments</strong>
                </AccentHeadingLight>
              </Heading>
              <Spacing top="normal">
                <HeaderParagraph>
                  <Text color="light">
                    Development syndications are now available!
                  </Text>
                </HeaderParagraph>
              </Spacing>
              <Spacing bottom="normal">
                <HeaderParagraph>
                  <Text color="light">
                    BrickX Development syndications facilitate property
                    acquisitions, builder selection, project management and
                    future liquidity through the BrickX Platform.
                  </Text>
                </HeaderParagraph>
              </Spacing>
              <Spacing bottom="normal">
                <SocialLinksContainer>
                  <SocialLinkShareLabel>
                    <Text color="light">
                      <strong>Share On: </strong>
                    </Text>
                  </SocialLinkShareLabel>
                  <SocialLinkIcon link={pagePublicLink} social="facebook" />
                  <SocialLinkIcon link={pagePublicLink} social="linkedin" />
                  <SocialLinkIcon link={pagePublicLink} social="twitter" />
                  <SocialLinkIcon link={pagePublicLink} social="whatsapp" />
                  <SocialLinkIcon
                    link={pagePublicLink}
                    social="email"
                    icon="envelope"
                  />
                </SocialLinksContainer>
              </Spacing>
            </HeaderContent>
          </ContentContainer>
        </ContentSection>
      </ContentBlock>
      <BigSection
        id="what-it-is"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="white"
      >
        <ContentSection>
          <SectionHeading>What is BrickX Developments?</SectionHeading>
        </ContentSection>
        <Spacing top="large">
          <ContentContainer className={ListItemNumber.counterResetClass}>
            <NumberedPoint
              colorCode="darkblue"
              heading="Select your development opportunity"
              text="BrickX will assist you with the acquisition due diligence and project feasibility assessments."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="Create your syndicate and lock in your funding"
              text="You put together your syndicate of investors who commit to taking up 100% of the Bricks in the BrickX Trust relating to your chosen development."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="A BrickX Trust buys the property for your syndicate"
              text="BrickX manages the purchase, settlement, and project management of the property development."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="BrickX will handle the boring stuff"
              text="Once the property is purchased BrickX will manage the administration of your property development – this even includes the sourcing of debt finance if required."
            />
          </ContentContainer>
        </Spacing>
      </BigSection>

      <BigSection
        id="why"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="lightgrey"
      >
        <ContentSection>
          <SectionHeading>Why BrickX Developments?</SectionHeading>
        </ContentSection>
        <WhyReasonsContainer>
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-control-decision.svg"
            title="Control your purchase decision"
            text="See something you like and want to act – go ahead you now can."
          />
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-private-syndicate.svg"
            title="Keep your syndicate private"
            text="You can structure a private syndicate or open it up to other BrickX investors to create liquidity post acquisition."
          />
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-ease-and-efficient.svg"
            title="Ease and Efficiency"
            text="Rather than working out how to establish a private trust of your own and keeping the peace amongst investors you can rely on BrickX to manage this for you."
          />
        </WhyReasonsContainer>
      </BigSection>
      <BigSection id="faqs" width="large">
        <SectionHeading>FAQs</SectionHeading>
        <hr />
        <FaqRow>
          <Text className="question">Who can apply?</Text>
          <Text className="answer">
            BrickX Developments has been designed to enable BrickX members to
            choose their own property for syndication – they are also enabled to
            lead and limit the syndication to investors of their choosing –
            while also able to later open the offer up to other investors
            through the BrickX Platform should they want to.
            <br />
            Any Australian tax resident that are over the age of 18.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            How is a development approved for syndication?
          </Text>
          <Text className="answer">
            Developments are accepted subject to the acquisition and proposed
            development being deemed acceptable and in compliance with the
            BrickX Platform offering.
            <br />
            For more information please reach out to the BrickX team.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            Can Debt be used to purchase the property?
          </Text>
          <Text className="answer">
            Yes - BrickX can assist you with placing debt into the Trust to
            facilitate the property purchase. If debt is used to fund the
            initial purchase of the property the debt is held in the name of the
            BrickX Trust and not yours, the debt itself will be secured by the
            property.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">How is the property selected?</Text>
          <Text className="answer">
            The BrickX property team will review properties you select ensuring
            that the assets are acceptable to the BrickX Platform.
            <br />
            The BrickX Property Investment Management team will also assist with
            the financial modelling to assess the properties ability to cover
            its costs, as well as the costs involved in running a compliant
            trust and also to provide you with a prospective forecast in
            respects of any potential income that it may generate.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            What happens at the end of the trust term?
          </Text>
          <Text className="answer">
            The initial trust term can be up to 5 years.
            <br />
            At the end of a trust term, the standard BrickX processes will kick
            in:
            <br />A member vote will be managed where you and your syndicate
            will vote on whether you want to:
            <ul>
              <li>Sell the property;</li>
              <li>Extend the term.</li>
            </ul>
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            Can the completed property be leased to become income producing?
          </Text>
          <Text className="answer">
            Yes – retaining the property post construction can form part of the
            investment strategy proposed to investors as part of the initial
            syndication or alternatively this can be an option put forward to
            investors at the end of the initial term for them to vote on.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What documents will I need to apply?</Text>
          <Text className="answer">
            Once you have completed the initial application, BrickX will touch
            base with you to discuss your property buying strategy with you and
            the BrickX process in more detail.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            Is there a potential for liquidity in the trust?
          </Text>
          <Text className="answer">
            Yes – post completion of the development – should the investors
            choose to retain and lease the property, they can also vote to elect
            to offer investors in the syndicate to sell their bricks (units)
            between themselves or to third party investors.
          </Text>
        </FaqRow>
        <hr />
      </BigSection>
    </Fragment>
  );
};

const BrickXDevelopmentsLandingPage = () => {
  return (
    <LandingPageLayout>
      <BrickXDevelopmentsLandingPageHeader />
      <BrickXDevelopmentsLandingPageBody />
      <Footer />
    </LandingPageLayout>
  );
};

export default BrickXDevelopmentsLandingPage;

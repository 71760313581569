import PropTypes from 'prop-types';
import React from 'react';
import {
  percentDecimal,
  dollar,
  date,
  monthYear,
} from 'scripts/utilities/formatters';
import {
  propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription,
  historicalGrowthDataProvider,
  historicalGrowthTitle,
} from 'src/settings/text';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import InvestmentTypes from 'src/enums/investmentTypes';
import { historicalRBACashRate10yr } from 'src/settings/financials';
import { maximumLoanToValueRatio } from 'src/settings/financials';

const CapitalReturn = ({
  isPreorderOrComingSoonProperty,
  property,
  averageAnnualGrowthAmount,
  averageAnnualGrowthNumberOfYears,
}) => {
  const title =
    property.investmentType === InvestmentTypes.property
      ? 'Capital Returns'
      : 'Security Info';

  const isPreOrderOrNotIndependentValuedProperty =
    isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;
  const latestValueBoxTitle = isPreOrderOrNotIndependentValuedProperty
    ? 'Total Purchase Cost'
    : 'LATEST VALUE';
  const latestValueBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? date(property.platformSettlementDate)
    : monthYear(property.financials.latestValuationDate);
  const debtBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? date(property.platformSettlementDate)
    : monthYear(property.financials.latestValuationDate);

  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title={title}
      titleIcon="icn-line-chart-blue"
      linkText="See Capital Returns tab"
      link={`/investments/${property.propertyCode}/returns`}
    >
      <div className="row">
        {property.investmentType === InvestmentTypes.mortgage ? (
          <FinancialsBox
            className="col-md-4 financials-box-small"
            title={'MAX. LOAN TO VALUE RATIO'}
            value={maximumLoanToValueRatio::percentDecimal()}
            description={latestValueBoxDescription}
          />
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <FinancialsBox
            className="col-md-4 financials-box-small"
            title={'HISTORICAL RBA CASH RATE'}
            value={historicalRBACashRate10yr::percentDecimal()}
            description={'10 years average'}
          />
        ) : (
          <FinancialsBox
            className="col-md-4 five-year-historical-suburb-growth financials-box-small"
            title={historicalGrowthTitle(property.propertyType)}
            value={averageAnnualGrowthAmount}
            description={propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription(
              averageAnnualGrowthNumberOfYears,
              historicalGrowthDataProvider(property.propertyType)
            )}
            valueTestRef="capital-returns-value"
            descriptionTestRef="capital-returns-description"
          />
        )}

        {property.investmentType === InvestmentTypes.mortgage ? (
          <FinancialsBox
            className="col-md-4 financials-box-small"
            title={'LASTEST VALUE'}
            value={property.financials.latestValue::dollar()}
            description={latestValueBoxDescription}
          />
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <FinancialsBox
            className="col-md-4 financials-box-small"
            title={'LASTEST VALUE'}
            value={property.financials.latestValue::dollar()}
            description={latestValueBoxDescription}
          />
        ) : (
          <FinancialsBox
            className="col-md-4 change-in-brick-value financials-box-small"
            title={latestValueBoxTitle}
            value={property.financials.latestValue::dollar()}
            description={latestValueBoxDescription}
          />
        )}

        {property.investmentType === InvestmentTypes.mortgage ? (
          <FinancialsBox
            className="col-md-4 loan-value-ratio financials-box-small"
            title={'LOAN ADVANCE'}
            value={property.financials.liveLoanAdvance::dollar()}
            description={debtBoxDescription}
          />
        ) : property.investmentType === InvestmentTypes.financialAsset ? (
          <FinancialsBox
            className="col-md-4 loan-value-ratio financials-box-small"
            title={'LOAN ADVANCE'}
            value={property.financials.liveLoanAdvance::dollar()}
            description={debtBoxDescription}
          />
        ) : (
          <FinancialsBox
            className="col-md-4 loan-value-ratio financials-box-small"
            title={'DEBT'}
            value={property.financials.lVR::percentDecimal()}
            description={debtBoxDescription}
          />
        )}
      </div>
    </FinancialsPanel>
  );
};

CapitalReturn.propTypes = {
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
  property: propertyPropType.isRequired,
  averageAnnualGrowthFor20yr: PropTypes.string.isRequired,
};

export default CapitalReturn;
